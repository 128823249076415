import React from 'react';

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import SectionComponent from "../../general/section/section.component";
import {useStyles} from "./location-how.styles";
import HotelBlack from '../../../assets/ic-hotel-black.svg';
import BgImage from '../../../assets/group-961.svg';

const LocationHow = ({partnerContent}) => {
  const classes = useStyles();

  return (
    <>
      <SectionComponent bgImage={BgImage} size='medium'>
        <Container maxWidth='xl' className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h2'>
                Arrange the ride
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.mainBox}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5'>
                      Book the accommodation
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' className={classes.boxText}>
                      If you haven't done so already, make sure you book a stay on dates when there are v-bike
                      available.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      component={'a'}
                      target='_blank'
                      href={partnerContent.partnerUrl}
                      className={classes.button}
                    >
                      <img src={HotelBlack} alt="Icon"/>
                      <Typography className={classes.buttonText}>
                        Book a stay
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.subBox}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5'>
                      Go to your inbox
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' className={classes.boxText}>
                      You'll find a confirmation e-mail with a link to make a v-bike reservation.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.subBox}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5'>
                      Reserve a v-bike
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' className={classes.boxText}>
                      Make a reservation for <span className={classes.boxTextOrange}>EUR 35,00</span> and start mapping
                      out some exciting routes to explore. Have fun!
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
    </>
  )
};

export default LocationHow;
