import React from "react";
import {Controller, useForm, useWatch} from "react-hook-form";
import {useMutation} from "react-query";
import axios from "axios";

import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomCheckbox from "../../../atoms/checkbox/checkbox.component";
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";

import SectionComponent from "../../general/section/section.component";
import DoubleBreadCrumb from "../../general/double-bread-crumb/double-bread-crumb.component";
import {useAuth} from "../../../utils/auth";
import {useRouter} from "../../../utils/router";
import {useSnackbar} from "../../../utils/snackbar";
import {useStyles} from "./portal-bikes-report.styles";
import AttachImage from '../../../assets/ic-attach-file.svg';

const PortalBikesReport = ({type, damageCategories, currentCrumb, header, subHeader}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const {handleSubmit, control, errors, register} = useForm();

  const router = useRouter();
  const auth = useAuth();
  const classes = useStyles();
  const {setSnackbar} = useSnackbar();

  const [selected, setSelected] = React.useState(false);
  const [hasDescription, setHasDescription] = React.useState(false);
  const description = useWatch({control, name: 'description', defaultValue: ''});

  const handleDescription = () => {
    if (!description.length) {
      setHasDescription(false);
    } else if (description.length) {
      setHasDescription(true);
    }
  };

  const bikeId = router.query.id;
  const damageMutation = useMutation((data) => axios.post(`/partner/report/${bikeId}`, data));

  const onSubmit = async (data) => {
    data['partner_name'] = auth.user[0].partner_name;
    data['partner_phone'] = auth.user[0].phone_number;
    data['partner_email'] = auth.user[0].email;
    data['partner_city'] = auth.user[0].city;
    data['partner_street'] = auth.user[0].street_name;
    data['partner_street_number'] = auth.user[0].street_number;
    data['partner_postal_code'] = auth.user[0].postal_code;
    data['type'] = type;

    const formData = new FormData();
    formData.append("image", data.image[0])
    Object.entries(data).map(([key, value]) => {
      return value && formData.append(`${key}`, `${value}`);
    });

    // damageMutation.mutate(formData);

    await axios.post(`/partner/report/${bikeId}`, formData).then((res) => {
      router.push('/partner/bikes');
      setSnackbar('Problem reported.', 'success');
    }).catch((err) => {
      setSnackbar(`Something went wrong: ${err}`, 'error');
    })
  };

  return (
    <>
      <SectionComponent size='auto'>
        <Container maxWidth='lg' className={classes.root}>
          <Grid container>
            <Grid container item direction="column" xs={12} lg={7}>
              <DoubleBreadCrumb start='Our Bikes' current={currentCrumb} rerouteUrl='/partner/bikes'/>
              <Typography variant='h1' className={classes.header}>{header}</Typography>
              <Typography variant='body1' className={classes.subHeader}>
                {subHeader}
              </Typography>
              <form className={classes.formRoot} onSubmit={handleSubmit(onSubmit)}>
                <Grid container item>
                  {
                    Object.entries(damageCategories).map(([name, label]) => (
                      <Grid container item xs={12} sm={6} alignItems='center'>
                        <Controller
                          control={control}
                          name={name}
                          render={(props) => (
                            <CustomCheckbox
                              onChange={(e) => {
                                props.onChange(e.target.checked);
                                setSelected(true); // todo user can click and then unclick to enable submit button
                              }}
                              checked={props.value}
                            />
                          )}
                        />
                        <Typography variant='body1' className={classes.chbLabel}>{label}</Typography>
                      </Grid>
                    ))
                  }
                </Grid>
                <Grid container item direction='column' className={classes.textFieldContainer}>
                  <TextField
                    name='description'
                    type="text"
                    variant="outlined"
                    label='Description of the damage'
                    multiline
                    onChange={handleDescription}
                    rows={6}
                    error={errors.description ? true : false}
                    helperText={errors.description && errors.description.message}
                    inputProps={{
                      classes: {
                        root: classes.textFieldOutline,
                      }
                    }}
                    inputRef={register({
                      required: "Please enter a description."
                    })}
                  />
                  <Typography variant='caption' className={classes.descriptionRequired}>Required</Typography>
                </Grid>
                <Grid container item justify='flex-end' className={classes.buttonContainer}>
                  <Grid item xs={12} sm={4}>
                    <input
                      ref={register}
                      accept="image/*"
                      name='image'
                      className={classes.imageInput}
                      id="image"
                      type="file"
                    />
                    <label
                      htmlFor="image"
                      className={classes.buttonImage}
                    >
                      <img src={AttachImage}
                           alt='Icon'
                           className={classes.buttonImageIcon}/>
                      <Typography className={classes.buttonImageText}>
                        Attach image
                      </Typography>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      type='submit'
                      disabled={!selected || !hasDescription}
                      className={(!selected || !hasDescription) ? `${classes.buttonReport} ${classes.buttonReportDisabled}` : `${classes.buttonReport}`}
                    >
                      <Typography className={classes.buttonReportText}>
                        Report damage
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            {matches && (
              <Grid item xs={5}>
              </Grid>
            )}
          </Grid>
        </Container>
      </SectionComponent>
    </>
  )
};

export default PortalBikesReport;
