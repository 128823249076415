import React from 'react';
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";

import {Controller} from "react-hook-form";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core/styles";

import {excludeDatesArray} from "../../utils/booking";
import icDateRange from '../../assets/ic-date-range.svg';

const useStyles = makeStyles((theme) => ({
  dateRangeIcon: {
    width: 'auto',
    height: '25px',
    margin: '0 0 0 -10px',
    objectFit: 'contain',
  },
  inputField: {
    width: '100%',
  },
  input: {
    fontSize: '1rem !important',
  },
}));

const ControlledDatePicker = ({
                                control, name, emptyLabel, amountBikes,
                                bikesAndDates, defaultValue = null,
                                minDate = undefined, disabled = false,
                                chosenDates = []
                              }) => {

  const classes = useStyles();

  const excludeDate = (date) => {
    let booked = false;

    excludeDatesArray(amountBikes, bikesAndDates).forEach(d => {
      if (dayjs(date).isSame(dayjs(d), 'date')) {
        booked = true;
      }
    });
    if (chosenDates?.filter(x => x)?.some(cd => dayjs(cd)?.isSame(dayjs(date), 'date'))) {
      booked = true
    }

    return booked;
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Controller
        name={name}
        control={control}
        rules={{required: true}}
        defaultValue={defaultValue}
        render={({ref, ...rest}) => (
          <KeyboardDatePicker
            id={name}
            className={classes.inputField}
            emptyLabel={emptyLabel}
            disableToolbar
            initialFocusedDate={defaultValue}
            defaultValue={defaultValue}
            inputVariant='outlined'
            keyboardIcon={<img src={icDateRange} className={classes.dateRangeIcon} alt='Calender'/>}
            autoOk={true}
            margin="normal"
            format="dddd DD MMM YYYY"
            inputRef={ref}
            shouldDisableDate={excludeDate}
            disablePast={true}
            minDate={minDate}
            disabled={disabled}
            InputProps={{
              inputMode: "numeric",
              classes: {
                root: classes.input
              }
            }}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            {...rest}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  )
};

export default ControlledDatePicker;
