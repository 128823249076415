import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        maxWidth: 'var(--max-width-12)',
    },
    bgBox: {
        backgroundColor: '#202020',
        paddingBottom: '30vh',
    },
    root: {
        padding: '0',
    },
    headerBoxTitle: {
        fontSize: '2rem',
        textTransform: 'uppercase',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        padding: '10px 0 10px 0'
    },
    subHeader: {
        color: '#BEBEBE',
        fontWeight: "normal",
        fontSize: 18,
        paddingBottom: 20
    },
    leftSection: {
      maxWidth: '75%',
      flexBasis: '75%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        }
    },
    leftTextBox: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20%',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        }
    },
    divider: {
        height: 20,
        alignSelf: "baseline"
    },
    listItem: {
        "&::before": {
            content: "\\2022",
            color: '#12B5AD'
        }
    },
    picture: {
        width: '100%'
    },
    contactUsSection: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse !important',
        justifyContent: 'flex-end',
        maxWidth: '25%',
        flexBasis: '25%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        }
    },
    buttonLocations: {
        alignSelf: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'flex-start',
        },
        padding: '12px 16px',
        backgroundColor: '#12b5ad',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#0f9690'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        }
    },
    buttonLocationsText: {
        fontSize: '1rem',
        fontWeight: '1000',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    faqList: {
        maxWidth: '50%',
        flexBasis: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        }
    }
}));
