import React from 'react';
import SectionComponent from "../../../general/section/section.component";
import {useStyles} from './hero-content-section.styles';
import {Container, Grid} from "@mui/material";
import {Box, Typography} from "@material-ui/core";
import ZigZagDivider from "../../../../assets/v2-icons/zig-zag-divider.svg";
import TeroPicture from '../../../../assets/v2-pictures/DSC08368.jpg';
import {useQuery} from "react-query";
import {client} from "../../../../utils/contentful";

const HeroContentSectionComponent = () => {

    // Imports
    const classes = useStyles();

    // States

    // Functions

    // Declarations

    // Data retrieval
    const {data} = useQuery(
        ['landing-business-content-section'],
        async () => {
            const res = await client.getEntries({content_type: 'landingBusinessContentSection'});
            console.log(res.items);
            return res.items;
        }
    );

    return (
        <>
            {data?.map((section, index) => {
                return (
                    <SectionComponent size="auto" className={classes.root} style={{backgroundColor: '#202020 !important'}}>
                        <Box className={classes.bgBox}>
                            <Container maxWidth={false} className={classes.container}>
                                <Grid container>
                                    <Grid item className={classes.section}>
                                        <Box className={classes.leftTextBox}>
                                            <img src={ZigZagDivider} alt="Divider" className={classes.divider}/>
                                            <Typography className={classes.headerBoxTitle}>{section.fields.title}</Typography>
                                            <Typography variant='caption2' className={classes.subHeader}>
                                                {section.fields.subtitle}
                                            </Typography>
                                            <ul className={classes.list}>
                                                {section.fields.dots.map((dot, index) => {
                                                    return (
                                                            <li className={classes.listItem}>{dot}</li>
                                                        )
                                                })}
                                            </ul>
                                        </Box>
                                    </Grid>
                                    <Grid item className={classes.section}>
                                        <img src={section.fields.image.fields.file.url} alt="Picture" className={classes.picture}/>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </SectionComponent>
                )
            })}
        </>
    )
};

export default HeroContentSectionComponent;
