import React, {useEffect, useState} from 'react';
import {Card, CardContent, Grid, Divider, Box, TextField, Collapse} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import FilterDropdownObj from "../../../atoms/filter-dropdown-obj/filter-dropdown-obj.component";
import PartnerModel from "../../../models/dashboard/partner.model";
import axios from "axios";
import {useSnackbar} from "../../../utils/snackbar";
import StyledButton from "../../../atoms/styled-button/styled-button.component";
import SimpleModal from "../../../atoms/simple-modal/simple-modal.component";

const AdminRentalCard = ({bikeModel, partners, timespan}) => {
    const classes = useStyles();
    const {setSnackbar} = useSnackbar();

    const [framenumber, setFramenumber] = useState(bikeModel.framenumber !== null ? bikeModel.framenumber : 'No value found');
    const [gps_tag, setGpsTag] = useState(bikeModel.gps_tag !== null ? bikeModel.gps_tag : 'No value found');
    const [update, setUpdate] = useState(false);
    const [bikeState, setBikeState] = useState(bikeModel.bike_state);

    const [open, setOpen] = useState(false);

    const transferBike = async (partner) => {
        const bikeObj = {
            partner_id: partner._partner_id,
            partner_name: partner._partner_name,
            bike_id: bikeModel.bike_id
        }

        await axios.post(`/dashboard/bike-transfer`, bikeObj).then((res) => {
            setSnackbar(`${res.data.message}`, 'success');
        }).catch((err) => {
            setSnackbar(`${err.response.data.message}`, 'error');
        });
    }

    const updateBike = async () => {
        const gps_tag_f = (gps_tag === 'No value found' ? null : `'${gps_tag.toString()}'`);
        const framenumber_f = (framenumber === 'No value found' ? null : `'${framenumber.toString()}'`);

        const bikeInfo = {
            bike_id: bikeModel.bike_id,
            framenumber: framenumber_f,
            gps_tag: gps_tag_f
        };

        await axios.post('/dashboard/bike-update-info', bikeInfo).then((res) => {
            setSnackbar(`Bike ${bikeModel.bike_id} has been updated succesfully.`, 'success')
            setUpdate(false);
        }).catch((err) => {
            setSnackbar(`${err.response.data.message}.`, 'error');
        });
    }

    const changeBikeState = async () => {
        const newState = (bikeState === "G" ? "R" : "G");
        const data = [newState, bikeModel.bike_id]
        axios.post('/dashboard/bike-state-change', data).then(res => {
            setSnackbar(`Bike status is changed for bike ${bikeModel.bike_id}.`, 'success');
            setBikeState(newState);
        }).catch((err) => {
            setSnackbar('Something went wrong.', 'error');
        });
    }

    return (
        <Card style={{ backgroundColor: bikeModel.partner_name !== null ? '#202020' : '#545454'}}>
            <SimpleModal open={open} handleClose={() => setOpen(!open)} onConfirm={() => {changeBikeState(); setOpen(!open)}} title={`Are you sure you want to change the state of bike ${bikeModel.bike_id}?`} confirmButtonText="Change"/>
            <CardContent style={{paddingBottom: 0, paddingTop: 0}}>
                <Grid container>
                    {/*Status icon, id & hotel*/}
                    <Grid item xs>
                        <Grid container direction="column" style={{height: '100%'}}>
                            <Grid item xs={6} style={{paddingLeft: '2%'}}>
                                <Grid container direction='row' style={{height: '100%'}}>
                                    <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                                        {bikeState === "G" && <Box style={{height: 0, backgroundColor: '#7ed957', width: '50%', paddingBottom: '50%', borderRadius: 50, cursor: 'pointer'}} onClick={() => setOpen(!open)}/>}
                                        {bikeState === "R" && <Box style={{height: 0, backgroundColor: '#f93333', width: '50%', paddingBottom: '50%', borderRadius: 50, cursor: 'pointer'}} onClick={() => setOpen(!open)}/>}
                                    </Grid>
                                    <Grid item xs={10} style={{display: 'flex', alignItems: 'center', paddingLeft: '5%'}}>
                                        <Typography variant="h3" style={{color: '#fff', paddingLeft: '5%', fontStyle: 'normal', fontWeight: 100, lineHeight: 0.25}}>{bikeModel.display_number}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container style={{height: '100%'}}>
                                    <Grid item xs>
                                        <FilterDropdownObj placeholder={bikeModel.partner_name !== null ? bikeModel.partner_name : "FILL IN"} optionsArray={partners} primary onSelect={(value) => transferBike(new PartnerModel(value.key, value.name))}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*Total rides*/}
                    <Grid item xs={2}>
                        <Typography variant="h1" style={{color: '#f19413', textAlign: 'center', fontStyle: 'normal', fontWeight: 100}}>{bikeModel[`${timespan}_frequency`] ? bikeModel[`${timespan}_frequency`] : 0}</Typography>
                        <Typography style={{color: '#fff', textAlign: 'center'}}>Total rides</Typography>
                    </Grid>
                    <Divider flexItem orientation='vertical' style={{backgroundColor: '#545454', margin: 10}}/>
                    {/*Date & repairs*/}
                    <Grid item xs={2} style={{paddingLeft: '2%', paddingRight: '2%'}}>
                        <Typography variant="h7" className={classes.infoText}>Date added</Typography>
                        <Typography variant="h7" className={[classes.infoText, classes.textSecondary]}>{bikeModel.date_added !== null ? bikeModel.date_added : "01-08-2021"}</Typography>
                        <Typography variant="h7" className={classes.infoText}>Repairs</Typography>
                        <Typography variant="h7" className={[classes.infoText, classes.textSecondary]}>{bikeModel.total_repairs !== null ? bikeModel.total_repairs : 0}</Typography>

                    </Grid>
                    <Divider flexItem orientation='vertical' style={{backgroundColor: '#545454', margin: 10}}/>
                    {/*Frame & GPS*/}
                    <Grid item xs={2} style={{paddingLeft: '2%'}}>
                        <Typography variant="h7" className={classes.infoText}>Framenumber</Typography>
                        <TextField inputProps={{className: classes.input}} id="standard-basic" variant="standard" value={framenumber} onChange={(event) => {setFramenumber(event.target.value); setUpdate(true)}}/>
                        <Typography variant="h7" className={classes.infoText}>GPS-Tag</Typography>
                        <TextField inputProps={{className: classes.input}} id="standard-basic" variant="standard" value={gps_tag} onChange={(event) => {setGpsTag(event.target.value); setUpdate(true)}}/>
                    </Grid>
                </Grid>
            </CardContent>
            <Collapse in={update} timeout="auto" unmountOnExit>
                <CardContent>
                    <Grid container>
                        <Grid item xs>
                        {/*    Fill up space */}
                        </Grid>
                        <Grid item xs={2}>
                            <StyledButton primary text="Update bike" onClick={() => updateBike()}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    )
};

export const useStyles = makeStyles((theme) => ({
    infoText: {
        display: 'block',
        width: '100%',
        color: '#fff',
    },
    textSecondary: {
        color: '#12b2aa',
    },
    input: {
        color: "#12b2aa !important",
        padding: '0 !important',
    },
}));
export default AdminRentalCard;
