import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '890px',
    paddingTop: '54px',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '118px'
    }
  },
  card: {
    margin: '24px 0 16px 0',
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#222222',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '411px',
    }
  },
  leftCard: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '12px'
    }
  },
  rightCard: {
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '12px'
    }
  },
  cardMediaRoot: {
    backgroundSize: 'contain',
  },
  cardMedia: {
    objectFit: 'contain',
    width: '100%',
    height: '0',
    paddingTop: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '411px',
    }
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px'
  },
  cardHeader: {},
  rightArrow: {
    width: '24px',
    objectFit: 'contain',
  },
  mainHeader: {
    margin: '0 0 12px 0',
  },
  subHeader: {
    margin: '64px 0 16px 0',
  },
  subQuestionHeader: {
    margin: '0 0 24px 0'
  },
  keyboardIcon: {
    width: '24px',
    objectFit: 'contain',
    margin: '0 15px 0 0',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
  },
  menuItem: {
    fontSize: '1em',
  },
  partnerName: {
    margin: '24px 0 16px 0',
  },
  text: {},
  greenTextDiv: {
    marginTop: '24px',
  },
  greenText: {
    color: 'var(--java-green)',
  },
  partnerBox: {
    margin: '5px 0 0 0',
    height: '333px',
    padding: '24px',
    border: 'solid 1px #2f2f2f',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: '409px',
    }
  },
}));
