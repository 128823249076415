import React from 'react'
import {
  useFilters,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table'
import PropTypes from 'prop-types'

import TableCell from '@material-ui/core/TableCell'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableFooter from "@material-ui/core/TableFooter";

import TablePaginationActions from './utils/pagination-actions'
import TableToolbar from '../admin-toolbar/admin-toolbar.component'
import {useStyles} from "./enhanced-table.styles";
import {FilterDefaultColumn} from "./utils/filter-default-column";

const EnhancedTable = ({columns, data, skipPageReset, toolbar}) => {
  const classes = useStyles();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: FilterDefaultColumn,
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize, selectedRowIds},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: !skipPageReset,
    },
    useFilters,
    usePagination,
    useRowSelect,
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value))
  }

  const cellFieldNumerical = ['Bike ID', 'Total Frequency', 'Month Freq.', 'Total Freq.'];

  return (
    <TableContainer>
      {toolbar && (
        <TableToolbar
          numSelected={Object.keys(selectedRowIds).length}
          selectedRowIds={selectedRowIds}
          data={data}
        />
      )}
      <MaUTable {...getTableProps()} className={classes.table}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()} className={classes.columnHeaderGroup}>
              {headerGroup.headers.map(column => (
                <TableCell
                  {...(column.id === 'selection' && column.getHeaderProps())}
                  className={classes.columnHeader}
                >
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()} className={
                      cellFieldNumerical.some(h => h === cell.column.Header) ?
                        `${classes.cellField} ${classes.cellFieldNumber}`
                        :
                        `${classes.cellField}`
                    }>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className={classes.paginationText}
              rowsPerPageOptions={[
                5,
                10,
                25,
                {label: 'All', value: data.length},
              ]}
              colSpan={3}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
    </TableContainer>
  )
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
}

export default EnhancedTable
