import React from 'react';
import {useStyles} from './business-choser-mobile.styles';
import bgImage from "../../../../assets/TER00427-Edit.jpg";
import {Container} from "@mui/material";
import HeroMainNavNoPaddingComponent from "../hero-main-nav-no-padding/HeroMainNav.component";
import SectionComponent from "../../../general/section/section.component";
import Typography from "@material-ui/core/Typography";
import {Link} from "../../../../utils/router";
import {RightArrowIcon, RightArrowJavaGreen} from "../../../../assets/v2-icons";
import Button from "@material-ui/core/Button";

import {useQuery} from "react-query";
import {client} from "../../../../utils/contentful";


const BusinessChoserMobileComponent = () => {

    // Imports
    const classes = useStyles();

    // States

    // Functions
    const {data} = useQuery(
        ['business-choser-mobile'],
        async () => {
            const res = await client.getEntries({content_type: 'landingMobile'});
            return res.items[0].fields;
        }
    );

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent transparent={true} size='auto' bgImage={data?.background.fields.file.url} bgImageOpacity={1} height={'100vh'}>
                <Container maxWidth={false} className={classes.container}>
                    <HeroMainNavNoPaddingComponent noContact/>

                    <div className={classes.lowerDiv}>
                        <Typography className={classes.header}>Vesper</Typography>
                        <Typography className={classes.subHeader}>
                            {data?.subtitle}
                        </Typography>

                        <Button
                            className={classes.buttonLocationsConsumer}
                            component={Link}
                            to='/shop'
                            style={{marginTop: 120}}
                        >
                            <Typography className={classes.buttonLocationsTextConsumer}>
                                GO TO <b>SHOP</b>
                            </Typography>
                            <img src={RightArrowJavaGreen} style={{paddingLeft: 20}}/>
                        </Button>

                        <Typography className={classes.subHeader} style={{padding: 20}}>
                            or
                        </Typography>

                        <Button
                            className={classes.buttonLocations}
                            component={Link}
                            to='/business'
                        >
                            <Typography className={classes.buttonLocationsText}>
                                GO TO <b>BUSINESS WEBSITE</b>
                            </Typography>
                            <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                        </Button>

                    </div>
                </Container>
            </SectionComponent>
        </>
    )
};

export default BusinessChoserMobileComponent;
