import React from 'react';
import {Box, Grid, Typography} from "@material-ui/core";

const PartnerListItem = ({name, index, score, id}) => {
    return (
        <>
            <Grid container direction="row" style={{padding: '0.5% 2% 0.5% 2%', display: 'flex', alignItems: 'center'}}>
                {/*Index number*/}
                <Grid item xs={2}>
                    <Box style={{height: '0', width: '50%', paddingBottom: '25%', paddingTop: '25%', border: '1px solid #12b5ad', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography variant="h4" style={{fontStyle: 'normal'}}>{index}</Typography>
                    </Box>
                </Grid>
                {/*Partner name*/}
                <Grid item xs={8}>
                    <Typography variant="h5">{name}</Typography>
                </Grid>
                {/*Partner score*/}
                <Grid item xs={2}>
                    <Typography variant="h4" style={{fontStyle: 'normal', textAlign: 'center'}}>{score}</Typography>
                </Grid>
            </Grid>
        </>
    )
}
export default PartnerListItem;
