import React from "react";
import Select from "react-select";
import axios from "axios";
import {useQuery} from "react-query";

import {useSnackbar} from "../../../../utils/snackbar";
import {FilterNumberRangeColumn} from "./filter-number-range-column";
import {FilterSelectColumn} from "./filter-select-column";
import PageLoaderComponent from "../../../general/page-loader/page-loader.component";
import {Dialog} from "@material-ui/core";
import DialogTransferBike from "../../dialog-bike-transfer/dialog-transfer-bike.component";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#202020',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: '#202020',
    ":hover": {
      color: 'var(--java-green)',
      cursor: 'pointer'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--loafer-white)'
  })
};

export const columnBike = [
  {
    Header: "Bike ID",
    accessor: 'bike_id',
    Filter: FilterNumberRangeColumn,
    filter: 'between',
  },
  {
    Header: "Total Freq.",
    accessor: "total_frequency",
    Filter: FilterNumberRangeColumn,
    filter: 'between',
  },
  {
    Header: "Month Freq.",
    accessor: "month_frequency",
    Filter: FilterNumberRangeColumn,
    filter: 'between',
  },
  {
    Header: "Status",
    accessor: "state",
    Filter: FilterSelectColumn,
    filter: 'includes',
    Cell: ({row}) => {
      const {setSnackbar} = useSnackbar();
      const onItemClick = (value) => {
        const data = [value.value, row.original.bike_id]
        axios.post('/dashboard/bike-state-change', data).then(res => {
          console.log(res);
          setSnackbar('Bike status is changed.', 'success');
        }).catch((err) => {
          console.log(err);
          setSnackbar('Something went wrong.', 'error');
        });
      };

      return (
        <div style={{width: 'inherit'}}>
          <Select
            options={[
              {value: "G", label: "G"},
              {value: "R", label: "R"},
            ]}
            styles={customStyles}
            menuPortalTarget={document.body}
            onChange={onItemClick}
            placeholder={row.original.state}
          />
        </div>
      );
    },
  },
  {
    Header: "Partner name",
    accessor: "partner_name",
    width: 250,
    Cell: ({row}) => {
      const [selectedOption, setSelectedOption] = React.useState([]);
      const [transferDialing, setTransferDialog] = React.useState(false);
      const handleTransferDialog = () => setTransferDialog(!transferDialing);

      const onItemClick = (value) => {
        setSelectedOption([value.value, value.label, row.original.bike_id]);
        handleTransferDialog();
      };

      const {isLoading, data} = useQuery(
        ['dashboard-partners'],
        async () => {
          const res = await axios.get('/dashboard/get-partners')
          return res.data.rows;
        },
        {
          refetchInterval: 3000,
        }
      );

      const optionsSet = new Set();
      optionsSet.add({value: null, label: 'None'});
      data?.forEach(r => {
        optionsSet.add({value: r.partner_id, label: r.partner_name});
      });
      const options = Array.from(optionsSet);

      return (
        <>
          <div style={{width: 'inherit'}}>
            {isLoading ?
              <PageLoaderComponent/>
              :
              <Select
                options={options}
                styles={customStyles}
                menuPortalTarget={document.body}
                onChange={onItemClick}
                placeholder={row.original.partner_name}
              />
            }
          </div>
          <Dialog
            open={transferDialing}
            onClose={handleTransferDialog}
          >
            <DialogTransferBike selectedOption={selectedOption} handleTransferDialog={handleTransferDialog}/>
          </Dialog>
        </>
      );
    },
  },
]
