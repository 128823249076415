import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        maxWidth: 'var(--max-width-12)',
        padding: '20vh 0 20vh 0',
        [theme.breakpoints.down('sm')]: {
            padding: '10vh 0 10vh 0',
        },
    },
    section: {
        maxWidth: '50%',
        flexBasis: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    bgBox: {
      backgroundColor: '#202020'
    },
    root: {
        padding: '0',
    },
    headerBoxTitle: {
        fontSize: '2rem',
        textTransform: 'uppercase',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        padding: '10px 0 10px 0'
    },
    subHeader: {
        color: '#BEBEBE',
        fontWeight: "normal",
        fontSize: 18,
    },
    leftTextBox: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20%',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    divider: {
        height: 20,
        alignSelf: "baseline"
    },
    list: {
      paddingLeft: '20px !important'
    },
    listItem: {
        "&::marker": {
            color: 'var(--java-green)'
        }
    },
    picture: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            aspectRatio: 1,
            objectFit: 'cover',
            marginTop: 40
        },
    }
}));
