import React from 'react';
import HeroFooterComponent from "../b2c/hero-footer/hero-footer.component";
import HeroSmallFooterComponent from "../b2c/hero-small-footer/hero-small-footer.component";
import HeroTabComponent from "./hero-tab/hero-tab.component";
import HeroMainNavComponent from "../b2b/hero-main-nav/HeroMainNav.component";
import HeroBuySectionHeadComponent from "./hero-buy-section-head/hero-buy-section-head.component";
import {useQuery} from "react-query";
import {client} from "../../../utils/contentful";

const LandingB2cShopComponent = () => {

    const {data} = useQuery(
        ['landing-b2c-shop'],
        async () => {
            const res = await client.getEntries({content_type: 'landingShop'});
            return res.items[0].fields;
        }
    );

    return (
        <>
            <HeroMainNavComponent business={'shop'} black returnTo={'/shop'} iconRoute={'/shop'}/>
            <HeroBuySectionHeadComponent data={data}/>
            <HeroTabComponent/>
            <HeroFooterComponent data={data}/>
            <HeroSmallFooterComponent/>
        </>
    )
};

export default LandingB2cShopComponent;
