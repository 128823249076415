import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-12)',
  },
  card: {
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#222222',
    margin: '12px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '0 24px 48px 0'
    },
  },
  cardMediaRoot: {
    backgroundSize: 'contain',
  },
  cardMedia: {
    border: 'solid 1px #2f2f2f',
    objectFit: 'contain',
    height: '0',
    paddingTop: '50%',
  },
  cardContent: {
    padding: '24px',
    border: 'solid 1px #2f2f2f',
    backgroundColor: 'var(--background-surface)',
    [theme.breakpoints.up('sm')]: {
      minHeight: '150px',
    },
  },
  cardInfo: {
    margin: '8px 0 0 0',
    color: 'var(--loafer-white-soft)'
  },
}));
