import React from "react";
import axios from "axios";
import {useQuery} from "react-query";

import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Container, Typography, List, CircularProgress} from "@material-ui/core";

import {useStyles} from "./portal-bikes-overview.styles";
import {useAuth} from "../../../utils/auth";
import SectionComponent from "../../general/section/section.component";
import PortalBikeItem from "../portal-bikes-item/portal-bikes-item.component";

const PortalBikeOverview = () => {
  const auth = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const id = auth?.user[0]?.partner_id;
  const {isLoading, data} = useQuery(
    ['partner-bikes'],
    async () => {
      const res = await axios.get(`/bikes/${id}`);
      return res.data.rows;
    },
    {
      refetchInterval: 1000,
    }
  );

  return (
    <>
      <SectionComponent size={matches ? 'large' : 'normal'}  >
        <Container maxWidth="xl" className={classes.container}>
          <Typography variant='h1' className={classes.header}>Our bikes</Typography>
          {
            isLoading ?
              <CircularProgress/>
              :
              <>
                <List className={classes.list}>
                  {data.map((bike, i) => (
                    <PortalBikeItem bike={bike} index={i}/>
                  ))}
                </List>
              </>
          }
        </Container>
      </SectionComponent>
    </>
  )
};

export default PortalBikeOverview;
