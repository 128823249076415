import React from 'react';
import {useMutation} from "react-query";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {ListItem, Switch} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ModalReport from "../../../atoms/modal-bike-report/modal-bike-report.component";
import {useStyles} from './portal-bikes-item.styles';
import {useSnackbar} from "../../../utils/snackbar";

const PortalBikeItem = ({bike, index}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = useStyles();
  const {setSnackbar} = useSnackbar();

  const currentState = bike.state === 'G';
  const [bikeState, setBikeState] = React.useState(currentState);

  const [repairModal, setRepairModal] = React.useState(true);
  const handleMissingModal = () => setRepairModal(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRepairModal(true)
  };

  const mutation = useMutation((data) => axios.post(`/partner/bikes/${bike.bike_id}`, data));

  const handleChange = () => {
    setBikeState(!bikeState);
    mutation.mutate({state: bikeState});
  };

  return (
    <>
      <ListItem key={index} className={classes.listItem}>
        <Grid container alignItems='center' spacing={!matches ? 2 : 0}>
          <Grid item xs={9} sm={3}>
            <Typography variant='body1' className={classes.listName}>
              {`Vesper bike ${bike.display_number}`}
            </Typography>
          </Grid>
          {matches && (
            <Grid item sm={1} classes={{root: classes.gridListDivider}}>
              <Divider className={classes.listDivider}/>
            </Grid>
          )}
          <Grid item xs={2} sm={2} lg={1}>
            <Switch
              checked={bikeState}
              onChange={handleChange}
              color="primary"
              name="checkedB"
              inputProps={{'aria-label': 'primary checkbox'}}
            />
          </Grid>
          {!matches && (
            <Grid item xs={12} sm={1} classes={{root: classes.gridListDivider}}>
              <Divider className={classes.listDivider}/>
            </Grid>
          )}
          {matches && (
            <Grid item sm={2} lg={5}>
              <Typography variant='body1' className={classes.listBikeState}>
                {bikeState ? 'In use' : 'Out of service'}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={2} classes={{root: classes.gridListButton}}>
            <Button
              className={classes.listButton}
              onClick={handleOpen}
            >
              <Typography className={classes.listButtonText}>Report problem</Typography>
            </Button>
          </Grid>
        </Grid>
      </ListItem>
      <ModalReport
        handleMissingModal={handleMissingModal}
        repairModal={repairModal}
        bikeId={bike.bike_id}
        handleClose={handleClose}
        open={open}
        setSnackbar={setSnackbar}
      />
    </>
  )
};

export default PortalBikeItem
