import React, {useEffect} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {useQuery} from "react-query";
import axios from "axios";
import RevenueCards from "../../../atoms/revenue-cards/revenue-cards.component";
var numeral = require('numeral');

const AdminDashboardRevenue = ({timespan}) => {
    const {isLoading, data, refetch} = useQuery(
        ['dashboard-revenue'],
        async () => {
            const res = await axios.get('/dashboard/get-revenue', {params: {timespan: timespan}});
            return res.data.rows[0];
        }
    );

    useEffect(() => {
        refetch();
    }, [timespan]);

    return (
        <>
            {isLoading
                ?
                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CircularProgress size={64}/>
                    </div>
                :
                <Grid container spacing={4} style={{height: '100%', marginTop: '0.5%'}}>
                    <Grid item xs={6}>
                        <RevenueCards title="Rental - PC" amount={numeral(data.rental_pc).format('0,0.00')} color="#804ce7"/>
                    </Grid>
                    <Grid item xs={6}>
                        <RevenueCards title="Rental - iDeal" amount={numeral(data.rental_online).format('0,0.00')} color="#34a6e7"/>
                    </Grid>
                    <Grid item xs={6}>
                        <RevenueCards title="Refurbished" amount={numeral(data.total_refurbished).format('0,0.00')} color="#e75050"/>
                    </Grid>
                    <Grid item xs={6}>
                        <RevenueCards title="Retail" amount={numeral(data.retail_fatdaddy).format('0,0.00')} color="#e7ac52"/>
                    </Grid>
                    <Grid item xs={12}>
                        <RevenueCards title="Total" amount={numeral(data.total_revenue).format('0,0.00')} color="#12b5ad"/>
                    </Grid>
                </Grid>
            }
        </>
    )
}
export default AdminDashboardRevenue;
