import React from 'react'
import {useQuery} from "react-query";

import {makeStyles} from "@material-ui/core/styles";

import {useRouter} from "../../../utils/router";
import {client} from '../../../utils/contentful';

import MainNav from "../../general/main-nav/main-nav.component";
import FooterComponent from "../../general/main-footer/footer.component";
import LocationHero from "../location-hero/location-hero.component";
import PageLoaderComponent from "../../general/page-loader/page-loader.component";
import LocationCity from "../location-city/location-city.component";
import LocationPartnerInfo from "../location-partner-info/location-partner-info.component";
import LocationHow from "../location-how/location-how.component";
import LocationCalendar from "../location-calendar/location-calendar.component";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
}));

const Location = () => {
  const router = useRouter();
  const id = router.query.id;
  const classes = useStyles();

  const {isLoading, data: partnerContent} = useQuery(
    [`partnerContent${id}`],
    async () => {
      const res = await client.getEntries({
        'fields.partnerId': `${id}`,
        content_type: 'partner'
      });
      return res.items[0].fields;
    }
  );

  return (
    <div className={classes.root}>
      <MainNav transparent={true}/>
      {
        isLoading ?
          <PageLoaderComponent/>
          :
          <>
            <LocationHero partnerContent={partnerContent}/>
            <LocationCity partnerContent={partnerContent}/>
            <LocationPartnerInfo partnerContent={partnerContent}/>
            {id === '35' ? <LocationCalendar partnerId={'30'}/>
                : <LocationCalendar partnerId={id}/>
            }
            <LocationHow partnerContent={partnerContent}/>
          </>
      }
      <FooterComponent topFooter={true}/>
    </div>
  )
};

export default Location;
