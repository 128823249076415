import React from 'react';

import SectionComponent from "../../general/section/section.component";
import DoubleBreadCrumb from "../../general/double-bread-crumb/double-bread-crumb.component";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import {useStyles} from "./location-city.styles";

const LocationCity = ({partnerContent}) => {
  const classes = useStyles();

  return (
    <>
      <SectionComponent size='medium'>
        <Container maxWidth='xl' className={classes.container}>
          <DoubleBreadCrumb margin={false} start='Vesper Locations' current={partnerContent.partnerCity} rerouteUrl='/locations'/>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h1' className={classes.header}>
                Stay and explore
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.exploreInfo}>
                {partnerContent.stayAndExplore.content[0].content[0].value}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2'>
                Landscapes to discover
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.discoverInfo}>
                {partnerContent.landscapesDiscoverText}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              {partnerContent.landscapesDiscover.map((e, index) => (
                <Grid item xs={12}>
                  <Typography variant='body1'>
                    {e} <span>
                  <a
                    target='_blank' rel="noreferrer"
                    className={classes.bulletTextGreen} href='https://www.komoot.com/plan/@52.3824000,4.8995000,9z'
                  >
                  Get directions.
                  </a>
                </span>
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Grid container item xs={12}>
              <div className={classes.imageContainer}>
                <img src={partnerContent.areaImages[0].fields.file.url} className={classes.imageVertical} alt='AMS'/>
                <div className={classes.imageSubContainer}>
                  <img src={partnerContent.areaImages[1].fields.file.url} className={classes.imageHorizon} alt='Schans'/>
                  <img src={partnerContent.areaImages[2].fields.file.url} className={classes.imageHorizon} alt='Bos'/>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Divider className={classes.divider}/>
      </SectionComponent>
    </>
  )
};

export default LocationCity;
