class InvoiceModel{
    constructor(partner_id, partner_name, partner_code, partner_email, code_35_count, code_20_count) {
        this._partner_id = partner_id;
        this._partner_name = partner_name;
        this._partner_code = partner_code;
        this._partner_email = partner_email;
        {code_35_count != null ? this._code_35_count = code_35_count : this._code_35_count = 0};
        {code_20_count != null ? this._code_20_count = code_20_count : this._code_20_count = 0};
    }

    get partner_id() {
        return this._partner_id;
    }

    set code_35_count(value) {
        this._code_35_count = value;
    }

    set code_20_count(value) {
        this._code_20_count = value;
    }

    get partner_name() {
        return this._partner_name;
    }

    get partner_code() {
        return this._partner_code;
    }

    get partner_email() {
        return this._partner_email;
    }

    get code_35_count() {
        return this._code_35_count;
    }

    get code_20_count() {
        return this._code_20_count;
    }
}
export default InvoiceModel;
