import React from 'react';

import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Card, CardContent, CardMedia, Typography, Container, Grid} from "@material-ui/core";

import SectionComponent from "../../general/section/section.component";
import {useStyles} from "./become-partner-guide.styles";

import cardImage1 from '../../../assets/card-bikes-parked@2x.jpg';
import cardImage2 from '../../../assets/card-bell-phone@2x.jpg';
import cardImage3 from '../../../assets/card-laptop-coffee@2x.jpg';
import cardImage5 from '../../../assets/card-key-hand@2x.jpg';
import cardImage6 from '../../../assets/card-guest-bike@2x.jpg';
import cardImage7 from '../../../assets/card-charge-battery@2x.jpg';


const guide = [
  {
    title: 'Host our bikes for free',
    subTitle: 'We provide you with everything you need in order to start renting.',
    image: cardImage1
  },
  {
    title: 'Link with Vesper',
    subTitle: 'Link your touchpoints with Vesper so your guests can reserve the rides.',
    image: cardImage2
  },
  {
    title: 'Manage reservations',
    subTitle: 'View reservations, book for guests and request repairs with our business portal.',
    image: cardImage3
  },
  {
    title: 'Instruct the rider',
    subTitle: 'Hand over the keys to the rider and explain how they can use the bike.',
    image: cardImage5
  },
  {
    title: 'Get them going',
    subTitle: 'You’re the expert here – ask what they’d like to see and get them going.',
    image: cardImage6
  },
  {
    title: 'Charge the e-bike',
    subTitle: 'Make sure to charge the bike after use. You don’t want it to run out of juice!',
    image: cardImage7
  },
]

const BecomePartnerGuide = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <SectionComponent size='medium' >
      <Container maxWidth='xl' className={classes.container}>
        <Grid container spacing={matches ? 4 : 2} justify='center'>
          <Grid item xs={12}>
            <Typography variant='h2'>
              How to host an v-bike?
            </Typography>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            xs={12}
          >
            {
              guide.map((step, i) => (
                <Grid key={i} item xs={12} sm={6} lg={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      image={step.image}
                      classes={{root: classes.cardMediaRoot}}
                      className={classes.cardMedia}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography variant='h5'>
                        {step.title}
                      </Typography>
                      <Typography variant='body1' className={classes.cardInfo}>
                        {step.subTitle}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Container>
    </SectionComponent>
  )
};

export default BecomePartnerGuide;
