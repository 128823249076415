import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '110px 0 80px 0',
    [theme.breakpoints.up('lg')]: {
      padding: '150px 0 80px 0',
    },
  },
  list: {
    backgroundColor: 'transparent !important',
  },
  crumbRoot: {
    padding: '0 20px 180px 20px',
  },
  subHeader: {
    margin: '16px 0 32px 0',
  },
  faqGroup: {
    margin: '0 0 72px 0',
  },
  faqSubHeader: {
    // margin: '60px 0 0 0',
  },
}));
