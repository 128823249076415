import React from 'react';
import {
  CarouselProvider,
  Dot,
  Slide,
  Slider
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useStyles} from "./become-partner-carousel.styles";
import SectionComponent from "../../general/section/section.component";
import Container from "@material-ui/core/Container";

import StarIcon from '../../../assets/ic-star.svg';
import BgImage from '../../../assets/01-vesper-patterns-and-graphical-elements-05@2x.jpg';

const reviews = [
  {
    name: 'Maria',
    job: '_',
    review: '"Superb service! Vesper takes full responsibility for each part of service and delivers a very user-friendly portal portal to book bikes for our guests as well."',
    rating: 5,
  },
  {
    name: 'Peter',
    job: '_',
    review: '"Recommendable to any hotel that has our service values! This is a high-end service in everything done. No shortcuts whatsoever, very happy & exciting."',
    rating: 5,
  },
  {
    name: 'Frank',
    job: '_',
    review: '"This concept is a way to look forward again after the pandemic! A completely new experience for guests and a great service-minded team at Vesper!"',
    rating: 5,
  },
];

const BecomePartnerCarousel = () => {
  const classes = useStyles();

  return (
    <SectionComponent bgImage={BgImage}>
      <Container maxWidth='xl' className={classes.container}>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={30}
          totalSlides={3}
        >
          <Grid className={classes.carouselContainer}>
            <Grid item xs={12}>
              <Slider className={classes.reviewSlider}>
                {
                  reviews.map((r, i) => (
                    <Slide index={i} className={classes.reviewSlide}>
                      <Grid container item className={classes.reviewGrid}>
                        <Grid item xs={12}>
                          <Typography variant='body2' className={classes.reviewName}>
                            {r.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='caption'>
                            {r.job || ''}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' className={classes.reviewReview}>
                            {r.review}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {
                              [...Array(r.rating)].map((e, i) => (
                                <img key={i} src={StarIcon} className={classes.reviewRatingIcon} alt='Star'/>
                              ))
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Slide>
                  ))
                }
              </Slider>
            </Grid>
            <Grid item container xs={12} justify='center'>
              <div className={classes.dotGroup}>
                {reviews.map((r, i) => (
                  <Dot slide={i} className={classes.dot}/>
                ))}
              </div>
            </Grid>
          </Grid>
        </CarouselProvider>
      </Container>
    </SectionComponent>
  )
};

export default BecomePartnerCarousel;
