import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-8)',
    [theme.breakpoints.up('lg')]: {
      padding: '0 0 80px 0'
    }
  },
  mainHeader: {
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center'
    }
  },
  subHeader: {
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
    margin: '16px 0 32px 0'
  },
  badgeDisabled: {
    opacity: '0.32',
    color: 'var(--loafer-white)',
  },
  badgeAvailable: {
    color: 'var(--java-green)',
  }
}));

export const materialTheme = createMuiTheme({
  spacing: 6,
  palette: {
    primary: {
      main: "#12b5ad", // #charged-green
      backgroundColor: "#121212" //background-surface
    },
    secondary: {
      main: "#f1a209", // #electrical-yellow
      backgroundColor: "#0b0a07", //midnight-forest
    },
    error: {
      main: "#cf5268", // #error
    }
  },
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        backgroundColor: 'transparent'
      }
    },
    MuiPickersBasePicker: {
      container: {
        justifyContent: 'center'
      },
      pickerView: {
        minWidth: '-webkit-fill-available',
        [breakpoints.up('sm')]: {
          maxWidth: '460px',
        }
      }
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        [breakpoints.up('sm')]: {
          maxWidth: '460px',
        }
      },
      switchHeader: {
        fontSize: '1rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.25px',
        textAlign: 'center',
        color: 'var(--loafer-white)',
        [breakpoints.up('sm')]: {
          justifyContent: 'center'
        }
      },
      daysHeader: {
        display: 'none',
      },
      dayLabel: {
        color: 'var(--loafer-white)',
      },
      iconButton: {
        backgroundColor: 'transparent'
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '410px',
      }
    },
    MuiPickersDay: {
      day: {
        [breakpoints.down('xs')]: {
          width: '50px',
        },
        width: '80px',
        height: '66px',
        color: 'var(--loafer-white)',
        "&&:hover": {
          cursor: 'default'
        },
      },
      daySelected: {
        backgroundColor: 'transparent',
        color: 'var(--loafer-white)',
        "&&:hover": {
          backgroundColor: 'transparent',
          cursor: 'default'
        }
      },
      dayDisabled: {
        opacity: '0.32',
        color: 'var(--loafer-white)',
      }
    },
    MuiBadge: {
      badge: {
        fontSize: '1rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.25px',
        textAlign: 'center',
        margin: '0 0 0 42px',
        [breakpoints.down('xs')]: {
          margin: '0 0 0 27px',
        },
      }
    },
    MuiTypography: {
      body2: {
        fontSize: '1.125em',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.5px',
        textAlign: 'center',
      }
    }
  },
  typography: {
    fontFamily: "Kanit, sans-serif",
  },
})
