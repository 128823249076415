import React from 'react';
import {useStyles} from "./hero-small-footer.styles";
import SectionComponent from "../../../general/section/section.component";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

const HeroSmallFooterComponent = () => {

    // Imports
    const classes = useStyles();

    // States

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto">
                <Box className={classes.bgBox}>
                    <Container maxWidth={false} className={classes.container}>
                        <a variant='caption2' className={classes.subHeaderFooter}>Copyright Vesper 2022 - All rights reserved</a>
                        <div className={classes.links}>
                            <a variant='caption2' className={classes.subHeaderFooter} href={'https://vesperbike.com/privacy'}>Privacy statement</a>
                            <a variant='caption2' className={classes.subHeaderFooter}> | </a>
                            <a variant='caption2' className={classes.subHeaderFooter} href={''}>Cookies</a>
                            <a variant='caption2' className={classes.subHeaderFooter}> | </a>
                            <a variant='caption2' className={classes.subHeaderFooter} href={'https://vesperbike.com/terms'}>Terms & Conditions</a>

                        </div>
                    </Container>
                </Box>
            </SectionComponent>
        </>
    )
};

export default HeroSmallFooterComponent;
