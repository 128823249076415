import React, {useEffect} from 'react';
import {useStyles} from "./hero-bikes.styles";
import SectionComponent from "../../../general/section/section.component";
import {Container} from "@mui/material";
import {Box, Grid, Typography} from "@material-ui/core";
import ZigZagDivider from "../../../../assets/v2-icons/zig-zag-divider.svg";
import BookAStay from '../../../../assets/v2-pictures/DSC08374.jpg';
import DashboardPanelPicture from '../../../../assets/dashboard_reservation_panel.png';
import accentPattern from "../../../../assets/pattern.svg";
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import {RightArrowIconBlack} from "../../../../assets/v2-icons";
import Button from "@material-ui/core/Button";

const HeroBikesComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States

    // Functions
    useEffect(() => {
        console.log(data);
    }, [data]);

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto" className={classes.root}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item className={classes.leftSectionText}>
                            <Box className={classes.leftTextBox}>
                                <Typography className={classes.header}><a className={classes.vesperBlue}>VESPER</a><br/> E-BIKEs</Typography>
                                <Typography variant='caption2' className={classes.subHeader}>
                                    {data?.slidePictureSubtitle}
                                </Typography>
                                {/*<div style={{display: 'flex', flexDirection: 'row'}}>*/}
                                {/*    <Button*/}
                                {/*        className={classes.buttonLocations}*/}
                                {/*        style={{backgroundColor: '#fff'}}*/}
                                {/*        component={Link}*/}
                                {/*        to='/shop/continue'*/}
                                {/*    >*/}
                                {/*        <Typography className={classes.buttonLocationsText} style={{color: '#000'}}>*/}
                                {/*            Learn more*/}
                                {/*        </Typography>*/}
                                {/*        <img src={RightArrowIconBlack} style={{paddingLeft: 20}}/>*/}
                                {/*    </Button>*/}
                                {/*    <Button*/}
                                {/*        className={classes.buttonLocations}*/}
                                {/*        component={Link}*/}
                                {/*        to='/shop/continue'*/}
                                {/*    >*/}
                                {/*        <Typography className={classes.buttonLocationsText}>*/}
                                {/*            Learn more*/}
                                {/*        </Typography>*/}
                                {/*        <img src={RightArrowIcon} style={{paddingLeft: 20}}/>*/}
                                {/*    </Button>*/}
                                {/*</div>*/}

                            </Box>
                        </Grid>
                        <Grid item className={classes.rightSectionPicture}>
                            <Box className={classes.rightSection}>
                                <img src={data?.slidePicture.fields.file.url} alt="Hotel picture" className={classes.hotelPicture}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroBikesComponent;
