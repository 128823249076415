import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  rightArrow: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  },
  breadCrumbs: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '40px 0 24px 0',
  },
  breadCrumbsBottomFree: {
    margin: '0',
  },
  breadCrumb: {
    fontSize: '1rem',
    letterSpacing: '0.44px',
    color: 'var(--java-green)',
    textDecoration: 'none',
    padding: 0,
    display: 'flex',
    alignItems: 'flex-end',
  },
  backCrumb: {
    cursor: 'pointer',
    "&&:hover": {
      color: 'rgba(18,181,173,0.5)'
    },
  },
}));
