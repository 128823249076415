import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: '24px 0',
    padding: '16px 24px 16px 26px',
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#222222',
    height: '160px',
    [theme.breakpoints.up('sm')]: {
      width: '758px',
      height: '80px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1062px',
      height: '80px',
    }
  },
  listName: {
    width: '150px',
    height: '27px',
    fontWeight: 'bold',
    color: 'var(--loafer-white-soft)',
  },
  gridListDivider: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '3.33%'
    }
  },
  listDivider: {
    opacity: '0.12',
    backgroundColor: 'var(--loafer-white)',
    width: 'auto',
    height: '1px',
    [theme.breakpoints.up('sm')]: {
      width: '1px',
      height: '48px',
    }
  },
  listBikeState: {
    width: '130px',
    height: '27px',
    color: 'var(--loafer-white)',
    [theme.breakpoints.up('sm')]: {
      margin: '0 0 0 18px',
    }
  },
  gridListButton: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '90px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
    }
  },
  listButton: {
    height: '48px',
    padding: '12px 16px',
    width: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      width: '182px',
    }
  },
  listButtonText: {
    width: '150px',
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--java-green)',
  },
}));
