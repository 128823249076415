import React from 'react';

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Container from "@material-ui/core/Container";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

import MainNav from "../../general/main-nav/main-nav.component";
import FooterComponent from "../../general/main-footer/footer.component";
import SectionComponent from "../../general/section/section.component";
import {useStyles} from "./privacy-policy.styles";

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <>
      <MainNav/>
      <SectionComponent size='large'>
        <Container maxWidth='lg'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h1'>
                Privacy Policy
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Vesper is committed to providing excellent service to all users, which includes respecting their
                concerns about privacy and ensuring We take the relevant measures to safeguard their personal data in
                accordance with the applicable regulations of the Netherlands. We have provided this Privacy Policy to
                help you, the user, understand how We collect, use and protect your personal data when you visit and use
                Vesper’s website, mobile applications, social media pages, and other technological platforms in whatever
                form, and how that information will be treated. Please read the following carefully to understand Our
                views and practices regarding your personal data and how We will treat and use it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                This Privacy Policy should be read together with our terms and conditions and any other documents referred
                to herein.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                How we collect and use the personal data?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                We collect and use personal data held about you in the following ways:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Some of Our services may require you to sign-up and create an account or provide personal data. In such
                circumstances, We will request personal data such as your name, email address, telephone number, etc. By
                providing this personal data, you agree that We may:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Use it to provide you with the information and services that you request from Us;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Use it to respond to your questions, comments and requests;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Use it to notify you about changes to one or all of Our Platforms; and
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Use it to ensure that content from Our Platforms is presented in the most effective manner for you
                    and for your devices.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                What Information Do We collect?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Information that We collect (which may be collected automatically in some circumstances) may include:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Device event information such as crashes, system activity, hardware settings, browser type, browser
                    language, the date and time of your request and referral URL;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Cookies or similar technologies that may uniquely identify your browser or any of your accounts on
                    Our Platforms;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Information (including personal data) locally on your device using mechanisms such as browser web
                    storage and application data caches.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                In addition to the above, We may require you to provide certain other personal data to sign-up or create
                an account with Us.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Why do We collect your information?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                We collect your information to:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Administer Our Platforms and for internal operations, including troubleshooting, data analysis,
                    testing, research, statistical and survey purposes;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Offer you tailored content;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Improve Our Platforms to ensure that content is presented in the most effective manner for you and
                    for your devices;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Keep Our Platforms safe and secure;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <FiberManualRecordIcon classes={{root: classes.listIcon}} size='small' color='primary'/>
                  </ListItemIcon>
                  <ListItemText variant='body1'>
                    Offer you access to certain products and services.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                To Whom May We disclose your personal information?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Vesper may disclose your personal information to government bodies, law enforcement agencies and other
                institutions that have the authority to request such information.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                You can also choose not to provide certain personal information that We request. Kindly note that his
                will not affect your ability to access and enjoy Our Platforms, but you may not be able to access
                certain options or services.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Cookies and Tracking Technologies
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                In some cases, We may also collect your personal data through the use of cookies. The term “cookie”
                refers to a small piece of text which is downloaded and stored on your hard drive. This cookie will not
                be stored on your browser without your permission; cookies only occupy a minimal part of your hard
                drive. When you access Our Platforms, We may send a cookie to your device. There are other forms of
                tracking technologies We may use, that may be used in conjunction with cookies, such as pixel tags.
                These cookies and other tracking technologies enable Us to recognize your device and customize the
                online experience. It also helps Us keep track of the services you view, so that We can send you news
                about those services. We also use cookies to measure traffic patterns, to determine which areas of Our
                Websites have been visited, and to measure transaction patterns in the aggregate. We use this to
                research Our users’ habits so that We can improve Our services. If you do not wish to receive cookies,
                you can set your browser so that your device does not accept them.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Consent
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                By using Our Platforms, you agree to be bound by the terms of this Privacy Policy and to Vesper using
                your personal data in the manner described in this Privacy Policy.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Disclaimer
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Vesper is not responsible if information made available on Our Platforms is not accurate, complete or
                current. The information provided is for general information purposes only. Any reliance you place on
                such information is strictly at your own risk. You agree that it is your responsibility to monitor
                changes on Our Platforms.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                You agree to indemnify, defend and hold harmless Vesper and its affiliates and their officers,
                directors, employees, contractors, agents, licensors from and against any and all losses, liabilities,
                expenses, damages and costs, including reasonable attorneys' fees and court costs, arising or resulting
                from your use of the Our Platforms and any violation of this Privacy Policy and Our Terms & Conditions
                of Use. If you cause a technical disruption to any of Our Platforms or the systems transmitting the
                Platforms to you or others, you agree to be responsible for any and all losses, liabilities, expenses,
                damages and costs, including reasonable attorneys' fees and court costs, arising or resulting from that
                disruption.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>

              </Typography>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
      <FooterComponent topFooter={false}/>
    </>
  )
};

export default PrivacyPolicy;
