import React from "react";
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";

import {Badge} from "@material-ui/core";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core/styles";

import icDateRange from '../../assets/ic-date-range.svg';
import {bikesDates} from "../../utils/booking";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available'
    }
  },
  dateRangeIcon: {
    width: '24px',
    margin: '0 0 0 50px',
    objectFit: 'contain',
    "&&:hover": {
      opacity: '0.7'
    }
  },
  input: {
    fontSize: '1rem !important',
  },
  dot: {
    marginTop: '8px',
    marginRight: '8px',
  }
}));

const PortalReservationDatePicker = ({chosenDate, setChosenDate, data}) => {
  const classes = useStyles();
  const [selectedDays, setSelectedDays] = React.useState([]);
  const bookedDays = bikesDates(data);
  const currentBookedDays = [];

  const portalCalendarBadges = (date) => {
    return new Promise(resolve => {
      bookedDays.forEach((bookedDay) => {
        bookedDay.dates.forEach((day) => {
          if (day.get('month') === dayjs(date).get('month') && day.get('year') === dayjs(date).get('year')) {
            currentBookedDays.push(day.get('date'));
          }
        })
      });
      setSelectedDays(currentBookedDays);
      resolve();
    });
  };

  const handleMonthChange = (date) => {
    return portalCalendarBadges(date)
  };

  const onOpen = () => {
    return portalCalendarBadges(chosenDate);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <KeyboardDatePicker
          autoOk={true}
          disableToolbar={true}
          className={classes.datePicker}
          keyboardIcon={<img src={icDateRange} className={classes.dateRangeIcon} alt='Calendar'/>}
          inputVariant='outlined'
          label="Date"
          format="dddd DD MMM YYYY"
          onOpen={onOpen}
          value={chosenDate}
          onChange={setChosenDate}
          onMonthChange={handleMonthChange}
          renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
            const date = dayjs(day).get('date');
            const isSelected = dayInCurrentMonth && selectedDays.includes(date);

            return <Badge
              invisible={!isSelected}
              variant='dot'
              color='primary'
            >
              {dayComponent}
            </Badge>;
          }}
          InputProps={{
            classes: {
              root: classes.input
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
}

export default PortalReservationDatePicker;
