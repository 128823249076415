import React from "react";
import AuthSectionComponent from "../auth-section/auth-section.component";
import {useRouter} from "../../../utils/router.js";
import image from '../../../assets/group-961.svg';

const Auth = () => {
  const router = useRouter();

  return (
    <AuthSectionComponent
      bgImage={image}
      type={router.query.type}
      afterAuthPath={router.query.next || "/partner/:page"}
    />
  );
}

export default Auth;
