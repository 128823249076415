import React, {useEffect, useRef, useState} from 'react';
import {useStyles} from "./hero-footer.styles";
import {Box, Typography} from "@material-ui/core";
import {Container, Grid} from "@mui/material";
import Button from "@material-ui/core/Button";
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import SectionComponent from "../../../general/section/section.component";
import Pattern from '../../../../assets/group-961.svg';
import FacebookIcon from '../../../../assets/social-facebook.svg';
import LinkedInIcon from '../../../../assets/social-linked-in.svg';
import InstagramIcon from '../../../../assets/social-instagram.svg';

const HeroFooterComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States
    const backgroundRef = useRef();
    const [backgroundHeight, setBackgroundHeight] = useState(300);

    // Functions
    const checkForDevice = () => {
        let windowWidth = window.innerWidth;
        if (windowWidth < 767.98) {
            return true;
        } else {
            return false;
        }
    };

    // Declarations

    // Data retrieval
    useEffect(() => {
        if (checkForDevice()){
            setBackgroundHeight(backgroundRef.current.clientHeight ? backgroundRef.current.clientHeight + 200 : 300);
        } else {
            setBackgroundHeight(backgroundRef.current.clientHeight ? backgroundRef.current.clientHeight : 300);
        }
    }, [backgroundRef.current]);

    return (
        <>
            <SectionComponent size="auto">
                <Box className={classes.bgBox} style={{backgroundImage: `url(${Pattern})`}}>
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box className={classes.partnerUp}>
                                    <div className={classes.background} style={{height: backgroundHeight}}>
                                        <Typography className={classes.backgroundV}>V</Typography>
                                    </div>
                                    <Grid container className={classes.grid} ref={backgroundRef}>
                                        <Grid item className={classes.leftSection}>
                                            <div className={classes.headerSection}>
                                                <Typography className={classes.header} style={{color: '#000'}}>Interested in&nbsp;</Typography>
                                                <Typography className={classes.header}>partnering up?</Typography>
                                            </div>
                                            <Typography variant='caption2' className={classes.subHeader}>
                                                {data?.partnerUpSubtitle}
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.rightSection}>
                                            <Button
                                                className={classes.buttonLocations}
                                                component={Link}
                                                to='/contact'
                                            >
                                                <Typography className={classes.buttonLocationsText}>
                                                    Contact Us
                                                </Typography>
                                                <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid container style={{paddingTop: '5vh'}}>
                                    <Grid item className={classes.sectionThree}>
                                        <Typography className={classes.footerHeader}>FOR THE RIDERS</Typography>
                                        <a variant='caption2' className={classes.subHeaderFooter} href={'/booking'}>Reserve a ride</a>
                                        <a variant='caption2' className={classes.subHeaderFooter} href={'/faq'}>FAQ</a>
                                    </Grid>
                                    <Grid item className={classes.sectionThree}>
                                        <Typography className={classes.footerHeader}>FOR THE PARTNERS</Typography>
                                        <a variant='caption2' className={classes.subHeaderFooter} href={"/become-partner"}>Become a partner</a>
                                        <a variant='caption2' className={classes.subHeaderFooter} href={"/auth/signin?next=/partner"}>Business Portal</a>
                                    </Grid>
                                    <Grid item xs={3}/>
                                    <Grid item className={classes.sectionThree}>
                                        <Typography className={classes.footerHeader}>FOllOW OUR SOCIALS</Typography>
                                        <Typography variant='caption2' className={classes.subHeaderFooter}>Get inspired and stay up to date via our social channels</Typography>
                                        <div className={classes.socials}>
                                            <a href="src/modules/guest-v2/b2b/hero-footer/hero-footer.component">
                                                <img src={InstagramIcon} alt="Instagram icon"/>
                                            </a>
                                            <a href="src/modules/guest-v2/b2b/hero-footer/hero-footer.component">
                                                <img src={LinkedInIcon} alt="Linkedin icon"/>
                                            </a>
                                            <a href="src/modules/guest-v2/b2b/hero-footer/hero-footer.component">
                                                <img src={FacebookIcon} alt="Facebook icon"/>
                                            </a>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </SectionComponent>
        </>
    )
};

export default HeroFooterComponent;
