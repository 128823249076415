import React, {useState} from 'react';
import {Grid} from "@material-ui/core";
import StyledButton from "../../../atoms/styled-button/styled-button.component";
import StyledCircle from "../../../atoms/styled-circle/styled-circle.component";
import {useQuery} from "react-query";
import axios from "axios";
import PageLoaderComponent from "../../general/page-loader/page-loader.component";
import SimpleMoveForm from "../../../atoms/simple-move-form/simple-move-form.component";

const AdminDashboardInventory = () => {
    const [open, setOpen] = useState(false);
    const [openMove, setOpenMove] = useState(false);

    const {isLoading, data, refetch} = useQuery(
        ['dashboard-inventory'],
        async () => {
            const res = await axios.get('/dashboard/get-inventory');
            return res.data.rows;
        }
    );

    const getCountPerLocation = (locationReq) => {
        if (data.find(({location}) => location === locationReq)){
            return (data.find(({location}) => location === locationReq).count);
        } else {
            return 0;
        }
    }

    return (
        <>
            <SimpleMoveForm newBike open={open} handleClose={() => {setOpen(!open); refetch()}} title="Add V-bike(s)"/>
            <SimpleMoveForm dataInv={data} open={openMove} handleClose={() => {setOpenMove(!openMove); refetch()}} title="Move V-bike(s)"/>
            {/* Button section*/}
            <Grid container justify="flex-end" spacing={2}>
                <Grid item xs={3}>
                    <StyledButton text="Add" primary onClick={() => setOpen(!open)}/>
                </Grid>
                <Grid item xs={3}>
                    <StyledButton text="Move" onClick={() => setOpenMove(!openMove)}/>
                </Grid>
            </Grid>

            {/*Info sections*/}
            {isLoading ?
                <PageLoaderComponent/>
                :
                <Grid container spacing={4} style={{height: '90%', display: "flex", alignItems: "center"}}>
                    <Grid item xs={3}>
                        <StyledCircle name="Rental" count={getCountPerLocation('Rental')}/>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledCircle name="Fatdaddy" count={getCountPerLocation('Fatdaddy')}/>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledCircle name="Available Ref." count={getCountPerLocation('Available Ref.')}/>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledCircle name="Warehouse" count={getCountPerLocation('Warehouse')}/>
                    </Grid>
                </Grid>
            }
        </>
    )
}
export default AdminDashboardInventory;
