import React from 'react';
import {FormControl, MenuItem, Select} from "@material-ui/core";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FilterDropdown = ({placeholder, optionsArray, primary, onSelect, disabled, listItem}) => {
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {target: { value }} = event;
        const string = typeof value === 'string' ? value.split(',') : value;
        setPersonName(string);
        onSelect(string[0])
    };

    return (
        <FormControl fullWidth style={{backgroundColor: listItem ? '#545454' : '#202020', textAlign: listItem && 'center', borderRadius: 4, height: '100%', paddingLeft: '5%', paddingRight: '5%'}}>
            <Select
                displayEmpty
                disabled={disabled}
                style={{height:'100%', color: primary && 'rgb(0, 217, 225)'}}
                labelId="simple_dropdown_label"
                id="simple_dropdown"
                value={personName}
                renderValue={(selected) => {
                    if (selected.length === 0 && primary) {
                        return <em style={{color: 'rgb(0, 217, 225)'}}>{placeholder}</em>;
                    } else if (selected.length === 0){
                        return <em>{placeholder}</em>;
                    }

                    return <em style={{color: primary && 'rgb(0, 217, 225)'}}>{selected}</em>;
                }}
                onChange={handleChange}
                IconComponent={() => (listItem ? <></> : <ArrowDropDownIcon style={{color:'white'}}/>)}
            >
                {optionsArray?.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default FilterDropdown;
