import React from 'react';
import dayjs from "dayjs";
import {useStateMachine} from "little-state-machine";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import MainNav from "../../general/main-nav/main-nav.component";
import SectionComponent from "../../general/section/section.component";
import {useRouter} from "../../../utils/router";
import {useStyles} from "./booking-success.styles";

import CheckIcon from '../../../assets/ic-check-circle-large.svg';

const BookingSuccess = () => {
  const classes = useStyles();
  const router = useRouter();
  const {state} = useStateMachine();

  const {first_name, last_name, amount_bikes, partner_name, amount, start_date} = state.bookingDetails;

  return (
    <>
      <MainNav bookingGuest transparent={false}/>
      <SectionComponent size='large'>
        <Container className={classes.container}>
          <Grid container classes={{root: classes.rootGrid}}>
            <Grid item xs={12}>
              <img src={CheckIcon} alt='Success' className={classes.checkIcon}/>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.greetingText}>
                Hi {first_name} {last_name},
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' className={classes.titleText}>
                Thank you for booking with Vesper.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption' className={classes.subHeader}>
                Booking details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' className={classes.bookingInfoText}>
                To summarise you paid EUR {amount} for renting {amount_bikes} {amount_bikes > 1 ? 'e-bikes' : 'e-bike'} during your stay
                in {partner_name} on {dayjs(start_date).format('dddd')}, {dayjs(start_date).format('DD-MM-YYYY')}.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.bookingThank}>
                We hope you'll enjoy your adventurous ride. We're looking forward to hearing your experiences!
              </Typography>
            </Grid>
            <Grid container item xs={12} classes={{root: classes.buttonGridGroup}}>
              <Grid item xs={12}>
                <Button
                  onClick={() => router.replace('/')}
                  className={classes.buttonHome}
                >
                  <Typography className={classes.buttonHomeText}>
                    Go to Homepage
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
    </>
  )
};

export default BookingSuccess;
