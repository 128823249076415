import React from "react";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1200,
    xl: 1920,
  },
};

const breakpoints = createBreakpoints({...customBreakpoints});

// Select field
export const selectTheme = createMuiTheme({
  spacing: 6,
  palette: {
    primary: {
      main: "#12b5ad", // #charged-green
      backgroundColor: "#121212" //background-surface
    },
    secondary: {
      main: "#f1a209", // #electrical-yellow
      backgroundColor: "#0b0a07", //midnight-forest
    },
    error: {
      main: "#cf5268", // #error
    }
  },
  typography: {
    fontFamily: "Kanit, sans-serif",
  },
  overrides: {
    MuiInputLabel: {
      root: {
        "&&": {
          color: 'rgba(249,252,246, 0.32)',
        }
      },
      shrink: {
        color: "var(--java-green) !important"
      },
    },
    MuiInputBase: {
      input: {
        "&&": {
          color: 'var(--loafer-white-soft)'
        }
      }
    },
    MuiSelect: {
      root: {
        "&&": {
          color: 'rgba(249,252,246, 0.32)',
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        "&&": {
          borderColor: 'rgba(249,252,246, 0.32)',
        }
      }
    },
    MuiList: {
      root: {
        "&&": {
          backgroundColor: '#2e2e2e',
        }
      }
    },
    MuiMenuItem: {
      root: {
        "&&": {
          fontSize: '1em',
          color: 'var(--loafer-white-soft)'
        },
        '&&:hover': {
          backgroundColor: 'rgba(249,252,246, 0.04)',
          color: 'var(--loafer-white-soft)'
        }
      },
    },
    MuiSvgIcon: {
      root: {
        visibility: 'hidden'
      },
    }
  }
});

// Global Styling
const themeConfig = {
  common: {
    palette: {
      background: {
        default: '#121212',
        main: '#202020'
      },
      primary: {
        main: "#12b5ad", // #charged-green
        backgroundColor: "#121212" //background-surface
      },
      secondary: {
        main: "#f1a209", // #electrical-yellow
        backgroundColor: "#0b0a07", //midnight-forest
      },
      error: {
        main: "#cf5268", // #error
      }
    },
    typography: {
      fontFamily: "Kanit, sans-serif",
      fontSize: '16px',
      h1: {
        [breakpoints.up('sm')]: {
          fontSize: '3em',
          letterSpacing: '-0.69px',
        },
        fontSize: '2.25em',
        letterSpacing: '-0.58px',
        lineHeight: '1.25',
        fontWeight: 'bold',
        fontStyle: 'italic',
        textTransform: 'uppercase',
        color: 'var(--loafer-white)',
      },
      h2: {
        [breakpoints.up('sm')]: {
          fontSize: '2.4375em',
          letterSpacing: '-0.3px',
        },
        fontSize: '2em',
        letterSpacing: '-0.25px',
        lineHeight: '1.25',
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: 'var(--loafer-white-soft)',
        textTransform: 'uppercase',
      },
      h3: {
        [breakpoints.up('sm')]: {
          fontSize: '2.5em',
          letterSpacing: '0',
        },
        fontSize: '1.75rem',
        letterSpacing: '0',
        lineHeight: 'normal',
        fontWeight: '600',
        fontStyle: 'italic',
        color: 'var(--loafer-white-soft)',
        textTransform: 'uppercase',
      },
      h4: {
        fontSize: '1.5625em',
        letterSpacing: '-0.17px',
        lineHeight: '1.25',
        fontWeight: '600',
        fontStyle: 'italic',
        color: 'var(--loafer-white-soft)',
        textTransform: 'uppercase',
      },
      h5: {
        fontSize: '1.5rem',
        letterSpacing: 'normal',
        lineHeight: '1.25',
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: 'var(--loafer-white-soft)',
        textTransform: 'uppercase',
      },
      body1: {
        fontSize: '1.125rem',
        letterSpacing: '0.5px',
        lineHeight: '1.5',
        fontWeight: '300',
        fontStyle: 'normal',
      },
      body2: {
        fontSize: '1em',
        letterSpacing: '0.25px',
        lineHeight: '1.5',
        fontWeight: '300',
        fontStyle: 'normal',
        color: 'var(--loafer-white-soft)',
      },
      caption: {
        fontSize: '0.75rem',
        letterSpacing: '0.37px',
        color: 'var(--loafer-white-soft)',
      },
    },
    breakpoints: {
      ...customBreakpoints
    },
    // Override component styles
    overrides: {
      // Global styles
      MuiButton: {
        root: {
          "&&": {
            border: 'none'
          }
        }
      },
      MuiInputLabel: {
        root: {
          "&&": {
            color: 'rgba(249,252,246, 0.32)',
          }
        },
        shrink: {
          color: "var(--java-green) !important"
        },
      },
      MuiInputBase: {
        input: {
          "&&": {
            fontSize: '1em',
            color: 'var(--loafer-white-soft)'
          }
        }
      },
      MuiSelect: {
        root: {
          "&&": {
            color: 'rgba(249,252,246, 0.32)',
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          fontSize: '1em',
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: "var(--java-green)"
          }
        },
        notchedOutline: {
          "&&": {
            borderColor: 'rgba(249,252,246, 0.32)',
          },
        }
      },
      MuiList: {
        root: {
          "&&": {
            backgroundColor: '#2e2e2e',
          }
        }
      },
      MuiMenuItem: {
        root: {
          "&&": {
            color: 'var(--loafer-white-soft)'
          },
          '&&:hover': {
            backgroundColor: 'rgba(249,252,246, 0.04)',
            color: 'var(--loafer-white-soft)'
          }
        },
      },
      MuiFormLabel: {
        root: {
          "&&": {
            fontSize: '1em',
          }
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '16px',
          fontWeight: '200',
          backgroundColor: 'var(--information)',
        },
        arrow: {
          "&&:before": {
            backgroundColor: 'var(--information)',
          }
        }
      },
      MuiSvgIcon: {
        root: {
          width: '1.5rem',
          height: '1.5rem',
          '&$checked': {
            width: '1.5rem',
            height: '1.5rem',
          },
        }
      },
      MuiBadge: {
        root: {
        },
        dot: {
          marginTop: '8px',
          marginRight: '8px',
        }
      },
      MuiCssBaseline: {
        "@global": {
          "#root": {
            // Flex column that is height
            // of viewport so that footer
            // can push self to bottom by
            // with auto margin-top
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            // Prevent child elements from
            // shrinking when content
            // is taller than the screen
            // (quirk of flex parent)
            "& > *": {
              flexShrink: 0,
            },
            overflowX: 'hidden',
          },
        },
      },
    },
  },
};

function getTheme() {
  // Create MUI theme from themeConfig
  return createMuiTheme({
    ...themeConfig.common,
    overrides: {
      ...(themeConfig.common && themeConfig.common.overrides),
    },
  });
}

export const ThemeProvider = (props) => {
  // Get MUI theme object
  const theme = getTheme();

  return (
    <MuiThemeProvider theme={theme}>
      {/* Set global MUI styles */}
      <CssBaseline/>
      {props.children}
    </MuiThemeProvider>
  );
};

