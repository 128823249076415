import React from 'react';
import {useStyles} from "./hero-concept-overlay-sections.styles";
import {Container, Box, Grid, Typography} from "@material-ui/core";
import SectionComponent from "../../../general/section/section.component";
import ElectricIcon from '../../../../assets/v2-icons/electric.svg';
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import Button from "@material-ui/core/Button";
import accentPattern from "../../../../assets/pattern.svg";

const HeroConceptOverlaySectionsComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto" className={classes.root}>
                <Container maxWidth={false} className={classes.container}>
                    <Box className={classes.box}>
                        <Grid container className={classes.gridContainer}>
                            <Grid item xs>
                                <Box className={classes.gridItem}>
                                    <img src={ElectricIcon} alt="Electric icon" className={classes.icon}/>
                                    <Typography className={classes.header}>{data?.headerSpecification1Title}</Typography>
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        {data?.headerSpecification1Subtitle}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <Box className={classes.gridItem}>
                                    <img src={ElectricIcon} alt="Electric icon" className={classes.icon}/>
                                    <Typography className={classes.header}>{data?.headerSpecification2Title}</Typography>
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        {data?.headerSpecification2Subtitle}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <Box className={classes.gridItem}>
                                    <img src={ElectricIcon} alt="Electric icon" className={classes.icon}/>
                                    <Typography className={classes.header}>{data?.headerSpecification3Title}</Typography>
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        {data?.headerSpecification3Subtitle}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <Box className={classes.gridItem}>
                                    <img src={ElectricIcon} alt="Electric icon" className={classes.icon}/>
                                    <Typography className={classes.header}>{data?.headerSpecification4Title}</Typography>
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        {data?.headerSpecification4Subtitle}
                                    </Typography>
                                </Box>
                                <Button
                                    className={classes.buttonLocations}
                                    component={Link}
                                    to='/contact'
                                >
                                    <Typography className={classes.buttonLocationsText}>
                                        Contact Us
                                    </Typography>
                                    <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <div className={classes.patternDiv}>
                        <img
                            src={accentPattern}
                            alt="Pattern"
                            className={classes.pattern}
                        />
                    </div>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroConceptOverlaySectionsComponent;
