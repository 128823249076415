import React from 'react';

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import SectionComponent from "../../general/section/section.component";
import LocationImageCarousel from "../location-image-carousel/location-image-carousel.component";
import {useStyles} from "./location.partner.info.styles";

const LocationPartnerInfo = ({partnerContent}) => {
  const classes = useStyles();

  return (
    <>
      <SectionComponent size='small'>
        <Container maxWidth='xl' className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h2'>
                About the {partnerContent.partnerName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.descriptionPartner}>
                {partnerContent.aboutHotel.content[0].content[0].value}
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{root: classes.roomImageGrid}}>
              <LocationImageCarousel hotelImages={partnerContent.hotelImages}/>
            </Grid>
            <Grid item xs={12} classes={{root: classes.infoButtonGrid}}>
              <Button
                component={'a'}
                target="_blank"
                href={partnerContent.partnerUrl}
                className={classes.infoButton}
              >
                <Typography className={classes.infoButtonText}>
                  More information
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
      <Divider className={classes.divider}/>
    </>
  )
};

export default LocationPartnerInfo;
