import React from "react";
import {useForm} from "react-hook-form";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import AuthFooterComponent from "../auth-footer/auth-footer.component";
import {useAuth} from "../../../utils/auth.js";
import {useStyles} from "./auth-form.styles";
import {useRouter} from "../../../utils/router";

const adminEmails = [process.env.REACT_APP_EMAIL_ADMIN_1, process.env.REACT_APP_EMAIL_ADMIN_2, process.env.REACT_APP_EMAIL_ADMIN_3];

function AuthFormComponent(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const nextPath = router.query.next;

  const [pending, setPending] = React.useState(false);
  const {handleSubmit, register, errors, getValues} = useForm();

  const submitHandlersByType = {
    signin: ({email, pass}) => {
      if (nextPath.includes('/dashboard') && !adminEmails.includes(email)) {
        setPending(false);
        return props.onFormAlert({
          type: "error",
          message: "You do not have the right credentials",
        });
      }
      return auth.signin(email, pass)
        .then((user) => {
          // Call auth complete handler
          props.onAuth(user);
        }).catch((err) => {
          setPending(false);
          return props.onFormAlert({
            type: "error",
            message: `${err}`,
          });
        });
    },
    forgotpass: ({email}) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Password reset email sent",
        });
      });
    },
    // TODO not needed
    changepass: ({pass}) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({email, pass}) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
    })?.catch((error) => {
      setPending(false);
      // Show error alert message
      props.onFormAlert({
        type: "error",
        message: error.message,
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {["signin", "forgotpass"].includes(props.type) && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="email"
              label="Email address"
              name="email"
              placeholder="user@example.com"
              error={errors.email ? true : false}
              helperText={errors.email && errors.email.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your email",
              })}
            />
          </Grid>
        )}

        {["signin", "changepass"].includes(props.type) && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="password"
              label="Password"
              name="pass"
              error={errors.pass ? true : false}
              helperText={errors.pass && errors.pass.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter a password",
              })}
            />
          </Grid>
        )}

        {["changepass"].includes(props.type) && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="password"
              label="Confirm Password"
              name="confirmPass"
              error={errors.confirmPass ? true : false}
              helperText={errors.confirmPass && errors.confirmPass.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your password again",
                validate: (value) => {
                  if (value === getValues().pass) {
                    return true;
                  } else {
                    return "This doesn't match your password";
                  }
                },
              })}
            />
          </Grid>
        )}
        <AuthFooterComponent type={props.type} typeValues={props.typeValues}/>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button
            className={classes.loginButton}
            variant="contained"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {
              !pending && <Typography className={classes.loginButtonText}>
                {props.typeValues.buttonText}
              </Typography>
            }
            {pending && <CircularProgress size={28}/>}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default AuthFormComponent;
