import React from 'react';
import { Container, Grid } from '@material-ui/core';
import SectionComponent from "../../general/section/section.component";
import {AdminDashboardInfoCard} from '../admin-dashboard-sections/index';
import AdminPartnerPerformance from "../admin-partner-performance/admin-partner-performance.component";
import AdminDashboardInventory from "../admin-dashboard-inventory/admin-dashboard-inventory.component";
import AdminDashboardService from "../admin-dashboard-service/admin-dashboard-service.component";
import AdminDashboardBooking from "../admin-dashboard-booking/admin-dashboard-booking.component";
import AdminDashboardRevenue from "../admin-dashboard-revenue/admin-dashboard-revenue.component";
import FilterDropdown from "../../../atoms/filter-dropdown/filter-dropdown.component";
import AdminReviewsComponent from "../admin-reviews/admin-reviews.component";

const AdminDashboardInfoComponent = () => {

    const [timespan, setTimespan] = React.useState('365');

    const setTimeSpan = (text) => {
        switch (text){
            case '7 days': {
                setTimespan('7');
                break;
            }
            case '30 days': {
                setTimespan('30');
                break;
            }
            case '365 days': {
                setTimespan('365');
                break;
            }
            case 'Total': {
                setTimespan('0');
                break;
            }
            case 'This month': {
                setTimespan(new Date().getUTCDate().toString());
                break;
            }
            case 'Last month': {
                setTimeSpan(new Date().getUTCDate().toString()); // TODO: Get amount of days from today till the first day of the last month - data from now till begin of current month
                break;
            }
        }
    }

    return (
        <>
            <SectionComponent size='auto'>
                <div style={{position: "absolute", top: '94%', left: '89%', width: '10%', border: '1px solid rgb(18, 181, 173)', zIndex: 5}}>
                    <FilterDropdown placeholder="Sort" optionsArray={["7 days", "30 days", "365 days", "This month", "Last month", "Total"]} onSelect={(text) => setTimeSpan(text)}/>
                </div>
                <Container maxWidth='xl' style={{height: '85vh', marginTop: '15vh'}}>
                    <Grid container spacing={2}>
                        <AdminDashboardInfoCard title="Inventory">
                            <AdminDashboardInventory/>
                        </AdminDashboardInfoCard>
                        <AdminDashboardInfoCard title="Revenue">
                            <AdminDashboardRevenue timespan={timespan}/>
                        </AdminDashboardInfoCard>
                        <AdminDashboardInfoCard title="Partner performance">
                           <AdminPartnerPerformance timespan={timespan}/>
                        </AdminDashboardInfoCard>
                        <AdminDashboardInfoCard title="Service">
                            <AdminDashboardService/>
                        </AdminDashboardInfoCard>
                        <AdminDashboardInfoCard title="Partner review">
                            <AdminReviewsComponent/>
                        </AdminDashboardInfoCard>
                        <AdminDashboardInfoCard title="Bookings">
                            <AdminDashboardBooking timespan={timespan}/>
                        </AdminDashboardInfoCard>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    );
};

export default (AdminDashboardInfoComponent);
