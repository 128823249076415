import React from 'react'
import axios from "axios";
import {useForm} from "react-hook-form";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";

import FooterComponent from "../../general/main-footer/footer.component";
import MainNav from "../../general/main-nav/main-nav.component";
import SectionComponent from "../../general/section/section.component";

import BgImage from '../../../assets/group-961.svg';
import {useSnackbar} from "../../../utils/snackbar";
import {useStyles} from "./booking-cancel.styles";

const BookingCancel = () => {
  const classes = useStyles();
  const {setSnackbar} = useSnackbar();

  const {handleSubmit, register, errors, reset} = useForm();
  const [pending, setPending] = React.useState(false);

  const onSubmit = async (data) => {
    setPending(true);

    await axios.delete(`/booking/${data.reservation_code.replace('VSPR0', '')}`)
      .then((res) => {
        console.log(res);
        setSnackbar('Successfully cancelled your reservation.', 'success');
      })
      .catch((err) => {
        console.log(err);
        setSnackbar('Something went wrong, please try again.', 'error');
      });

    setPending(false);
    reset();
  };

  return (
    <>
      <MainNav/>
      <SectionComponent size='auto' bgImage={BgImage}>
        <Container maxWidth='xl' className={classes.container}>
          <div className={classes.cancelContainer}>
            <Typography variant='h3' className={classes.header}>Cancel reservation</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Last name"
                    name="last_name"
                    error={errors.last_name ? true : false}
                    helperText={errors.last_name && errors.last_name.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter a last name",
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Invalid last name"
                      }
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Reservation code"
                    name="reservation_code"
                    multiline={true}
                    rows={1}
                    error={errors.reservation_code ? true : false}
                    helperText={errors.reservation_code && errors.reservation_code.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter a reservation code",
                      pattern: {
                        value: /VSPR0\d+/,
                        message: "Please enter a valid booking code"
                      }
                    })}
                  />
                  <Typography variant='caption' className={classes.inputFieldInfo}>
                    Can be found in the confirmation e-mail
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    type='submit'
                    disabled={pending}
                  >
                    <Typography className={classes.buttonText}>
                      {pending ? <CircularProgress/> : 'Cancel reservation'}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </SectionComponent>
      <FooterComponent topFooter={false}/>
    </>
  )
};

export default BookingCancel;
