import React from 'react';
import {Backdrop, Modal, Box, Grid, Typography} from "@material-ui/core";
import {Fade} from "../fade/fade.component";
import {makeStyles} from "@material-ui/core/styles";
import StyledButton from "../styled-button/styled-button.component";

const SimpleModal = ({open, handleClose, children, title, onConfirm, confirmButtonText}) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            className={classes.modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <Box className={classes.box}>
                    <Grid container style={{height: '100%', padding: '5%'}} direction="column">
                        <Grid item xs>
                            <Typography variant="h5" style={{textAlign: 'center', paddingBottom: '5%'}}>{title}</Typography>
                        </Grid>
                        <Grid item xs>
                            {children}
                        </Grid>
                        {confirmButtonText && <Grid container direction="row">
                            <Grid item xs={6} className={classes.center}>
                                <StyledButton warning text="Cancel" onClick={handleClose}/>
                            </Grid>
                            <Grid item xs={6} className={classes.center}>
                                <StyledButton primary text={confirmButtonText} onClick={onConfirm}/>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    },
    box: {
        backgroundColor: '#1f1f1f',
        width: '30vw',
        height: '100%',
        // paddingTop: '60%',
        minWidth: 600
    },
    center: {
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: '10%',
        paddingRight: '10%'
    }
}));
export default SimpleModal;
