class BikeModel {
    get display_number() {
        return this._display_number;
    }

    set display_number(value) {
        this._display_number = value;
    }
    get week_frequency() {
        return this._week_frequency;
    }

    set week_frequency(value) {
        this._week_frequency = value;
    }

    get month_frequency() {
        return this._month_frequency;
    }

    set month_frequency(value) {
        this._month_frequency = value;
    }

    get year_frequency() {
        return this._year_frequency;
    }

    set year_frequency(value) {
        this._year_frequency = value;
    }
    get bike_id() {
        return this._bike_id;
    }

    set bike_id(value) {
        this._bike_id = value;
    }

    get bike_state() {
        return this._bike_state;
    }

    set bike_state(value) {
        this._bike_state = value;
    }

    get partner_name() {
        return this._partner_name;
    }

    set partner_name(value) {
        this._partner_name = value;
    }

    get partner_id() {
        return this._partner_id;
    }

    set partner_id(value) {
        this._partner_id = value;
    }

    get total_frequency() {
        return this._total_frequency;
    }

    set total_frequency(value) {
        this._total_frequency = value;
    }

    get total_repairs() {
        return this._total_repairs;
    }

    set total_repairs(value) {
        this._total_repairs = value;
    }

    get framenumber() {
        return this._framenumber;
    }

    set framenumber(value) {
        this._framenumber = value;
    }

    get gps_tag() {
        return this._gps_tag;
    }

    set gps_tag(value) {
        this._gps_tag = value;
    }

    get date_added() {
        return this._date_added;
    }

    set date_added(value) {
        this._date_added = value;
    }

    constructor(bike_id, bike_state, partner_name, partner_id, total_frequency, total_repairs, framenumber, gps_tag, date_added, week_frequency, month_frequency, year_frequency, display_number) {
        this._bike_id = bike_id;
        this._bike_state = bike_state;
        this._partner_name = partner_name;
        this._partner_id = partner_id;
        this._total_frequency = total_frequency;
        this._total_repairs = total_repairs;
        this._framenumber = framenumber;
        this._gps_tag = gps_tag;
        this._date_added = date_added;
        this._week_frequency = week_frequency;
        this._month_frequency = month_frequency;
        this._year_frequency = year_frequency;
        this._display_number = display_number;
    }
}
export default BikeModel;
