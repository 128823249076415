import React, {useEffect, useState} from 'react';
import {Backdrop, Modal, Box, Grid, Typography} from "@material-ui/core";
import {Fade} from "../fade/fade.component";
import {makeStyles} from "@material-ui/core/styles";
import StyledButton from "../styled-button/styled-button.component";

const SelectCriteriaModal = ({open, handleClose, onConfirm}) => {
    const classes = useStyles();
    const [criteria, setCriteria] = useState('');
    const [clicked, setClicked] = useState('');

    useEffect(() => {
        setCriteria('');
    }, [open]);

    return (
        <Modal
            open={open}
            className={classes.modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <Box className={classes.box}>
                    <Grid container style={{height: '100%'}} direction="column">
                        <Grid container direction="row">
                            <Grid item xs={6} style={{border: '1px solid #545454'}}>
                                <StyledButton list text="Most rentals" listButtonClicked={clicked === 'mr'} onClick={() => {setCriteria('Most rentals'); setClicked('mr')}}/>
                            </Grid>
                            <Grid item xs={6} style={{border: '1px solid #545454'}}>
                                <StyledButton list text="Least rentals" listButtonClicked={clicked === 'lr'} onClick={() => {setCriteria('Least rentals'); setClicked('lr')}}/>
                            </Grid>
                            <Grid item xs={6} style={{border: '1px solid #545454'}}>
                                <StyledButton list text="Most PC 20 rentals" listButtonClicked={clicked === 'mp20'} onClick={() => {setCriteria('Most PC 20 rentals'); setClicked('mp20')}}/>
                            </Grid>
                            <Grid item xs={6} style={{border: '1px solid #545454'}}>
                                <StyledButton list text="Least PC 20 rentals" listButtonClicked={clicked === 'lp20'} onClick={() => {setCriteria('Least PC 20 rentals'); setClicked('lp20')}}/>
                            </Grid>
                            <Grid item xs={6} style={{border: '1px solid #545454'}}>
                                <StyledButton list text="Most PC 35 rentals" listButtonClicked={clicked === 'mp35'} onClick={() => {setCriteria('Most PC 35 rentals'); setClicked('mp35')}}/>
                            </Grid>
                            <Grid item xs={6} style={{border: '1px solid #545454'}}>
                                <StyledButton list text="Least PC 35 rentals" listButtonClicked={clicked === 'lp35'} onClick={() => {setCriteria('Least PC 35 rentals'); setClicked('lp35')}}/>
                            </Grid>
                            <Grid item xs={6} style={{border: '1px solid #545454'}}>
                                <StyledButton list text="Most iDeal rentals" listButtonClicked={clicked === 'mi'} onClick={() => {setCriteria('Most iDeal rentals'); setClicked('mi')}}/>
                            </Grid>
                            <Grid item xs={6} style={{border: '1px solid #545454'}}>
                                <StyledButton list text="Least iDeal rentals" listButtonClicked={clicked === 'li'} onClick={() => {setCriteria('Least iDeal rentals'); setClicked('li')}}/>
                            </Grid>
                        </Grid>

                        <Grid container direction="row">
                            <Grid item xs={6} className={classes.center}>
                                <StyledButton popupCancel text="Cancel" onClick={handleClose}/>
                            </Grid>
                            <Grid item xs={6} className={classes.center}>
                                {criteria !== '' && <StyledButton popupConfirm text="Select" onClick={() => {onConfirm(criteria); setCriteria(''); handleClose()}}/>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    },
    box: {
        backgroundColor: '#1f1f1f',
        width: '30vw',
        height: '100%',
        // paddingTop: '60%',
        minWidth: 600,
        border: '1px solid #12b5ad'
    },
    center: {
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: '10%',
        paddingRight: '10%'
    }
}));
export default SelectCriteriaModal;
