import dayjs from "dayjs";

export const calendarBadges = (data) => {
  const totalBikes = data?.[0]?.total_bikes;
  const bikesAndDates = data?.map(row => row.rental_dates);
  const bikesOnDay = {};

  /**
   * Use date as key, if date already exists: add 1
   * If not, assign 1
   */
  for (let i = 0; i < bikesAndDates?.length; i++) {
    for (let j = 0; j < bikesAndDates[i]?.length; j++) {
      if (bikesOnDay[bikesAndDates[i][j]]) {
        bikesOnDay[bikesAndDates[i][j]] += 1;
      } else {
        bikesOnDay[bikesAndDates[i][j]] = 1
      }
    }
  }

  /**
   * Return array with date as key and available bikes instead of booked bikes as value
   */
  return Object.keys(bikesOnDay).map(key => ({date: dayjs(key).format('MM/DD/YYYY'), amount_bikes: totalBikes - bikesOnDay[key]}));
}

export const initialCalendarBadges = (data) => {
  const currentBookedDays = [];
  calendarBadges(data)?.forEach((dayAndBikes) => {
    if (dayjs(dayAndBikes.date).get('month') === dayjs().get('month')) {
      currentBookedDays.push(dayAndBikes);
    }
  });
  return currentBookedDays;
}
