import React from 'react';
import {useStyles} from "./hero-dashboard.styles";
import SectionComponent from "../../../general/section/section.component";
import {Container} from "@mui/material";
import {Box, Grid, Typography} from "@material-ui/core";
import ZigZagDivider from "../../../../assets/v2-icons/zig-zag-divider.svg";
import PersonIcon from "../../../../assets/ic-person.svg";
import {Rating} from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import BookAStay from '../../../../assets/book-a-stay@3x.png';
import DashboardPanelPicture from '../../../../assets/dashboard_reservation_panel.png';
import accentPattern from "../../../../assets/pattern.svg";

const HeroDashboardComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto" className={classes.root}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item className={classes.textSection}>
                            <Box className={classes.leftTextBox}>
                                <img src={ZigZagDivider} alt="Divider" className={classes.divider}/>
                                <Typography className={classes.headerBoxTitle}>Dashboard</Typography>
                                <Typography variant='caption2' className={classes.subHeader}>
                                    {data?.dashboardSubtitle}
                                </Typography>
                                <ul className={classes.list}>
                                    {data?.dashboardDots.map((dot, index) => {
                                        return (
                                            <li className={classes.listItem}>{dot}</li>
                                        )
                                    })}
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item className={classes.photoSection}>
                            <Box className={classes.rightSection}>
                                <img src={data?.dashboardBackgroundImage.fields.file.url} alt="Hotel picture" className={classes.hotelPicture}/>
                                <div className={classes.hoverPicture}>
                                    <img src={data?.dashboardImage.fields.file.url} alt="Dashboard reservation panel" className={classes.dashboardPicture}/>
                                    <div className={classes.patternDiv}>
                                        <img
                                            src={accentPattern}
                                            alt="Pattern"
                                            className={classes.pattern}
                                        />
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroDashboardComponent;
