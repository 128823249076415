import React, {useState} from 'react';
import axios from "axios";
import {Controller, useForm, useWatch, useFieldArray} from "react-hook-form";
import {useQuery} from "react-query";

import {
  assignBikes,
  calculateCostBooking,
  availableBikes,
  bikesDates,
  phoneNumberReg
} from "../../../utils/booking";
import {useAuth} from "../../../utils/auth";
import {useSnackbar} from "../../../utils/snackbar";
import {Link, useRouter} from "../../../utils/router";
import {countries} from "../../../utils/countries";
import {selectTheme} from "../../../utils/theme";

import {ThemeProvider} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AddIcon from '@material-ui/icons/Add';
import {
  TextField,
  Paper,
  Box,
  InputAdornment,
  MenuItem,
  Button,
  Grid,
  Typography,
  Container, Tooltip, IconButton, Divider
} from "@material-ui/core";

import CustomCheckbox from "../../../atoms/checkbox/checkbox.component";
import RadioButton from "../../../atoms/radio-button/radio-button.component";
import SectionComponent from "../../general/section/section.component";
import ControlledDatePicker from "../../../atoms/date-picker-booking/date-picker-booking.component";
import PageLoaderComponent from "../../general/page-loader/page-loader.component";
import TooltipInsurance from "../../../atoms/tooltip-insurance/tooltip-insurance.component";

import {useStyles} from "./portal-booking.styles";
import icKeyboardIcon from "../../../assets/ic-keyboard-arrow-down.svg";
import TooltipPartnerCode from "../../../atoms/tooltip-partner-code/tooltip-partner-code.component";
import SimpleDatePicker from "../../../atoms/simple-date-picker/simple-date-picker.component";

const PortalBooking = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const {setSnackbar} = useSnackbar();

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [date, setDate] = useState(null);

  /**
   * Data retrieval
   */
  const id = auth.user[0].partner_id;
  const {isLoading, data} = useQuery(
    ['book-portal'],
    async () => {
      const res = await axios.get(`/bookings/${id}`);
      return res.data.rows;
    }
  );

  /**
   * Handle Form variables
   */
  const {handleSubmit, control, reset, errors, register} = useForm({
    mode: "onChange"
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: "extra_dates"
  });
  let amountBikes = useWatch({control, name: 'amount_bikes', defaultValue: 0});
  let extraDates = useWatch({control, name: 'extra_dates'});
  let startDate = useWatch({control, name: 'start_date'});
  const rentalDates = [startDate];
  extraDates?.forEach(d => rentalDates.push(d.date));

  /**
   * Handle terms
   */
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const handleTermsAccept = () => setAcceptTerms(!acceptTerms);

  /**
   * Determine payment choice
   */
  const [paymentChoice, setPaymentChoice] = React.useState('credit_card');
  const [partnerCode, setPartnerCode] = React.useState(false);
  const handlePaymentChange = (event) => {
    setPaymentChoice(event.target.value);
    setPartnerCode(!partnerCode);
  };

  /**
   * Extra variables for form
   */
  const options = availableBikes(data, rentalDates);
  const bikesAndDates = bikesDates(data);

  const onSubmit = async (formData) => {
    setIsProcessing(true);

    formData['rental_dates'] = rentalDates;
    formData['assigned_bikes'] = assignBikes(amountBikes, bikesAndDates, rentalDates);
    formData['amount'] = calculateCostBooking(amountBikes, rentalDates);
    formData['partner_id'] = id;
    formData['partner_name'] = data[0].partner_name;
    formData['amount_bikes'] = amountBikes;
    formData['redirect_url'] = 'partner';
    formData['birth_date'] = date;

    if (paymentChoice === 'partner_code') {
      formData['methods'] = ['partner_code'];
      await axios.post('/partner-code', formData)
        .then((res) => {
          console.log(res);
          router.push('/partner');
          setSnackbar('Booking completed successfully.', 'success');
        })
        .catch((err) => {
          console.log(err);
          setSnackbar('Something went wrong, please try again.', 'error');
        });
    } else if (paymentChoice === 'partner_code_discount') {
      formData['methods'] = ['partner_code_discount_eur20'];
      await axios.post('/partner-code', formData)
          .then((res) => {
            console.log(res);
            router.push('/partner');
            setSnackbar('Booking completed successfully.', 'success');
          })
          .catch((err) => {
            console.log(err);
            setSnackbar('Something went wrong, please try again.', 'error');
          });
    } else if (paymentChoice === 'credit_card') {
      formData['methods'] = ['creditcard'];
      await axios.post('/payment', formData)
        .then((res) => {
          window.location.replace(res.data);
        })
        .catch((err) => {
          setSnackbar('Something went wrong, please try again.', 'error');
          console.log(err);
        });
    }

    reset();
  }

  return (
    <>
      <SectionComponent
        bgColor='var(----background-surface)'
        size='large'
      >
        <Container maxWidth="xl" className={classes.container}>
          {
            isLoading ? (<PageLoaderComponent/>)
              :
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justify='space-between'>
                  <Grid container item xs={12} lg={5}>
                    <div className={classes.details}>
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <Typography variant='h1' className={classes.sectionTitleReservation}>
                            Reservation
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ControlledDatePicker
                              control={control}
                              name='start_date'
                              emptyLabel='Date'
                              amountBikes={amountBikes}
                              bikesAndDates={bikesAndDates}
                              defaultValue={props.location.state}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={selectTheme}>
                            <Controller
                              control={control}
                              name="amount_bikes"
                              rules={{required: "This is required"}}
                              as={
                                <TextField
                                  select
                                  disabled={!startDate}
                                  label='Number of V-bikes'
                                  id="amount_bikes"
                                  variant='outlined'
                                  error={errors.amount_bikes ? true : false}
                                  helperText={errors.amount_bikes && errors.amount_bikes.message}
                                  fullWidth={true}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <img src={icKeyboardIcon} className={classes.keyboardIcon} alt='Select'/>
                                      </InputAdornment>
                                    )
                                  }}
                                >
                                  {options.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.value > 1 ? option.label : '1 V-bike'}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              }
                            />
                          </ThemeProvider>
                        </Grid>
                        {
                          fields.map(({id, name}, index) => {
                            return (
                              <Grid item xs={12}>
                                <div key={id} className={classes.extraDayDiv}>
                                  <ControlledDatePicker
                                    control={control}
                                    name={`extra_dates[${index}].date`}
                                    emptyLabel='Date'
                                    amountBikes={amountBikes}
                                    bikesAndDates={bikesAndDates}
                                    minDate={startDate}
                                    chosenDates={rentalDates}
                                  />
                                  <Button
                                    type="button" onClick={() => remove(index)}
                                  >
                                    <Tooltip title='Remove' placement='right-middle'>
                                      <IconButton>
                                        <CancelOutlinedIcon className={classes.extraDayIcon} color='primary'/>
                                      </IconButton>
                                    </Tooltip>
                                  </Button>
                                </div>
                              </Grid>
                            )
                          })
                        }
                        <Grid item xs={12}>
                          <div className={classes.addDayButton}>
                            <Button
                              type="button"
                              onClick={() => append({})}
                            >
                              <IconButton>
                                <AddIcon className={classes.extraDayIcon} color='primary'/>
                              </IconButton>
                            </Button>
                            <Typography variant='body2'>
                              Add another day?
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={`${classes.rootText} ${classes.nameFirst}`}
                            variant="outlined"
                            type="text"
                            label="First name"
                            name="first_name"
                            multiline={true}
                            rows={1}
                            error={errors.first_name ? true : false}
                            helperText={errors.first_name && errors.first_name.message}
                            fullWidth={true}
                            inputRef={register({
                              required: "This field is required",
                              pattern: {
                                value: /^[a-zA-Z]+$/,
                                message: "Please only use alphabetic characters"
                              }
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={`${classes.rootText} ${classes.nameLast}`}
                            variant="outlined"
                            type="text"
                            label="Last name"
                            name="last_name"
                            multiline={true}
                            rows={1}
                            error={errors.last_name ? true : false}
                            helperText={errors.last_name && errors.last_name.message}
                            fullWidth={true}
                            inputRef={register({
                              required: "This field is required",
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Please only use alphabetic characters"
                              }
                            })}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleDatePicker text={"Birth date"} chosenDate={date} setChosenDate={(date) => setDate(date)}/>
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            classes={{paper: classes.autoComplete}}
                            options={countries}
                            getOptionLabel={(option) => option.label}
                            renderOption={(option) => (<span>{option.label}</span>)}
                            popupIcon={
                              <img
                                src={icKeyboardIcon}
                                className={`${classes.keyboardIcon} ${classes.keyboardIconCountry}`}
                                alt='Select'
                              />
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className={classes.rootText}
                                name="country"
                                label="Nationality"
                                variant="outlined"
                                error={errors.country ? true : false}
                                helperText={errors.country && errors.country.message}
                                // inputRef={register({ TODO disabled validation to ensure first typed character is written in the input field
                                //   required: "This field is required",
                                // })}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.rootText}
                            variant="outlined"
                            type="text"
                            label="Email address"
                            name="email"
                            multiline={true}
                            rows={1}
                            error={errors.email ? true : false}
                            helperText={errors.email && errors.email.message}
                            fullWidth={true}
                            inputRef={register({
                              required: "This field is required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Enter a valid e-mail address",
                              },
                            })}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.rootText}
                            variant="outlined"
                            type="text"
                            label="Phone number"
                            name="phone_number"
                            multiline={true}
                            rows={1}
                            error={errors.phone_number ? true : false}
                            helperText={errors.phone_number && errors.phone_number.message}
                            fullWidth={true}
                            inputRef={register({
                              pattern: {
                                value: phoneNumberReg,
                                message: "Enter a valid phone number",
                              },
                            })}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              className={classes.rootText}
                              variant="outlined"
                              type="text"
                              label="Notes"
                              name="note"
                              multiline={true}
                              rows={1}
                              fullWidth={true}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid container item xs={12} lg={5} alignItems='flex-start'>
                    <div className={classes.payment}>
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <Typography variant='h2' className={classes.sectionTitlePayment}>
                            PAYMENT
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.radioButtonContainer}>
                            <Box className={classes.radioButtonBox}>
                              <RadioButton
                                checked={paymentChoice === 'credit_card'}
                                onChange={handlePaymentChange}
                                value="credit_card"
                                name="radio-button-demo"
                                inputProps={{'aria-label': 'credit_card'}}
                              />
                              <Typography variant='body1' className={classes.radioText}>
                                Credit card
                              </Typography>
                            </Box>
                            <Box className={classes.radioButtonBox}>
                              <RadioButton
                                checked={paymentChoice === 'partner_code'}
                                onChange={handlePaymentChange}
                                value="partner_code"
                                name="radio-button-demo"
                                inputProps={{'aria-label': 'partner_code'}}
                              />
                              <Typography variant='body1' className={classes.radioText}>
                                Partner code (€ 35)
                              </Typography>
                            </Box>
                            <Box className={classes.radioButtonBox}>
                              <RadioButton
                                  checked={paymentChoice === 'partner_code_discount'}
                                  onChange={handlePaymentChange}
                                  value="partner_code_discount"
                                  name="radio-button-demo"
                                  inputProps={{'aria-label': 'partner_code_discount'}}
                              />
                              <Typography variant='body1' className={classes.radioText}>
                                Partner code (€ 20)
                              </Typography>
                              <TooltipPartnerCode/>
                            </Box>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper className={classes.pricePaper}>
                            <Grid container item xs={12} alignItems='center'>
                              <Grid item xs={6}>
                                <Typography variant='body1' className={classes.totalTitle}>Total price</Typography>
                              </Grid>
                              <Grid item xs={6} container justify='flex-end'>
                                <Typography variant='h5'>
                                  EUR {calculateCostBooking(amountBikes, rentalDates, (paymentChoice === 'partner_code_discount'))}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider className={classes.priceDivider}/>
                              </Grid>
                              <Grid item xs={12}>
                                <TooltipInsurance/>
                              </Grid>
                            </Grid>
                          </Paper>
                          <Grid item xs={12}>
                            <Paper className={classes.termsPaper}>
                              <Grid container item xs={12} alignItems='center'>
                                <Grid item xs={2}>
                                  <Controller
                                    name="accept_terms"
                                    id="accept_terms"
                                    control={control}
                                    defaultValue={false}
                                    render={() => (
                                      <CustomCheckbox
                                        onChange={handleTermsAccept}
                                        checked={acceptTerms}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography variant='body2' className={classes.termsAccept}>
                                    I have read and accept the
                                    <Link className={classes.termsLink} target="_blank" to='/terms'>
                                      terms and conditions
                                    </Link>.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </form>
          }
        </Container>
      </SectionComponent>
      <div className={classes.rootFooter}>
        <Button
          className={classes.cancelButton}
          type='button'
          onClick={() => router.push('/partner')}
        >
          <Typography className={classes.cancelButtonText}>Cancel</Typography>
        </Button>
        <Button
          className={
            (!acceptTerms || isProcessing) ?
              `${classes.button} ${classes.buttonDisabled}`
              :
              `${classes.button}`
          }
          disabled={!acceptTerms || isProcessing}
          onClick={handleSubmit(onSubmit)}
        >
          <Typography className={classes.buttonText}>
            Reserve now
          </Typography>
        </Button>
      </div>
    </>
  )
};

export default PortalBooking;
