import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#202020'
    },
    gridItem: {
        margin: '10% 15% 0 15%',
        display: 'flex',
        flexDirection: 'column',
    },
    root: {
        padding: '0',
        backgroundColor: '#ccc !important',
    },
    overlay: {
        backgroundImage: 'linear-gradient(90deg, rgba(32,32,32,1) 0%, rgba(255,255,255,0) 50%, rgba(32,32,32,1) 100%)',
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    grid: {
        height: '15vh',
        alignItems: 'center'
    },
    image: {
        flex: 1,
        height: '100%',
        width: '100%',
        padding: '3.5%'
    },
    box: {
        [theme.breakpoints.down('lg')]: {
            height: 'max-content',
            paddingBottom: 60,
            width: '93%',
        },
        backgroundColor: '#121212',
        width: '82%',
        height: '25vh',
        marginTop: '-12.5vh',
        zIndex: 2
    },
    gridContainer: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        zIndex: 5,
        flexDirection: 'row'
    },
    icon: {
        width: '13%',
        aspectRatio: 1
    },
    subHeader: {
        color: '#909090',
        fontFamily: 'Kanit',
        margin: '8px 0 0 0',
        fontWeight: "normal",
        fontSize: '0.8rem'
    },
    header: {
        fontFamily: 'Kanit',
        fontStyle: 'italic',
        textTransform: 'uppercase',
        fontWeight: "bold",
        marginTop: 10,
        fontSize: '1.2rem'
    },
    buttonLocations: {
        position: 'absolute',
        bottom: 0,
        marginBottom: -22.5,
        height: 45,
        padding: '12px 16px',
        backgroundColor: '#12b5ad',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#0f9690'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    buttonLocationsText: {
        fontSize: '0.8rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    patternDiv: {
        zIndex: 1,
        position: 'absolute',
        marginBottom: '-1.5vw',
        bottom: 0,
        top: '1vh',
        right: 'calc(80vw - 7.5vh)',
        [theme.breakpoints.down('lg')]: {
            top: '0.4vh',
            right: 'calc(90vw - 7.5vh)',
        },
        [theme.breakpoints.down('sm')]: {
            left: 0,
            marginBottom: '-9vw',
        }
    },
    pattern: {
        objectFit: 'contain',
        width: '15vh',
        aspectRatio: 1,
        [theme.breakpoints.down('sm')]: {
            width: '30vw',
        },
        [theme.breakpoints.down('lg')]: {
            width: '16vh',
        }
    },
}));
