import React from 'react';

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {useStyles} from "./terms.styles";
import SectionComponent from "../../general/section/section.component";
import MainNav from "../../general/main-nav/main-nav.component";
import FooterComponent from "../../general/main-footer/footer.component";
import List from "@material-ui/core/List";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

const Terms = () => {
  const classes = useStyles();

  return (
    <>
      <MainNav/>
      <SectionComponent size='large'>
        <Container maxWidth='lg'>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h1' >
                Terms & conditions Vesper
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                In these terms and conditions the following definitions apply:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Vesper</span>: Vesper e-bikes B.V.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>The website</span>: www.vesperbike.com
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Bike</span>: the electronic bike (V-bike) made available to the renter by Vesper
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Renter</span>: the natural or legal person who enters into the lease as a tenant and agrees to these terms & conditions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Rentee</span>: Vesper
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Consumer</span>: the renter who is a natural person and who has agreed to the rental agreement
                for purposes that fall outside his business or professional activity
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Damage suffered by the rentee</span>: the financial loss suffered by the rentee as a result of:
                damage (including abnormal wear) or loss of the bike, or of accessories (such as a key), or
                of bike parts. This damage includes the costs of replacing (accessories and parts of) the
                bike and loss of rental income
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Rider</span>: The actual driver of the bike
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Written</span>: in writing or electronic
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                <span className={classes.textBold}>Partner</span>: the accommodation where the V-bike is rented
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 1 – Applicability
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                These terms & conditions are applicable for rental agreements between rentee and renter.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 2 – Offer
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The offer is written
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The proposal can be revoked until 24 hours before delivery. The offer includes a full and
                    accurate description of:
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The bike;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The rent duration;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The sum for renting;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The payment amount;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Possible additional costs;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The costs of a insurance disclosed by Vesper
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The offer includes these terms & conditions
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 3 – The agreement
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The agreement is permanent by accepting the offer. The renter accepts the agreement
                    written.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The duration of the agreement applies to the period and amount as described in the
                    agreement. The agreement states the day and time on which the rental period begins and
                    ends.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 4 – Termination
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                The renter has a right of cancellation until 48 hours before the day of rental.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 5 – Price and price deviations
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    De rental sum and optional other costs are agreed upon before the rent starts. This also
                    applies
                    for possible price changes. The rental sum is displayed in the agreement and hereby final.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Any price deviations occurring after the agreement will not affect the price as written in
                    the
                    agreement.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The 2nd paragraph is not applicable on price deviations by law like an increase of taxes.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Only costs that are agreed upon can be credited to renter. renter does need to pay loss of
                    income or damage when applicable (causing damage due to irresponsible behavior;
                    alcohol consumption while riding).
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 6 – De duration of renting and exceeding the rental period
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter is obligated to return the bike on day and time as agreed upon by contract.
                    The end time for a ride is, without exception, 18:00 Dutch time.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter can only return the bike outside the time-window when agreed upon by the
                    partner/rentee.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The rentee can take the bike back immediately if the bike is not returned as agreed upon.
                    The contractual agreements are permanent and applicable until the bike is returned to the
                    rentee.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If the renter does not return the bike within the agreed upon time-window, the rentee can
                    ask an additional 30% fee of the total rental sum for every hour that the bike is returned
                    too late. Rentee also receives a damage compensation for the caused damage and the
                    collateral damage that derives from the violation in the future. If proven circumstances
                    make it impossible to return the bike within agreed upon time-window, the rentee will not
                    ask for any compensation.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>5.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The fees for returning the bike overdue will not apply in case of force majeure.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 7 – Cancellation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    There are no costs associated with the reservation. In the unlikely event that the Renter
                    has to cancel, he will receive the entire amount paid back to his account within 14 days,
                    provided that you have notified the Rentee 48 hours before the day of rental via the
                    cancellation screen on the website.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    In case of cancellation less than 48 hours before the day of rental, the Renter will not
                    receive a refund.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    In the event of extreme weather conditions such as snow, sleet, sub-zero temperatures,
                    dangerous wind speeds or other weather conditions that do not allow reasonable and fair
                    use, cancellations are made by the partner for safety reasons. In this case, all available Vbikes
                    with this partner will be cancelled, there is no possibility of rental. In this case, the
                    entire amount paid will be refunded to your account within 14 days.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 8 – Payment and insurance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    At the start of the rental period, renters are required to take insurance at Vesper.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    In most cases, Vesper's insurance removes the risk of extra costs caused by damage to
                    the V-bike for the rentee.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Renter is not insured with the Vesper insurance for any type of damage caused by
                    irresponsible behavior or violation of (one of) the article 9 paragraphs.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The sum of the rent including insurance is € 35,00 incl. VAT.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>5.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Payments for the rental of a V-bike will be made via the website at all times, except
                    when the payment has been fulfilled to the partner.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 9 – Duties rentee
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter treats the Vesper properties with decency and the bike is being used as
                    designed (exploring scenery; no dangerous joyriding). It is forbidden to use the bike on a
                    circuit, or on terrain for which the bike is not suitable (sand, sea water, etc.). The bike must
                    always be locked with the lock provided, where possible to a fixed object. The bike must be
                    locked by connecting the back wheel of the bike with another object that cannot be
                    replaced without violence.</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    renter has to deliver the bikes in the same state as received. Possible changes on the
                    bikes need to be undone before returning.</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Only 1 person with a max weight of 120KG can be carried by the bike. If this standard is
                    exceeded, any damage caused to the bike will be recovered from the rentee. Note that the
                    action radius of the bike decreases with an increase of weight on the bike
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter must ensure that no one uses the bike who is unable to do so due to a
                    physical or mental disability.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>5.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The rentee may not rent out the bike.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>6.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter is not allowed to cycle to other countries.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>7.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    In case of a bike failure Article 10/paragraph 1 applies and the renter may not continue
                    cycling if this makes it worse.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>8.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Partner/rentee is required to point out the rules and terms of use regarding bikes to
                    renter and to ensure that they also adhere to them.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>9.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter must handle the keys of the bike properly.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>10.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    renter, or rider, is not permitted to use the bike with an alcohol percentage that
                    surpasses 0,53.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 10 – Instructions for renter
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If the bike is visibly broken, the bike has been damaged or the bike is missing, the renter
                    must follow these instructions:
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    renter informs rentee;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    renter follows instructions of partner/rentee;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    renter agrees upon collaboration regarding any documentation for insurance companies;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    renter leaves the bike in such a way that it is properly protected against damage or loss;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    rentee can ask for damage compensation from a third party. renter is permitted to
                    collaborate with rentee in providing information.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If the bike is missing, the renter is obliged to report it to the local police.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 11 – Duties renter
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The moment the partner/rentee gives a bike to the renter, it has the agreed accessories
                    and specifications and also the equipment that is mandatory in the Netherlands. The bike
                    will also be clean, well maintained, (as far as known or known to the rentee) in good
                    technical condition and the battery is fully charged.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If possible, the renter will receive another V-bike free of charge if the agreed bike cannot
                    be given.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter will receive a full refund if the provisions of paragraph 2 are permanently
                    impossible
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The agreement contains telephone numbers for contact.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>5.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    There is adequate roadside assistance in the Netherlands.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>6.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Adequate breakdown assistance is in any case understood to mean that the bike will be
                    repaired as soon as possible, unless this is not reasonably possible.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>7.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The partner inspects the bike immediately upon return by the renter for any damage.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 12 – Responsibility of the renter for damage
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Renter is usually not liable for any damages suffered if he has agreed upon, and paid for
                    the Vesper insurance when entering into the agreement.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If the damage results from something the renter has or has not done in violation of
                    article 9, or if he can otherwise be blamed for something, then the renter must in principle
                    fully compensate the rentee for the damage. A first possible exception to this is that the
                    renter proves that this act or omission cannot be attributed to him. A second exception
                    could be that it is not reasonable and fair for the renter to pay for everything.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter remains liable for damage suffered by the rentee until the time when the
                    partner/rentee has actually inspected the bike or had it inspected. Partner/rentee will
                    inspect the bike at the first opportunity and will inform the renter the same day, or within a
                    reasonable term, if damage is discovered.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If the renter takes another person on the bike as a passenger or if the renter lets another
                    person use the bike, the renter is liable for what these people do or do not do.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 13 – Damaged on bike and responsibility Rentee
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    When the renter asks the rentee to resolve defects, the rentee must in principle do this.
                    This is not necessary if a defect really cannot be resolved. If the renter is liable to the rentee
                    for the defect or for the consequences of the defect, the rentee does not have to resolve the
                    defects, even if the renter has requested this.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If someone who has suffered personal damage has been able to recover this damage
                    from his non-life insurer or if such a person has received another payment for this, the
                    rentee is not liable for this personal damage.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    However, that which is stated in Article 13 paragraph 2 does not apply if there is personal
                    damage, while the rentee knew or should have known about the defects when making the
                    rental agreement, or while defects have arisen through intent or gross negligence on the
                    part of the rentee.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The stated range of the bike is no more than an estimate.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Artikel 14 – Damage & repairs
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Repairs to the bike are at the expense of the rentee, unless the repair is the result of
                    careless use of the bike or the obligations referred to in Article 9 have not been met.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If the bike is damaged during the rental period, the renter must return the damaged bike
                    to the location where it was rented.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter must report damage to the bike to the partner/rentee as soon as reasonably
                    possible.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The rentee is obliged to compensate damage caused by a defect in the bike in the
                    following situations:
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    the defect was already present when the lease was entered into and the partner/rentee
                    knew about this or should have known about the defects;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    the defect was already there, but partner/rentee reported to the renter at the time of the
                    appointment that the bike did not have the defect;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    the defect arose after entering into the lease, but can be attributed to the partner/rentee.
                    Article
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 15 – Dissolution of the rental
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Partner/rentee can terminate the rental agreement and take back the bike when:
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    the renter does not, not timely or not fully fulfill one or more of his obligations during the
                    rental period, unless this is not serious enough for a dissolution;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    the renter dies, is placed under guardianship, applies for a moratorium, is declared
                    bankrupt or is subject to the Natural Persons Debt Rescheduling Act;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: `${classes.listIconContainer} ${classes.listNested}`}}>
                    <Typography className={`${classes.listIconNumber} ${classes.listNestedIconNumber}`}>-</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    the partner/rentee is aware of the existence of circumstances that are of such a nature
                    that he would not have entered into the lease (in this way) with the renter if he had been
                    aware of this. In that case, the rentee can continue to request compensation for costs,
                    damage and interest.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The renter will fully cooperate with the partner/rentee to return the bike.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The rentee is not liable for damage suffered by the renter as a result of dissolution under
                    this article.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 16 – Complaints & mediation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Complaints about the implementation of the agreement must be submitted fully and
                    clearly described to the rentee, on time, after the renter has discovered that in his opinion
                    something has gone wrong. If the renter is late, he may lose his rights.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    If it appears that the renter is not satisfied with the result of the complaint handling by
                    the rentee, the following applies. The renter can submit a dispute to a disputes committee
                    within six weeks after it has arisen.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 17 – Disputes
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Disputes between renter and rentee can be presented to Dutch ‘geschillencommissie
                    voertuigverhuur’. Address: De Geschillencommissie, Postbus 90600, 2509 LP te Den Haag
                    (visitation address: Borderwijklaan 46, 2591 XR te Den Haag).
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    A dispute will be taken into account by the dispute commission in case the renter first
                    presented the complaint for rentee. A dispute arises when a complaint is not handled
                    satisfactorily by the rentee.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    In case of a no-outcome between the two parties, there will be a decision of the dispute
                    within 12 months after filing in. A dispute is only regarded as such when the complaint is
                    filed at rentee within the written time period for application, but the result is not
                    satisfactory for renter.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>4.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    When a renter files a dispute at the ‘geschillencommissie’, the result of this commission is
                    final. When a renter is intending to go to the dispute commission, the renter needs to notify
                    the rentee within 5 weeks and receive an approval.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>5.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The ‘Geschillencommissie’ makes a decision based on the legal paragraphs from the
                    commission. 6. Only a judge can be notified of any disputes.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 18 – Compliance guarantee
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List classes={{root: classes.list}}>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>1.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Rentee guarantees compliance with the binding advice by its members if the lessor does
                    not comply with the binding advice, unless the member decides to submit the binding
                    advice to the court for review within two months after it was sent and the judgment in
                    which the court declares the binding advice non-binding has become final.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>2.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    Rentee's guarantee concerns an amount to be paid out by rentee of a maximum of € 450
                    against assignment of the renter’s claim. For amounts greater than € 450 per dispute, the
                    rentee will pay the maximum amount of € 450 to the renter under the same conditions. For
                    the excess, the renter is offered to assign his claim to rentee, after which rentee will request
                    payment in court if necessary. In that case, the rentee undertakes to transfer collected
                    funds to the renter.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon classes={{root: classes.listIconContainer}}>
                    <Typography className={classes.listIconNumber}>3.</Typography>
                  </ListItemIcon>
                  <ListItemText variant='body1' className={classes.text}>
                    The guarantee referred to in paragraph 2 does not apply if a judge sets aside the binding
                    advice. In the event of bankruptcy, suspension of payments or business termination of the
                    renter, rentee will only pay out an amount up to a maximum of € 450 per dispute and the
                    guarantee will only apply if the renter has fulfilled the formal obligations to bring the
                    dispute before the Vehicle Rental Disputes Committee before such a situation exists.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 19 – Processing of personal information renter en rider
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                The personal data stated on the contract will be processed by the rentee as the responsible
                party within the meaning of the General Data Protection Regulation in a personal register.
                Based on this processing, the rentee can implement these conditions, execute the
                agreement and provide the renter or driver with optimal service and current product
                information. The renter and driver can request inspection and correction with regard to the
                processed personal data and can lodge an objection. If it concerns direct mailing, such a
                protest will always be honored.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>
                Article 20 – Applicable law
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.text}>
                On this agreement the Dutch law applies.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
      <FooterComponent topFooter={false}/>
    </>
  )
};

export default Terms;
