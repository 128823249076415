import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-8)',
  },
  carouselContainer: {
    position: 'relative',
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
  },
  reviewSlider: {
    height: '320px',
    [theme.breakpoints.up('sm')]: {
      height: '240px'
    }
  },
  reviewSlide: {
    width: 'auto',
    backgroundColor: '#222222',
    height: '290px',
    paddingBottom: '33% !important',
    [theme.breakpoints.up('sm')]: {
      height: '200px',
    }
  },
  reviewGrid: {
    padding: '18px 24px 24px',
  },
  reviewName: {
    fontWeight: '600',
  },
  reviewReview: {
    margin: '16px 0 16px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '16px 0 24px 0',
    }
  },
  reviewRatingIcon: {
    width: '24px',
    height: '24px',
    margin: '0 8px 0 0',
    objectFit: 'contain',
  },
  dotGroup: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '92%',
    textAlign: 'center',
    width: 'fit-content',
    [theme.breakpoints.up('sm')]: {
      top: '90%',
    }
  },
  dot: {
    [theme.breakpoints.down('md')]: {
      height: '18px',
    },
    height: '12px',
    borderRadius: '100%',
    margin: '0 5px',
    border: 'solid 1.5px rgba(249,252,246, 0.5)',
    outline: 'none',
    backgroundColor: 'transparent',
    "&.carousel__dot--selected": {
      backgroundColor: 'rgba(249,252,246, 0.5)',
    },
  },
}));
