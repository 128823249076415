import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    section: {
        position: 'relative',
        width: '100%'
    },
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        height: '100vh',
        top: 0,
        padding: '0 !important',
        margin: '0 !important',
        position: 'absolute',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            height: 1000
        },
    },
    photoRightSection: {
        display: 'inline-block',
        overflow: 'hidden',
        height: '100vh',
        maxWidth: '41.666667%',
        flexBasis: '41.666667%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '0%',
            flexBasis: '0%',
        },
    },
    headerBox: {
        backgroundColor: '#12B5AD',
        marginBottom: 10,
        width: '50%',
        [theme.breakpoints.up('sm')]: {
            padding: 10,
            width: 'fit-content'
        },
    },
    headerBoxTitle: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '40px',
            lineHeight: '1.25',
            letterSpacing: '2px',
            fontWeight: '800',
            textAlign: 'center'
        },
        fontSize: '1em',
        padding: '0 20px 0 20px',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: '700',
        textAlign: 'left'
    },
    bikeImage: {
        width: '100%',
        objectFit: 'cover',
        height: '100vh',
        objectPosition: 'right',
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        },
        filter: 'blur(3px)'
    },
    gridContainer: {
        height: '100%',
        padding: '0 !important'
    },
    chooseSection: {
        flex: 1,
        backgroundColor: '#202020',
        width: '100%',
        height: 400
    },
    priceSection: {
        height: '12vh',
        backgroundColor: '#12B5AD',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '20vh',
        },
    },
    chooseGrid: {
        display: 'flex',
        flexDirection: 'column !important',
        height: '100vh',
        maxWidth: '58.333333%',
        flexBasis: '58.333333%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
            height: '100%'
        },
    },
    buyList: {
        marginLeft: 340,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '50%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            maxWidth: 'var(--max-width-12)',
            padding: 20
        },
    },
    buyListUnder: {
        marginLeft: 340,
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            flexDirection: 'column',
            padding: 20
        },
    },
    optionsSection: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center'
    },
    horizontalFlex: {
        display: 'flex',
        flexDirection: 'row'
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.5rem',
            lineHeight: '1.25',
            letterSpacing: '2px !important',
        },
        fontSize: '3rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    vesperBlue: {
        color: '#12B5AD'
    },
    bikeBox: {
        height: '17vh',
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        marginTop: 50,
        opacity: 0.7,
        backgroundColor: '#333333',
        "&&:hover": {
            border: '1px solid #12B5AD',
            cursor: 'pointer',
            opacity: 1
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 150,
        },
    },
    selectedBikeBox: {
        border: '1px solid #12B5AD',
        cursor: 'pointer',
        opacity: 1
    },
    gridBikeBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '5%',
        flex: 1
    },
    bikeBoxHeader: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1.25',
            letterSpacing: '1px !important',
        },
        fontSize: '3rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
        }
    },
    bikeBoxPrice: {
        color: '#12B5AD',
        fontSize: 20
    },
    subHeader: {
        color: '#fff',
        fontWeight: "normal",
        fontSize: 18,
        marginTop: 15
    },
    subHeaderBlack: {
        fontWeight: "normal",
        fontSize: 18,
        color: '#000'
    },
    vesperBikePricingOption: {
        height: '100%',
        objectFit: 'contain',
        scale: 1
    },
    pricingOptionBikeDiv: {
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    buttonLocations: {
        height: 30,
        padding: '20px 16px',
        backgroundColor: '#12b5ad',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#000'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'max-content'
        }
    },
    unClicked: {
        height: 30,
        padding: '20px 16px',
        borderRadius: 0,
        backdropFilter: 'blur(4px)',
        border: '1px solid white !important',
        "&&:hover": {
            backgroundColor: '#000'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'max-content'
        }
    },
    inputField: {
        backdropFilter: 'blur(4px)',
        color: '#fff',
        border: '1px solid white !important',
        background: 'transparent',
        padding: '12px 16px',
        fontFamily: "Kanit",
        "&&::placeholder": {
            color: '#FFF',
            opacity: 0.3,
            fontFamily: "Kanit",
            letterSpacing: 1
        },
    },
    buttonOrderNow: {
        height: 50,
        padding: '12px 30px',
        margin: '0 10px 0 10px',
        backgroundColor: '#000',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#000'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
        marginRight: 50
    },
    buttonLocationsText: {
        fontSize: '0.8rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        width: '100%',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'max-content'
        }
    },
    orderHere: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    }
}));
