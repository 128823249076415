import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootNav: {
    backgroundColor: '#202020',
  },
  container: {
    maxWidth: 'auto',
    zIndex: 1,
  },
  xsNavbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: 0,
      visibility: 'hidden',
    },
  },
  xsNavBarPortal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: 0,
      visibility: 'hidden',
    },
  },
  lgNavbar: {
    [theme.breakpoints.up('lg')]: {
      width: '100vw',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  subNav: {
    display: 'flex',
  },
  menuButton: {
    padding: 0
  },
  menuIcon: {
    width: '24px',
    height: '24px',
    margin: '4px',
    objectFit: 'contain',
  },
  divider: {
    width: '1px',
    height: '50px',
    opacity: '0.12',
    backgroundColor: 'var(--loafer-white)',
  },
  xsDivider: {
    width: '1px',
    height: '32px',
    margin: '0 16px',
    opacity: '0.12',
    backgroundColor: 'var(--loafer-white)',
  },
  logoContainer: {
    display: 'flex',
    alignContent: 'center',
  },
  logoContainerPortal: {
    display: 'flex',
    alignContent: 'center',
    marginLeft: 'auto',
  },
  logoLgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoTitle: {
    margin: '0 0 0 24px',
    fontSize: '1.125em',
    letterSpacing: '0.15px',
    color: 'var(--loafer-white)',
  },
  xsLogoTitle: {
    fontSize: '1.125em',
    letterSpacing: '0.15px',
    color: 'var(--loafer-white)',
    textTransform: 'uppercase',
  },
  horizontalLogo: {
    width: '159px',
    margin: '26px 40px',
    objectFit: 'contain',
  },
  logo: {
    width: '25px',
    margin: '4px',
    objectFit: 'contain',
  },
  xsLogo: {
    width: '25px',
    margin: '4px',
    objectFit: 'contain',
    marginLeft: 'auto',
  },
  menuPaper: {
    top: '60px !important',
      [theme.breakpoints.up('sm')]: {
      top: '100px !important'
    }
  },
  menuItemRoot: {
    padding: 0
  },
  menuListItem: {
    width: '280px'
  },
  flagIconContainer: {
    width: '24px',
  },
  flagIconButton: {
    padding: '12px 16px'
  },
  flagIcon: {
    width: '24px',
    height: '24px'
  },
  flagText: {
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '-0.25px',
  },
  toolBar: {
    width: 'auto',
    height: '64px',
    padding: '20px 24px',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.32)',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      padding: '20px 24px 0 0',
      height: '118px',
    }
  },
  toolbarNoShadow: {
    boxShadow: 'none',
  },
  toolBarItem: {
    width: 'auto',
    height: '95px',
    padding: 0,
    borderRadius: 0,
    [theme.breakpoints.up('lg')]: {
      padding: '12px 40px 10px 40px',
      whiteSpace: 'noWrap',
      borderBottom: 'solid 4px transparent !important',
      "&&:hover": {
        borderBottom: 'solid 4px var(--loafer-white) !important'
      },
    },
  },
  toolBarItemActive: {
    borderBottom: 'solid 4px var(--loafer-white) !important',
    width: 'auto',
    height: '95px',
    padding: 0,
    borderRadius: 0,
    [theme.breakpoints.up('lg')]: {
      padding: '12px 40px 10px 40px',
      whiteSpace: 'noWrap',
    },
  },
  toolBarItemIcon: {
    width: 'auto',
    height: '95px',
    padding: 0,
    borderRadius: 0,
    [theme.breakpoints.up('lg')]: {
      padding: '12px 40px 10px 40px',
      whiteSpace: 'noWrap',
      borderBottom: 'solid 4px transparent',
      "&&:hover": {
        opacity: 0.75,
      },
    },
  },
  toolbarItemText: {
    height: '27px',
    fontSize: '1.125em',
    fontWeight: '500',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'var(--loafer-white)',
  },
  drawerVesper: {
    width: '98px',
    objectFit: 'contain',
    marginBottom: '22px'
  },
  drawer: {
    width: '264px',
  },
  drawerTitle: {
    margin: '0 0 30px 0',
    fontSize: '1rem',
    fontWeight: '500',
    letterSpacing: '0.1px',
    color: 'var(--loafer-white)',
    textTransform: 'uppercase',
  },
  drawerList: {
    height: '100%',
    padding: '24px 16px',
  },
  drawerListItem: {
    width: '246px',
    padding: '10px 16px 11px 16px',
    borderLeft: 'solid 4px transparent',
  },
  drawerListItemActive: {
    width: '246px',
    padding: '10px 16px 11px 16px',
    borderLeft: 'solid 4px var(--loafer-white)',
  },
  drawerListItemText: {
    fontSize: '1.125em',
    fontWeight: '500',
    letterSpacing: '0.5px',
    color: 'var(--loafer-white)',
    textTransform: 'uppercase'
  },
  drawerDivider: {
    width: '264px',
    height: '1px',
    margin: '8px 0',
    opacity: '0.12',
    backgroundColor: 'var(--loafer-white)',
  },
  drawerRoutes: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerToolBarItem: {
    border: 'none',
    borderRadius: 'none',
    paddingLeft: '0 !important'
  },
  exitAppIcon: {
    width: '24px',
    margin: '0 15px 0 0',
    objectFit: 'contain',
  },
}));
