import React from 'react';

import {Route, useRouter} from "../../../utils/router";
import FooterComponent from "../../general/main-footer/footer.component";
import PortalBikeOverview from "../portal-bikes-overview/portal-bikes-overview.component";
import PortalBikeFailure from "../portal-bikes-failure/portal-bikes-failure.component";
import PortalBikesDamage from "../portal-bikes-damage/portal-bikes-damage.component";

const BikesSection = () => {
  const router = useRouter();
  return (
    <>
      <Route exact path={`${router.match.url}`} component={PortalBikeOverview}/>
      <Route exact path={`${router.match.url}/failure/:id/:type`} component={PortalBikeFailure}/>
      <Route exact path={`${router.match.url}/damage/:id`} component={PortalBikesDamage}/>
      <FooterComponent topFooter={false}/>
    </>
  )
};

export default BikesSection;
