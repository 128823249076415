import React from 'react';
import {useStyles} from "./HeroImageSection.styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from '../../../../assets/v2-icons';
import SectionComponent from "../../../general/section/section.component";
import { Textfit } from 'react-textfit';
import bgImage from '../../../../assets/v2-pictures/DSC08352.jpg';

const HeroImageSectionComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent transparent={true} size='auto' bgImage={data?.headerImage.fields.file.url} bgImageOpacity={0.72}>
                <Container maxWidth='xl' className={classes.container}>
                    <Grid container>
                        <Box className={classes.boxContainer}>
                            <Grid item xs={12}>
                                <Typography className={classes.header}>Vesper</Typography>
                                <div className={classes.headerBox}>
                                    {/*<Typography className={classes.headerBoxTitle}>For business</Typography>*/}
                                    <Textfit className={classes.headerBoxTitle} mode="single">For business</Textfit>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='caption2' className={classes.subHeader}>
                                    {data?.headerSubtitle}
                                </Typography>
                            </Grid>
                            {/*<Grid container item className={classes.buttonContainer}>*/}
                            {/*    <Grid item classes={{root: classes.buttons}}>*/}
                            {/*        <Button*/}
                            {/*            className={classes.buttonLocations}*/}
                            {/*            component={Link}*/}
                            {/*            to='/become-partner'*/}
                            {/*        >*/}
                            {/*            <Typography className={classes.buttonLocationsText}>*/}
                            {/*                Learn more*/}
                            {/*            </Typography>*/}
                            {/*            <img src={RightArrowIcon} style={{paddingLeft: 20}}/>*/}
                            {/*        </Button>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={12} sm classes={{root: classes.buttons}}>*/}
                            {/*        <Button*/}
                            {/*            className={classes.buttonLocations}*/}
                            {/*            component={Link}*/}
                            {/*            to='/become-partner'*/}
                            {/*            style={{backgroundColor: '#000'}}*/}
                            {/*        >*/}
                            {/*            <Typography className={classes.buttonLocationsText}>*/}
                            {/*                Learn more*/}
                            {/*            </Typography>*/}
                            {/*            <img src={RightArrowIcon} style={{paddingLeft: 20}}/>*/}
                            {/*        </Button>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}
                        </Box>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroImageSectionComponent;
