import React from 'react';

import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import axios from "axios";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import SectionComponent from "../../general/section/section.component";
import {useStyles} from "./become-form.styles";
import {useSnackbar} from "../../../utils/snackbar";

export const BecomePartnerForm = () => {
  const [pending, setPending] = React.useState(false);
  const {handleSubmit, reset, errors, register} = useForm();

  const classes = useStyles();
  const {setSnackbar} = useSnackbar();

  const mutationPartnerReq = useMutation(data => axios.post('/become-partner-contact', data));

  const onSubmit = async (data) => {
    setPending(true);

    mutationPartnerReq.mutate(data);

    setSnackbar('Your interest is successfully submitted.', 'success');
    setPending(false);

    reset();
  }

  return (
    <SectionComponent size='medium'>
      <Container maxWidth='xl' className={classes.container}>
        <Grid container >
          <Grid item xs={12}>
            <Typography variant='h2'>Interested in partnering up?</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' className={classes.subHeader}>
              Enter your contact details and we’ll get in touch as soon as possible.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.inputField}
                    variant="outlined"
                    type="text"
                    label="Company name"
                    name="partner_name"
                    error={errors.partner_name ? true : false}
                    helperText={errors.partner_name && errors.partner_name.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter a company name",
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.inputField}
                    variant="outlined"
                    type="text"
                    label="Contact person"
                    name="contact_person"
                    error={errors.contact_person ? true : false}
                    helperText={errors.contact_person && errors.contact_person.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter a contact person",
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Please enter a correct name"
                      }
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.inputField}
                    variant="outlined"
                    type="email"
                    label="Email address"
                    name="email"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter a email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please enter a correct e-mail address",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.inputField}
                    variant="outlined"
                    type="text"
                    label="Phone Number"
                    name="phone_number"
                    fullWidth={true}
                    inputRef={register({})}
                  />
                </Grid>
                <Grid item xs={7} className={classes.optionalContainer}>
                  <Typography variant='caption'>Optional</Typography>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button
                    disabled={pending}
                    className={classes.button}
                    type='submit'
                  >
                    <Typography className={classes.buttonText}>
                      {pending ? <CircularProgress/> : `Submit interest`}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </SectionComponent>
  )
};

export default BecomePartnerForm;
