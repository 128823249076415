import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    section: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        top: 0,
        padding: '0 !important',
        margin: '0 !important',
        position: 'absolute',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            height: 'unset',
        },
    },
    leftSection: {
        maxWidth: '50%',
        flexBasis: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    gridContainer: {
        maxWidth: 'var(--max-width-12)',
        margin: 'auto',
        marginTop: 0,
        marginBottom: 0,
        padding: '24px !important'
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '5vh',
            paddingBottom: '5vh'
        },
    },
    leftGrid: {
      backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    bikeImage: {
        width: '110%',
        zIndex: 2
    },
    headerBoxTitle: {
        fontSize: '2rem',
        justifyContent: 'center',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        color: '#fff',
        lineHeight: '1.25',
    },
    specificationTitle: {
        paddingTop: 20,
        fontSize: '1.2rem',
        justifyContent: 'center',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        color: '#fff',
        lineHeight: '1.25',
    },
    subHeader: {
        color: '#fff',
        margin: '8px 0 0 0',
        fontWeight: "100",
        fontStyle: 'normal',
        fontSize: '1.2rem',
        maxWidth: '70%',
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 20
    },
    specification: {
        flex: '50%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginLeft: 0,
        maxWidth: '50%',
        paddingTop: 10
    }
}));
