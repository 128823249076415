import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '700px',
  },
  rootGrid: {
    padding: '50px 0',
  },
  checkIcon: {
    width: '48px',
    height: '48px',
    objectFit: 'contain',
    margin: '0 0 16px 0',
  },
  greetingText: {
    fontSize: '1.125em',
    letterSpacing: '0.15px',
    color: 'var(--loafer-white)',
    margin: '0 0 8px 0',
  },
  titleText: {
    margin: '0 0 32px 0',
  },
  subHeader: {
    margin: '0 0 8px 0',
  },
  bookingInfoText: {
    margin: '0 0 32px 0',
  },
  bookingThank: {
    color: 'var(--loafer-white-soft)',
    margin: '0 0 32px 0',
  },
  buttonGridGroup: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  buttonHome: {
    padding: '12px 16px',
    margin: '8px 0 0 0',
    width: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      width: '180px',
      margin: 0,
    }
  },
  buttonHomeText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--java-green)',
    "&&:hover": {
      color: 'rgba(18,181,173,0.5)'
    },
  },
  buttonLocation: {
    padding: '12px 16px',
    borderRadius: 0,
    backgroundColor: 'var(--buttercup-yellow)',
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    },
    width: '-webkit-fill-available',
  },
  buttonLocationText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--midnight-forest-black)',
  },
}));
