import React from 'react';

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {useStyles} from "./become-why.styles";
import {useRouter} from "../../../utils/router";
import SectionComponent from "../../general/section/section.component";

import differentIcon from '../../../assets/rich-icons-differentiation.svg';
import pageIcon from '../../../assets/rich-icons-page.svg';
import moneyIcon from '../../../assets/rich-icons-money.svg';
import repairIcon from '../../../assets/rich-icons-repair.svg';

const BecomePartnerWhy = () => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <SectionComponent size='medium'>
      <Container maxWidth='xl' className={classes.container}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography variant='h1' className={classes.mainHeader}>
              Becoming a partner
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' className={classes.mainInfo}>
              Want to give your guests an adventurous – and sustainable! – way to explore the scenery? And want to give
              them something to talk about long after they’ve gone home? Find out what your options are to host our
              electric motor bikes and start renting them to your guests. (Watch out, their excited reactions once they
              first hop on, can become addictive!)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h2' className={classes.subHeader}>
              The benefits
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' className={classes.subInfo}>
              Giving your guests the possibility to get that adrenaline rush, let’s you stand out from other
              accommodations. You can host the electrical V-bikes for free, and the guest will have to pay only EUR
              35,00 for a ride.
            </Typography>
          </Grid>
          <Grid container item xs={12} className={classes.richIconContainer}>
            <Grid item xs={4} sm={2} lg={2}>
              <img src={differentIcon} alt="Guide Icon" className={classes.richIconImage}/>
            </Grid>
            <Grid container item xs={8} lg={6} className={classes.richIconSubContainer}>
              <Grid item container xs={12}>
                <Typography variant='h4' className={classes.richIconHeader}>
                  Stand out with exclusivity
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant='body1' className={classes.richIconText}>
                  Differentiate your accommodation by being one of the few partners in your region to host our V-bikes.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h2' className={classes.subHeader}>
              Service
            </Typography>
          </Grid>
          <Grid container item xs={12} className={classes.richIconContainer}>
            <Grid item xs={4} sm={2} lg={2}>
              <img src={pageIcon} alt="Guide Icon" className={classes.richIconImage}/>
            </Grid>
            <Grid container item xs={8} lg={6} className={classes.richIconSubContainer}>
              <Grid item container xs={12}>
                <Typography variant='h4' className={classes.richIconHeader}>
                  The business portal
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant='body1' className={classes.richIconText}>
                  Manage and make reservations for your guests, hassle-free.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.richIconContainer}>
            <Grid item xs={4} sm={2} lg={2}>
              <img src={repairIcon} alt="Guide Icon" className={classes.richIconImage}/>
            </Grid>
            <Grid container item xs={8} lg={6} className={classes.richIconSubContainer}>
              <Grid item container xs={12}>
                <Typography variant='h4' className={classes.richIconHeader}>
                  Bike repairs
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant='body1' className={classes.richIconText}>
                  Simply request a repair in your business portal.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.richIconContainer}>
            <Grid item xs={4} sm={2} lg={2}>
              <img src={moneyIcon} alt="Guide Icon" className={classes.richIconImage}/>
            </Grid>
            <Grid container item xs={8} lg={6} className={classes.richIconSubContainer}>
              <Grid item container xs={12}>
                <Typography variant='h4' className={classes.richIconHeader}>
                  Commission based earning
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant='body1' className={classes.richIconText}>
                  The cherry on top: earn with every V-bike reservation made.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </SectionComponent>
  )
};

export default BecomePartnerWhy;
