import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-10)'
  },
  portalReservations: {
    margin: '0 0 32px 0'
  },
  reservationsContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30px'
    }
  },
}));
