import React from 'react';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Image,
  Slide,
  Slider
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import backIcon from '../../../assets/ic-chevron-left-no-clear-space.svg';
import nextIcon from '../../../assets/ic-chevron-right-no-clear-space.svg';
import {useStyles} from "./location-image-carousel.styles";

const LocationImageCarousel = ({hotelImages}) => {
  const classes = useStyles();

  return (
    <CarouselProvider
      naturalSlideWidth={30}
      naturalSlideHeight={15}
      totalSlides={hotelImages.length}
    >
      <div className={classes.container}>
        <Slider>
          {hotelImages.map((img, index) => (
            <Slide key={index} index={index}>
              <Image src={img.fields.file.url}/>
            </Slide>
          ))}
        </Slider>
        <ButtonBack className={classes.buttonBack}>
          <img src={backIcon} alt='Back' className={classes.icon}/>
        </ButtonBack>
        <ButtonNext className={classes.buttonNext}>
          <img src={nextIcon} alt='Next' className={classes.icon}/>
        </ButtonNext>
      </div>
    </CarouselProvider>
  )
};

export default LocationImageCarousel;
