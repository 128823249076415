import React from "react";

import Container from "@material-ui/core/Container";

import SectionComponent from "../section/section.component";
import AuthContainer from "../auth-container/auth-container-component";
import FooterComponent from "../main-footer/footer.component";
import MainNav from "../main-nav/main-nav.component";
import {useStyles} from "./auth-section.styles";
import {Typography} from "@material-ui/core";

function AuthSectionComponent(props) {
  const classes = useStyles();
  // Values for each auth type

  const allTypeValues = {
    signin: {
      // Top title
      title: `Log in ${props.afterAuthPath === '/dashboard' ? 'as management' : ''}`,
      // Submit button text
      buttonText: "Log in",
      // Link text to other auth types
      linkTextSignup: "Create an account",
      linkTextForgotpass: "Forgot Password?",
    },
    forgotpass: {
      title: "Get a new password",
      buttonText: "Reset password",
      linkTextLogin: 'Back to login'
    },
    changepass: {
      title: "Choose a new password",
      buttonText: "Change password",
    },
  };
  const currentType = allTypeValues[props.type] ? props.type : "signup";
  const typeValues = allTypeValues[currentType];

  return (
    <>
      <MainNav transparent={false}/>
      <SectionComponent size='auto' bgImage={props.bgImage}>
        <Container maxWidth='xl' className={classes.container}>
          <div className={classes.loginContainer}>
            <Typography variant='h3' className={classes.loginHeader}>
              {allTypeValues[currentType].title}
            </Typography>
            <AuthContainer
              type={currentType}
              typeValues={typeValues}
              afterAuthPath={props.afterAuthPath}
              key={currentType}
            />
          </div>
        </Container>
      </SectionComponent>
      <FooterComponent topFooter={false}/>
    </>
  );
}

export default AuthSectionComponent;
