import React from 'react';
import axios from "axios";
import {useQuery} from 'react-query';

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {CircularProgress} from "@material-ui/core";

import SectionComponent from "../../general/section/section.component";
import EnhancedTable from "../admin-table/enhanced-table";
import {columnPartner} from "../admin-table/utils/column-partner";

const AdminPartnerComponent = () => {
  const skipResetRef = React.useRef(false);

  const {isLoading, data} = useQuery(
    ['dashboard-partners'],
    async () => {
      const res = await axios.get('/dashboard/get-partners')
      return res.data.rows;
    },
    {
      refetchInterval: 3000,
    }
  );

  return (
    <>
      <SectionComponent size='large'>
        <Container maxWidth='xl'>
          <Grid container spacing={3}>
            {isLoading ?
              <CircularProgress/>
              :
              <>
                <Grid item xs={12}>
                  <EnhancedTable
                    columns={columnPartner}
                    data={data}
                    extraWidth={0}
                    toolbar={true}
                    skipPageReset={skipResetRef.current}
                  />
                </Grid>
              </>
            }
          </Grid>
        </Container>
      </SectionComponent>
    </>
  )
};

export default AdminPartnerComponent;
