import React from 'react';

import {Route, useRouter} from "../../../utils/router";

import BookingSelectSection from "../booking-select-section/booking-select-section.component";
import Footer from "../../general/main-footer/footer.component";
import BookingForm from "../booking-form/booking-form.component";
import BookingPayment from "../booking-payment/booking-payment.component";
import BookingSuccess from "../booking-success/booking-success.component";

import newImage from '../../../assets/TER00427-Edit.jpg';

const BookingGuestSection = () => {
  const router = useRouter();

  return (
    <>
      <Route exact path={`${router.match.url}`}><BookingSelectSection bgImage={newImage}/></Route>
      <Route exact path={`${router.match.url}/book/:id`} component={BookingForm}/>
      <Route exact path={`${router.match.url}/payment/:id`} component={BookingPayment}/>
      <Route exact path={`${router.match.url}/success`} component={BookingSuccess}/>
      <Footer topFooter={false}/>
    </>
  )
};

export default BookingGuestSection;
