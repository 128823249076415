import React, {useEffect, useState} from 'react';
import {Backdrop, Box, Button, Modal, TextField} from "@material-ui/core";
import {Fade} from "../fade/fade.component";
import {Grid, Typography} from "@mui/material";
import FilterDropdown from "../filter-dropdown/filter-dropdown.component";
import StyledButton from "../styled-button/styled-button.component";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from "../../utils/snackbar";
import axios from "axios";
import SimpleDatePicker from "../simple-date-picker/simple-date-picker.component";

const MoveFormInformation = ({open, handleClose, moveObj, previousLocation, amount}) => {
    const {setSnackbar} = useSnackbar();
    const classes = useStyles();
    const [price, setPrice] = useState(moveObj?.price !== undefined ? moveObj.price : 0);
    const [note, setNote] = useState('');
    const [date, setDate] = useState();

    const [disabledConfirm, setDisabledConfirm] = useState(true);

    const onSubmit = async () => {
        if (moveObj?.note !== undefined){
            // Request from update archive section
            const bikeInfo = {
                price: price,
                note: note,
                archive_id: moveObj?.archive_id,
                date_archived: date
            };

            await axios.put('/dashboard/move-bike-inventory', bikeInfo).then((res) => {
               setSnackbar(`${res.data}`, 'success');
               handleClose();
            }).catch((err) => {
                setSnackbar(`Archive gaat fout.`, 'error');
            });
        } else {
            const bikeInfo = {
                bike_id: (moveObj.bike?.bike_id ? moveObj.bike.bike_id : undefined),
                amount: (amount > 1 ? amount : 1),
                price: price,
                note: note,
                original_location: previousLocation,
                date: date
            };

            await axios.post('/dashboard/add-bike-to-archive', bikeInfo).then(async (res) => {
                let bikeInfo;
                if (amount >= 1){
                    bikeInfo = {
                        amount: amount,
                        location: moveObj.to,
                        oldLocation: previousLocation
                    };
                } else {
                    bikeInfo = {
                        bike_id: moveObj.bike.bike_id,
                        location: moveObj.to
                    };
                }
                await axios.post('/dashboard/move-bike-inventory', bikeInfo).then((res) => {
                    setSnackbar(`Bike(s) succesfully archived`, 'success');
                    handleClose();
                    setNote('');
                    setPrice(0);
                }).catch((error) => {
                    setSnackbar(`Move gaat fout.`, 'error');
                });
            }).catch((err) => {
                setSnackbar(`Archive gaat fout.`, 'error');
            });
        }
    }

    useEffect(() => {
        if (date !== null && price !== 0 && note !== ''){
            setDisabledConfirm(false);
        }
    }, [date, price, note])

    useEffect(() => {
        setPrice(moveObj?.price !== undefined ? moveObj.price : 0 );
        setNote(moveObj?.note !== undefined ? moveObj.note : '');
        setDate(moveObj?.date !== undefined ? moveObj.date : null );
    }, [open]);

    return (
        <Modal
            open={open}
            className={classes.modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <Box className={classes.box}>
                    <Grid container style={{height: '100%', padding: '5%'}} direction="column">
                        <Grid item xs>
                            <Typography variant="h5">Information</Typography>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="column" className={classes.form}>
                                <Grid item xs>
                                    <Grid container direction="row" style={{paddingBottom: '5%'}}>
                                        <Grid item xs={4} style={{display: 'flex', alignItems: 'center'}}>
                                            <Typography variant="h6">Date</Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', justifyContent: 'center'}}>
                                            <SimpleDatePicker text={"Date"} chosenDate={date} setChosenDate={(date) => setDate(date)}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                    <Grid container direction="row" style={{paddingBottom: '5%'}}>
                                        <Grid item xs={4}>
                                            <Typography variant="h6">From</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FilterDropdown listItem disabled placeholder={previousLocation} optionsArray={[]}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {amount !== 0
                                    ?
                                        <Grid item xs>
                                            <Grid container direction="row" style={{paddingBottom: '5%'}}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Amount</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField disabled id="standard-basic" variant="standard" value={(amount)} className={classes.inputField}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    :
                                        <Grid item xs>
                                            <Grid container direction="row" style={{paddingBottom: '5%'}}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Bike number</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField disabled id="standard-basic" variant="standard" value={moveObj.bike?.bike_id} className={classes.inputField}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                }
                                <Grid item xs>
                                    <Grid container direction="row" style={{paddingBottom: '5%'}}>
                                        <Grid item xs={4}>
                                            <Typography variant="h6" style={{color: '#f1a209'}}>Price</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField inputProps={{className: classes.input}} id="standard-basic" variant="standard" value={price} className={classes.inputField} onChange={(event => setPrice(event.target.value))}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                    <Grid container direction="row">
                                        <Grid item xs={4}>
                                            <Typography variant="h6">Note</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField id="standard-basic" variant="standard" value={note} className={classes.inputField} onChange={(event => setNote(event.target.value))}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" className={classes.buttonSection}>
                            <Grid item xs={6} className={classes.center}>
                                <StyledButton popupCancel text="Cancel" onClick={handleClose}/>
                            </Grid>
                            <Grid item xs={6} className={classes.center}>
                                <StyledButton popupConfirmDisabled={disabledConfirm} popupConfirm={!disabledConfirm} text={moveObj?.note ? 'Save' : 'Move'} onClick={() => onSubmit()}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    buttonSection: {
      paddingTop: '5%'
    },
    form: {
        paddingTop: '5%',
    },
    inputField: {
        width: '100%',
        paddingLeft: '5%',
        paddingRight: '5%',
        backgroundColor: '#545454',
        borderRadius: 5,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    box: {
        backgroundColor: '#1f1f1f',
        width: '30vw',
        height: '100%',
        // paddingTop: '60%',
        minWidth: 600
    },
    center: {
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: '10%',
        paddingRight: '10%'
    },
    input: {
        color: '#f1a209 !important'
    }
}));

export default MoveFormInformation;
