import React from "react";

import Container from "@material-ui/core/Container";

import {makeStyles} from "@material-ui/core/styles";
import {bikeManual, other} from './portal.faq.json';

import Faq from "../../general/faq/faq.component";
import FooterComponent from "../../general/main-footer/footer.component";
import PartnerContact from "../portal-contact/portal-contact.component";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-8)'
  },
}));

const PortalFaqSection = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="xl" className={classes.container}>
        <Faq
          mainHeader='Frequently asked questions'
          subHeader='Everything you need to know about the Vesper bike and riding it.'
          categories={[bikeManual]}
          subCategory={other}
          crumb={false}
        />
        <PartnerContact/>
      </Container>
      <FooterComponent topFooter={false}/>
    </>
  )
};

export default PortalFaqSection;
