import React, {useState} from 'react';
import {useStyles} from "./hero-tab.styles";
import SectionComponent from "../../../general/section/section.component";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import HighlightsLandingComponent from "../highlights-landing/highlights-landing.component";
import FeaturesLandingComponent from "../features-landing/features-landing.component";
import SpecificationsLandingComponent from "../specifications-landing/specifications-landing.component";

const HeroTabComponent = () => {

    // Imports
    const classes = useStyles();

    // States
    const [selectedPage, setSelectedPage] = useState(0);

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto" className={classes.root} bgColor={"#fff"}>
                <Container maxWidth={false} className={classes.container}>
                    <Box className={classes.box}>
                        <Button
                            className={classes.buttonLocations}
                            style={{backgroundColor: selectedPage === 0 ? 'black' : 'white'}}
                            onClick={() => setSelectedPage(0)}
                        >
                            <Typography className={classes.buttonLocationsText} style={{color: selectedPage !== 0 ? 'black' : 'white'}}>
                                Highlights
                            </Typography>
                        </Button>
                        <Button
                            className={classes.buttonLocations}
                            style={{backgroundColor: selectedPage === 1 ? 'black' : 'white'}}
                            onClick={() => setSelectedPage(1)}
                        >
                            <Typography className={classes.buttonLocationsText} style={{color: selectedPage !== 1 ? 'black' : 'white'}}>
                                Smart features
                            </Typography>
                        </Button>
                        <Button
                            className={classes.buttonLocations}
                            style={{backgroundColor: selectedPage === 2 ? 'black' : 'white'}}
                            onClick={() => setSelectedPage(2)}
                        >
                            <Typography className={classes.buttonLocationsText} style={{color: selectedPage !== 2 ? 'black' : 'white'}}>
                                Specifications
                            </Typography>
                        </Button>

                    </Box>
                </Container>
            </SectionComponent>

            {selectedPage === 0 &&
                <HighlightsLandingComponent/>
            }

            {selectedPage === 1 &&
                <FeaturesLandingComponent/>
            }

            {selectedPage === 2 &&
                <SpecificationsLandingComponent/>
            }
        </>
    )
};

export default HeroTabComponent;
