import React, {useEffect, useRef, useState} from 'react';
import {useStyles} from "./hero-concept.styles";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import SectionComponent from "../../../general/section/section.component";
import ZigZagDivider from "../../../../assets/v2-icons/zig-zag-divider.svg";
import Button from "@material-ui/core/Button";
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import ImageGallery from "react-image-gallery";
import ChevronLeft from '../../../../assets/icons/chevron-left-solid.svg'
import ChevronRight from '../../../../assets/icons/chevron-right-solid.svg'
import {isMobile} from 'react-device-detect'

import HeroImageGalleryBtnComponent from "./hero-image-gallery-btn.component";
import {newBikePrice, exDemoBikePrice} from '../../config'

const HeroConceptComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States
    const [imageGalleryPosition, setImageGalleryPosition] = useState(0);
    const [bikeImages, setBikeImages] = useState([]);

    // Functions

    // Declarations
    const sliderRef = useRef();

    // Data retrieval
    useEffect(() => {
        console.log(sliderRef.current);
    }, [sliderRef]);

    useEffect(() => {
        const arr = data?.bikePictures.map((bike) => {
            return {original: bike.fields.file.url}
        });

        if (arr !== undefined){
            setBikeImages(arr);
        } else {
            setBikeImages([]);
        }

        console.log(arr);
    }, [data]);

    return (
        <>
            <SectionComponent size="auto" className={classes.section}>
                <Container maxWidth={false} className={classes.container}>
                    <Box className={classes.textBox}>
                        <Typography className={classes.header}>Vesper bike</Typography>
                        <div className={classes.topSection}>
                            <img src={ZigZagDivider} alt="Divider" style={{width: 127, alignSelf: 'center'}}/>
                            <Typography className={classes.headerBoxTitle}>{data?.bikeTitle}</Typography>
                        </div>
                        <Typography variant='caption2' className={classes.subHeader}>
                            {data?.bikeSubtitle}
                        </Typography>
                    </Box>
                </Container>

                <Box className={classes.bikePictures}>
                    <Grid container direction={'row'}>
                        <Grid item xs={1} className={classes.verticalCenter}>
                            <button className={classes.navBtn} onClick={() => sliderRef?.current?.slideToIndex(imageGalleryPosition - 1)}><img width={30} height={30} src={ChevronLeft} alt={'Button to view previous image'}/></button>
                        </Grid>
                        <Grid item xs={10} style={{padding: isMobile ? '0' : '0 20%'}}>
                            <ImageGallery
                                ref={sliderRef}
                                showNav={false}
                                items={bikeImages}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                sizes={500}
                                showThumbnails={false}
                                onSlide={(pos) => setImageGalleryPosition(pos)}
                            />
                        </Grid>
                        <Grid item xs={1} className={classes.verticalCenter}>
                            <button className={classes.navBtnRight} onClick={() => sliderRef?.current.slideToIndex(imageGalleryPosition + 1)}><img width={30} height={30} src={ChevronRight} alt={'Button to view next image'}/></button>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} className={classes.imageGalleryBtn}>
                        {bikeImages?.map((images, index) => {
                            return (
                                <HeroImageGalleryBtnComponent key={index} selected={imageGalleryPosition === index} onClick={() => sliderRef?.current.slideToIndex(index)}/>
                            )
                        })}
                    </Grid>
                </Box>


                <Box className={classes.buyOptionsBox}>
                    <Box className={classes.buyOptionsLeft}>
                        <Typography variant='caption2' className={classes.subHeader} style={{paddingBottom: 10, textAlign: 'left'}}>
                            Select an option</Typography>
                        <div>
                            <Button
                                className={classes.buttonLocations}
                                style={{marginRight: 20}}
                                component={Link}
                                to='/become-partner'
                            >
                                <Typography className={classes.buttonLocationsText}>
                                    NEW
                                </Typography>
                            </Button>
                            <Button
                                className={classes.buttonRef}
                                component={Link}
                                to='/become-partner'
                            >
                                <Typography className={classes.buttonTextRef}>
                                    REFURBISHED
                                </Typography>
                            </Button>
                        </div>
                    </Box>
                    <Box className={classes.buyOptionsRight}>
                        <div className={classes.lowerBackSection}>
                            <Button
                                className={classes.buttonLocations}
                                style={{height: 40, padding: '20px 40px 20px 40px'}}
                                component={Link}
                                to='/shop/continue'
                            >
                                <Typography className={classes.buttonLocationsText}>
                                    Order now for € {newBikePrice}.00
                                </Typography>
                                <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                            </Button>
                            <Typography variant='caption2' className={classes.subHeaderChoose} style={{fontSize: 16, paddingBottom: '0px !important'}}>
                                or ex-demo for <a style={{fontWeight: 'bold', color: '#12B5AD'}}>€{exDemoBikePrice}.00</a></Typography>
                        </div>
                    </Box>
                </Box>
            </SectionComponent>
        </>
    )
};

export default HeroConceptComponent;
