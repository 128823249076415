import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px 20px 200px 20px',
  },
  container: {
    maxWidth: 'var(--max-width-10)'
  },
  header: {
    margin: '60px 0 8px 0',
    [theme.breakpoints.up('lg')]: {
      margin: '118px 0 0 00',
      width: '519px',
    }
  },
  list: {
    backgroundColor: 'transparent !important',
  },
}));
