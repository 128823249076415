import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  table: {
    margin: '10px 0 0 0',
    backgroundColor: '#202020',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.2px 1.2px 2.2px',
    borderColor: 'rgba(229, 231, 235, 1)',
    borderRadius: '5px',
  },
  cellField: {
    fontWeight: 300,
    color: 'var(--loafer-white)',
    fontSize: '1.125em',
    padding: '10px 24px',
  },
  cellFieldNumber: {
    width: '155px',
  },
  columnHeaderGroup: {
  },
  columnHeader: {
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 500,
    height: '90px',
    color: 'var(--java-green)',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    padding: '20px 20px 12px 20px',
  },
  columnHeaderNum: {
    width: '32px'
  },
  sortLabel: {
    float: 'right',
    width: '20%',
  },
  sortLabelActive: {
    color: 'var(--java-green) !important',
  },
  paginationText: {
    color: 'var(--loafer-white)',
  },
  filterInput: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    marginTop: '0.25rem',
    lineHeight: '1.25rem',
    borderColor: 'rgba(209, 213, 219,1)',
    borderRadius: '0.275rem',
    color: 'var(--loafer-white)',
  },
}))
