import React from "react";
// import { useScrollTrigger } from "@material-ui/core";

const ScrollHandler = props => {
  // Disabled for now. Will leave here for future design changes
  // const trigger = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: 0,
  //   target: props.window ? window() : undefined
  // });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: "#202020",
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.32)',

      // Left here for future design changes
      // backgroundColor: trigger ? "#202020" : "transparent",
      // color: trigger ? "white" : "black",
      // transition: trigger ? "0.3s" : "0.5s",
      // boxShadow: trigger ? '0 4px 4px 0 rgba(0, 0, 0, 0.32)' : 'none',
    }
  });
};

const ScrollToColor = props => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

export default ScrollToColor;
