import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-8)'
  },
  header: {
    marginTop: '16px',
  },
  exploreInfo: {
    fontWeight: '500',
    margin: '16px 0 32px 0'
  },
  discoverInfo: {
    margin: '16px 0 24px 0'
  },
  bulletTextGreen: {
    fontWeight: 'normal',
    color: 'var(--java-green)',
    cursor: 'pointer',
    textDecoration: 'none',
    "&&:hover": {
      color: 'rgba(18,181,173,0.5)'
    },
  },
  imageContainer: {
    margin: '12px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  imageSubContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  imageVertical: {
    width: '100%',
    margin: '8px 0',
    height: '205px',
    [theme.breakpoints.up('sm')]: {
      height: '436px',
      width: '335px',
      margin: '12px 12px 12px 0',
    },
    [theme.breakpoints.up('lg')]: {
      width: '410px',
    }
  },
  imageHorizon: {
    height: '205px',
    width: '100%',
    margin: '8px 0',
    [theme.breakpoints.up('sm')]: {
      width: '410px',
      margin: '12px 0 12px 12px',
    }
  },
  divider: {
    height: '1px',
    marginTop: '40px',
    backgroundColor: 'rgba(249,252,246,0.12)'
  },
}));
