import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 0 90px 0',
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.up('xl')]: {
      height: '96vh',
    }
  },
  bookingBox: {
    margin: '155px 0 0',
    padding: '24px',
    boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.32)',
    backgroundColor: '#1d1d1d',
    [theme.breakpoints.up('sm')]: {
      width: '700px',
    }
  },
  selectContainer: {
    margin: '32px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      margin: '16px 0',
    },
  },
  selectSvgHidden: {
    visibility: 'hidden',
  },
  extraDayDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      margin: '0 0 0 4px',
    }
  },
  addDayButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      margin: '6px 0',
    }
  },
  extraDayIcon: {
    [theme.breakpoints.up('sm')]: {
      margin: '8px 0 0 0',
    },
    "&&:hover": {
      opacity: '0.8'
    }
  },
  addDayText: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '5px'
    }
  },
  bookingAlignmentRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonReserve: {
    padding: '12px 16px',
    backgroundColor: 'var(--buttercup-yellow)',
    borderRadius: 0,
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    },
    width: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      width: '187px',
    }
  },
  buttonReserveText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--midnight-forest-black)',
  },
  buttonVideo: {
    width: '180px',
    height: '48px',
    margin: '6px 24px',
    padding: '12px 16px 12px 12px',
  },
  buttonVideoText: {
    margin: '0 0 0 8px',
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--loafer-white-soft)',
  },
  buttonIcon: {
    width: '24px',
    height: '24px',
    margin: '0 8px 0 0',
    objectFit: 'contain',
  },
  pricePaper: {
    border: 'solid 1px #2f2f2f',
    backgroundColor: 'var(--aztec-black)',
    padding: '24px',
    margin: '0 0 32px 0',
  },
  totalPrice: {
    color: 'var(--loafer-white-soft)',
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  priceDivider: {
    height: '1px',
    margin: '24px 0 16px',
    opacity: 0.12,
    backgroundColor: 'var(--loafer-white-soft)',
  },
}));
