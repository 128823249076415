import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-8)',
    display: 'flex',
    justifyContent: 'center',
  },
  heroBox: {
    width: '628px',
    padding: '24px',
    margin: '120px 0',
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: 'rgba(18,18,18,0.8)',
  },
  heroBoxTitle: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  heroBoxSubTitle: {
    margin: '16px 0 0',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  heroBoxSubOrange: {
    fontWeight: '500',
    color: 'var(--buttercup-yellow)',
  },
  bottomShark: {
    position: 'relative',
    marginBottom: '-55px',
    [theme.breakpoints.up('sm')]: {
      width: '100vw',
      marginBottom: '-167px',
    }
  },
}));
