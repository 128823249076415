import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: 'solid 1px rgba(249,252,246,0.12)',
    marginTop: "auto",
  },
  container: {
    maxWidth: 'var(--max-width-12)',
    marginTop: "auto",
  },
  bgFooterTop: {
    height: 'auto',
    display: 'flex',
    padding: '32px 0',
    backgroundColor: 'var(--background-surface)',
  },
  logoColorVesper: {
    objectFit: "contain",
    margin: '0 0 0 10px',
    [theme.breakpoints.up('sm')]: {
      margin: 0
    },
  },
  linkContainer: {
    display: 'flex',
    flexWrap: 'initial',
  },
  linkCategory: {
    margin: '32px 12px 16px 12px',
    [theme.breakpoints.up("sm")]: {
      margin: '0 0 24px 0',
      minWidth: '110px'
    },
  },
  links: {
    color: 'var(--loafer-white)',
    textDecoration: 'none',
    "&&:hover": {
      color: 'var(--java-green)'
    },
    margin: '8px 12px',
    [theme.breakpoints.up("sm")]: {
      margin: '0 0 20px 0',
      padding: 0
    },
  },
  socialIconContainer: {
    display: 'flex',
  },
  socialLinksInfo: {
    margin: '12px 12px',
    [theme.breakpoints.up("sm")]: {
      margin: '8px 0',
      padding: '0 0 0 0',
    },
  },
  socialLinks: {
    width: '32px',
    margin: '24px 32px 24px 12px',
    objectFit: 'contain',
    [theme.breakpoints.up('sm')]: {
      margin: '8px 32px 8px 0',
    },
  },
  bgFooter: {
    width: '100vw',
    height: '120px',
    padding: '24px 12px;',
    backgroundColor: 'var(--midnight-forest-black)',
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
    },
    [theme.breakpoints.up("lg")]: {
      padding: '24px 250px 24px 240px',
    },
  },
  bgFooterMargin: {
    marginTop: 'auto',
  },
  copyrightText: {
    textAlign: 'center',
  },
  copyrightTextGreen: {
    color: 'var(--loafer-white-soft)',
    textDecoration: 'none',
    "&&:hover": {
      color: 'var(--java-green)'
    },
  },
  sticky: {},
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: 'column',
  },
  item: {
    display: "flex",
    flex: "none",
    justifyContent: "center",
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.up("sm")]: {
      flex: "50%",
    },
  },
  footerItem: {
    display: "flex",
    flex: "none",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flex: "50%",
    },
  },
  brand: {
    display: "block",
    height: 32,
  },
  social: {
    alignItems: "flex-end",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    lineHeight: 1,
    "&&:hover": {
      color: 'var(--java-green)'
    },
    "&:not(:first-of-type)": {
      marginLeft: 20,
    },
  },
  copyright: {
    backgroundColor: '#0b0a07',
    color: 'white',
  }
}));
