import React from 'react';
import axios from "axios";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

import {useSnackbar} from "../../../utils/snackbar";
import {useStyles} from "./dialog-transfer-bike.styles";

const DialogTransferBike = ({selectedOption, handleTransferDialog}) => {
  const {setSnackbar} = useSnackbar();
  const classes = useStyles();

  const handleTransfer = () => {
    axios.post('/dashboard/bike-transfer', selectedOption).then(res => {
      console.log(res);
      handleTransferDialog();
      setSnackbar(`${res.data.message}.`, 'success');
    }).catch((err) => {
      console.log(err);
      handleTransferDialog();
      setSnackbar(`${err.response.data.message}.`, 'error');
    });
  };

  return (
    <>
      <div className={classes.dialogRoot}>
        <DialogTitle className={classes.dialogTitle}>Delete partner</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          Are you sure you want to transfer bike {selectedOption[2]} to {selectedOption[1]}?
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={handleTransfer}
          >
            <Typography className={classes.buttonText}>
              Transfer
            </Typography>
          </Button>
          <Button
            className={classes.button}
            onClick={handleTransferDialog}
          >
            <Typography className={classes.buttonText}>
              Cancel
            </Typography>
          </Button>
        </DialogActions>
      </div>
    </>
  )
};

export default DialogTransferBike;
