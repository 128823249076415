import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 'var(--max-width-12)',
        display: 'flex',
        alignItems: 'center',
        height: '85vh',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            height: '85vh',
        }
    },
    boxContainer: {
        height: 'auto',
        padding: '16px',
        marginBottom: '20px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0vh',
        },
        zIndex: 2,
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '110px',
            lineHeight: '1.25',
            letterSpacing: '-0.81px',
        },
        fontSize: '3.5em',
        letterSpacing: '-0.58px',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    subHeader: {
        color: 'var(--loafer-white)',
        margin: '8px 0 0 0',
        fontWeight: "normal",
        maxWidth: '50%',
        display: 'inline-block',
        fontSize: 18
    },
    buttonContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
        flexDirection: 'row',
        marginTop: '15px'
    },
    buttons: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10
        },
    },
    headerBox: {
        backgroundColor: '#12B5AD',
        marginBottom: 10,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: 10,
            width: 'fit-content'
        },
    },
    headerBoxTitle: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '80px',
            lineHeight: '1.25',
            letterSpacing: '-0.81px',
            fontWeight: '500',
            textAlign: 'center'
        },
        fontSize: '2.2em',
        padding: '0 20px 0 20px',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: '700',
        textAlign: 'left'
    },
    buttonLocations: {
        height: '48px',
        padding: '12px 16px',
        margin: '0 20px 0 0',
        backgroundColor: '#12b5ad',
        width: 'fit-content',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#0f9690'
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        }
    },
    buttonLocationsText: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    buttonVideo: {
        height: '48px',
        margin: 0,
        padding: '12px 16px 12px 12px',
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: '250px',
        }
    },
    buttonVideoText: {
        margin: '0 0 0 8px',
        fontSize: '1rem',
        fontWeight: '500',
        fontStyle: 'italic',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: 'var(--loafer-white)',
    },
    buttonIcon: {
        width: '24px',
        height: '24px',
        margin: '0 8px 0 0',
        objectFit: 'contain',
    },
    bottomShark: {
        position: 'absolute',
        marginBottom: '-7px',
        bottom: 0,
        [theme.breakpoints.up('lg')]: {
            width: '100vw'
        }
    },
}));
