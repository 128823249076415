import React from 'react';

import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import {useStyles} from "./become-partner-hero.styles";
import BgImage from '../../../assets/become-partner-hero@2x.jpg'
import BgImageMobile from "../../../assets/landing-bike-ams-mobile.jpg";
import BottomShark from '../../../assets/brand-elements-vesper-skyline.svg';
import SectionComponent from "../../general/section/section.component";

const BecomePartnerHero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {matches ?
        <></>
        :
        <SectionComponent transparent={true} size='auto' bgImage={BgImageMobile}>
          <Container maxWidth='xl'>
              <Box className={classes.boxContainer}></Box>
          </Container>
          <img className={classes.bottomShark} src={BottomShark} alt='vesper-shark-bottom'/>
        </SectionComponent>
      }
    </>
  )
};

export default BecomePartnerHero;
