import React from "react";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import SectionComponent from "../../general/section/section.component";
import {useAuth} from "../../../utils/auth";
import {useSnackbar} from "../../../utils/snackbar";
import {useStyles} from "./portal-contact.styles";

const PartnerContact = () => {
  const auth = useAuth();
  const {setSnackbar} = useSnackbar();
  const classes = useStyles();

  const [pending, setPending] = React.useState(false);
  const {handleSubmit, register, errors, reset} = useForm();

  const mutationPartnerReq = useMutation(data => axios.post(`/partner-question/${auth.user.email}`, data));
  const onSubmit = (data) => {
    setPending(true);

    data['partner_name'] = auth.user[0].partner_name;

    mutationPartnerReq.mutate(data);
    setSnackbar('Thank you for your message.', 'success');

    setPending(false);
    reset();
  };

  return (
    <SectionComponent
      bgColor='var(----background-surface)'
      size='auto'
    >
      <div className={classes.contactRoot}>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={12}>
            <Divider className={classes.divider}/>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography className={classes.contactTitle}>Contact</Typography>
          </Grid>
          {/*<Grid item xs={12} lg={5}>*/}
     {/*       <Grid item xs={12} lg={6}>
              <Typography className={classes.vesperTitle}>Vesper B.V.</Typography>
            </Grid>
            <Grid container direction='column' item xs={12}>
              <Typography className={classes.contactDetails}>Singel 303 — 309</Typography>
              <Typography className={`${classes.contactDetails} ${classes.contactDetailsAddress}`}>1012 WJ
                Heerhugowaard</Typography>
              <Typography
                className={`${classes.contactDetails} ${classes.contactDetailsGreen}`}>management@vesperbike.com</Typography>

              <Typography className={`${classes.contactDetails} ${classes.contactDetailsBold}`}>Emergency
                line</Typography>
              <Typography className={`${classes.contactDetails} ${classes.contactDetailsGreen}`}>
                +31 (0)20-6245146
              </Typography>
            </Grid>*/}
          {/*</Grid>*/}
          <Grid item xs={12} lg={7}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12} lg={12} classes={{root: classes.formGridContainer}}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Topic"
                    name="topic"
                    error={errors.topic ? true : false}
                    helperText={errors.topic && errors.topic.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter a topic",
                    })}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Message"
                    name="request"
                    multiline={true}
                    rows={7}
                    error={errors.request ? true : false}
                    helperText={errors.request && errors.request.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter a message",
                    })}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    type='submit'
                    disabled={pending}
                  >
                    {!pending && <Typography className={classes.buttonText}>
                      Send
                    </Typography>}
                    {pending && <CircularProgress color='default' size={28}/>}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </SectionComponent>
  );
}

export default PartnerContact;
