import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    bikePictures: {
        backgroundColor: '#fff',
    },
    verticalCenter: {
      display: 'flex',
      alignItems: 'center',
        justifyContent: 'center'
    },
    imageGalleryBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50
    },
    navBtn: {
        width: 50,
        height: 50,
        border: '3px solid #121212 !important',
        backgroundColor: '#fff',
        borderRadius: 50,
        "&&:hover": {
            cursor: 'pointer',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    navBtnRight: {
        position: 'absolute',
        width: 50,
        backgroundColor: '#fff',
        zIndex: 5,
        height: 50,
        border: '3px solid #121212 !important',
        borderRadius: 50,
        "&&:hover": {
            cursor: 'pointer',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    container: {
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
    },
    section: {
        padding: '0',
        display: 'flex',
        flexDirection: 'row'
    },
    textBox: {
        marginTop: '20vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 'var(--max-width-12)',
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    topSection: {
      zIndex: 3,
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    header: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '12rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '10rem',
        },
        fontSize: '15rem',
        letterSpacing: '-0.58px',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: '#F4F4F4',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        position: 'absolute',
        top: 0,
        right: 0
    },
    headerBox: {
        backgroundColor: '#12B5AD',
        marginBottom: 10,
        zIndex: 1,
        position: 'relative',
        padding: '5px 20px 5px 20px',
        width: 'fit-content',
    },
    headerBoxTitle: {
        fontSize: '2rem',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#202020',
        lineHeight: '1.25',
    },
    subHeader: {
        color: '#909090',
        margin: '8px 0 0 0',
        fontWeight: "normal",
        fontSize: 18,
        maxWidth: '70%',
        textAlign: 'center',
        paddingBottom: 50,
        zIndex: 5
    },
    subHeaderChoose: {
        color: '#909090',
        margin: '8px 0 0 0',
        fontWeight: "normal",
        fontSize: 18,
        maxWidth: '70%',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
        },
    },
    picture: {
        [theme.breakpoints.down('lg')]: {
            width: '80%'
        },
        [theme.breakpoints.down('md')]: {
            width: '60%'
        },
        width: '100%',
        aspectRatio: 3.33,
        objectFit: 'cover',
        marginBottom: 200
    },
    buyOptionsBox: {
        height: 150,
        backgroundColor: '#121212',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 'var(--max-width-12)',
        alignSelf: 'center',
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
            flexDirection: 'column',
            height: 'unset',
        },
    },
    buyOptionsLeft: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center'
    },
    buyOptionsRight: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            paddingTop: 20
        }
    },
    lowerBackSection: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    buttonLocations: {
        height: 30,
        padding: '12px 16px',
        backgroundColor: '#12B5AD',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#12B5AD'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    buttonLocationsText: {
        fontSize: '0.8rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    buttonRef: {
        height: 30,
        padding: '12px 16px',
        backgroundColor: '#FFFFFF',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#FFFFFF'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        }
    },
    buttonTextRef: {
        fontSize: '0.8rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#000',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
}));
