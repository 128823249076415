import React from 'react';
import dayjs from "dayjs";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import {bikesAndStartDates, bikesDates} from "../../../utils/booking";
import {useRouter} from "../../../utils/router";
import {useStyles} from "./portal-reserve-footer.styles";

const PortalReserveFooter = ({data, chosenDate}) => {
  const classes = useStyles();
  const router = useRouter();

  /**
   * If all the rental dates are equal to the chosen date, return true
   * Or if chosen date is before the current date
   * If there aren't any bikes with a booking, assign false to bookedBikes
   */
  const handleDisabled = () => {
    const bikes = bikesAndStartDates(bikesDates(data));
    const bookedBikes = bikes.length ?
      (bikes
        .map(bike => bike.dates.some(date => date.isSame(dayjs(chosenDate), 'date')))
        .every(e => e))
      :
      false;

    return bookedBikes || dayjs(chosenDate).isBefore(dayjs(), "day");
  }

  return (
    <>
      <div className={classes.root}>
        <Button
          disabled={handleDisabled()}
          className={handleDisabled() ? `${classes.buttonDisabled}` : `${classes.button}`}
          type='button'
          onClick={() => router.push(`${router.pathname}/book`, chosenDate)}
        >
          <Typography className={classes.buttonText}>Reserve</Typography>
        </Button>
      </div>
    </>
  )
};

export default PortalReserveFooter;
