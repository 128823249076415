import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@mui/material";
import FilterDropdown from "../filter-dropdown/filter-dropdown.component";
import {Backdrop, Box, Button, Modal, TextField} from "@material-ui/core";
import axios from "axios";
import {useSnackbar} from "../../utils/snackbar";
import {Fade} from "../fade/fade.component";
import StyledButton from "../styled-button/styled-button.component";
import {useQuery} from "react-query";
import {get} from "react-hook-form";
import MoveFormInformation from "../move-form-information/move-form-information.component";
import BikePickerModal from "../bike-picker-modal/bike-picker-modal.component";

const SimpleMoveForm = ({open, handleClose, title, newBike, dataInv}) => {
    const classes = useStyles();
    const {setSnackbar} = useSnackbar();

    const [location, setLocation] = useState('');
    const [count, setCount] = useState(0);
    const [toSelect, setToSelect] = useState('');
    const [selectedBikeLocation, setSelectedBikeLocation] = useState();
    const [extraInfo, setExtraInfo] = useState(false);

    const [toOptions, setToOptions] = useState(["Rental", "Fatdaddy", 'Available Ref.', 'Warehouse', 'Archive']);

    const [max, setMax] = useState(0);

    const [disabledConfirm, setDisabledConfirm] = useState(true);

    const onSubmit = () => {
        if (newBike){
            addNewBike()
        } else {
            moveBike()
        }
    }
    useEffect(() => {
        if (!extraInfo && toSelect !== ''){
            handleClose();
        }
    }, [extraInfo]);

    useEffect(() => {
        setDisabledConfirm(true);
        setLocation('');
        setCount(0);
        setToSelect('');
        setSelectedBikeLocation(null);
    }, [open]);

    useEffect(() => {
        if (newBike){
            if (toSelect !== '' && count >= 1){
                setDisabledConfirm(false);
            }
        } else {
            if (location !== '' && (selectedBikeLocation !== null || count >= 1) && toSelect !== ''){
                setDisabledConfirm(false);
            }
        }
    }, [count, toSelect, location, selectedBikeLocation]);

    useEffect(() => {
        if (location === 'Warehouse'){
            setToOptions(["Rental", "Fatdaddy", 'Archive']);
        } else if (location === 'Rental'){
            setToOptions(["Available Ref."]);
        } else if (location === 'Fatdaddy'){
            setToOptions(['Archive']);
        } else if (location === 'Available Ref.'){
            setToOptions(['Archive']);
        }
    }, [location]);

    const {isLoading, data, refetch} = useQuery(
        ['dashboard-inventory-move'],
        async () => {
            const res = await axios.get('/dashboard/get-bikes');
            return res.data.rows;
        }
    );

    const {isLoading: isLoadingRef, data: dataRef, refetch: refetchRefBike} = useQuery(
        ['dashboard-inventory-refurbished'],
        async () => {
            const res = await axios.get('/dashboard/get-bikes-refurbished');
            return res.data.rows;
        }
    )

    const moveBike = async () => {
        if (location === '' || toSelect === ''){
            setSnackbar(`Vul alle velden in`, 'error');
            return;
        }

        if (location === 'Warehouse'){
            if(toSelect === 'Rental'){
                const bikeInfo = {
                    amount: count,
                    location: 'Rental',
                    oldLocation: location,
                };

                await axios.post('/dashboard/move-bike-inventory', bikeInfo).then(async (res) => {
                    handleClose();
                    setSnackbar(`Bike succesfully transferred..`, 'success');
                }).catch((err) => {
                    setSnackbar(`Bike niet van Warehouse naar Rental verplaatst.`, 'error');
                });
            } else if (toSelect === 'Fatdaddy'){
                const bikeInfo = {
                    amount: count,
                    location: 'Fatdaddy',
                    oldLocation: location
                };

                await axios.post('/dashboard/move-bike-inventory', bikeInfo).then((res) => {
                    setSnackbar(`${count} bike(s) have been added.`, 'success')
                    handleClose();
                }).catch((err) => {
                    setSnackbar(`${err.response.data.message}.`, 'error');
                });
            }
        }

        if (location === 'Rental'){
            console.log(selectedBikeLocation);
            const bikeInfo = {
                bike_id: selectedBikeLocation?.bike_id,
                location: toSelect,
                oldLocation: location,
            };

            await axios.post('/dashboard/move-bike-inventory', bikeInfo).then((res) => {
                setSnackbar(`${count} bike(s) have been added.`, 'success');
                refetch();
                refetchRefBike();
                handleClose();
            }).catch((err) => {
                setSnackbar(`${err.response.data.message}.`, 'error');
            });
        }

        if(toSelect === 'Archive'){
            setExtraInfo(!extraInfo);
        } else if (toSelect !== 'Rental'){
            const bikeInfo = {
                bike_id: selectedBikeLocation?.bike_id,
                location: toSelect,
                oldLocation: location
            };

            await axios.post('/dashboard/move-bike-inventory', bikeInfo).then((res) => {
                setSnackbar(`${res.data}`, 'success')
                handleClose();
                setToSelect('');
                setSelectedBikeLocation(null);
            }).catch((err) => {
                setSnackbar(`${err.response.data.message}.`, 'error');
            });
        }
    }

    const addNewBike = async () => {
        const bikeInfo = {
            amount: count,
            location: 'Warehouse'
        };

        await axios.post('/dashboard/add-new-inventory-item', bikeInfo).then((res) => {
            setSnackbar(`${count} bike(s) have been added.`, 'success')
            handleClose();
        }).catch((err) => {
            setSnackbar(`${err.response.data.message}.`, 'error');
            console.log(err);
        });
    }

    let min = 0;

    useEffect(() => {
        setMax(999);
        if (!newBike){
            setMax(dataInv?.find((obj) => obj.location === location) ? dataInv?.find((obj) => obj.location === location)['count'] : 0);
            setCount(0);
        }
    }, [location]);

    const [bikePicker, setBikePicker] = useState(false);

    return (
        <>
            <Modal
                open={open}
                className={classes.modal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <MoveFormInformation amount={count} previousLocation={location} open={extraInfo} handleClose={() => setExtraInfo(!extraInfo)} moveObj={{bike: selectedBikeLocation, to: toSelect}}/>
                    <BikePickerModal title="Pick refurbished V-bike(s)" open={bikePicker} handleClose={(bike) => {setBikePicker(false); setSelectedBikeLocation(bike)}} bikes={dataRef}/>
                    <Box className={classes.box}>
                        <Grid container style={{height: '100%', padding: '5%'}} direction="column">
                            <Grid item xs>
                                <Typography variant="h5">{title}</Typography>
                            </Grid>
                            <Grid item xs>
                                <Grid container direction="column" className={classes.form}>
                                    {!newBike &&
                                        <>
                                            <Grid item xs>
                                                <Grid container direction="row" style={{paddingBottom: '5%'}}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h6">From</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <FilterDropdown listItem placeholder={selectedBikeLocation?.location} optionsArray={["Rental", "Available Ref.", "Fatdaddy", "Warehouse"]} onSelect={(text) => setLocation(text)}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {(location === "Rental" || location === "Available Ref.") &&
                                                <Grid item xs>
                                                    <Grid container direction="row">
                                                        <Grid item xs={4}>
                                                            <Typography variant="h6">Bike number</Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            {location === 'Rental'
                                                                ?
                                                                <FilterDropdown listItem placeholder=""
                                                                                optionsArray={location === 'Available Ref.'
                                                                                    ?
                                                                                    dataRef?.map((bike) => bike.display_number.toString())
                                                                                    :
                                                                                    data?.map((bike) => bike.display_number.toString())
                                                                                }
                                                                                onSelect={(number) => {
                                                                                    location === 'Available Ref.'
                                                                                        ?
                                                                                        setSelectedBikeLocation(dataRef.find((bike) => bike.display_number.toString() === number))
                                                                                        :
                                                                                        setSelectedBikeLocation(data.find((bike) => bike.display_number.toString() === number))
                                                                                }}/>
                                                                :
                                                                <Box style={{width: '100%', height: '100%', backgroundColor: '#545454', borderRadius: 5, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => setBikePicker(true)}>
                                                                    <Typography variant="h7">{selectedBikeLocation?.bike_id}</Typography>
                                                                </Box>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {(newBike || location === "Fatdaddy" || location === "Warehouse") &&
                                        <Grid item xs>
                                            <Grid container direction="row" style={{paddingBottom: '5%'}}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Amount</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField id="standard-basic" variant="standard" value={count} className={classes.inputField} onChange={(e) => {
                                                        if (!isNaN(e.target.value)){
                                                            if (e.target.value === ''){
                                                                setCount(0);
                                                                e.target.value = 0;
                                                            }

                                                            let value = parseInt(e.target.value, 10);

                                                            if (value > max) value = max;
                                                            if (value < min) value = min;

                                                            setCount(value);
                                                        }
                                                    }}/>
                                                </Grid>
                                                <Grid item xs className={classes.partnerComponents}>
                                                    <Button className={classes.buttonCount} onClick={() => {
                                                        let value = parseInt(count - 1, 10);

                                                        if (value > max) value = max;
                                                        if (value < min) value = min;

                                                        setCount(value);

                                                        if (value < 0){
                                                            setCount(0);
                                                        }
                                                    }}>
                                                        <Typography>-</Typography>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs className={classes.partnerComponents}>
                                                    <Button className={classes.buttonCount} onClick={() => {
                                                        let value = parseInt(count + 1, 10);

                                                        if (value > max) value = max;
                                                        if (value < min) value = min;

                                                        setCount(value);
                                                    }}>
                                                        <Typography>+</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item xs>
                                        <Grid container direction="row">
                                            <Grid item xs={4}>
                                                <Typography variant="h6">To</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FilterDropdown listItem placeholder="" optionsArray={newBike ?  ["Warehouse"] : toOptions} onSelect={(location) => {setToSelect(location)}}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" className={classes.buttonSection}>
                                <Grid item xs={6} className={classes.center}>
                                    <StyledButton popupCancel text="Cancel" onClick={handleClose}/>
                                </Grid>
                                <Grid item xs={6} className={classes.center}>
                                    <StyledButton popupConfirm={!disabledConfirm} popupConfirmDisabled={disabledConfirm} text={newBike ? 'Add' : 'Move'} onClick={() => onSubmit()}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    buttonSection: {
        paddingTop: '5%'
    },
    form: {
        paddingTop: '5%',
        paddingBottom: '5%'
    },
    inputField: {
        width: '100%',
        paddingLeft: '10%',
        paddingRight: '5%',
        backgroundColor: '#545454',
        borderRadius: 5,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    },
    box: {
        backgroundColor: '#1f1f1f',
        width: '30vw',
        height: '100%',
        minWidth: 600
    },
    center: {
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: '10%',
        paddingRight: '10%'
    },
    buttonCount: {
        backgroundColor: '#12B5AD',
        borderRadius: 10,
        height: '80%',
        "&&:hover": {
            backgroundColor: 'rgba(18, 181, 173, 0.8)'
        },
    },
    partnerComponents: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default SimpleMoveForm;
