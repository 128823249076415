import React from 'react';
import TextField from "@material-ui/core/TextField";
import {useStyles} from "../enhanced-table.styles";

export const FilterNumberRangeColumn = ({column: {filterValue = [], preFilteredRows, setFilter, id}}) => {
  const classes = useStyles();

  const [min] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div className=''>
      <TextField
        className={classes.filterInput}
        value={filterValue[0] || ''}
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
      />
    </div>
  )
};
