import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import {Container, Dialog, Grid, InputBase, Paper} from "@material-ui/core";
import SectionComponent from "../../general/section/section.component";
import SearchInputField from "../../../atoms/search-input-field/search-input-field.component";
import FilterDropdown from "../../../atoms/filter-dropdown/filter-dropdown.component";
import AdminRentalCard from "../admin-rental-card/admin-rental-card.component";
import axios from "axios";
import PageLoaderComponent from "../../general/page-loader/page-loader.component";
import BikeModel from "../../../models/dashboard/bike.model";
import PartnerModel from "../../../models/dashboard/partner.model";
import StyledButton from "../../../atoms/styled-button/styled-button.component";
import CreatePartnerForm from "../admin-create-partner-form/create-partner-form.component";

const AdminRentalComponent = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [bikeData, setBikeData] = useState([]);
    const [timespan, setTimespan] = useState('total');

    const {data: partnerData} = useQuery(
        ['partners'],
        async () => {
            const res = await axios.get('/dashboard/get-partners');
            let partnerArray = [];
            res.data.rows.forEach((partner) => {
                partnerArray.push(new PartnerModel(partner.partner_id, partner.partner_name));
            })
            return partnerArray;
        }
    )

    // Sort functions
    const searchBike = (text) => {
        setBikeData(data.filter((bike) => bike._display_number.toString().includes(text.target.value)));
    }

    const searchPartner = (text) => {
        setBikeData(data.filter((bike) => bike._partner_name?.toString().toLowerCase().includes(text.target.value?.toString().toLowerCase())));
    }

    useEffect(async () => {
        console.log("IK LAAD");

        // Initial load bikes
        const res = await axios.get('/dashboard/get-bikes');
        let bikeArray = [];
        res.data.rows.forEach((bike) => {
            bikeArray.push(new BikeModel(bike.bike_id, bike.state, bike.partner_name, bike.partner_id, bike.total_frequency, bike.total_repairs, bike.framenumber, bike.gps_tag, bike.date_added, bike.week_frequency, bike.month_frequency, bike.year_frequency, bike.display_number));
        })

        console.log(bikeArray);

        setBikeData(bikeArray);
        setData(bikeArray);

        setIsLoading(false);

        // Initial load partners


    }, []);

    const setTimeSpan = (text) => {
        switch (text){
            case '7 days': {
                setTimespan('week');
                break;
            }
            case '30 days': {
                setTimespan('month');
                break;
            }
            case '365 days': {
                setTimespan('year');
                break;
            }
            case 'Total':{
                setTimespan('total');
                break;
            }
        }
    }

    const sortBikes = (text) => {
        switch (text){
            case 'Location (A-Z)': {
                const sort = [...data].sort((a, b) => a._partner_name > b._partner_name ? 1 : -1);
                setBikeData(sort);
                break;
            }
            case 'Bike number': {
                const sort = [...data].sort((a, b) => a._display_number > b._display_number ? 1 : -1);
                setBikeData(sort);
                break;
            }
            case 'Most used': {
                const sort = [...data].sort((a,b) => {return b[`_${timespan}_frequency`]-a[`_${timespan}_frequency`]});
                setBikeData(sort);
                break;
            }
            case 'Least used':{
                const sort = [...data].sort((a,b) => {return a[`_${timespan}_frequency`]-b[`_${timespan}_frequency`]});
                setBikeData(sort);
                break;
            }
        }
    }

    const [openPartner, setOpenPartner] = React.useState(false)

    const handlePartnerDialog = () => setOpenPartner(!openPartner);

    return (
        <>
            <SectionComponent size='dashboard'>
                <Container maxWidth='lg'>

                <Dialog
                    maxWidth={false}
                    open={openPartner}
                    onClose={handlePartnerDialog}
                >
                    <CreatePartnerForm handleDialog={handlePartnerDialog}/>
                </Dialog>
                {/* Section above */}
                <Grid container spacing={2}>
                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <StyledButton text="Add partner" onClick={() => handlePartnerDialog()}/>
                    </Grid>
                    <Grid item xs>
                        <FilterDropdown placeholder="Sort" optionsArray={["Location (A-Z)" ,"Bike number", "Most used", "Least used"]} onSelect={(text) => sortBikes(text)}/>
                    </Grid>
                    <Grid item xs>
                        <FilterDropdown placeholder="Timespan" optionsArray={["7 days", "30 days", "365 days", "Total"]} onSelect={(text) => setTimeSpan(text)}/>
                    </Grid>
                    <Grid item xs>
                        <SearchInputField placeholder="Search partner" onChange={(text) => searchPartner(text)}/>
                    </Grid>
                    <Grid item xs>
                        <SearchInputField placeholder="Search bike" onChange={(text) => searchBike(text)}/>
                    </Grid>
                </Grid>

                {/* List view*/}
                    {isLoading ?
                        <PageLoaderComponent/>
                        :
                        <Grid container spacing={1} style={{paddingTop: '0.5%'}}>
                            {bikeData?.map((bm, index) => {
                                return <>
                                    <Grid item xs={12} key={bm._bike_id}>
                                        <AdminRentalCard bikeModel={bm} partners={partnerData} timespan={timespan} key={bm._bike_id}/>
                                    </Grid>
                                </>
                            })}
                        </Grid>
                    }
                </Container>
            </SectionComponent>
        </>
    );
};

export default (AdminRentalComponent);
