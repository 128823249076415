import * as React from 'react';

import {useStyles} from './specifications-landing.styles';
import {Container} from "@mui/material";
import SectionComponent from "../../../general/section/section.component";
import {useQuery} from "react-query";
import {client} from "../../../../utils/contentful";

const SpecificationsLandingComponent = () => {

    const classes = useStyles();

    const {data} = useQuery(
        ['landing-b2c-shop-specifications'],
        async () => {
            const res = await client.getEntries({content_type: 'shopSpecifications'});
            return res.items;
        }
    );

    return (
        <>
            <SectionComponent size="auto" className={classes.root} bgColor={"#fff"}>
                <Container maxWidth={false} className={classes.container}>
                    <table style={{width: '100%', borderCollapse: 'collapse'}}>
                        {data?.map((specification, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{color: 'black', border: '1px solid #9e9e9e', padding: 8}}>{specification.fields.parameter}</td>
                                    <td style={{color: 'gray', border: '1px solid #9e9e9e', padding: 8}}>{specification.fields.specification}</td>
                                </tr>
                            )
                        })}
                    </table>
                </Container>
            </SectionComponent>
        </>
    )
};

export default SpecificationsLandingComponent;
