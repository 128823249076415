import React from 'react';
import axios from "axios";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

import {useStyles} from './create-partner-form.styles';
import {useAuth} from "../../../utils/auth";
import {useSnackbar} from "../../../utils/snackbar";
import {phoneNumberReg} from "../../../utils/booking";

const CreatePartnerForm = ({handleDialog}) => {
  const classes = useStyles();
  const auth = useAuth();
  const {setSnackbar} = useSnackbar();

  const [pending, setPending] = React.useState(false);
  const [formAlert, setFormAlert] = React.useState(null);

  const handleFormAlert = (data) => setFormAlert(data);

  const {handleSubmit, errors, register} = useForm({
    mode: "onChange"
  });
  const mutationAddPartner = useMutation(data => axios.post('add-partner', data));

  const onSubmit = (data, e) => {
    e.preventDefault();
    setPending(true);

    auth.signup(data.email)
      .then(() => {
        mutationAddPartner.mutate(data);
        auth.sendPasswordResetEmail(data.email);
        setSnackbar(`Added ${data.partner_name} as partner.`, 'success');
      }).catch((error) => {
      setSnackbar(`${data.partner_name} already exists.`, 'error');
      handleFormAlert({
        type: "error",
        message: error.message,
      });
    });

    e.target.reset();
    handleDialog();
  };

  return (
    <>
      <Grid container classes={{root: classes.gridRoot}} spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.mainHeader}>Add partner</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider}/>
        </Grid>
        <Grid item xs={12}>
          {formAlert && (
            <Box mb={3}>
              <Alert severity={formAlert.type}>{formAlert.message}</Alert>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container item xs={12} spacing={4}>
              {/*<Grid item xs={6}>*/}
              {/*  <TextField*/}
              {/*    className={classes.inputField}*/}
              {/*    variant="outlined"*/}
              {/*    type="text"*/}
              {/*    label="Partner Code"*/}
              {/*    name="partner_code"*/}
              {/*    multiline={true}*/}
              {/*    rows={1}*/}
              {/*    error={errors.partner_code ? true : false}*/}
              {/*    helperText={errors.partner_code && errors.partner_code.message}*/}
              {/*    fullWidth={true}*/}
              {/*    inputRef={register({*/}
              {/*      required: "Please enter a value",*/}
              {/*      max: {*/}
              {/*        value: 16,*/}
              {/*        message: 'Partner code is too long, max is 16.'*/}
              {/*      }*/}
              {/*    })}*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={6}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  type="text"
                  label="Partner name"
                  name="partner_name"
                  multiline={true}
                  rows={1}
                  error={errors.partner_name ? true : false}
                  helperText={errors.partner_name && errors.partner_name.message}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter a value",
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  type="text"
                  label="Email"
                  name="email"
                  multiline={true}
                  rows={1}
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  inputRef={register({
                    required: "Enter your e-mail",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid e-mail address",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  type="number"
                  label="Phone number"
                  name="phone_number"
                  placeholder='+31(0)2032421'
                  multiline={true}
                  rows={1}
                  error={errors.phone_number ? true : false}
                  helperText={errors.phone_number && errors.phone_number.message}
                  fullWidth={true}
                  inputRef={register({
                    pattern: {
                      value: phoneNumberReg,
                      message: "Enter a valid phone number",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  type="text"
                  label="Street name"
                  name="street_name"
                  multiline={true}
                  rows={1}
                  error={errors.street_name ? true : false}
                  helperText={errors.street_name && errors.street_name.message}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter a value",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Please only use alphabetic characters"
                    }
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  type="text"
                  label="Street number"
                  name="street_number"
                  multiline={true}
                  rows={1}
                  error={errors.street_number ? true : false}
                  helperText={errors.street_number && errors.street_number.message}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter a value",
                    pattern: {
                      value: /^\d+$/,
                      message: 'Please enter a number'
                    }
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  type="text"
                  label="House suffix"
                  name="house_suffix"
                  multiline={true}
                  rows={1}
                  error={errors.house_suffix ? true : false}
                  helperText={errors.house_suffix && errors.house_suffix.message}
                  fullWidth={true}
                  inputRef={register()}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  type="text"
                  label="Postal code"
                  name="postal_code"
                  multiline={true}
                  rows={1}
                  error={errors.postal_code ? true : false}
                  helperText={errors.postal_code && errors.postal_code.message}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter a value",
                    pattern: {
                      value: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
                      message: 'Please enter a valid postal code.'
                    }
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  type="text"
                  label="City"
                  name="city"
                  multiline={true}
                  rows={1}
                  error={errors.city ? true : false}
                  helperText={errors.city && errors.city.message}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter a value",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Please only use alphabetic characters"
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={pending}
                  className={classes.button}
                  type="submit"
                >
                  {!pending && <span className={classes.buttonText}>Add</span>}
                  {pending && <CircularProgress size={28}/>}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  )
};

export default CreatePartnerForm;
