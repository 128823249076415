import React from 'react';
import {Grid, Container} from "@mui/material";
import {useStyles} from "./HeroMainNav.styles";
import Typography from "@material-ui/core/Typography";
import SectionComponent from "../../../general/section/section.component";
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import Button from "@material-ui/core/Button";
import LogoColorIcon from '../../../../assets/rich-icons-vesper.svg';

const HeroMainNavNoPaddingComponent = ({business, noContact}) => {

    // Imports
    const classes = useStyles();

    // States

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent transparent={true} size="auto" className={classes.section}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container direction={'row'} height={'100%'}>
                        <Grid item xs={6}>
                            <div className={classes.breadcrumb}>
                                <img src={LogoColorIcon} alt={'Vesper logo'} className={classes.logo}/>
                                <Typography className={classes.header}>Vesper</Typography>
                                <Typography className={`${classes.header} ${classes.breadCrumbSubHeader}`}>{business ? `/ FOR ${business}` : ``}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            {!noContact &&
                                <div className={classes.breadCrumbEnd}>
                                    <Button
                                        className={classes.buttonLocations}
                                        component={Link}
                                        to='/contact'
                                    >
                                        <Typography className={classes.buttonLocationsText}>
                                            Contact Us
                                        </Typography>
                                        <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                                    </Button>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroMainNavNoPaddingComponent;
