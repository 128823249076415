import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-8)',
  },
  mainBox: {
    padding: '24px',
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#222222',
    margin: '24px 0 8px',
    [theme.breakpoints.up('sm')]: {
      margin: '24px 0 12px',
    }
  },
  subBox: {
    padding: '24px',
    border: 'solid 1px #2f2f2f',
    backgroundColor: 'var(--background-surface)',
    margin: '8px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '12px 0 12px',
    }
  },
  boxText: {
    marginTop: '8px'
  },
  boxTextOrange: {
    fontWeight: '500',
    color: 'var(--buttercup-yellow)'
  },
  button: {
    margin: '32px 0 0 0',
    padding: '12px 16px',
    backgroundColor: 'var(--buttercup-yellow)',
    borderRadius: 0,
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    }
  },
  buttonIcon: {
    width: '24px',
    margin: '8px',
    objectFit: 'contain',
  },
  buttonText: {
    fontSize: '1rem',
    margin: '0 0 0 8px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 'normal',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--midnight-forest-black)',
  },
}))
