import React from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import icDateRange from "../../assets/ic-date-range.svg";
import {makeStyles} from "@material-ui/core/styles";

const SimpleDatePicker = ({chosenDate, setChosenDate, text}) => {
    const classes = useStyles();

    return (
        <>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
                <KeyboardDatePicker
                    autoOk={true}
                    className={classes.datePicker}
                    keyboardIcon={<img src={icDateRange} className={classes.dateRangeIcon} alt='Calendar'/>}
                    inputVariant='outlined'
                    label={text}
                    emptyLabel="DD/MM/YYYY"
                    openTo="year"
                    views={['year', 'month', 'date']}
                    format="DD/MM/YYYY"
                    value={chosenDate}
                    onChange={setChosenDate}
                    InputProps={{
                        classes: {
                            root: classes.input
                        }
                    }}
                />
            </MuiPickersUtilsProvider>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    datePicker: {
        [theme.breakpoints.down('sm')]: {
            width: '-webkit-fill-available'
        },
        width: '100%',
        height: '100%'
    },
    dateRangeIcon: {
        width: '24px',
        margin: '0 0 0 50px',
        objectFit: 'contain',
        "&&:hover": {
            opacity: '0.7'
        }
    },
    input: {
        fontSize: '1rem !important',
        height: '100%'
    },
    dot: {
        marginTop: '8px',
        marginRight: '8px',
    }
}));

export default SimpleDatePicker;
