import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loginButton: {
    width: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    padding: '12px 16px',
    backgroundColor: 'var(--buttercup-yellow)',
    borderRadius: 0,
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    }
  },
  loginButtonText: {
    fontSize: '1rem',
    fontWeight: '600',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--midnight-forest-black)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
