import React from 'react';
import PartnerServiceListItem from "../../../atoms/partner-service-list-item/partner-service-list-item.component";
import ScrollableList from "../../../atoms/scrollable-list/scrollable-list.component";
import {useQuery} from "react-query";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Typography} from "@material-ui/core";

const AdminDashboardService = () => {
    const {isLoading, data} = useQuery(
        ['dashboard-service'],
        async () => {
            const res = await axios.get('/dashboard/get-bike-reports');
            return res.data.rows;
        }
    );

    return (
        <ScrollableList height="95%">
            {isLoading ?
                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress size={64}/>
                </div>
            :
                <>
                    {data?.length === 0
                        ?
                            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography>Er zijn geen reparaties gevonden.</Typography>
                            </div>
                        :
                        <>
                            {data?.map((serviceItem) => {
                                return <PartnerServiceListItem serviceItem={serviceItem}/>
                            })}
                        </>
                    }
                </>
            }
        </ScrollableList>
    )
};

export default AdminDashboardService;
