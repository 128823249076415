import React, {useState} from 'react';
import SectionComponent from "../../general/section/section.component";
import {Container, Grid} from "@material-ui/core";
import FilterDropdown from "../../../atoms/filter-dropdown/filter-dropdown.component";
import SearchInputField from "../../../atoms/search-input-field/search-input-field.component";
import AdminArchiveCard from "../admin-archive-card/admin-archive-card.component";
import {useQuery} from "react-query";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

const AdminArchiveComponent = () => {
    const [bikeData, setBikeData] = useState([]);

    const initialize = (data) => {
        setBikeData(data);
        sortBikes("Newest first");
    }

    const {isLoading, data, refetch} = useQuery(
        ['dashboard-get-archive'],
        async () => {
            const res = await axios.get('/dashboard/get-archive');
            return res.data.rows;
        },
        {onSuccess: initialize}
    );

    const convertToDate = (dateString) => {
        let d = dateString.split("/");
        let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
        return dat;
    }

    const sortBikes = (text) => {
        if (data === undefined){
            return;
        }

        switch (text){
            case 'Newest first': {
                const sort = [...data].sort((a, b) => convertToDate(a.date_archived) < convertToDate(b.date_archived) ? 1 : -1);
                setBikeData(sort);
                break;
            }
            case 'Oldest first': {
                const sort = [...data].sort((a, b) => convertToDate(a.date_archived) > convertToDate(b.date_archived) ? 1 : -1);
                setBikeData(sort);
                break;
            }
            case 'Price': {
                const sort = [...data].sort((a, b) => a.price < b.price ? 1 : -1);
                setBikeData(sort);
                break;
            }
            case 'Amount': {
                const sort = [...data].sort((a,b) => a.amount < b.amount ? 1 : -1);
                setBikeData(sort);
                break;
            }
        }
    }

    const getDateObj = (dateString) => {
        const dateObjString = `${parseInt(dateString.toString().substring(6,10))}/${parseInt(dateString.toString().substring(3,5))}/${parseInt(dateString.toString().substring(0,2))}`;
        return new Date(dateObjString);
    }

    const setTimeSpan = (text) => {
        const currentDate = new Date();
        switch (text){
            case '7 days': {
                let last = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));

                setBikeData(data.filter((archive) => {
                    const objDate = getDateObj(archive.date_archived);
                    return objDate > last;
                }));
                break;
            }
            case '30 days': {
                let last = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
                setBikeData(data.filter((archive) => {
                    const objDate = getDateObj(archive.date_archived);
                    return objDate > last;
                }));
                break;
            }
            case '365 days': {
                let last = new Date(currentDate.getTime() - (365 * 24 * 60 * 60 * 1000));
                setBikeData(data.filter((archive) => {
                    const objDate = getDateObj(archive.date_archived);
                    return objDate > last;
                }));
                break;
            }
            case 'Total':{
                setBikeData(data);
                break;
            }
        }
    }

    const searchLocation = (text) => {
        setBikeData(data.filter((bike) => bike.original_location.toString().includes(text.target.value)));
    }

    return (
        <>
            <SectionComponent size='dashboard'>
                <Container maxWidth='lg'>
                    {/* Section above */}
                    <Grid container spacing={2} style={{paddingBottom: 4}}>
                        <Grid item xs={4}>
                            {/*    Leave empty to comply with design */}
                        </Grid>
                        <Grid item xs>
                            <FilterDropdown placeholder="Sort" optionsArray={["Newest first", "Oldest first", "Price", "Amount"]} onSelect={(text) => sortBikes(text)}/>
                        </Grid>
                        <Grid item xs>
                            <FilterDropdown placeholder="Timespan" optionsArray={["7 days", "30 days", "365 days", "Total"]} onSelect={(text) => setTimeSpan(text)}/>
                        </Grid>
                        <Grid item xs>
                            <SearchInputField placeholder="Search" onChange={(text) => searchLocation(text)}/>
                        </Grid>
                    </Grid>

                    {/* List view*/}
                    {isLoading ?
                        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <CircularProgress size={64}/>
                        </div>
                    :
                        <Grid container spacing={1}>
                            {bikeData?.map((archiveObj) => (
                                <Grid item xs={12}>
                                    <AdminArchiveCard archiveObj={archiveObj} refetch={() => refetch()}/>
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Container>
            </SectionComponent>
        </>
    );
};

export default (AdminArchiveComponent);
