import React, {useEffect, useState} from 'react';
import {useStyles} from "./hero-buy-section-head.styles";
import SectionComponent from "../../../general/section/section.component";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ImageGallery from "react-image-gallery";
import "./styling.css";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Link, useRouter} from "../../../../utils/router";
import {FormControl, InputLabel, MenuItem} from "@mui/material";
import Select from "@mui/material/Select";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import {newBikePrice, exDemoBikePrice, exDemoBikePriceWithChain} from '../../config';

const HeroBuySectionHeadComponent = ({data}) => {

    // Imports
    const classes = useStyles();
    const router = useRouter();

    // States
    const [bike, setBike] = React.useState(null);

    const handleChange = (event) => {
        setBike(event.target.value);
    };

    // Functions
    const orderBikeLocal = () => {
        bike && router.push(`/shop/continue/order/${bike}`);
    }

    // Declarations
    const [properties, setProperties] = useState({
        thumbnailPosition: "bottom",
        useBrowserFullscreen: false,
        showFullscreenButton: false,
        showNav: false,
        showPlayButton: false,
        items: []
    })

    useEffect(() => {
        const arr = data?.bikePictures.map((picture) => {
            return {original: picture.fields.file.url, thumbnail: picture.fields.file.url};
        });

        if (!arr){
            setProperties(properties => properties);
        } else {
            setProperties(properties => {
                const copy = properties;
                copy['items'] = arr;
                return copy;
            });
        }
    }, [data]);

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto" className={classes.root} bgColor={'#fff'}>
                <Box className={classes.bgBox}>
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container direction={'row'}>
                            <Grid item className={classes.leftSection}>
                                <div className={classes.imageGallery}>
                                    {data
                                        ?
                                        <ImageGallery {...properties}/>
                                        :
                                        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <Typography>Er zijn geen reparaties gevonden.</Typography>
                                        </div>
                                    }
                                </div>
                            </Grid>
                            <Grid item className={classes.rightSection}>
                                <Typography className={classes.header}>THE ORIGINAL <a className={classes.vesperBlue}>VESPER</a> BIKE</Typography>
                                <div className={classes.optionsSection}>
                                    <Typography className={classes.bikeBoxHeader} style={{paddingBottom: 5}}>From €{newBikePrice}.00</Typography>
                                    <div className={classes.horizontalFlex}>
                                        <Typography variant='caption2' className={classes.subHeaderBlack}>or</Typography>
                                        <Button
                                            className={classes.buttonLocations}
                                            component={Link}
                                            to='/shop/continue'
                                        >
                                            <Typography className={classes.buttonLocationsText}>
                                                Ex-demo
                                            </Typography>
                                        </Button>
                                        <Typography variant='caption2' className={classes.subHeaderBlack}>for <a style={{fontWeight: 'bold', fontStyle: 'italic'}}>€{exDemoBikePrice}.00</a></Typography>
                                    </div>
                                </div>

                                <Typography variant={'caption2'} style={{color: 'black'}}>Choose model:</Typography>
                                <FormControl fullWidth style={{paddingTop: 10, marginBottom: 20}}>
                                    <InputLabel id="demo-simple-select-label" style={{paddingTop: 10}}>Bike</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={bike}
                                        label="Bike"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"947e6be83e7e2e3ddf3148da3c2d6052"}>New - €{newBikePrice}.00</MenuItem>
                                        <MenuItem value={"4b513d53d56b79fd8a41da3009f95d61"}>Ex-demo - €{exDemoBikePrice}.00</MenuItem>
                                        <MenuItem value={"87fe131535a71cd6f77a6ce63ea1a406"}>Ex-demo + Used ART-2 chain lock - €{exDemoBikePriceWithChain}.00</MenuItem>
                                    </Select>
                                </FormControl>

                                <Typography variant={'caption2'} style={{color: 'gray'}}>{data?.subtitleHeader}</Typography>

                                <ul style={{paddingLeft: 20}}>
                                    {data?.headerDots.map((dot, index) => {
                                        return <li key={index} className={classes.listItem}>{dot}</li>
                                    })}
                                </ul>

                                <Button
                                    className={classes.buttonLocationsOrder}
                                    disabled={!bike}
                                    style={{opacity: bike ? 1 : 0.5}}
                                    onClick={() => orderBikeLocal()}
                                >
                                    <Typography className={classes.buttonLocationsTextOrder}>
                                        Order now
                                    </Typography>
                                    <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </SectionComponent>
        </>
    )
};

export default HeroBuySectionHeadComponent;
