import React from 'react';

import Container from "@material-ui/core/Container";

import {makeStyles} from "@material-ui/core/styles";
import {reserving, conditions, iAlreadyReserved} from './guest-faq-reserve.json';

import Faq from "../../general/faq/faq.component";
import DoubleBreadCrumb from "../../general/double-bread-crumb/double-bread-crumb.component";
import SectionComponent from "../../general/section/section.component";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '64px 0 8px 0',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '158px',
      paddingBottom: '8px',
    },
  },
  container: {
    maxWidth: 'var(--max-width-8)'
  },
}));

const GuestFaqReserve = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        <SectionComponent size='auto'>
          <DoubleBreadCrumb start="Contact & FAQ" current="About the reservation" rerouteUrl='/faq'/>
        </SectionComponent>
        <Faq
          mainHeader='About the reservation'
          subHeader='Everything you need to know about the Vesper bike and riding it.'
          categories={[reserving, conditions, iAlreadyReserved]}
          crumb={true}
        />
      </Container>
    </div>
  )
};

export default GuestFaqReserve;
