import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        maxWidth: 'var(--max-width-12)',
    },
    bgBox: {
        backgroundColor: '#fff',
        [theme.breakpoints.down('sm')]: {
            // padding: '10vh 0 10vh 0',
            padding: '0 24px 0 24px'
        },
    },
    section: {
        maxWidth: '50%',
        flexBasis: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    root: {
        padding: '0',
    },
    headerBoxTitle: {
        fontSize: '2rem',
        textTransform: 'uppercase',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        padding: '10px 0 10px 0',
        color: '#000'
    },
    subHeader: {
        color: '#848484',
        fontWeight: "normal",
        fontSize: 18,
    },
    leftTextBox: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20%',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    rightTextBox:{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    divider: {
        height: 20,
        alignSelf: "baseline"
    },
    list: {
        paddingLeft: '20px !important'
    },
    listItem: {
        color: '#121212',
        "&::marker": {
            color: 'var(--java-green)'
        }
    },
    picture: {
        width: '100%',
        aspectRatio: 2,
        objectFit: 'cover',
        objectPosition: 'bottom',
        [theme.breakpoints.down('sm')]: {
            aspectRatio: 1,
        },
    }
}));
