import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '32px',
    height: '131px',
    border: 'solid 1px #2f2f2f',
    backgroundColor: 'var(--background-surface)',
    padding: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    backgroundColor: 'transparent !important',
    margin: '32px 0',
  },
  listItem: {
    width: 'auto',
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#222222',
    height: '160px',
    padding: '16px',
    margin: '0 0 25px 0px',
    [theme.breakpoints.up('sm')]: {
      height: '59px',
      margin: '12px 0',
      padding: '16px 24px 16px 26px',
    }
  },
  guestName: {
    fontWeight: 'bold',
    color: 'var(--loafer-white-soft)',
    minWidth: '177px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 30px 0 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 60px 0 0',
    }
  },
  vesperBikes: {
    color: 'var(--loafer-white-soft)',
    [theme.breakpoints.up('sm')]: {
      margin: '0 24px',
    }
  },
  vesperBikeId: {
    fontWeight: 'bold',
    color: 'var(--loafer-white-soft)',
    margin: '0 24px 0 0',
    [theme.breakpoints.up('sm')]: {
      margin: '0 12px',
    }
  },
  dividerLg: {
    backgroundColor: 'rgba(249,252,246, 0.12)',
    width: '1px',
    height: '27px',
    margin: '0 24px',
  },
  dividerSm: {
    width: '256px',
    height: '1px',
    margin: '14px 0 16px',
    opacity: '0.12',
    backgroundColor: 'var(--loafer-white)',
  },
  nextIcon: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
    [theme.breakpoints.up('sm')]: {
      margin: '1px 2px',
    }
  },
  idGrid: {
    display: 'flex',
  },
  nameGrid: {
    display: 'flex',
    alignItems: 'center',
  },
}));
