import React, {createContext, useContext, useState} from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Typography from "@material-ui/core/Typography";

import { makeStyles } from '@material-ui/core/styles';
import SuccessIcon from "../assets/ic-check-circle.svg";
import ErrorIcon from '../assets/ic-add.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 0 60px 0',
    padding: '12px',
    boxShadow: '1px 8px 8px 0 rgba(0, 0, 0, 0.32)',
    backgroundColor: '#2e2e2e',
  },
  snackMessage: {
    width: 'auto',
    margin: '0 12px',
    fontSize: '1.125em',
    fontWeight: '300',
    letterSpacing: '0.5px',
    color: 'var(--loafer-white)',
  },
  closeText: {
    margin: '12px 24px',
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'var(--java-green)',
    cursor: 'pointer',
  },
  successIcon: {
    width: '24px',
    height: '24px',
    margin: '11px 12px 13px 0',
    objectFit: 'contain',
  },
}));

export const AppSnackbar = () => {
  const classes = useStyles();
  const { snackbar, setSnackbar } = useSnackbar();
  const { message, type, open } = snackbar;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(message, type, false);
  };

  return (
    <div>
      <Snackbar
        open={!!open}
        autoHideDuration={4000}
        onClose={handleClose}
        ContentProps={{
          classes: {
            root: classes.root
          }
        }}
        action={
          <React.Fragment>
            {
              snackbar.type === 'success' ?
                <img src={SuccessIcon} className={classes.successIcon} alt="Success Icon"/>
                :
                <img src={ErrorIcon} className={classes.successIcon} alt="Success Icon"/>
            }
            <Typography className={classes.snackMessage}>{message}</Typography>
            <Typography className={classes.closeText} onClick={handleClose}>
              Close
            </Typography>
          </React.Fragment>
        }
      />
    </div>
  );
}

const snackBarContext = createContext({})

export const SnackBarProvider = ({children}) => {
  const [snackbar, setSnackbar] = useState({
    message: '',
    type: 'default',
    open: false
  });

  const handleSnackbarSet = (message, type = 'default', open = true) => {
    setSnackbar({
      message, type, open
    })
  };

  const contextValue = {
    setSnackbar: handleSnackbarSet,
    snackbar
  };

  return (
    <snackBarContext.Provider value={contextValue}>
      {children}
    </snackBarContext.Provider>
  )
}

export const useSnackbar = () => {
  return useContext(snackBarContext);
}
