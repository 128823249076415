import React from 'react';
import axios from "axios";
import {useQuery} from "react-query";
import {useStateMachine} from "little-state-machine";
import {useForm, Controller, useWatch, useFieldArray} from "react-hook-form";
import dayjs from "dayjs";

import {selectTheme} from "../../../utils/theme";
import updateFormAction from "../../../state-machine/actions/update-form-action";
import {useRouter} from "../../../utils/router";
import {
  assignBikes,
  availableBikes,
  bikesDates,
  calculateCostBooking
} from "../../../utils/booking";

import {ThemeProvider} from "@material-ui/core/styles";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AddIcon from "@material-ui/icons/Add";
import {
  CircularProgress,
  TextField,
  Tooltip,
  InputAdornment,
  MenuItem,
  Button,
  Grid,
  Container,
  Typography,
  Divider,
  Box,
  IconButton, Paper
} from "@material-ui/core";

import ControlledDatePicker from "../../../atoms/date-picker-booking/date-picker-booking.component";
import SectionComponent from "../../general/section/section.component";
import MainNav from "../../general/main-nav/main-nav.component";
import TooltipInsurance from "../../../atoms/tooltip-insurance/tooltip-insurance.component";
import {useStyles} from "./booking-form.styles";

import icKeyboardIcon from "../../../assets/ic-keyboard-arrow-down.svg";
import {client} from "../../../utils/contentful";

const BookingForm = () => {
    const classes = useStyles();
    const router = useRouter();
    const id = router.query.id;
    const [getBackgroundImageUrl, setBackgroundImageUrl] = React.useState("");

    /**
     * Data retrieval
     */
    const {isLoading, data} = useQuery(
      ['book'],
      async () => {
        const parterInfo = (await client.getEntries({content_type: 'partner'})).items;
        await parterInfo.forEach(value => {
            if (value.fields.partnerId === parseInt(id)){
                setBackgroundImageUrl(value.fields.heroImage.fields.file.url);
            }
        })
        const res = await axios.get(`/bookings/${id}`);
        return res.data.rows;
      }
    );

    /**
     * Form variables
     */
    const {actions} = useStateMachine({updateFormAction});
    const {handleSubmit, control, errors} = useForm({
      mode: "onChange"
    });
    const {fields, append, remove} = useFieldArray({
      control,
      name: "extra_dates"
    });
    let amountBikes = useWatch({control, name: 'amount_bikes', defaultValue: 0});
    let extraDates = useWatch({control, name: 'extra_dates'});
    let startDate = useWatch({control, name: 'start_date'});
    const rentalDates = [startDate];
    extraDates?.forEach(d => rentalDates.push(d.date));

    /**
     * Extra variables for form
     */
    const options = availableBikes(data, rentalDates);
    const bikesAndDates = bikesDates(data);

    const onSubmit = (formData) => {
      formData['rental_dates'] = rentalDates;
      formData['assigned_bikes'] = assignBikes(amountBikes, bikesAndDates, rentalDates);
      formData['amount'] = calculateCostBooking(amountBikes, rentalDates);
      formData['partner_id'] = id;
      formData['partner_name'] = data?.[0].partner_name;
      formData['amount_bikes'] = amountBikes;

      for (let i of formData['assigned_bikes']){
          delete i.dates;
      }

      actions.updateFormAction(formData);
      router.push(`/booking/payment/${id}`);
    }

    // TODO add video
    return (
      <>
        <MainNav bookingGuest={true} transparent={true}/>
        <SectionComponent transparent={true} bgImage={getBackgroundImageUrl}>
          <Container maxWidth='lg' className={classes.container}>
            <Grid container>
              <Grid item xs={12}>
                {/*  <Button*/}
                {/*    className={classes.buttonVideo}*/}
                {/*  >*/}
                {/*    <img src={icArrow} className={classes.buttonIcon} alt='Arrow'/>*/}
                {/*    <Typography className={classes.buttonVideoText}>*/}
                {/*      Play video*/}
                {/*    </Typography>*/}
                {/*  </Button>*/}
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.bookingBox}>
                  <Grid container>
                    {isLoading ?
                      <Grid item xs={12}>
                        <CircularProgress/>
                      </Grid>
                      :
                      <Grid container item>
                        <Grid item xs={12}>
                          <Typography variant='h3'>
                            Reserve your ride at {`${data?.[0]?.partner_name}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container alignContent='center' spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <ControlledDatePicker
                                        control={control}
                                        name='start_date'
                                        emptyLabel='Date'
                                        amountBikes={amountBikes}
                                        bikesAndDates={bikesAndDates}
                                    />
                                </Grid>
                              <Grid item xs={12} sm={6}>
                                <Box className={classes.selectContainer}>
                                  <ThemeProvider theme={selectTheme}>
                                    <Controller
                                      control={control}
                                      name="amount_bikes"
                                      rules={{required: "This is required"}}
                                      as={
                                        <TextField
                                          disabled={!startDate}
                                          select
                                          label='Number of V-bikes'
                                          id="amount_bikes"
                                          variant='outlined'
                                          error={!!errors.amount_bikes}
                                          helperText={errors.amount_bikes && errors.amount_bikes.message}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                  <IconButton onClick={() => {console.log(control)}}>
                                                      <img src={icKeyboardIcon} alt='Select'/>
                                                  </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                        >
                                          {options.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.value > 1 ? option.label : '1 V-bike'}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      }
                                    />
                                  </ThemeProvider>
                                </Box>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12} sm={12}
                              >
                                <Grid item xs={12} sm={6}>
                                  <div className={classes.addDayButton}>
                                    <Button
                                      type="button"
                                      onClick={() => append({})}
                                    >
                                      <IconButton>
                                        <AddIcon className={classes.extraDayIcon} color='primary'/>
                                      </IconButton>
                                    </Button>
                                    <Typography variant='body2' className={classes.addDayText}>
                                      Add another day?
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item container xs={12} sm={6}>
                                  {
                                    fields.map(({id, name}, index) => {
                                      return (
                                        <Grid item xs={12} sm={12}>
                                          <div key={id} className={classes.extraDayDiv}>
                                            <ControlledDatePicker
                                              control={control}
                                              name={`extra_dates[${index}].date`}
                                              emptyLabel='Date?'
                                              amountBikes={amountBikes}
                                              bikesAndDates={bikesAndDates}
                                              minDate={startDate || dayjs()}
                                              disabled={amountBikes < 1}
                                              chosenDates={rentalDates}
                                            />
                                            <Button
                                              type="button" onClick={() => remove(index)}
                                            >
                                              <Tooltip title='Remove' placement='right-middle'>
                                                <IconButton>
                                                  <CancelOutlinedIcon className={classes.extraDayIcon} color='primary'/>
                                                </IconButton>
                                              </Tooltip>
                                            </Button>
                                          </div>
                                        </Grid>
                                      )
                                    })
                                  }</Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Paper className={classes.pricePaper}>
                                  <Grid container item>
                                    <Grid item xs={6}>
                                      <Typography variant='body1' className={classes.totalPrice}>Total price</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.priceContainer}>
                                      <Typography variant='h5'>
                                        {`EUR ${calculateCostBooking(amountBikes, rentalDates) || 0}`}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Divider className={classes.priceDivider}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TooltipInsurance/>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                              <Grid
                                className={classes.bookingAlignmentRight}
                                item
                                xs={12}>
                                <Button
                                  className={classes.buttonReserve}
                                  type="submit"
                                  variant="contained"
                                >
                                  <Typography className={classes.buttonReserveText}>
                                    Continue
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </SectionComponent>
      </>
    )
  }
;

export default BookingForm;
