import React from "react";
import SectionComponent from "../section/section.component";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FooterComponent from "../main-footer/footer.component";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'var(--background-surface)',
    minHeight: 'calc(100vh - 118px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  spinner: {
    margin: '136px 0 0 0'
  },
  header: {
    margin: '80px 0 32px 0',
    height: '49px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.4375em',
    },
    fontSize: '2rem',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: '1.25',
    letterSpacing: '-0.3px',
    textAlign: 'center',
    color: 'var(--loafer-white)',
  },
  buttonRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonMain: {
    width: '287px',
    height: '48px',
    padding: '12px 16px',
    margin: '0 0 0 9px',
    backgroundColor: 'var(--java-green)',
    borderRadius: 0,
  },
  buttonMainText: {
    fontSize: '1rem',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 'normal',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--midnight-forest-black)',
    display: 'flex',
    alignItems: 'center',
  },
  buttonCancel: {
    width: '98px',
    height: '48px',
    padding: '12px 16px',
    border: 'solid 1px var(--java-green)',
    borderRadius: 0,
  },
  buttonCancelText: {
    fontSize: '1rem',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 'normal',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--java-green)',
    display: 'flex',
    alignItems: 'center',
  },
}));

function PageLoaderComponent(props) {
  const {height = 350} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SectionComponent bgColor={props.bgColor}>
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection='column'
            height={height}
          >
            {!props.children && <CircularProgress className={classes.spinner} size={64}/>}

            <Typography className={classes.header}>{props.header}</Typography>
            {props.payment && (
              <>
                <Container className={classes.buttonRoot} maxWidth="sm">
                  <Button className={classes.buttonCancel}>
                    <Typography className={classes.buttonCancelText}>Cancel</Typography>
                  </Button>
                  <Button className={classes.buttonMain}>
                    <Typography className={classes.buttonMainText}>Change payment method</Typography>
                  </Button>
                </Container>
              </>
            )}
          </Box>
        </Container>
      </SectionComponent>
      {props.payment && (<FooterComponent topFooter={false}/>)}
    </div>
  );
}

export default PageLoaderComponent;
