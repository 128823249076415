import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import BusinessChoserComponent from "./desktop/business-choser.component";
import BusinessChoserMobileComponent from "./mobile/business-choser-mobile.component";

const BusinessChoserDeviceComponent = () => {
    return (
        <>
            <BrowserView>
                <BusinessChoserComponent/>
            </BrowserView>
            <MobileView>
                <BusinessChoserMobileComponent/>
            </MobileView>
        </>
    )
};

export default BusinessChoserDeviceComponent;
