import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 'auto',
    width: '100vw',
    height: '96px',
    padding: '24px 0 0',
    boxShadow: '1px 16px 16px 0 rgba(0, 0, 0, 0.32)',
    backgroundColor: '#353535',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  button: {
    height: '48px',
    padding: '12px 16px',
    backgroundColor: 'var(--buttercup-yellow);',
    borderRadius: 0,
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    },
    margin: '0 32px',
    width: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      width: '227px',
    }
  },
  buttonDisabled: {
    height: '48px',
    padding: '12px 16px',
    borderRadius: 0,
    margin: '0 32px',
    backgroundColor: 'rgba(249,252,246,0.14)',
    width: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      width: '227px',
    },
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 'normal',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--midnight-forest-black)',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  buttonTextDisabled: {
    fontWeight: '500',
    color: 'rgba(11,10,7, 0.14)',
  },
}));
