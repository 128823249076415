import {FilterNumberRangeColumn} from "./filter-number-range-column";
import CustomCheckbox from "../../../../atoms/checkbox/checkbox.component";
import React from "react";

export const columnPartner = [
  {
    id: "selection",
    Header: () => (
      <div style={{height: '67px'}}>
        Select
      </div>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({row}) => {
      return (
        <CustomCheckbox
          color='primary'
          {...
            row.getToggleRowSelectedProps()
          }
        />
      )
    },
  },
  {
    Header: () => (
      <div style={{height: '32px'}}>
        ID
      </div>
    ),
    accessor: "partner_id",
    Filter: FilterNumberRangeColumn,
  },
  {
    Header: () => (
      <div style={{height: '32px'}}>
        Partner name
      </div>
    ),
    accessor: 'partner_name',
  },
  {
    Header: () => (
      <div style={{height: '32px'}}>
        Email
      </div>
    ),
    accessor: 'email',
  },
  {
    Header: () => (
      <div style={{height: '32px'}}>
        City
      </div>
    ),
    accessor: 'city',
  },
  {
    Header: () => (
      <div style={{height: '32px'}}>
        Bikes
      </div>
    ),
    accessor: 'bikes',
    Filter: FilterNumberRangeColumn,
    filter: 'between',
    width: 95
  },
  {
    Header: "Total Frequency",
    accessor: 'total_frequency',
    Filter: FilterNumberRangeColumn,
    filter: 'between',
  },
  {
    Header: "Year Frequency",
    accessor: 'year_frequency',
    Filter: FilterNumberRangeColumn,
    filter: 'between',
  },
  {
    Header: "Month Frequency",
    accessor: 'month_frequency',
    Filter: FilterNumberRangeColumn,
    filter: 'between',
  },
  {
    Header: "Week Frequency",
    accessor: 'week_frequency',
    Filter: FilterNumberRangeColumn,
    filter: 'between',
  }
]
