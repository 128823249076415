import React from 'react';

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import SectionComponent from "../../general/section/section.component";
import {useStyles} from "./location-hero.styles";
import BottomShark from "../../../assets/brand-elements-vesper-skyline.svg";

const LocationHero = ({partnerContent}) => {
  const classes = useStyles();

  return (
    <>
      <SectionComponent size='large' transparent={true} bgImage={partnerContent.heroImage.fields.file.url}>
        <Container maxWidth='xl' className={classes.container}>
          <Box justifyContent='center' className={classes.heroBox}>
            <Typography variant='h2' className={classes.heroBoxTitle}>{partnerContent.partnerCity}</Typography>
            <Typography variant='body1' className={classes.heroBoxSubTitle}>
              Rent a Vesper e-bike while staying at the {partnerContent.partnerName}
            </Typography>
          </Box>
        </Container>
        <img className={classes.bottomShark} src={BottomShark} alt='vesper-shark-bottom'/>
      </SectionComponent>
    </>
  )
};

export default LocationHero;
