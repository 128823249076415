import React from 'react';
import ScrollableList from "../../../atoms/scrollable-list/scrollable-list.component";
import PartnerReviewListItem from "../../../atoms/partner-review-list-item/partner-review-list-item.component";
import Typography from "@material-ui/core/Typography";
import {useQuery} from "react-query";
import axios from "axios";
import PageLoaderComponent from "../../general/page-loader/page-loader.component";

const AdminReviewsComponent = () => {

    const {isLoading, data} = useQuery(
        ['dashboard-reviews'],
        async () => {
            const res = await axios.get('/dashboard/get-reviews');
            return res.data.rows;
        }
    );

    return (
        <>
            {isLoading ?
                <PageLoaderComponent/>
            :
                <>
                    {data.length !== 0 ?
                        <ScrollableList height="95%">
                            {data?.map((partner, index) => {
                                return <PartnerReviewListItem name={partner.partner_name} key={index} index={index + 1} rating={partner.rating} id={partner.partner_id}/>
                            })}
                        </ScrollableList>
                    :
                        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography style={{color: 'lightgray'}}>No reviews have been left yet...</Typography>
                        </div>
                    }
                </>
            }
        </>
    )
}
export default AdminReviewsComponent;
