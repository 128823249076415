import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-12)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '95vh',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '10%',
      paddingBottom: '90%',
      alignItems: 'flex-start',
    }
  },
  partnerSelectContainer: {
    padding: '24px',
    backgroundColor: '#121212',
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#1d1d1d',
      width: '450px',
      boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.32)',
      padding: '24px',
    },
  },
  selectHeader: {
    margin: '0 0 16px 0',
  },
  subHeader: {
    margin: '0 0 16px 0',
  },
}));
