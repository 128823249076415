import React from 'react';

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {useStyles} from "../enhanced-table.styles";

export const FilterSelectColumn = ({column: {filterValue, setFilter, preFilteredRows, id},}) => {
  const classes = useStyles()

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })

    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <div className="">
      <Select
        placeholder='All'
        className={classes.filterInput}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <MenuItem value="">All</MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
};
