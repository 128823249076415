import React from 'react';
import {useQuery} from "react-query";
import axios from "axios";
import dayjs from "dayjs";

import {Route, useRouter} from "../../../utils/router";
import {useAuth} from "../../../utils/auth";

import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import SectionComponent from "../../general/section/section.component";
import PortalReservationDatePicker from "../../../atoms/date-picker-reservations/portal-reservation-date-picker";
import PortalReserveList from "../portal-reserve-list/portal-reserve-list.component";
import PortalReserveFooter from "../portal-reserve-footer/portal-reserve-footer.component";
import PortalBooking from "../portal-booking/portal-booking.component";
import PortalReserveDetail from "../portal-reserve-detail/portal-reserve-detail.component";
import PageLoaderComponent from "../../general/page-loader/page-loader.component";
import {useStyles} from "./portal-reservations.styles";
import {Typography} from "@material-ui/core";

const PortalReservations = () => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();

  const [chosenDate, setChosenDate] = React.useState(dayjs());

  const id = auth?.user?.[0]?.partner_id;
  const {isLoading, data} = useQuery(
    ['bookings'],
    async () => {
      const res = await axios.get(`/bikes-booking/${id || 0}`);
      return res.data.rows;
    },
    {
      refetchInterval: 3000,
    }
  );

  return (
    <>
      <Route exact path={`${router.match.url}`} render={() => (
        <>
          <SectionComponent
            bgColor='var(----background-surface)'
            size='large'
          >
            <Container maxWidth="xl" className={classes.container}>
              {!id ?
                <PageLoaderComponent/>
                :
                <div className={classes.reservationsContainer}>
                  <Typography variant='h1' className={classes.portalReservations}>Reservations</Typography>
                  <PortalReservationDatePicker data={data} chosenDate={chosenDate} setChosenDate={setChosenDate}/>
                  {
                    isLoading
                      ? <CircularProgress/>
                      : <PortalReserveList chosenDate={chosenDate}/>
                  }
                </div>
              }
            </Container>
          </SectionComponent>
          <PortalReserveFooter data={data} chosenDate={chosenDate}/>
        </>
      )}
      />
      <Route exact path={`${router.match.url}/book`} component={PortalBooking}/>
      <Route exact path={`${router.match.url}/book/:bookingId`} component={PortalReserveDetail}/>
    </>
  )
};

export default PortalReservations;
