import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import StyledButton from "../../../atoms/styled-button/styled-button.component";
import ScrollableList from "../../../atoms/scrollable-list/scrollable-list.component";
import PartnerListItem from "../../../atoms/partner-list-item/partner-list-item.component";
import {useQuery} from "react-query";
import axios from "axios";
import PageLoaderComponent from "../../general/page-loader/page-loader.component";
import SelectCriteriaModal from "../../../atoms/select-criteria-modal/select-criteria-modal.component";

const AdminPartnerPerformance = ({timespan}) => {
    const [partnerData, setPartnerData] = useState([]);
    const [open, setOpen] = useState(false);

    const setDefaultValues = (data) => {
        const objArr = [];
        if (data !== undefined){
            data.forEach((row) => {
                const obj = {
                    partner_name: row.partner_name,
                    score: row.total_rentals
                };
                objArr.push(obj);
            })
            const sort = objArr.sort((a,b) => {return b.score-a.score});
            setPartnerData(sort);
        }
    }

    const {isLoading, data, refetch} = useQuery(
        ['dashboard-partner-performance'],
        async () => {
            const res = await axios.get('/dashboard/get-partner-performance', { params: { timespan: timespan }});
            return res.data.rows;
        },
        {onSuccess: setDefaultValues}
    );

    useEffect(() => {
        refetch();
    }, [timespan]);

    const setCriteria = (criteria) => {
        console.log(data);
        switch (criteria){
            case 'Most rentals':
                const mostRentalArr = [];
                if (data !== undefined){
                    data.forEach((row) => {
                        const obj = {
                            partner_name: row.partner_name,
                            score: row.total_rentals
                        };
                        mostRentalArr.push(obj);
                    })
                    const sort = mostRentalArr.sort((a,b) => {return b.score-a.score});
                    setPartnerData(sort);
                }
                break;
            case 'Least rentals':
                const leastRentalArr = [];
                if (data !== undefined){
                    data.forEach((row) => {
                        const obj = {
                            partner_name: row.partner_name,
                            score: row.total_rentals
                        };
                        leastRentalArr.push(obj);
                    })
                    const sort = leastRentalArr.sort((a,b) => {return a.score-b.score});
                    setPartnerData(sort);
                }
                break;
            case 'Most PC 20 rentals':
                const most20rentals = [];
                if (data !== undefined){
                    data.forEach((row) => {
                        const obj = {
                            partner_name: row.partner_name,
                            score: row.total_pc_20
                        };
                        most20rentals.push(obj);
                    })
                    const sort = most20rentals.sort((a,b) => {return b.score-a.score});
                    setPartnerData(sort);
                }
                break;
            case 'Least PC 20 rentals':
                const least20rentals = [];
                if (data !== undefined){
                    data.forEach((row) => {
                        const obj = {
                            partner_name: row.partner_name,
                            score: row.total_pc_20
                        };
                        least20rentals.push(obj);
                    })
                    const sort = least20rentals.sort((a,b) => {return a.score-b.score});
                    setPartnerData(sort);
                }
                break;
            case 'Most PC 35 rentals':
                const most35rentals = [];
                if (data !== undefined) {
                    data.forEach((row) => {
                        const obj = {
                            partner_name: row.partner_name,
                            score: row.total_pc_35
                        };
                        most35rentals.push(obj);
                    })
                    const sort = most35rentals.sort((a, b) => {
                        return b.score - a.score
                    });
                    setPartnerData(sort);
                }
                break;
            case 'Least PC 35 rentals':
                const least35rentals = [];
                if (data !== undefined){
                    data.forEach((row) => {
                        const obj = {
                            partner_name: row.partner_name,
                            score: row.total_pc_35
                        };
                        least35rentals.push(obj);
                    })
                    const sort = least35rentals.sort((a,b) => {return a.score-b.score});
                    setPartnerData(sort);
                }
                break;
            case 'Most iDeal rentals':
                const mostOnlinerentals = [];
                if (data !== undefined) {
                    data.forEach((row) => {
                        const obj = {
                            partner_name: row.partner_name,
                            score: row.total_online
                        };
                        mostOnlinerentals.push(obj);
                    })
                    const sort = mostOnlinerentals.sort((a, b) => {
                        return b.score - a.score
                    });
                    setPartnerData(sort);
                }
                break;
            case 'Least iDeal rentals':
                const leastOnlinerentals = [];
                if (data !== undefined) {
                    data.forEach((row) => {
                        const obj = {
                            partner_name: row.partner_name,
                            score: row.total_online
                        };
                        leastOnlinerentals.push(obj);
                    })
                    const sort = leastOnlinerentals.sort((a, b) => {
                        return a.score - b.score
                    });
                    setPartnerData(sort);
                }
                break;
        }
    }

    const truncate = (str) => {
        return str.length > 25 ? str.substring(0, 22) + "..." : str;
    }

    return (
        <>
            <SelectCriteriaModal open={open} handleClose={() => setOpen(!open)} onConfirm={(criteria) => setCriteria(criteria)}/>
            <Grid container justify="flex-end" spacing={2}>
                <Grid item xs={5}>
                    <StyledButton text="Select criteria" onClick={() => setOpen(!open)}/>
                </Grid>
            </Grid>

            {/*Info sections*/}
            {isLoading ?
                <PageLoaderComponent/>
                :
                <ScrollableList height="80%">
                    {partnerData?.map((data, index) => {
                        return <PartnerListItem name={truncate(data.partner_name)} key={index} index={index+1} score={data.score}/>
                    })}
                </ScrollableList>
            }
        </>
    )
}
export default AdminPartnerPerformance;
