import React from "react";

import {Link, useRouter} from "../../../utils/router.js";
import {useAuth} from "../../../utils/auth.js";

import {
  Menu,
  AppBar,
  ListItemText,
  Drawer,
  MenuItem,
  ListItem,
  Container,
  Toolbar,
  Hidden,
  IconButton,
  Button,
  Divider,
  List, Typography
} from "@material-ui/core";

import {useStyles} from "./main-nav.styles";
import SectionComponent from "../section/section.component";
import ScrollToColor from "../nav-scroll-wrapper/nav-scroll-wrapper.component";
import logoHorizontal from "../../../assets/logo-color-horizontal-db.svg";
import englishFlagIcon from '../../../assets/icons-uk.svg';
import MenuIcon from '../../../assets/ic-menu.svg'
import VesperIcon from '../../../assets/logo-color-icon.svg';
import DrawerVesper from '../../../assets/logo-b-w-wordmark-db.svg';

const MainNav = ({transparent = false, bookingGuest = false, admin = false}) => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const currentPath = router.pathname;
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [menuState, setMenuState] = React.useState(null);
  const handleOpenMenu = (event, id) => {
    setMenuState({anchor: event.currentTarget, id});
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <SectionComponent transparent={transparent} size="auto">
      <ScrollToColor>
        <AppBar position="fixed" color='transparent' elevation={0}>
          <Container
            maxWidth={"false"}
            className={transparent ? `${classes.container}` : `${classes.container} ${classes.rootNav}`}
            disableGutters
          >
            <Toolbar
              className={`${transparent ? `${classes.toolBar} ${classes.toolbarNoShadow}` : `${classes.toolBar}`}`}>
              <Hidden smUp implementation="css"
                      className={auth.user ? `${classes.xsNavBarPortal}` : `${classes.xsNavbar}`}>
                <IconButton
                  className={classes.menuButton}
                  onClick={() => {
                    setDrawerOpen(true);
                  }}
                  color="inherit"
                >
                  <img src={MenuIcon} alt="Menu" className={classes.menuIcon}/>
                </IconButton>
                <Link to="/" className={auth.user ? `${classes.logoContainerPortal}` : `${classes.logoContainer}`}>
                  <img src={VesperIcon} className={classes.xsLogo} alt="Logo"/>
                </Link>
                <>
                  <IconButton
                    className={`${classes.toolBarItemIcon} ${classes.flagIconContainer}`}
                    color="inherit"
                    onClick={(event) => {
                      handleOpenMenu(event, "language-small-menu");
                    }}
                  >
                    <img src={englishFlagIcon} className={classes.flagIcon} alt='Icon'/>
                  </IconButton>
                  <Menu
                    id="language-small-menu"
                    open={menuState && menuState.id === "language-small-menu"}
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted
                    classes={{paper: classes.menuPaper}}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    MenuListProps={{
                      classes: {
                        root: classes.menuListItem
                      }
                    }}
                  >
                    <MenuItem classes={{root: classes.menuItemRoot}}>
                      <IconButton>
                        <img src={englishFlagIcon} className={classes.flagIcon} alt="Icon"/>
                      </IconButton>
                      <Typography className={classes.flagText}>
                        English
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              </Hidden>
              <Hidden mdDown implementation="css" className={classes.lgNavbar}>
                {!bookingGuest && (
                  <>
                    <Link to="/">
                      <img src={logoHorizontal} className={classes.horizontalLogo} alt="Logo"/>
                    </Link>
                    <div className={classes.subNav}>
                      <Button
                        className={currentPath === '/' ? `${classes.toolBarItemActive}` : `${classes.toolBarItem}`}
                        component={Link} to="/"
                      >
                        <p className={classes.toolbarItemText}>Home</p>
                      </Button>
                      {/*<Button*/}
                      {/*  className={currentPath.includes('/locations') ? `${classes.toolBarItemActive}` : `${classes.toolBarItem}`}*/}
                      {/*  component={Link} to="/locations"*/}
                      {/*>*/}
                      {/*  <p className={classes.toolbarItemText}>Locations</p>*/}
                      {/*</Button>*/}
                      <Button
                        className={currentPath.includes('/faq') ? `${classes.toolBarItemActive}` : `${classes.toolBarItem}`}
                        component={Link} to="/faq"
                      >
                        <p className={classes.toolbarItemText}>FAQ</p>
                      </Button>
                      <IconButton
                        className={`${classes.toolBarItemIcon} ${classes.flagIconContainer}`}
                        color="inherit"
                        onClick={(event) => {
                          handleOpenMenu(event, "language-menu");
                        }}
                      >
                        <img src={englishFlagIcon} className={classes.flagIcon} alt='Icon'/>
                      </IconButton>
                      <Menu
                        id="language-menu"
                        open={menuState && menuState.id === "language-menu"}
                        anchorEl={menuState && menuState.anchor}
                        getContentAnchorEl={undefined}
                        onClick={handleCloseMenu}
                        onClose={handleCloseMenu}
                        keepMounted
                        classes={{paper: classes.menuPaper}}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          classes: {
                            root: classes.menuListItem
                          }
                        }}
                      >
                        <MenuItem classes={{root: classes.menuItemRoot}}>
                          <IconButton>
                            <img src={englishFlagIcon} className={classes.flagIcon} alt="Icon"/>
                          </IconButton>
                          <Typography className={classes.flagText}>
                            English
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </>
                )}
                {bookingGuest && (
                  <>
                    <Link to="/">
                      <img src={logoHorizontal} className={classes.horizontalLogo} alt="Logo"/>
                    </Link>
                    <IconButton
                      className={`${classes.toolBarItemIcon} ${classes.flagIconContainer}`}
                      color="inherit"
                      onClick={(event) => {
                        handleOpenMenu(event, "language-menu-booking");
                      }}
                    >
                      <img src={englishFlagIcon} className={classes.flagIcon} alt='Icon'/>
                    </IconButton>
                    <Menu
                      id="language-menu-booking"
                      open={menuState && menuState.id === "language-menu-booking"}
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted
                      classes={{paper: classes.menuPaper}}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      MenuListProps={{
                        classes: {
                          root: classes.menuListItem
                        }
                      }}
                    >
                      <MenuItem classes={{root: classes.menuItemRoot}}>
                        <IconButton>
                          <img src={englishFlagIcon} className={classes.flagIcon} alt="Icon"/>
                        </IconButton>
                        <Typography className={classes.flagText}>
                          English
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>
      </ScrollToColor>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={classes.drawer}
      >
        <List
          onClick={() => setDrawerOpen(false)}
          className={classes.drawerList}
        >
          <Link to="/">
            <img src={DrawerVesper} alt="Logo" className={classes.drawerVesper}/>
          </Link>
          <>
            <ListItem
              className={currentPath === '/' ? `${classes.drawerListItemActive}` : `${classes.drawerListItem}`}
              button component={Link} to="/"
            >
              <ListItemText className={classes.drawerListItemText}>Home</ListItemText>
            </ListItem>
            {/*<ListItem*/}
            {/*  className={currentPath.includes('/locations') ? `${classes.drawerListItemActive}` : `${classes.drawerListItem}`}*/}
            {/*  button component={Link} to="/locations"*/}
            {/*>*/}
            {/*  <ListItemText className={classes.drawerListItemText}>Locations</ListItemText>*/}
            {/*</ListItem>*/}
            <ListItem
              className={currentPath.includes('/faq') ? `${classes.drawerListItemActive}` : `${classes.drawerListItem}`}
              button component={Link} to="/faq"
            >
              <ListItemText className={classes.drawerListItemText}>FAQ</ListItemText>
            </ListItem>
          </>
          <Divider/>
        </List>
      </Drawer>
    </SectionComponent>
  );
}

export default MainNav;
