import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '55px',
    [theme.breakpoints.up('lg')]: {
      padding: '118px 0 0 0',
    }
  },
  header: {
    margin: '16px 0',
  },
  subHeader: {
    color: 'var(--loafer-white-soft)',
  },
  formRoot: {
    margin: '30px 0 0 0',
  },
  textFieldContainer: {
    margin: '24px 0 0 0',
  },
  textFieldOutline: {
    height: '160px',
  },
  descriptionRequired: {
    margin: '4px 12px 32px 16px',
  },
  chbLabel: {
    color: 'var(--loafer-white-soft)',
  },
  buttonContainer: {
    margin: '0 0 55px 0',
  },
  imageInput: {
    display: 'none'
  },
  buttonImage: {
    padding: '12px 16px 12px 12px',
    margin: '0 16px',
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      margin: '0 0 16px 0',
      width: '-webkit-fill-available'
    },
  },
  buttonImageIcon: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  },
  buttonImageText: {
    margin: '0 0 0 8px',
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--java-green)',
    textTransform: 'uppercase',
  },
  buttonReport: {
    height: '48px',
    padding: '12px 16px',
    backgroundColor: 'var(--buttercup-yellow);',
    borderRadius: 0,
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    },
    width: '-webkit-fill-available',
    [theme.breakpoints.up('lg')]: {
      width: '174px',
    }
  },
  buttonReportDisabled: {
    backgroundColor: 'rgba(249,252,246,0.32)',
  },
  buttonReportText: {
    width: '142px',
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--midnight-forest-black)',
  },
}));
