import React from "react";
import {useRouter} from "../../../utils/router";
import PortalBikesReport from "../portal-bikes-report/portal-bikes-report.component";

const PortalBikesDamage = () => {
  const router = useRouter();
  const bikeId = router.query.id;

  const damageCategories = {
    "Accu": 'Accu',
    "Brakes": 'Brakes',
    "Display": 'Display',
    "Low profile tire": 'Low profile tire',
    "Motor": 'Motor',
    "Other": 'Other',
  };

  return (
    <>
      <PortalBikesReport
        type='Damage'
        currentCrumb='Report light damage'
        header='Report light damage'
        subHeader={`Vesper bike ${bikeId} is still operational and this is what needs to be fixed:`}
        damageCategories={damageCategories}
      />
    </>
  )
};

export default PortalBikesDamage;
