import React from "react";
import dayjs from "dayjs";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {useStyles} from './portal-reserve-detail.styles';
import {useRouter} from "../../../utils/router";
import SectionComponent from "../../general/section/section.component";
import ModalCancel from "../../../atoms/modal-cancel/modal-cancel.component";
import DoubleBreadCrumb from "../../general/double-bread-crumb/double-bread-crumb.component";
import FooterComponent from "../../general/main-footer/footer.component";

import icPerson from '../../../assets/ic-person.svg';
import icMail from '../../../assets/ic-email.svg';
import icPhone from '../../../assets/ic-phone.svg';
import icDirection from '../../../assets/ic-directions-bike.svg';
import icDateRange from '../../../assets/ic-date-range.svg';
import icInfo from '../../../assets/ic-info.svg';

const PortalReserveDetail = () => {
  const classes = useStyles();
  const router = useRouter()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const booking = router.location.state;

  console.log(booking);

  return (
    <>
      <SectionComponent size='large'>
        <Container maxWidth="lg" className={classes.container}>
          <DoubleBreadCrumb margin={false} start="Reservations" current="Reservation Details" rerouteUrl="/partner"/>
          <Typography variant='h1' className={classes.header}>{matches ? 'RESERVATIONS DETAILS' : 'RESERVATIONS'}</Typography>
          <div className={classes.box}>
            <Grid container>
              <Grid item xs={12} className={classes.gridItem}>
                <img
                  src={icPerson}
                  className={classes.bookingIcon}
                  alt='person'
                />
                <Typography className={`${classes.bookingDetails} ${classes.detailsWhite}`}>
                  {`${booking.first_name} ${booking.last_name}`}
                </Typography>
              </Grid>
              <Grid item xs={12} className={`${classes.gridItem} ${classes.personDetails}`}>
                <div className={classes.personDetailsSub}>
                  <img src={icMail}
                       className={classes.bookingIcon}
                       alt='email'
                  />
                  <Typography className={`${classes.bookingDetails} ${classes.detailsGreen}`}>
                    {booking.email}
                  </Typography>
                </div>
                <div className={classes.personDetailsSub}>
                  <img src={icPhone}
                       className={classes.bookingIcon}
                       alt='phone'
                  />
                  <Typography className={`${classes.bookingDetails} ${classes.detailsGreen}`}>
                    {booking.phone_number || ' N / A'}
                  </Typography>
                </div>
                <div className={classes.personDetailsSub}>
                  <img src={icDirection}
                       className={classes.bookingIcon}
                       alt='bike'
                  />
                  {booking.bikes.map(b => (
                    <Typography className={`${classes.bookingDetails} ${classes.detailsWhite}`}>
                      {b}
                    </Typography>
                  ))}
                </div>
                <div className={classes.personDetailsSub}>
                  <img src={icDateRange}
                       className={classes.bookingIcon}
                       alt='date'
                  />
                  <Typography className={`${classes.bookingDetails} ${classes.detailsWhite}`}>
                    {dayjs(booking.rental_date, 'DD/MM/YYYY').format('dddd DD MMM YYYY')}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider light={true} variant="fullWidth"/>
                {
                  dayjs(booking.rental_date, 'DD/MM/YYYY').diff(dayjs(dayjs(), 'DD/MM/YYYY'), 'hour') <= 72 ?
                    <div className={`${classes.noticeBox} ${classes.gridItem}`}>
                      <img src={icInfo}
                           className={classes.bookingIcon}
                           alt='notice'
                      />
                      <Typography variant='body2' className={classes.noticeText}>
                        The cancellation period for this reservation has expired. Only cancel in case the guest is not
                        able to attend this reservation.
                      </Typography>
                    </div>
                    :
                    <div className={`${classes.noticeBoxEmpty} ${classes.gridItem}`}></div>
                }
              </Grid>
              <Grid item xs={12} className={` ${classes.buttonItem}`}>
                <Button
                  className={classes.cancelButton}
                  onClick={handleOpen}
                >
                  <Typography
                    className={classes.cancelText}
                  >
                    Cancel reservation
                  </Typography>
                </Button>
                <ModalCancel bookingId={booking.booking_id} handleClose={handleClose} open={open}/>
              </Grid>
            </Grid>
          </div>
        </Container>
      </SectionComponent>
      <FooterComponent topFooter={false}/>
    </>
  )
};

export default PortalReserveDetail;
