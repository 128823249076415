import React from "react";

import {requireAuth} from "../../../utils/auth.js";
import {Route, useRouter} from "../../../utils/router";

import PortalReservations from "../portal-reservations/portal-reservations.component";
import BikesSection from "../portal-bikes-section/portal-bikes-section.component";
import PortalFaqSection from "../portal-faq-section/portal-faq-section";
import ProtectedNav from "../../general/protected-nav/protected-nav.component";

const PortalSection = () => {
  const router = useRouter();

  return (
    <>
      <ProtectedNav />
      <Route path={`${router.match.url}`} component={PortalReservations}/>
      <Route path={`${router.match.url}/bikes`} component={BikesSection}/>
      <Route exact path={`${router.match.url}/contact`} component={PortalFaqSection}/>
    </>
  );
}

export default requireAuth(PortalSection);
