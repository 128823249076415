import React from 'react';
import {InputBase, Paper} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const SearchInputField = ({placeholder, onChange}) => {
    return (
        <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400}} style={{backgroundColor: '#202020', display: 'flex', padding: '2% 0 2% 5%'}}>
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder={placeholder} onKeyPress={(ev) => {if (ev.key === 'Enter') {ev.preventDefault();}}} onChange={(text) => onChange(text)} style={{padding: 0}}/>
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" disabled>
                <SearchIcon sx={{ color: '#fff' }}/>
            </IconButton>
        </Paper>
    )
};
export default SearchInputField;
