import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FilterDropdownObj = ({placeholder, optionsArray, primary, onSelect}) => {
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {target: { value }} = event;
        const string = typeof value === 'string' ? value.split(',') : value;
        setPersonName(string);

        onSelect({
            name: value,
            key: event.currentTarget.dataset.myValue
        });
    };

    return (
        <FormControl fullWidth style={{backgroundColor: placeholder !== "FILL IN" ? '#202020' : '#545454', borderRadius: 4, height: '100%', paddingLeft: '5%'}}>
            <Select
                displayEmpty
                style={{height:'100%', color: primary && 'rgb(0, 217, 225)'}}
                labelId="simple_dropdown_label"
                id="simple_dropdown"
                value={personName}
                renderValue={(selected) => {
                    if (selected.length === 0 && primary) {
                        return <em style={{color: 'rgb(0, 217, 225)'}}>{placeholder}</em>;
                    } else if (selected.length === 0){
                        return <em>{placeholder}</em>;
                    }

                    return <em style={{color: primary && 'rgb(0, 217, 225)'}}>{selected}</em>;
                }}
                onChange={handleChange}
                IconComponent={() => (<ArrowDropDownIcon style={{color:'white'}}/>)}
            >
                {optionsArray?.map((obj) => (
                    <MenuItem
                        key={Object.values(obj)[0]}
                        data-my-value={Object.values(obj)[0]}
                        value={Object.values(obj)[1]}
                    >
                        {Object.values(obj)[1]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default FilterDropdownObj;
