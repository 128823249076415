import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  rootModal: {
    width: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#383838',
    flexDirection: 'column',
    padding: '16px',
    height: '400px',
    margin: '16px',
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      padding: '24px',
      width: '628px',
      height: '308px',
    },
  },
  paperMissing: {
    height: '351px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalHeaderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalHeader: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: '20px',
    },
  },
  modalHeaderMissing: {},
  buttonGrid: {
    display: 'flex',
    margin: 'auto 0 0 0',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      margin: 'auto 0 0 0',
    },
  },
  button: {
    width: '-webkit-fill-available',
    height: '48px',
    padding: '12px 16px',
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
    }
  },
  buttonText: {
    height: '24px',
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--java-green)',
    textTransform: 'uppercase',
  },
  buttonDisabledText: {
    color: 'rgba(249,252,246, 0.38)',
  },
  closeGrid: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeIcon: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
    cursor: 'pointer',
    display: 'flex',
  },
  gridRadioButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0',
    },
  },
  radioButtonText: {
    margin: '6px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      margin: '6px 0',
    },
    color: 'var(--loafer-white-soft)',
  },
  noticeBox: {
    padding: '12px 16px 16px 11px',
    backgroundColor: 'rgba(241,162,9, 0.32)',
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      width: '580px',
      height: '76px',
    }
  },
  noticeText: {
    color: 'var(--loafer-white)',
    display: 'flex',
    alignItems: 'flex-end',
  },
  bookingIcon: {
    width: '24px',
    height: '24px',
    margin: '2px 16px 1px 0',
    objectFit: 'contain',
  },
}));
