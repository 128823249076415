import React from 'react';

import Container from "@material-ui/core/Container";

import {bikeManual, returningTheBike, unlikelyEvents} from './guest-faq-ride.json';
import {makeStyles} from "@material-ui/core/styles";

import Faq from "../../general/faq/faq.component";
import DoubleBreadCrumb from "../../general/double-bread-crumb/double-bread-crumb.component";
import SectionComponent from "../../general/section/section.component";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '64px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '158px',
    },
  },
  container: {
    maxWidth: 'var(--max-width-8)'
  },
}));

const GuestFaqRide = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        <SectionComponent size='auto'>
          <DoubleBreadCrumb start='Contact & FAQ' current="About the ride" rerouteUrl="/faq"/>
        </SectionComponent>
        <Faq
          mainHeader='About the ride'
          subHeader='Everything you need to know about the Vesper bike and riding it.'
          categories={[bikeManual, returningTheBike, unlikelyEvents]}
          crumb={true}
        />
      </Container>
    </div>
  )
};

export default GuestFaqRide;
