import React from 'react';
import {useStyles} from "./hero-concept.styles";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import SectionComponent from "../../../general/section/section.component";
import LandingBikeAms from '../../../../assets/TER00427-Edit.jpg';

const HeroConceptComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto" className={classes.root}>
                <Container maxWidth={false} className={classes.container}>
                    <Box className={classes.textBox}>
                        <Typography className={classes.header}>Vesper</Typography>
                        <div className={classes.headerBox}>
                            <Typography className={classes.headerBoxTitle}>The concept</Typography>
                        </div>
                        <Typography variant='caption2' className={classes.subHeader}>
                            {data?.conceptSubtitle}
                        </Typography>
                        <img src={data?.conceptImage.fields.file.url} alt="Picture" className={classes.picture}/>
                    </Box>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroConceptComponent;
