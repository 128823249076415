import React from "react";
import {useRouter} from "../../../utils/router.js";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    margin: '6px 126px 32px 16px',
  },
  footerLink: {
    color: 'var(--loafer-white-soft)',
    textDecoration: 'none',
    "&&:hover": {
      color: 'var(--java-green)',
      cursor: 'pointer'
    },
  }
}));

function AuthFooterComponent(props) {
  const classes = useStyles();
  const router = useRouter();

  return (
    <div className={classes.root}>
      {props.type === "signin" && (
        <>
          <Typography onClick={() => router.push("/auth/forgotpass")} className={classes.footerLink}>
            {props.typeValues.linkTextForgotpass}
          </Typography>
        </>
      )}
      {props.type === "forgotpass" && (
        <>
          <Typography onClick={() => router.push("/auth/signin")} className={classes.footerLink}>
            {props.typeValues.linkTextLogin}
          </Typography>
        </>
      )}
    </div>
  );
}

export default AuthFooterComponent;
