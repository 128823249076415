import React from "react";

import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import BookingSelectPartner from "../booking-select-partner/booking-select-partner.component";
import SectionComponent from "../../general/section/section.component";
import MainNav from "../../general/main-nav/main-nav.component";
import {useStyles} from "./booking-select-section.styles";

const BookingSelectSection = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <MainNav bookingGuest={true} transparent={true}/>
      <SectionComponent transparent={true} bgImage={props.bgImage} bgPosition={'60%'}>
        <Container className={classes.container}>
          <div className={classes.partnerSelectContainer}>
            <Typography variant='h2' className={classes.selectHeader}>
            Welcome to Vesper
          </Typography>
            <Typography variant='body1' className={classes.subHeader}>
              Please select your accommodation.
            </Typography>
            <BookingSelectPartner/>
          </div>
        </Container>
      </SectionComponent>
    </>
  )
};

export default BookingSelectSection;
