import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    lowerDiv: {
        width: '100%',
        bottom: 0,
        position: 'absolute',
        marginLeft: -16,
        background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,100%))',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 50
    },
    container: {
        display: 'flex',
        height: '100vh'
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '110px',
            lineHeight: '1.25',
            letterSpacing: '-0.81px',
        },
        fontSize: '5em',
        letterSpacing: '-0.58px',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        textAlign: 'center'
    },
    subHeader: {
        color: 'var(--loafer-white)',
        margin: '8px 0 0 0',
        fontWeight: "normal",
        fontSize: 18,
        textAlign: 'center'
    },
    buttonLocations: {
        height: '48px',
        padding: '12px 16px',
        backgroundColor: '#12b5ad',
        width: 'fit-content',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#0f9690'
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        }
    },
    buttonLocationsText: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    buttonLocationsConsumer: {
        height: '48px',
        padding: '12px 16px',
        backgroundColor: '#fff',
        width: 'fit-content',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#fff'
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        }
    },
    buttonLocationsTextConsumer: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#12b5ad',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
}));
