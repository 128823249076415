import React from 'react';
import * as dayjs from "dayjs";
import * as relativeTime from 'dayjs/plugin/relativeTime';

import TextField from "@material-ui/core/TextField";

import {useStyles} from "../enhanced-table.styles";

dayjs.extend(relativeTime);

// Define a default UI for filtering
export const FilterDefaultColumn = ({column: {filterValue, preFilteredRows, setFilter},}) => {
  const count = preFilteredRows.length
  const classes = useStyles();
  return (
    <TextField
      className={classes.filterInput}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
};
