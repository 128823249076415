import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {useStyles} from "../admin-create-partner-form/create-partner-form.styles";
import {useQuery} from "react-query";
import axios from "axios";
import ScrollableList from "../../../atoms/scrollable-list/scrollable-list.component";
import {Rating} from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";

const AdminReviewsListComponent = ({handleDialog, partner_id, partner_name}) => {
    const classes = useStyles();

    const {isLoading, data} = useQuery(
        ['dashboard-reviews-hotel'],
        async () => {
            const res = await axios.post('/dashboard/get-review-per-hotel', {partner_id: partner_id});
            console.log(res);
            return res.data.rows;
        }
    );

    return (
        <Grid container classes={{root: classes.gridRoot}} spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.mainHeader}>{partner_name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
            </Grid>
            {data?.map((review, index) => (
                <Grid container style={{padding: '0 2% 0 2%', display: 'flex', alignItems: 'center', border: '1px solid #12b5ad', height: '20%', marginBottom: '2%'}}>
                    <Grid item xs direction={'column'}>
                        <Grid item xs>
                            <Typography variant="h7" style={{color: '#dbc7b6', fontStyle: 'normal'}}>{review.first_name} {review.last_name}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h7" style={{color: '#f1a209', fontStyle: 'normal'}}>{review.email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <Rating precision={0.25} name="read-only" value={review.rating} readOnly emptyIcon={<StarIcon style={{ opacity: 0.55, stroke: '#12b5ad' }} fontSize="inherit" />} icon={<StarIcon style={{ color: '#12b5ad' }} fontSize="inherit" />} />
                    </Grid>
                    <Grid container item xs={4} style={{textAlign: 'right'}} direction={'column'}>
                        <Typography variant="h7" style={{color: '#dbc7b6', fontStyle: 'normal'}}>{review.phone_number}</Typography>
                        <Typography variant="h7" style={{color: '#dbc7b6', fontStyle: 'normal'}}>{review.date}</Typography>
                    </Grid>
                    <Typography variant="h7" style={{color: '#dbc7b6', fontStyle: 'normal'}}>{review.comments}</Typography>
                </Grid>
            ))}
        </Grid>
    )
};
export default AdminReviewsListComponent;

