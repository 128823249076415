import React from 'react';

import {requireAuth} from "../../../utils/auth";
import {Route, useRouter} from "../../../utils/router";

import ProtectedNav from "../../general/protected-nav/protected-nav.component";
import AdminDashboardInfoComponent from "../admin-dashboard-info/admin-dashboard-info.component";
import AdminInvoicesComponent from "../admin-invoices/admin-invoices.component";
import AdminRentalComponent from "../admin-rental/admin-rental.component";
import AdminArchiveComponent from "../admin-archive/admin-archive.component";
import AdminPartnerComponent from "../admin-partner/admin-partner.component";
import AdminBikeComponent from "../admin-bike/admin-bike.component";
const AdminDashboardComponent = () => {
  const router = useRouter();

  return (
    <>
      <ProtectedNav admin={true}/>
      <Route exact path={`${router.match.url}`} component={AdminDashboardInfoComponent}/>
      <Route exact path={`${router.match.url}/rental`} component={AdminRentalComponent}/>
      <Route exact path={`${router.match.url}/archive`} component={AdminArchiveComponent}/>
      <Route exact path={`${router.match.url}/invoices`} component={AdminInvoicesComponent}/>
      {/* Old routes */}
      <Route exact path={`${router.match.url}/partner`} component={AdminPartnerComponent}/>
      <Route exact path={`${router.match.url}/bikes`} component={AdminBikeComponent}/>
    </>
  );
};

export default requireAuth(AdminDashboardComponent);
