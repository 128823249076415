import React from 'react';
import FooterComponent from "../../general/main-footer/footer.component";
import MainNav from "../../general/main-nav/main-nav.component";
import BecomePartnerHero from "../become-partner-hero/become-partner-hero.component";
import BecomePartnerWhy from "../become-partner-why/become-why.component";
import BecomePartnerGuide from "../become-partner-guide/become-partner-guide.component";
import BecomePartnerForm from "../become-partner-form/become-form.component";
import BecomePartnerCarousel from "../become-partner-carousel/become-partner-carousel.component";

export const BecomePartnerSection = () => {

  return (
    <>
      <MainNav/>
      <BecomePartnerHero/>
      <BecomePartnerWhy/>
      <BecomePartnerGuide/>
      <BecomePartnerCarousel/>
      <BecomePartnerForm/>
      <FooterComponent topFooter={true}/>
    </>
  )
};

export default BecomePartnerSection;
