import React from "react";
import axios from "axios";

import Modal from '@material-ui/core/Modal';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

import {Fade} from "../fade/fade.component";
import {useRouter} from "../../utils/router";
import {useSnackbar} from "../../utils/snackbar";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#383838',
    flexDirection: 'column',
    padding: '24px 6px 24px 24px',
    margin: '16px',
    [theme.breakpoints.up('lg')]: {
      width: '628px',
      height: '222px',
      margin: 0,
    },
  },
  modalInfo: {
    margin: '16px 18px 32px 0',
    color: 'var(--loafer-white-soft)',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      flexDirection: 'row',
    }
  },
  button: {
    width: '98px',
    height: '48px',
    padding: '30px 16px',
    [theme.breakpoints.up('lg')]: {
      padding: '12px 16px',
      margin: '0 24px 0 0',
    }
  },
  buttonText: {
    height: '24px',
    fontSize: '1rem',
    fontWeight: '600',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--java-green)',
    "&&:hover": {
      color: 'rgba(18,181,173,0.5)'
    },
  },
}));

const ModalCancel = ({open, handleClose, bookingId}) => {
  const classes = useStyles();
  const router = useRouter();
  const {setSnackbar} = useSnackbar();

  const handleCancel = async () => {
    await axios.delete(`/booking/${bookingId}`)
      .then((res) => {
        console.log(res);
        router.push('/partner');
        handleClose();
        setSnackbar('Booking is deleted.', 'success');
      })
      .catch((err) => {
        console.log(err);
        handleClose();
        setSnackbar('Something went wrong, please try again.', 'error');
      });
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      className={classes.modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography variant='h4' id="spring-modal-title">
            Cancel reservation?
          </Typography>
          <Typography variant='body1' className={classes.modalInfo} id="spring-modal-description">
            The reservation will only be refunded to the guest if it's cancelled outside 72 hours before the ride
          </Typography>
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              onClick={handleClose}
            >
              <Typography className={classes.buttonText}>
                KEEP
              </Typography>
            </Button>
            <Button
              className={classes.button}
              onClick={handleCancel}
            >
              <Typography className={classes.buttonText}>
                CANCEL
              </Typography>
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
};

export default ModalCancel;
