import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    }
  },
  buttonBack: {
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
  buttonNext: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
  icon: {
    width: '48px',
    height: '48px',
    objectFit: 'contain',
  },
}));
