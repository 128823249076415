import React, {useState} from 'react';

import axios from "axios";
import {useForm, Controller} from "react-hook-form";
import {useStateMachine} from "little-state-machine";
import dayjs from "dayjs";

import updateFormAction from "../../../state-machine/actions/update-form-action";
import {Link, useRouter} from "../../../utils/router";
import {phoneNumberReg} from "../../../utils/booking";
import {countries} from "../../../utils/countries";
import {useSnackbar} from "../../../utils/snackbar";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {CircularProgress, TextField} from "@material-ui/core";

import CustomCheckbox from "../../../atoms/checkbox/checkbox.component";
import SectionComponent from "../../general/section/section.component";
import MainNav from "../../general/main-nav/main-nav.component";

import chevronRight from '../../../assets/ic-chevron-right.svg';
import SharkImage from '../../../assets/group-961.svg';
import icEdit from '../../../assets/ic-edit.svg';

import {useStyles} from "./booking-payment.styles";
import icKeyboardIcon from "../../../assets/ic-keyboard-arrow-down.svg";
import SimpleDatePicker from "../../../atoms/simple-date-picker/simple-date-picker.component";

const BookingPayment = () => {
  const router = useRouter();
  const {setSnackbar} = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [date, setDate] = useState(null);
  const handleTermsAccept = () => setAcceptTerms(!acceptTerms);

  const {actions, state} = useStateMachine({updateFormAction})
  const {handleSubmit, control, reset, register, errors, formState: {isValid}} = useForm({
    defaultValues: state.bookingDetails,
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    setIsProcessing(true);
    data['redirect_url'] = 'booking/success';
    data['methods'] = ['ideal', 'creditcard'];
    data['birth_date'] = date;
    actions.updateFormAction(data);

    // Workaround https://github.com/bluebill1049/little-state-machine/issues/42
    Object.assign(state.bookingDetails, data);

    await axios.post('/payment', state.bookingDetails)
      .then((res) => {
        window.location.replace(res.data);
      })
      .catch((err) => {
        setSnackbar(`${err.response.data.message}.`, 'error');
      });

    setIsProcessing(false);
    reset();
  };

  return (
    <>
      <MainNav bookingGuest={true} transparent={true}/>
      <SectionComponent transparent={true} bgImage={SharkImage}>
        <Container maxWidth='md' className={classes.heroContainer}>
          <Grid
            container
            item
            xs={12}
            spacing={matches ? 7 : 2}
            classes={{root: classes.detailsGrid}}
            className={classes.bookingDetailsContainer}
          >
            <Grid item xs={5} sm={2}>
              <Typography variant='body2'>
                {state.bookingDetails.amount_bikes > 1 ? 'V-bikes' : 'V-bike'}
              </Typography>
              <Typography variant='body1' className={classes.detailsValue}>
                {`${state.bookingDetails.amount_bikes}`}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant='body2'>
                {state.bookingDetails.rental_dates.length > 1 ? 'Dates' : 'Date'}
              </Typography>
              {
                state.bookingDetails.rental_dates.map(date => (
                  <Typography variant='body1' className={classes.detailsValue}>
                    {`${dayjs(date).format('DD-MM-YYYY')}`}
                  </Typography>
                ))
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='body2'>Accommodation</Typography>
              <Typography variant='body1' className={classes.detailsValue}>
                {state.bookingDetails.partner_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.editContainer}>
              <Button
                onClick={() => router.push(`/booking/book/${router.query.id}`)}
                className={classes.editButton}
              >
                <img src={icEdit} className={classes.editIcon} alt='Edit'/>
                <Typography className={classes.editButtonText}>Change</Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
      <SectionComponent size='auto'>
        <Container maxWidth='md' className={classes.container}>
          <Grid container >
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.header}>Personal details</Typography>
            </Grid>
            <Grid item>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.inputField}
                      variant="outlined"
                      type="text"
                      label="First name"
                      name="first_name"
                      multiline={true}
                      rows={1}
                      error={errors.first_name ? true : false}
                      helperText={errors.first_name && errors.first_name.message}
                      fullWidth={true}
                      inputRef={register({
                        required: "This field is required",
                        pattern: {
                          value: /^[a-zA-Z]+$/,
                          message: "Please only use alphabetic characters"
                        }
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.inputField}
                      variant="outlined"
                      type="text"
                      label="Last name"
                      name="last_name"
                      multiline={true}
                      rows={1}
                      error={errors.last_name ? true : false}
                      helperText={errors.last_name && errors.last_name.message}
                      fullWidth={true}
                      inputRef={register({
                        required: "This field is required",
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Please only use alphabetic characters"
                        }
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SimpleDatePicker text={"Birth date"} chosenDate={date} setChosenDate={(date) => setDate(date)}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.inputField}
                      variant="outlined"
                      type="text"
                      label="Email address"
                      name="email"
                      multiline={true}
                      rows={1}
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      inputRef={register({
                        required: "This field is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Enter a valid e-mail address",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.inputField}
                      variant="outlined"
                      type="text"
                      label="Phone number"
                      name="phone_number"
                      multiline={true}
                      rows={1}
                      error={errors.phone_number ? true : false}
                      helperText={errors.phone_number && errors.phone_number.message}
                      fullWidth={true}
                      inputRef={register({
                        pattern: {
                          value: phoneNumberReg,
                          message: "Enter a valid phone number",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      style={{height: '100%'}}
                      classes={{paper: classes.autoComplete}}
                      options={countries}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => (<span>{option.label}</span>)}
                      popupIcon={
                        <img
                          src={icKeyboardIcon}
                          className={`${classes.keyboardIcon} ${classes.keyboardIconCountry}`}
                          alt='Select'
                        />
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.inputField}
                          name="country"
                          label="Nationality"
                          variant="outlined"
                          error={errors.country ? true : false}
                          helperText={errors.country && errors.country.message}
                          // inputRef={register({
                          //   required: "This field is required",
                          // })}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container item xs={12} alignContent='center'>
                    <Grid
                      container
                      item
                      className={classes.bookingInfoTop}>
                      <Grid item xs={12} sm={7} className={classes.discountContainer}>
                        <img src={chevronRight} className={classes.discountIconArrow} alt='Arrow'/>
                        <Typography className={classes.discountCodeText}>Add discount code</Typography>
                      </Grid>
                      <Grid item xs={6} sm={3} classes={{root: classes.totalPriceGrid}}>
                        <Typography variant='body1' className={classes.totalPriceText}>Total price</Typography>
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <Typography variant='h5' className={classes.totalPrice}>EUR {state.bookingDetails.amount}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container item className={classes.bookingInfoBottom}>
                      <Grid item xs={12}>
                        <Typography variant='body1' className={classes.paymentConfirmInfo}>
                          We'll send you a confirmation e-mail once your payment has been processed.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.checkboxContainer}>
                        <Controller
                          name="accept_terms"
                          id="accept_terms"
                          control={control}
                          defaultValue={false}
                          render={() => (
                            <CustomCheckbox
                              onChange={handleTermsAccept}
                              checked={acceptTerms}
                            />
                          )}
                        />
                        <Typography variant='body2' className={classes.checkboxText}>I read and accept the
                          <Link className={classes.checkboxTextGreen} target="_blank" to='/terms'>terms and
                            conditions</Link>.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classes.buttonPaymentContainer} item xs={12}>
                    <Button
                      className={isProcessing ?
                        `${classes.buttonPayment} ${classes.buttonPaymentDisabled}`
                        :
                        `${classes.buttonPayment}`}
                      disabled={isProcessing}
                      type="submit"
                      name="submit"
                      id="submit"
                    >
                      <Typography className={classes.buttonPaymentText}>
                        {isProcessing ? <CircularProgress/> : `Continue to payment`}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
    </>
  )
};

export default BookingPayment;
