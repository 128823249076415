import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-10)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '40px'
    }
  },
  header: {
    marginTop: '24px'
  },
  box: {
    padding: '24px',
    borderBottom: 'solid 1px #2f2f2f',
    backgroundColor: 'var(--background-surface)',
    [theme.breakpoints.up('sm')]: {
      marginTop: '24px',
      border: 'solid 1px #2f2f2f',
    }
  },
  rightArrow: {
    width: '24px',
    height: '24px',
    margin: '40px 0 24px 0',
    objectFit: 'contain',
  },
  gridItem: {
    display: 'flex',
    margin: '12px 0'
  },
  bookingIcon: {
    width: '24px',
    height: '24px',
    margin: '2px 16px 1px 0',
    objectFit: 'contain',
  },
  bookingDetails: {
    margin: '0 5px',
    fontSize: '1.125em',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    display: 'flex',
    alignItems: 'center',
  },
  personDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  personDetailsSub: {
    display: 'flex',
    margin: '8px 0',
  },
  detailsWhite: {
    color: 'var(--loafer-white-soft)',
  },
  detailsGreen: {
    color: 'var(--java-green)',
  },
  buttonItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    padding: '12px 16px',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '-webkit-fill-available',
    [theme.breakpoints.up('lg')]: {
      width: '236px',
      padding: '12px 0 12px 36px'
    }
  },
  cancelText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--java-green)',
    "&&:hover": {
      color: 'rgba(18,181,173,0.5)'
    },
  },
  noticeBox: {
    margin: '24px 0',
    padding: '12px 16px',
    backgroundColor: 'rgba(241,162,9, 0.32)',
  },
  noticeBoxEmpty: {
    margin: '24px 0',
    padding: '12px 16px 16px 12px',
  },
  noticeText: {
    margin: '0 0 0 16px',
    display: 'flex',
    alignItems: 'flex-end',
  },
}));
