import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const StyledCircle = ({name, count}) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.circle}>
                <Typography variant="h3">
                    {count}
                </Typography>
            </div>
            <Typography className={classes.text}>{name}</Typography>
        </>
    )
}

export const useStyles = makeStyles((theme) => ({
    circle: {
        display: 'flex',
        border: '2px solid var(--java-green)',
        height: '0',
        paddingTop: '50%',
        paddingBottom: '50%',
        width: '100%',
        margin: 'auto',
        borderRadius: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        textAlign: 'center'
    }
}));
export default StyledCircle;
