import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heroContainer: {
    padding: 0,
  },
  container: {
    paddingTop: '90px',
    paddingBottom: '90px',
  },
  detailsGrid: {
    width: 'auto',
  },
  bookingDetailsContainer: {
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#222222',
    padding: '16px',
    margin: '120px 16px -80px 16px',
    [theme.breakpoints.up('lg')]: {
      padding: '0',
      margin: '15% 0 30px 0',
    }
  },
  detailsValue: {
    fontWeight: 'bold',
    color: 'var(--loafer-white-soft)',
  },
  editContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editButton: {
    height: '48px',
    padding: '12px 16px 12px 12px',
    width: '-webkit-fill-available',
    [theme.breakpoints.up('lg')]: {
      width: '130px',
    }
  },
  editButtonText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--java-green)',
  },
  editIcon: {
    width: '24px',
    height: '24px',
    margin: '0 8px 0 0',
    objectFit: 'contain',
  },
  header: {
    margin: '16px 0',
    [theme.breakpoints.up('lg')]: {
      margin: '0 0 24px',
    },
  },
  inputField: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
    height: '100%',
  },
  autoComplete: {
    "& > *": {
      fontSize: '16px',
      backgroundColor: '#2e2e2e',
      color: 'var(--loafer-white-soft)',
    },
    height: '100%'
  },
  keyboardIcon: {
    width: '24px',
    height: '25px',
    objectFit: 'contain',
    margin: '0 18px 0 0',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
  },
  keyboardIconCountry: {
    margin: '0 18px 0 0',
    position: 'relative',
  },
  rootText: {
    margin: '12px 0'
  },
  bookingInfoTop: {
    padding: '24px',
    border: 'solid 1px #2f2f2f',
    backgroundColor: 'var(--aztec-black)',
  },
  discountContainer: {
    display: 'flex',
    "&&:hover": {
      cursor: 'pointer',
      opacity: 0.5,
    },
    margin: '0 0 20px 0',
    [theme.breakpoints.up('lg')]: {
      margin: 0,
    }
  },
  discountCodeText: {
    margin: '0 0 0 8px',
    fontSize: '1.125em',
    letterSpacing: '0.5px',
    color: 'var(--loafer-white)',
  },
  discountIconArrow: {
    width: '24px',
    height: '24px',
    margin: '1px 8px 2px 0',
    objectFit: 'contain',
    "&&:hover": {
      opacity: 0.5,
    }
  },
  totalPriceGrid: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  totalPriceText: {
    margin: '5px 0 0 0',
    color: 'var(--loafer-white)',
  },
  totalPrice: {
    margin: '0 0 0 24px',
  },
  bookingInfoBottom: {
    padding: '23px 24px 24px',
    border: 'solid 1px #2f2f2f',
  },
  paymentConfirmInfo: {
    margin: '0 0 16px',
    fontWeight: '500',
    color: 'var(--loafer-white-soft)',
  },
  checkboxContainer: {
    display: 'flex',
  },
  checkboxText: {
    margin: '16px 8px',
    color: 'var(--loafer-white-soft)',
  },
  checkboxTextGreen: {
    margin: '0 0 0 5px',
    fontWeight: 'normal',
    color: 'var(--java-green)',
    textDecoration: 'none',
    "&&:hover": {
      color: 'rgba(18,181,173,0.5)'
    },
  },
  buttonPaymentContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonPayment: {
    padding: '12px 16px',
    backgroundColor: 'var(--buttercup-yellow)',
    borderRadius: '0',
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    },
    width: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      width: '227px',
    }
  },
  buttonPaymentDisabled: {
    backgroundColor: 'rgba(249,252,246,0.32)',
  },
  buttonPaymentText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--midnight-forest-black)',
  },
}));
