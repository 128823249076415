import React, {useState} from 'react';
import {useStyles} from "./contact-us.styles";
import HeroMainNavComponent from "../b2b/hero-main-nav/HeroMainNav.component";
import SectionComponent from "../../general/section/section.component";
import Container from "@material-ui/core/Container";
import {Box, Grid} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Pattern from "../../../assets/pattern-transparent-black.svg";
import Button from "@material-ui/core/Button";
import {RightArrowIcon} from "../../../assets/v2-icons";
import BikeBackground from "../../../assets/book-a-stay@3x.png";
import {Textfit} from "react-textfit";
import {useSnackbar} from "../../../utils/snackbar";
import axios from "axios";

const ContactUsComponent = () => {

    // Imports
    const classes = useStyles();

    // States
    const [clicked, setClicked] = useState(null);
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [message, setMessage] = useState('');

    // Functions

    // Declarations
    const {setSnackbar} = useSnackbar();

    const getDepartment = () => {
        switch (clicked){
            case 1:
                return "Business to business";
            case 2:
                return "Shop";
            default:
                return null;
        }
    }

    const sendRequest = () => {
        const obj = {mail: mail, name: name, message: message, department: getDepartment()};

        const isEmpty = Object.values(obj).some(value => {
            return value === null || value.length === 0;
        })

        if (!isEmpty){
            axios.post('/contactUs', obj).then((res) => {
                setSnackbar(`Request successfully sent.`, 'success');
            }).catch((err) => {
                setSnackbar(`${err}`, 'error');
            })
        } else {
            setSnackbar(`Not all values have been filled in.`, 'error');
        }
    }

    // Data retrieval


    return (
        <>
            <HeroMainNavComponent returnTo={'/shop'} iconRoute={'/shop'} noContactBtn/>
            <SectionComponent transparent={true} size="auto" className={classes.section}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container direction={'row'} className={classes.gridContainer}>
                        <Grid item className={classes.chooseGrid}>
                            <Box className={classes.chooseSection} style={{backgroundImage: `url(${Pattern})`}}>
                                <div className={classes.buyList}>
                                    {/*<Typography className={classes.header}>THE ORIGINAL <a className={classes.vesperBlue}>VESPER</a> BIKE</Typography>*/}
                                    <div className={classes.headerBox}>
                                        {/*<Typography className={classes.headerBoxTitle}>For business</Typography>*/}
                                        <Textfit className={classes.headerBoxTitle} mode="single">Contact us</Textfit>
                                    </div>
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        I have a question regarding
                                    </Typography>
                                    <Grid container style={{marginTop: 10}} columnSpacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                className={[clicked === 1 ? classes.buttonLocations : classes.unClicked]}
                                                onClick={() => setClicked(1)}
                                            >
                                                <Typography className={classes.buttonLocationsText}>
                                                    Business to business
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                className={[clicked === 2 ? classes.buttonLocations : classes.unClicked]}
                                                onClick={() => setClicked(2)}
                                            >
                                                <Typography className={classes.buttonLocationsText}>
                                                    Shop
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {/* Form */}
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        Your name
                                    </Typography>
                                    <input type="text" placeholder={'Your name...'} className={classes.inputField} value={name} onChange={(event) => {
                                        setName(event.target.value);
                                    }}/>

                                    <Typography variant='caption2' className={classes.subHeader}>
                                        Your email
                                    </Typography>
                                    <input type="text" placeholder={'Your email...'} className={classes.inputField} value={mail} onChange={(event) => {
                                        setMail(event.target.value);
                                    }}/>

                                    <Typography variant='caption2' className={classes.subHeader}>
                                        Your message
                                    </Typography>
                                    <textarea type="text" placeholder={'Your message...'} className={classes.inputField} value={message} onChange={(event) => {
                                        setMessage(event.target.value);
                                    }}/>

                                    <Button
                                        className={classes.buttonLocations}
                                        style={{maxWidth: 'max-content', marginTop: 20}}
                                        onClick={sendRequest}
                                    >
                                        <Typography className={classes.buttonLocationsText}>
                                            Send request
                                        </Typography>
                                        <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item className={classes.photoRightSection}>
                            <img src={BikeBackground} alt="Bike picture" className={classes.bikeImage}/>
                        </Grid>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};

export default ContactUsComponent;
