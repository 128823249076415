import React from 'react';
import {Box, Dialog, Grid, Typography} from "@material-ui/core";
import StyledButton from "../styled-button/styled-button.component";
import {Rating} from "@material-ui/lab";
import StarIcon from '@material-ui/icons/Star'
import AdminReviewsListComponent from "../../modules/admin/admin-reviews-list/admin-reviews-list.component";

const PartnerReviewListItem = ({name, index, rating, id}) => {

    const [openList, setOpenList] = React.useState(false)

    return (
        <>
            <Dialog
                maxWidth={false}
                open={openList}
                onClose={() => setOpenList(false)}
            >
                <AdminReviewsListComponent handleDialog={() => setOpenList(false)} partner_id={id} partner_name={name}/>
            </Dialog>
            <Grid container direction="row" style={{padding: '2%', display: 'flex', alignItems: 'center'}}>
                {/*Index number*/}
                <Grid item xs={2}>
                    <Box style={{height: '0', width: '50%', paddingBottom: '25%', paddingTop: '25%', border: '1px solid #12b5ad', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography variant="h4" style={{fontStyle: 'normal'}}>{index}</Typography>
                    </Box>
                </Grid>
                {/*Partner name*/}
                <Grid item xs={4}>
                    <Typography variant="h5">{name}</Typography>
                </Grid>
                {/*Partner score*/}
                <Grid item xs={4}>
                    <Rating precision={0.25} name="read-only" value={rating} readOnly emptyIcon={<StarIcon style={{ opacity: 0.55, stroke: '#12b5ad' }} fontSize="inherit" />} icon={<StarIcon style={{ color: '#12b5ad' }} fontSize="inherit" />} />
                </Grid>
                {/*List button*/}
                <Grid item xs={2}>
                    <StyledButton text="List" onClick={() => setOpenList(true)}/>
                </Grid>
            </Grid>
        </>
    )
}
export default PartnerReviewListItem;
