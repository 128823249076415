import React from "react";
import {useRouter} from "../../../utils/router";
import PortalBikesReport from "../portal-bikes-report/portal-bikes-report.component";

const PortalBikeFailure = () => {
  const router = useRouter();
  const bikeId = router.query.id;
  const type = router.query.type;

  const damageCategories = {
    "Battery": 'Battery',
    "Brakes": 'Brakes',
    "Display": 'Display',
    "Tires": 'Flat tire',
    "Motor": 'Motor',
    "Other": 'Other',
  };

  return (
    <>
      <PortalBikesReport
        type={type}
        currentCrumb='Report a bike failure'
        header='Report a Bike failure'
        subHeader={`Vesper bike ${bikeId} is out of service and this is what needs to be fixed:`}
        damageCategories={damageCategories}
      />
    </>
  )
};

export default PortalBikeFailure;
