import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    content: "",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }
}));

function VideoEmbed(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{opacity: props.opacity}}>
      <video className={classes.video} loop muted autoPlay playsInline id='video'>
        <source src={props.video} type='video/mp4'/>
      </video>
    </div>
  );
}

export default VideoEmbed;
