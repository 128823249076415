import React from "react";

import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";

import AuthFormComponent from "../auth-form/auth-form.component";
import {useRouter} from "../../../utils/router";

function AuthContainer(props) {
  const router = useRouter();
  const [formAlert, setFormAlert] = React.useState(null);

  const handleAuth = () => {
    router.push(props.afterAuthPath);
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
      <AuthFormComponent
        type={props.type}
        typeValues={props.typeValues}
        onAuth={handleAuth}
        onFormAlert={handleFormAlert}
      />
    </>
  );
}

export default AuthContainer;
