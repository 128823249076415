import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import auth0 from "./auth0";
import {useUser, createUser, updateUser} from "./db";
import {CustomError} from "./util";
import {history, useRouter} from "./router";
import PageLoaderComponent from "../modules/general/page-loader/page-loader.component";
import {generatePassword} from "./pass-gen";

// Whether to merge extra user data from database into auth.user
const MERGE_DB_USER = true;

const authContext = createContext();

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function AuthProvider({children}) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useAuthProvider() {
  // Store auth user object
  const [user, setUser] = useState(null);

  // Format final user object and merge extra data from database
  const finalUser = usePrepareUser(user);

  // Handle response from authentication functions
  const handleAuth = async (user) => {
    // Create the user in the database
    // Auth0 doesn't indicate if they are new so we attempt to create user every time
    await createUser(user.sub, {email: user.email});
    // Update user in state
    setUser(user);
    return user;
  };

  const signup = (email) => {
    return auth0.extended
      .signupAndAuthorize({
        email: email,
        password: generatePassword(8),
      })
      .then(handleAuth);
  };

  const signin = (email, password) => {
    return auth0.extended
      .login({
        username: email,
        password: password,
      })
      .then(handleAuth);
  };

  const signout = () => {
    return auth0.extended.logout();
  };

  const sendPasswordResetEmail = (email) => {
    return auth0.extended.changePassword({
      email: email,
    });
  };

  const confirmPasswordReset = (password, code) => {
    // This method is not needed with Auth0 but added in case your exported
    // Divjoy template makes a call to auth.confirmPasswordReset(). You can remove it.
    return Promise.reject(
      new CustomError(
        "not_needed",
        "Auth0 handles the password reset flow for you. You can remove this section or page."
      )
    );
  };

  const updateEmail = (email) => {
    return auth0.extended.updateEmail(email).then(() => {
      setUser({...user, email});
    });
  };

  const updatePassword = (password) => {
    return auth0.extended.updatePassword(password);
  };

  // Update auth user and persist to database (including any custom values in data)
  // Forms can call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    const {email, name, picture} = data;

    // Update auth email
    if (email) {
      await auth0.extended.updateEmail(email);
    }

    // Update auth profile fields
    if (name || picture) {
      let fields = {};
      if (name) fields.name = name;
      if (picture) fields.picture = picture;
      await auth0.extended.updateProfile(fields);
    }

    // Persist all data to the database
    await updateUser(user.sub, data);

    // Update user in state
    const currentUser = await auth0.extended.getCurrentUser();
    setUser(currentUser);
  };

  useEffect(() => {
    // Subscribe to user on mount
    const unsubscribe = auth0.extended.onChange(async (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Unsubscribe on cleanup
    return () => unsubscribe();
  }, []);

  return {
    user: finalUser,
    signup,
    signin,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateEmail,
    updatePassword,
    updateProfile,
  };
}

// Format final user object and merge extra data from database
function usePrepareUser(user) {
  // Fetch extra data from database (if enabled and auth user has been fetched)
  const userDbQuery = useUser(MERGE_DB_USER && user && user.sub);

  // Memoize so we only create a new object if user or userDbQuery changes
  return useMemo(() => {
    // Return if auth user is null (loading) or false (not authenticated)
    if (!user) return user;

    // Data we want to include from auth user object
    let finalUser = {
      uid: user.sub,
      email: user.email,
      emailVerified: user.email_verified,
      name: user.name,
    };

    // Get the provider which is prepended to user.sub
    const providerId = user.sub.split("|")[0];
    // Include an array of user's auth provider, such as ["password"]
    // Components can read this to prompt user to re-auth with the correct provider
    // In the future this may contain multiple if Auth0 Account Linking is implemented.
    const providerName = allProviders.find((p) => p.id === providerId).name;
    finalUser.providers = [providerName];

    // If merging user data from database is enabled ...
    if (MERGE_DB_USER) {
      switch (userDbQuery.status) {
        case "idle":
          // Return null user until we have db data to merge
          return null;
        case "loading":
          return null;
        case "error":
          // Log query error to console
          console.error(userDbQuery.error);
          return null;
        case "success":
          // If user data doesn't exist we assume this means user just signed up and the createUser
          // function just hasn't completed. We return null to indicate a loading state.
          if (userDbQuery.data === null) return null;

          // Merge user data from database into finalUser object
          Object.assign(finalUser, userDbQuery.data);

        // no default
      }
    }

    return finalUser;
  }, [user, userDbQuery]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return (props) => {
    // Get authenticated user
    const auth = useAuth();
    // get the router
    const router = useRouter();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        auth.signout();
        // append next param with current path
        history.replace(`/auth/signin?next=${router.pathname}`);
      }
      // add router to useEffect dependencies
    }, [auth, router]);

    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    if (!auth.user) {
      return <PageLoaderComponent/>;
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};

const allProviders = [
  {
    id: "auth0",
    name: "password",
  },
];
