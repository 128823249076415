import React from "react";
import {useQuery} from "react-query";
import axios from "axios";
import SectionComponent from "../../general/section/section.component";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {useStyles} from './admin-invoices.style';
import {Button, Typography} from "@material-ui/core";
import InvoiceModel from "../../../models/invoice.model";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const AdminInvoicesComponent = () => {
    const classes = useStyles();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [getData, setData] = React.useState([]);

    const {isLoading} = useQuery(
        ['dashboard-invoices'],
        async () => {
            const res = await axios.get('/dashboard/get-invoices');

            let objArray = [];
            res.data.rows.forEach(val => {
                if (val.code_20 !== null && val.code_35 === null){ // If hotel with 20 bike sells then this function runs
                    objArray.forEach((element, index) => {
                        if(element.partner_id === val.partner_id){
                            const currObj = objArray[index];
                            currObj.code_20_count = val.code_20;
                            objArray[index] = currObj;
                        }
                    })
                } else if (val.code_20 === null && val.code_35 !== null) { // If not existing in loop add new InvoiceModel
                    objArray.push(new InvoiceModel(val.partner_id, val.partner_name, val.partner_code, val.partner_email, val.code_35, val.code_20));
                }
            });
            setData(objArray);
        }
    );

    const increaseBikeCount = (index, value) => {
        if (value===35){
            const data = [...getData];
            data[index]._code_35_count++;
            setData(data);
        } else {
            const data = [...getData];
            data[index]._code_20_count++;
            setData(data);
        }
    }

    const decreaseBikeCount = (index, value) => {
        if (value===35){
            const data = [...getData];
            if (data[index]._code_35_count !== 0){
                data[index]._code_35_count--;
                setData(data);
            }
        } else {
            const data = [...getData];
            if (data[index]._code_20_count !== 0){
                data[index]._code_20_count--;
                setData(data);
            }
        }
    }

    const sendInvoice = async (partner, index) => {
        MySwal.fire({
            icon: "question",
            title: `${partner.partner_name}`,
            text: "Are you sure you want to send this invoice?",
            backdrop: true,
            showCancelButton: true,
            confirmButtonText: 'Send',
            cancelButtonText: 'Cancel',
            confirmButtonColor: "#12B5AD",
            cancelButtonColor: '#202020',
    }).then(async (result) => {
        if (result.isConfirmed) {
            const payload = await axios.post('/dashboard/send-invoice', partner);
            if (payload.status===200){
                const data = [...getData];
                data.splice(index, 1);
                setData(data);

                Swal.fire({
                    position: 'bottom-end',
                    toast: true,
                    icon: 'success',
                    title: 'Factuur verstuurd',
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                Swal.fire({
                    position: 'bottom-end',
                    toast: true,
                    icon: 'error',
                    title: 'Een fout heeft zich voorgedaan.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
    })};

    const date = new Date();

    return (
        <>
            <SectionComponent size='large'>
                <Container maxWidth='lg'>
                    <Grid container spacing={3}>
                        {isLoading ?
                            <div>Retrieving data...</div>
                            :
                            <>
                                <Grid container xs={12} className={classes.middleSection}>
                                    <Grid xs={10} className={classes.header}>
                                        <Typography>
                                            Invoices for the month:
                                        </Typography>
                                        <h1>{monthNames[new Date().getUTCMonth() - 1]}</h1>
                                    </Grid>

                                    <Grid container xs={10} columns={{ xs: 4, md: 8 }} spacing={{ xs: 2, md: 3 }} className={classes.partnerSection} style={{paddingTop: '1%'}}>
                                        <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                                            <Typography>Partner name</Typography>
                                        </Grid>
                                        <Grid item xs={1}>

                                        </Grid>
                                        <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}>
                                            <Typography>Bike 35</Typography>
                                        </Grid>
                                        <Grid item xs={1}>

                                        </Grid>
                                        <Grid item xs={1}>

                                        </Grid>
                                        <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}>
                                            <Typography>Bike 20</Typography>
                                        </Grid>
                                        <Grid item xs={1}>

                                        </Grid>
                                        <Grid item xs={2}>
                                        </Grid>
                                    </Grid>

                                    {getData?.map((p, i) => (
                                        <Grid key={i} container xs={10} columns={{ xs: 4, md: 8 }} spacing={{ xs: 2, md: 3 }} className={classes.partnerSection}>
                                            <Grid item xs={4} className={classes.partnerName}>
                                                <Typography className={classes.textPartnerName}>
                                                    {p._partner_name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} className={classes.partnerComponents}>
                                                <Button className={classes.buttonCount} onClick={() => decreaseBikeCount(i, 35)}>
                                                    <Typography>-</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} className={classes.partnerComponents}>
                                                <Typography >
                                                    {p._code_35_count}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} className={classes.partnerComponents}>
                                                <Button className={classes.buttonCount} onClick={() => increaseBikeCount(i, 35)}>
                                                    <Typography>+</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} className={classes.partnerComponents}>
                                                <Button className={classes.buttonCount} onClick={() => decreaseBikeCount(i, 20)}>
                                                    <Typography>-</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} className={classes.partnerComponents}>
                                                <Typography >
                                                    {p._code_20_count}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} className={classes.partnerComponents}>
                                                <Button className={classes.buttonCount} onClick={() => increaseBikeCount(i, 20)}>
                                                    <Typography>+</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2} className={classes.partnerComponents}>
                                                <Button className={classes.buttonSend} variant="contained" onClick={() => sendInvoice(p, i)}>
                                                    <Typography className={classes.buttonReserveText}>
                                                        Send invoice
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        }
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};
export default AdminInvoicesComponent;
