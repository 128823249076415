import React from 'react';

import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";

import {useStyles} from './faq.styles';
import SectionComponent from "../section/section.component";
import FaqListItem from "../../../atoms/faq-list-item/faq-list-item.component";

const Faq = ({mainHeader, subHeader, categories, subCategory, crumb = false}) => {
  const classes = useStyles();

  return (
    <>
      <SectionComponent
        className={!crumb ? `${classes.root} ${classes.crumbRoot}` : `${classes.root}`}
        bgColor='var(----background-surface)'
        size='auto'
      >
        <Typography variant='h1' className={classes.mainHeader}>{mainHeader}</Typography>
        <Typography variant='body1' className={classes.subHeader}>
          {subHeader}
        </Typography>
        {
          categories.map((category, index) => {
            return (
              <div key={index} className={classes.faqGroup}>
                <Typography variant='h2'>{category.title}</Typography>
                <List className={classes.list}>
                  {
                    category.questionsAnswers.map((b, i) => (
                      <FaqListItem key={i} question={b.question} answer={b.answer}/>
                    ))
                  }
                </List>
              </div>
            )
          })
        }
        {subCategory && (
          <>
            <Typography variant='h3' className={classes.faqSubHeader}>{subCategory.title}</Typography>
            <List className={classes.list}>
              {
                subCategory.questionsAnswers.map((b, i) => (
                  <FaqListItem key={i} question={b.question} answer={b.answer}/>
                ))
              }
            </List>
          </>
        )}
      </SectionComponent>
    </>
  )
};

export default Faq;
