import React from 'react';
import axios from "axios";
import {useQuery} from "react-query";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";

import {useStyles} from "./booking-select-partner.styles";
import {useRouter} from "../../../utils/router";
import icKeyboardIcon from "../../../assets/ic-keyboard-arrow-down.svg";

const BookingSelectPartner = () => {
  const classes = useStyles();
  const router = useRouter();

  const [partner, setPartner] = React.useState({});
  const [partnerBikes, setPartnerBikes] = React.useState();

  const handleChange = (event) => {
    setPartner(event.target.value);
    setPartnerBikes(event.target.value.total_bikes);
  };

  const {isLoading, data} = useQuery(
    ['partners'],
    async () => {
      const res = await axios.get('/partners')
      return res.data.rows;
    },
    {
      refetchInterval: 3000,
    }
  );

  return (
    <>
      <Grid container >
        <Grid item xs={12}>
          <Box className={classes.partnerBox}>
            {
              isLoading ?

                <CircularProgress/>
                :
                <>
                  <FormControl variant="outlined">
                    <InputLabel id="partner_select_label">Accommodation</InputLabel>
                    <Select
                      labelId="partner_select_label"
                      id="partner_select"
                      label="Accommodation"
                      value={Object.keys(partner).length ? partner : null}
                      onChange={handleChange}
                      IconComponent={() => (<img src={icKeyboardIcon} className={classes.keyboardIcon} alt='Select'/>)}
                    >
                      {data.map((p, i) => (
                          p.partner_id === 50 ? null : (<MenuItem key={i} value={p} className={classes.menuItem}>{p.partner_name}</MenuItem>)
                      ))}
                    </Select>
                    {
                      partnerBikes < 1 &&
                      <FormHelperText classes={{root: classes.helperText}}>
                        Selected partner is currently not hosting any of our bikes.
                      </FormHelperText>
                    }
                  </FormControl>
                </>
            }
          </Box>
        </Grid>
        <Grid className={classes.buttonContainer} item xs={12}>
          <Button
            className={
              Object.keys(partner).length === 0 || partnerBikes < 1 ?
                `${classes.continueButton} ${classes.continueButtonDisabled}`
                :
                `${classes.continueButton}`}
            disabled={Object.keys(partner).length === 0 || partnerBikes < 1}
            onClick={() => router.push(`/booking/book/${partner.partner_id}`)}
          >
            <Typography className={classes.continueButtonText}>
              Continue
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  )
};

export default BookingSelectPartner;
