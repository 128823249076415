import { useQuery, queryCache } from "react-query";
import { apiRequest } from "./util";

/**** USERS ****/

// Fetch user data (hook)
// This is called automatically by auth.page.js and merged into auth.user
export function useUser(uid) {
  // Unique cache key for this query
  const cacheKey = ["user", { uid }];
  // Query for fetching user
  const query = () => apiRequest(`user/${uid}`);
  // Fetch with react-query (only if we have a uid)
  // Docs: https://react-query.tanstack.com/docs/guides/queries
  return useQuery(cacheKey, query, { enabled: !!uid });
}

// Update an existing user
export function updateUser(uid, data) {
  // Send API request
  return apiRequest(`user/${uid}`, "PATCH", data).then((user) => {
    const cacheKey = ["user", { uid }];
    // Update user in cache (causing components to re-render with new data)
    queryCache.setQueryData(cacheKey, user);
    // Return the updated user
    return user;
  });
}

// Create a new user
export function createUser(uid, data) {
  return apiRequest("user", "POST", { uid, ...data });
}
