import React from 'react';
import HeroImageSectionComponent from "./hero-image-section/HeroImageSection.component";
import HeroMainNavComponent from "./hero-main-nav/HeroMainNav.component";
import HeroConceptOverlaySectionsComponent from "./hero-concept-overlay-sections/hero-concept-overlay-sections.component";
import HeroConceptComponent from "./hero-concept/hero-concept.component";
import HeroReferencesComponent from "./hero-references/hero-references.component";
import HeroDashboardComponent from "./hero-dashboard/hero-dashboard.component";
import HeroContentSectionComponent from "./hero-content-section/hero-content-section.component";
import HeroFaqComponent from "./hero-faq/hero-faq.component";
import HeroFooterComponent from "./hero-footer/hero-footer.component";
import {useQuery} from "react-query";
import {client} from "../../../utils/contentful";

const LandingB2bComponent = () => {

    const {data} = useQuery(
        ['landing-b2b'],
        async () => {
            const res = await client.getEntries({content_type: 'landingBusiness'});
            return res.items[0].fields;
        }
    );

    return (
        <>
            <HeroMainNavComponent business={'for business'} returnTo={'/'} iconRoute={'/business'}/>
            <HeroImageSectionComponent data={data}/>
            <HeroConceptOverlaySectionsComponent data={data}/>
            <HeroConceptComponent data={data}/>
            <HeroReferencesComponent data={data}/>
            <HeroDashboardComponent data={data}/>
            <HeroContentSectionComponent/>
            <HeroFaqComponent data={data}/>
            <HeroFooterComponent data={data}/>
        </>
    )
};

export default LandingB2bComponent;
