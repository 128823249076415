class PartnerModel{
    get partner_id() {
        return this._partner_id;
    }

    set partner_id(value) {
        this._partner_id = value;
    }

    get partner_name() {
        return this._partner_name;
    }

    set partner_name(value) {
        this._partner_name = value;
    }
    constructor(partner_id, partner_name) {
        this._partner_id = partner_id;
        this._partner_name = partner_name;
    }
}
export default PartnerModel;
