import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    logo: {
        marginRight: 20,
        height: '50%'
    },
    section: {
         position: 'absolute',
        width: '100%',
        background: 'none'
    },
    noPadding: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important'
    },
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        height: 70,
        zIndex: 3,
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        top: 0,
        margin: 'auto',
        position: 'absolute',
        maxWidth: 'var(--max-width-12)',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
        }
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.rem',
            lineHeight: '1.25',
            letterSpacing: '-0.81px',
        },
        fontSize: '1.0rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    breadCrumbSubHeader: {
        fontWeight: 'normal',
        marginLeft: 5
    },
    breadcrumb: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    breadCrumbEnd: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    buttonLocations: {
        height: '50%',
        padding: '12px 16px',
        backgroundColor: '#12b5ad',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#0f9690'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        }
    },
    buttonLocationsText: {
        fontSize: '0.8rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
}));
