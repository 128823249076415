import React from "react";
import {useQuery} from "react-query";
import axios from "axios";

import {
  Typography,
  Container,
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  MenuItem,
  CircularProgress, TextField, InputAdornment
} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {Link, useRouter} from "../../../utils/router";
import {useStyles} from "./guest-faq-main.styles";

import SectionComponent from "../../general/section/section.component";
import cardImageRideLarge from '../../../assets/guest-faq-ride@2x.jpg';
import cardImageRideSmall from '../../../assets/guest-faq-ride.jpg';
import cardImageBookLarge from '../../../assets/guest-faq-book@2x.jpg';
import cardImageBookSmall from '../../../assets/guest-faq-book.jpg';

import chevronRight from '../../../assets/ic-chevron-right.svg';
import icKeyboardIcon from '../../../assets/ic-keyboard-arrow-down.svg';

const phoneReformat = (phoneNumber) => {
  const [first, second, third, ...rest] = phoneNumber;
  return `${first}${second}${third} ${rest.join('')}`;
}

const GuestFaqMain = () => {
  const router = useRouter();
  const classes = useStyles();
  const [partner, setPartner] = React.useState({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = (event) => {
    setPartner(event.target.value);
  };

  const {isLoading, data} = useQuery(
    ['faq-partners'],
    async () => {
      const res = await axios.get('/partners')
      return res.data.rows;
    },
    {
      refetchInterval: 3000,
    }
  );


  return (
    <>
      <SectionComponent
        bgColor='var(----background-surface)'
        size='medium'
      >
        <Container maxWidth="xl" className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h1' className={classes.mainHeader}>Frequently asked questions</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={`${classes.card} ${classes.leftCard}`}>
                <CardActionArea component={Link} to={`${router.match.url}/ride`}>
                  <CardMedia
                    image={matches ? cardImageRideLarge : cardImageRideSmall}
                    classes={{root: classes.cardMediaRoot}}
                    className={classes.cardMedia}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography variant='h5' className={classes.cardHeader}>
                      Rides
                    </Typography>
                    <img src={chevronRight} alt='next' className={classes.rightArrow}/>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={`${classes.card} ${classes.rightCard}`}>
                <CardActionArea component={Link} to={`${router.match.url}/reserve`}>
                  <CardMedia
                    image={matches ? cardImageBookLarge : cardImageBookSmall}
                    classes={{root: classes.cardMediaRoot}}
                    className={classes.cardMedia}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography variant='h5' className={classes.cardHeader}>
                      Reservations
                    </Typography>
                    <img src={chevronRight} alt='next' className={classes.rightArrow}/>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' className={classes.subHeader}>Is your question not included? </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.subQuestionHeader}>
                Reach out to your hotel and they will help you out.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.partnerBox} elevation={0}>
                {
                  isLoading ?
                    <CircularProgress/>
                    :
                    <>
                      <TextField
                        select
                        label='Your hotel'
                        id="partner-select"
                        variant='outlined'
                        value={Object.keys(partner).length ? partner : null}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={icKeyboardIcon} className={classes.keyboardIcon} alt='Select'/>
                            </InputAdornment>
                          )
                        }}
                      >
                        {data?.map((p, i) => (
                          <MenuItem key={i} value={p} className={classes.menuItem}>{p.partner_name}</MenuItem>
                        ))}
                      </TextField>
                      <Typography variant='h5' className={Object.keys(partner).length ? `${classes.partnerName}` : ''}>
                        {partner.partner_name || ''}
                      </Typography>
                      <div>
                        <Typography variant='body1'>
                          {`${partner.partner_name || ''}`}
                        </Typography>
                        <Typography variant='body1'>
                          {`${partner.street_name || ''} ${partner.street_number || ''}  ${partner.house_suffix || ''}`}
                        </Typography>
                        <Typography variant='body1'>
                          {`${partner.postal_code || ''} ${partner.city || ''}`}
                        </Typography>
                      </div>
                      <div className={Object.keys(partner).length ? `${classes.greenTextDiv}` : ''}>
                        <Typography variant='body1' className={`${classes.greenText}`}>
                          {`${Object.keys(partner).length ? phoneReformat(partner.phone_number) : ''}`}
                        </Typography>
                        <Typography variant='body1' className={`${classes.greenText}`}>
                          {`${partner.email || ''}`}
                        </Typography>
                      </div>
                    </>
                }
              </Box>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
    </>
  )
};

export default GuestFaqMain;
