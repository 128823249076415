import React from 'react';

const ScrollableList = (props) => {
    return (
        <div style={{width: '100%', height: props.height, marginTop: '2.5%', overflow: 'auto'}}>
            {props.children}
        </div>
    )
}
export default ScrollableList;
