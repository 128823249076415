import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
      maxWidth: 'var(--max-width-8)',
    },
    descriptionPartner: {
      margin: '16px 0 29px 0',
      [theme.breakpoints.up('sm')]: {
        margin: '16px 0 25px 0',
      }
    },
    roomImageGrid: {
      [theme.breakpoints.down('xs')]: {
        padding: '16px 0 0 0 !important',
        margin: '-16px -16px 16px -16px',
      },
      marginBottom: '16px',
    },
    roomImage: {
      height: '0',
      paddingTop: '57.65%',
    },
    infoButtonGrid: {
      display: 'flex',
      justifyContent: 'center',
    },
    infoButton: {
      width: '200px',
      padding: '12px 16px',
    },
    infoButtonText: {
      fontSize: '1rem',
      fontWeight: '500',
      fontStyle: 'italic',
      lineHeight: 'normal',
      letterSpacing: '1.25px',
      textAlign: 'center',
      color: 'var(--java-green)',
      "&&:hover": {
        color: 'rgba(18,181,173,0.5)'
      },
    },
    divider: {
      width: '100vw',
      height: '1px',
      backgroundColor: 'rgba(249,252,246,0.12)',
      margin: '50px 0 20px 0',
      [theme.breakpoints.up('lg')]: {
        margin: '80px 0 40px 0',
      }
    },
  }))
;
