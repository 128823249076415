import React, {useEffect} from 'react';
import {useStyles} from "./hero-specifications.styles";
import {Box, Container, Typography} from "@material-ui/core";
import SectionComponent from "../../../general/section/section.component";
import {Grid} from "@mui/material";
import bgImage from '../../../../assets/book-a-stay@3x.png';
import BikeIcon from '../../../../assets/v2-icons/bike-icon.svg';

const HeroSpecificationsComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States

    // Functions
    useEffect(() => {
        console.log(data);
    }, [data]);

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto" className={classes.section} bgImage={bgImage} bgImageOpacity={0.6}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container direction={'row'} className={classes.gridContainer}>
                        <Grid item className={classes.leftSection}>
                            <Box>
                                <Box className={classes.info}>
                                    <Typography className={classes.headerBoxTitle}>{data?.specificationsTitle}</Typography>
                                    <Typography variant={'caption2'} className={classes.subHeader}>
                                        {data?.specificationsSubtitle}
                                    </Typography>
                                    <div className={classes.grid}>
                                        {data?.specificationsShorts.map((val, index) => {
                                            return (
                                                <div className={classes.specification} key={index}>
                                                    <img src={BikeIcon} alt="Bike SVG" style={{width: '25%'}}/>
                                                    <Typography className={classes.specificationTitle}>{val}</Typography>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} style={{display: 'flex'}}>

                        </Grid>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroSpecificationsComponent;
