import React, {useState} from "react";
import {useMutation} from "react-query";
import axios from "axios";

import {
  FormControl,
  Grid,
  Button,
  Typography,
  Modal,
  Backdrop,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  RadioGroup
} from "@material-ui/core";

import RadioButton from "../radio-button/radio-button.component";
import CustomCheckbox from "../checkbox/checkbox.component";
import {Fade} from "../fade/fade.component";
import {useStyles} from "./modal-bike-report.styles";
import {useAuth} from "../../utils/auth";
import {useRouter} from "../../utils/router";
import closeIcon from '../../assets/ic-close.svg';
import icInfo from "../../assets/ic-info.svg";

const ModalReport = ({open, handleClose, setSnackbar, handleMissingModal, bikeId, repairModal}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const router = useRouter();
  const auth = useAuth();

  const [selectedValue, setSelectedValue] = useState();
  const [accuLock, setAccuLock] = useState(false);
  const [chainLock, setChainLock] = useState(false);

  const handleAccuLock = () => setAccuLock(!accuLock)
  const handleChainLock = () => setChainLock(!chainLock)

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleProblem = () => {
    switch (selectedValue) {
      case 'high':
        router.push(`${router.match.url}/failure/${bikeId}/high`);
        break;
      case 'medium':
        router.push(`${router.match.url}/failure/${bikeId}/medium`);
        break;
      case 'low':
        router.push(`${router.match.url}/failure/${bikeId}/low`);
        break;
      default:
        break;
    }
  }

  const mutationMissing = useMutation(data => axios.post(`/partner/missing/${bikeId}`, data));

  const fileReport = () => {
    const report = {};
    report['lockMissing'] = accuLock ? 'Lock missing' : '';
    report['chainMissing'] = chainLock ? 'Chain missing' : '';
    report['partner_name'] = auth.user[0].partner_name;

    mutationMissing.mutate(report);

    handleClose();
    setSnackbar('Problem reported.', 'success');
  }

  return (
    <Modal
      open={open}
      className={classes.modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        {repairModal ?
          <Grid
            container
            className={classes.paper}
            classes={{root: classes.rootModal}}
          >
            <Grid
              container
              className={classes.modalHeaderGroup}
              spacing={matches ? 0 : 1}>
              <Grid item xs={9} lg={11}>
                <Typography variant='h4' className={classes.modalHeader} id="spring-modal-title">
                  {`What’s wrong with bike ${bikeId}?`}
                </Typography>
              </Grid>
              <Grid item xs={3} lg={1} className={classes.closeGrid}>
                <img
                  src={closeIcon}
                  className={classes.closeIcon}
                  onClick={handleClose}
                  alt='Close'
                />
              </Grid>
            </Grid>
            <Grid container classes={{root: classes.gridRadioButtonContainer}}>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="bike-report" name="bike-report" value={selectedValue} onChange={handleChange}>
                    <FormControlLabel value="high" control={<RadioButton />} label="It's damaged and needs a repair immediately " />
                    <FormControlLabel value="medium" control={<RadioButton />} label="It's damaged but it can still operate " />
                    <FormControlLabel value="low" control={<RadioButton />} label="There is something else I want to report" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid classes={{root: classes.buttonGrid}} container>
              <Button
                className={classes.button}
                onClick={handleProblem}
              >
                <Typography className={classes.buttonText}>
                  Continue
                </Typography>
              </Button>
            </Grid>
          </Grid>
          :
          <Grid
            container
            className={`${classes.paper} ${classes.paperMissing}`}
            classes={{root: classes.rootModal}}
          >
            <Grid
              container
              className={classes.modalHeaderGroup}
            >
              <Grid item lg={11}>
                <Typography variant='h4' className={classes.modalHeaderMissing}>
                  Report missing
                </Typography>
              </Grid>
              <Grid item lg={1}>
                <img
                  src={closeIcon}
                  className={classes.closeIcon}
                  onClick={handleClose}
                  alt='Close'
                />
              </Grid>
            </Grid>
            <Grid container item >
              <Grid item lg={1}>
                <CustomCheckbox
                  onChange={handleChainLock}
                  checked={chainLock}
                />
              </Grid>
              <Grid item lg={11}>
                <Typography variant='body1' className={classes.radioButtonText}>
                  Key for chain lock
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item lg={1}>
                <CustomCheckbox
                  onChange={handleAccuLock}
                  checked={accuLock}
                />
              </Grid>
              <Grid item lg={11}>
                <Typography variant='body1' className={classes.radioButtonText}>
                  Key for accu lock
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item lg={12} classes={{root: classes.noticeBox}}>
                <img src={icInfo}
                     className={classes.bookingIcon}
                     alt='notice'
                />
                <Typography variant='body2' className={classes.noticeText}>
                  When all key sets are missing or a bike is lost, please call your contact at Vesper immediately (+31612345678).
                </Typography>
              </Grid>
            </Grid>
            <Grid container item classes={{root: classes.buttonGrid}} >
              <Button
                className={classes.button}
                disabled={!accuLock && !chainLock}
                onClick={fileReport}
              >
                <Typography
                  className={!accuLock && !chainLock ? `${classes.buttonText} ${classes.buttonDisabledText}` : `${classes.buttonText}`}
                >
                  File report
                </Typography>
              </Button>
            </Grid>
          </Grid>
        }
      </Fade>
    </Modal>
  )
};

export default ModalReport;
