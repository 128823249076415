import React, {useState} from 'react';
import SectionComponent from "../../../general/section/section.component";
import {useStyles} from './hero-faq.styles';
import {Container, Grid} from "@mui/material";
import {Box, Typography} from "@material-ui/core";
import ZigZagDivider from "../../../../assets/v2-icons/zig-zag-divider.svg";
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import Button from "@material-ui/core/Button";
import FaqListItem from "../../../../atoms/faq-list-item/faq-list-item.component";
import {useQuery} from "react-query";
import {client} from "../../../../utils/contentful";

const HeroFaqComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States
    const [faq, setFaq] = useState([]);

    // Functions

    // Declarations

    // Data retrieval
    const {dataFaq} = useQuery(
        ['landing-consumer-faq'],
        async () => {
            const res = await client.getEntries({content_type: 'landingConsumerFaq'});
            return res.items;
        },
        {
            onSuccess: (data) => setFaq(data)
        }
    );

    return (
        <>
            <SectionComponent size="auto">
                <Box className={classes.bgBox}>
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12}>
                                <Box className={classes.leftTextBox}>
                                    <img src={ZigZagDivider} alt="Divider" className={classes.divider}/>
                                </Box>
                            </Grid>
                            <Grid item className={classes.leftSection}>
                                <Box className={classes.leftTextBox}>
                                    <Typography className={classes.headerBoxTitle}>Frequently asked questions</Typography>
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        {data?.faqSubtitle}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item className={classes.contactUsSection}>
                                <Button
                                    className={classes.buttonLocations}
                                    component={Link}
                                    to='/contact'
                                >
                                    <Typography className={classes.buttonLocationsText}>
                                        Contact Us
                                    </Typography>
                                    <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                                </Button>
                            </Grid>
                            {faq?.map((item, index) => {
                                return (
                                    <Grid item className={classes.faqList} key={index}>
                                        <FaqListItem question={item.fields.question} answer={item.fields.answer}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>
                </Box>
            </SectionComponent>
        </>
    )
};

export default HeroFaqComponent;
