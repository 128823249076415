import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  gridRoot: {
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    backgroundColor: '#202020',
    width: '800px',
    borderRadius: '5px',
    padding: '20px',
    margin: '0 !important',
  },
  mainHeader: {
    padding: '24px 0 0 16px',
    fontSize: '2.4375em',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: '1.25',
    letterSpacing: '-0.3px',
    color: 'var(--loafer-white)',
    textTransform: 'uppercase',
  },
  subHeader: {
    padding: '12px 0 12px 16px',
    fontSize: '1.5em',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: '1.25',
    letterSpacing: '-0.3px',
    color: 'var(--loafer-white)',
    textTransform: 'uppercase',
  },
  divider: {
    backgroundColor: 'rgba(249,252,246,0.5)',
  },
  inputField: {
    margin: '0 0 0 12px'
  },
  label: {
    padding: '8px 16px 4px 8px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: '500',
  },
  textCardField: {
    border: 'solid 1px #979797',
    height: '32px',
    margin: '0 0 18px 0',
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
  },
  button: {
    width: '250px',
    height: '40px',
    margin: '24px 0 40px 12px',
    padding: '1rem',
    borderRadius: 0,
    backgroundColor: 'var(--buttercup-yellow)',
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    }
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1.5px',
    textAlign: 'center',
    color: 'var(--midnight-forest-black)',
  }
}));
