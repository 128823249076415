import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-8)',
  },
  subHeader: {
    margin: '24px 0 16px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '24px 0 40px 0'
    }
  },
  inputField: {
    [theme.breakpoints.up('lg')]: {
      width: '411px',
    },
    width: '95%',
  },
  optionalContainer: {
    display: 'flex',
    margin: '-20px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '10px',
      justifyContent: 'flex-end',
    },
  },
  buttonContainer: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  button: {
    width: '179px',
    padding: '12px 16px',
    borderRadius: 0,
    backgroundColor: 'var(--java-green)',
    "&&:hover": {
      backgroundColor: 'rgba(18,181,173,0.5)',
    },
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--midnight-forest-black)',
  },
}));
