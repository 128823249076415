import React from 'react';
import axios from "axios";
import {useQuery} from "react-query";

import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {ListItem, ListItemSecondaryAction} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import {useRouter} from "../../../utils/router";
import {useAuth} from "../../../utils/auth";
import {useStyles} from "./portal-reserve-list.styles";

import icChevronRight from '../../../assets/ic-chevron-right.svg';

const PortalReserveList = ({chosenDate}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const router = useRouter();
  const auth = useAuth();

  const id = auth?.user?.[0]?.partner_id;

  const {data} = useQuery(
    ['booking-date'],
    async () => {
      const res = await axios.get(`/partner/${id}/${chosenDate}`);
      return res.data.rows;
    },
    {
      refetchInterval: 1000,
    }
  );

  return (
    <>
      {
        !data?.length ?
          <div className={classes.box}>
            <Typography variant='h5'>You don't have any reservations yet</Typography>
          </div>
          :
          <List className={classes.list}>
            {matches && (
              data.map((booking, i) => (
                <ListItem key={i} className={classes.listItem}>
                  <Typography variant='body1'  className={classes.guestName}>
                    {`${booking.first_name} ${booking.last_name}`}
                  </Typography>
                  <Divider className={classes.dividerLg}/>
                  <Typography variant='body1' className={classes.vesperBikes}>Vesper Bikes</Typography>
                  {
                    booking.bikes.map((b, i) => (
                      <Typography variant='body1'  key={i} className={classes.vesperBikeId}>{b}</Typography>
                    ))
                  }
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="go-to"
                    >
                      <img
                        src={icChevronRight}
                        className={classes.nextIcon}
                        alt='go to'
                        onClick={() => {
                          router.push(`${router.match.url}/book/${booking.booking_id}`, booking)
                        }}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )))}
            {!matches && (
              data.map((booking, i) => (
                <ListItem key={i} className={classes.listItem}>
                  <Grid container>
                    <Grid item xs={6} classes={{root: classes.nameGrid}}>
                      <Typography variant='body1' className={classes.guestName}>
                        {`${booking.first_name} ${booking.last_name}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} classes={{root: classes.nameGrid}}>
                      <Typography variant='body2' className={classes.guestName}>
                        {`${booking.notes}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="go-to"
                      >
                        <img
                          src={icChevronRight}
                          className={classes.nextIcon}
                          alt='go to'
                          onClick={() => {
                            router.push(`${router.match.url}/book/${booking.booking_id}`, booking)
                          }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.dividerSm}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1' className={classes.vesperBikes}>Vesper Bikes</Typography>
                    </Grid>
                    <Grid item xs={12} classes={{root: classes.idGrid}}>
                      {
                        booking.bikes.map((b, i) => (
                          <Typography variant='body1' key={i} className={classes.vesperBikeId}>{b}</Typography>
                        ))
                      }
                    </Grid>
                  </Grid>
                </ListItem>
              ))
            )}
          </List>
      }
    </>
  )
};

export default PortalReserveList;
