import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@mui/material";
import {Backdrop, Box, Button, Modal, TextField} from "@material-ui/core";
import {Fade} from "../fade/fade.component";
import StyledButton from "../styled-button/styled-button.component";

const BikePickerModal = ({open, handleClose, title, bikes}) => {
    const [disabledConfirm, setDisabledConfirm] = useState(true);
    const [selectedBike, setSelectedBike] = useState();

    const onSubmit = () => {
        handleClose(selectedBike);
    };

    const classes = useStyles();

    useEffect(() => {
        setSelectedBike(null);
    }, [open]);

    return (
        <>
            <Modal
                open={open}
                className={classes.modal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <Box className={classes.box}>
                        <Grid container style={{height: '100%', padding: '5%'}} direction="column">
                            <Grid item xs>
                                <Typography variant="h5">{title}</Typography>
                            </Grid>
                            <Grid item xs>
                                <Grid container style={{height: '100%', paddingTop: '5%', paddingBottom: '5%'}} direction="row" rowSpacing={2}>
                                    {bikes?.map((bike, index) => (
                                        <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <Button className={classes.bikeButton} style={{backgroundColor: selectedBike === bike ? 'rgba(18,181,173,1)' : '#545454'}} onClick={() => {setDisabledConfirm(false); setSelectedBike(bike)}}>
                                                <Typography variant="h7" color='white' style={{fontStyle: 'italic'}}>REF#{bike.bike_id}</Typography>
                                            </Button>
                                        </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                            <Grid container direction="row" className={classes.buttonSection}>
                                <Grid item xs={6} className={classes.center}>
                                    <StyledButton popupCancel text="Cancel" onClick={handleClose}/>
                                </Grid>
                                <Grid item xs={6} className={classes.center}>
                                    <StyledButton popupConfirm={!disabledConfirm} popupConfirmDisabled={disabledConfirm} text={'Select'} onClick={() => onSubmit()}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    bikeButton: {
        backgroundColor: '#545454',
        width: '90%',
        borderRadius: 0,
        padding: '10%',
        "&&:hover": {
            backgroundColor: 'rgba(18,181,173,1)',
        },
    },
    buttonActive: {
      backgroundColor: 'rgba(18,181,173,1)'
    },
    buttonSection: {
        paddingTop: '10%'
    },
    form: {
        paddingTop: '5%',
        paddingBottom: '5%'
    },
    inputField: {
        width: '100%',
        paddingLeft: '10%',
        paddingRight: '5%',
        backgroundColor: '#545454',
        borderRadius: 5,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    },
    box: {
        backgroundColor: '#1f1f1f',
        width: '30vw',
        height: '100%',
        minWidth: 600,
        border: '2px solid #12b5ad'
    },
    center: {
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: '10%',
        paddingRight: '10%'
    },
    buttonCount: {
        backgroundColor: '#12B5AD',
        borderRadius: 10,
        height: '80%',
        "&&:hover": {
            backgroundColor: 'rgba(18, 181, 173, 0.8)'
        },
    },
    partnerComponents: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default BikePickerModal;
