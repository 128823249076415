import React from 'react';
import HeroContentSectionComponent from "../../b2c/hero-content-section/hero-content.component";
import {useQuery} from "react-query";
import {client} from "../../../../utils/contentful";
import {isMobile} from 'react-device-detect'

const HighlightsLandingComponent = () => {

    const {data} = useQuery(
        ['landing-b2c-shop-highlights'],
        async () => {
            const res = await client.getEntries({content_type: 'shopHighlights'});
            return res.items;
        }
    );

    return (
        <>
            {data?.map((highlight, index) => {
                return (
                    <div key={index} style={{paddingTop: 50, paddingBottom: 50, backgroundColor: '#fff'}}>
                        <HeroContentSectionComponent noHeightPadding data={highlight}/>
                    </div>
                )
            })}

            <div style={{height: isMobile ? '15vh' : '20vh', backgroundColor: '#fff'}}>

            </div>
        </>
    )
};

export default HighlightsLandingComponent;
