import React from 'react';
import MainNav from "../../general/main-nav/main-nav.component";
import FooterComponent from "../../general/main-footer/footer.component";
import SectionComponent from "../../general/section/section.component";
import {Container} from "@mui/material";
import {useRouter} from "../../../utils/router";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useStyles} from "./ride-review.styles";
import {TextField} from "@material-ui/core";
import {useForm, useWatch} from "react-hook-form";
import {Rating} from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import StyledButton from "../../../atoms/styled-button/styled-button.component";
import axios from "axios";
import {useSnackbar} from "../../../utils/snackbar";

const RideReviewComponent = () => {
    const router = useRouter();
    const classes = useStyles();
    const {setSnackbar} = useSnackbar();

    const [rating, setRating] = React.useState(0);
    const [comments, setComments] = React.useState('');

    const onSubmit = async () => {
        await axios.post('/create-review', {guestId: router.query.guestId, bookingId: router.query.bookingId, rating: rating, comments: comments}).then((result) => {
            setSnackbar(result.data.message, 'success');
        }).catch((err) => {
            console.log(err);
            setSnackbar('Something went wrong or review has already been placed', 'error');
        });
    }

    return (
        <>
            <MainNav/>
                <SectionComponent size='large'>
                    <Container maxWidth='lg'>
                        <Grid container classes={{root: classes.gridRoot}}>
                            <Grid item xs={12}>
                                <Typography variant='h1'>
                                    Review your ride
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body1' className={classes.subHeader}>
                                    We hope you had an amazing day
                                    on our Vesper bike(s). We are constantly trying to upgrade our service so it would be great if you could take 30 seconds to tell us how you rated your ride.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{justifyContent: 'center', alignItems: 'center', display: 'flex', margin: 20}}>
                                <Rating precision={1}
                                        style={{transform: 'scale(1.5)'}}
                                        name="rating"
                                        size="large"
                                        value={rating}
                                        onChange={(event, newValue) => {
                                            setRating(newValue);
                                        }}
                                        emptyIcon={<StarIcon style={{ opacity: 0.55, stroke: '#12b5ad' }} fontSize="inherit" />}
                                        icon={<StarIcon style={{ color: '#12b5ad' }} fontSize="inherit" />} />
                            </Grid>
                            <Grid container item direction='column'>
                                <TextField
                                    name='comments'
                                    type="text"
                                    variant="outlined"
                                    label='Any comments?'
                                    multiline
                                    onChange={(text) => setComments(text.currentTarget.value)}
                                    rows={6}
                                    inputProps={{
                                        classes: {
                                            root: classes.textFieldOutline,
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid container direction='row' style={{marginTop: 20}}>
                                <Grid item xs style={{minWidth: 200}}>
                                    <StyledButton text="Send review" onClick={onSubmit}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </SectionComponent>
            <FooterComponent topFooter={false}/>
        </>
    )
}
export default RideReviewComponent;
