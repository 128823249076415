import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        maxWidth: 'var(--max-width-12)',
        padding: '5vh 24px 5vh 24px',
    },
    box: {
        width: '100%',
        borderBottomWidth: 2,
        borderBottomColor: '#111111',
        borderBottomStyle: 'solid'
    },
    root: {
        padding: '0',
    },
    buttonLocations: {
        height: '48px',
        padding: '12px 16px',
        width: 'fit-content',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#111'
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonLocationsText: {
        fontSize: '1rem',
        fontWeight: '700',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        padding: '0 20px 0 20px',
    },
}));
