import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    middleSection: {
        backgroundColor: "#202020",
        borderRadius: "5px",
        justifyContent: 'center'
    },
    header: {
        backgroundColor: "#121212",
        marginTop: '2%',
        paddingTop: '2%',
        borderRadius: '10px',
        display: "flex",
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    partnerSection: {
        marginBottom: '2%'
    },
    partnerName: {
        backgroundColor: "#121212",
        borderRadius: '10px',
        justifyContent: 'center',
        display: 'flex',
        paddingTop: '1%',
        paddingBottom: '1%',
        alignItems: 'center'
    },
    partnerComponents: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonSend: {
        padding: '12px 16px',
        backgroundColor: '#12B5AD',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: 'rgba(18, 181, 173, 0.8)'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: '187px',
        }
    },
    buttonCount: {
        backgroundColor: '#12B5AD',
        borderRadius: 10,
        "&&:hover": {
            backgroundColor: 'rgba(18, 181, 173, 0.8)'
        },
    },
    buttonReserveText: {
        fontSize: '1rem',
        fontWeight: '500',
        fontStyle: 'italic',
        letterSpacing: '1.25px',
        color: 'var(--midnight-forest-black)',
    },
    textPartnerName: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        color: '#fff',
    }
}));
