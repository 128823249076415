import React from "react";
import MainNav from "../../general/main-nav/main-nav.component";
import FooterComponent from "../../general/main-footer/footer.component";
import {Route, useRouter} from "../../../utils/router";
import GuestFaqRide from "../guest-faq-ride/guest-faq-ride.component";
import GuestFaqReserve from "../guest-faq-reserve/guest-faq-reserve.component";
import GuestFaqMain from "../guest-faq-main/guest-faq-main.component";

const GuestFaqSection = () => {
  const router = useRouter();
  return (
    <>
      <MainNav/>
      <Route exact path={`${router.match.url}`} component={GuestFaqMain}/>
      <Route exact path={`${router.match.url}/ride`} component={GuestFaqRide} />
      <Route exact path={`${router.match.url}/reserve`} component={GuestFaqReserve} />
      <FooterComponent topFooter={true}/>
    </>
  )
};

export default GuestFaqSection;
