import React from 'react';
import Container from "@material-ui/core/Container";
import SectionComponent from "../../../general/section/section.component";
import {Grid} from "@mui/material";
import {useStyles} from "./features-landing.styles";

import image from '../../../../assets/TER00427-Edit.jpg';
import Typography from "@material-ui/core/Typography";
import {useQuery} from "react-query";
import {client} from "../../../../utils/contentful";

const FeaturesLandingComponent = () => {

    // Imports
    const classes = useStyles();

    // States
    const {data} = useQuery(
        ['landing-b2c-shop-features'],
        async () => {
            const res = await client.getEntries({content_type: 'shopSmartFeatures'});
            return res.items;
        }
    );

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent size="auto" className={classes.root} bgColor={"#fff"}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container>
                        <Grid item xs={6}>
                            {data?.map((feature, index) => {
                                return (
                                    <div key={index}>
                                        <Typography variant={'caption2'} style={{color: 'black', fontWeight: 700}}>{feature.fields.title}</Typography>
                                        <br/>
                                        <Typography variant={'caption2'} style={{color: 'gray'}}>{feature.fields.subtitle}</Typography>

                                        <br/>
                                        <br/>
                                    </div>
                                )
                            })}
                        </Grid>
                        <Grid item xs={6}>
                            <img src={image} alt="Image" width={"100%"} style={{paddingLeft: '10%'}}/>
                        </Grid>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};

export default FeaturesLandingComponent;
