import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {List, ListItem, ListItemText, Tooltip, Typography} from "@material-ui/core";
import InfoIcon from "../../assets/ic-info-blue.svg";

export const useStyles = makeStyles((theme) => ({
  insuranceContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipBg: {
    backgroundColor: 'var(--information)'
  },
  insuranceText: {
    margin: '10px 8px',
  },
  tooltipText: {
    "& > *": {
      fontSize: '16px',
    }
  },
  tooltipList: {
    backgroundColor: 'transparent !important',
  },
  tooltipListItem: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  bookingInfoIcon: {
    width: '24px',
    height: '24px',
    margin: '10px 8px 10px 0',
    objectFit: 'contain',
  }
}));

const TooltipInsurance = () => {
  const classes = useStyles();

  return (
    <div className={classes.insuranceContainer}>
      <Tooltip
        arrow
        enterTouchDelay={0}
        placement="bottom-start"
        title={
          <div>
            <List classes={{root: classes.tooltipList}}>
              <ListItem classes={{root: classes.tooltipListItem}}>
                <ListItemText classes={{root: classes.tooltipText}}>
                  Full coverage for any damage
                </ListItemText>
              </ListItem>
              <ListItem classes={{root: classes.tooltipListItem}}>
                <ListItemText classes={{root: classes.tooltipText}}>
                  Full coverage for bike theft (always lock bike)
                </ListItemText>
              </ListItem>
              <ListItem classes={{root: classes.tooltipListItem}}>
                <ListItemText classes={{root: classes.tooltipText}}>
                  Full coverage for any service on the bike (fixing flat tire)
                </ListItemText>
              </ListItem>
              <ListItem classes={{root: classes.tooltipListItem}}>
                <ListItemText classes={{root: classes.tooltipText}}>
                  Zero own risk costs
                </ListItemText>
              </ListItem>
              <ListItem classes={{root: classes.tooltipListItem}}>
                <ListItemText classes={{root: classes.tooltipText}}>
                  Non-optional always insured
                </ListItemText>
              </ListItem>
            </List>
          </div>
        }
      >
        <img
          src={InfoIcon}
          className={classes.bookingInfoIcon}
          alt='info'
        />
      </Tooltip>
      <Typography variant='body2' className={classes.insuranceText}>Insurance included</Typography>
    </div>
  )
};

export default TooltipInsurance;
