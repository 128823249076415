import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from "@material-ui/core";

const CustomCheckbox = withStyles({
  root: {
    color: "var(--java-green)",
    '&$checked': {
      color: "var(--java-green)",
    },
    padding: '9px 9px 9px 0',
  },
  checked: {},
})((props) => (
  <Checkbox color="default"  {...props} />
));

export default CustomCheckbox;
