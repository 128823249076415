import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#202020'
    },
    text: {
      flexBasis: '25%',
      maxWidth: '25%',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            maxWidth: '100%',
        },
    },
    referencesGrid: {
        flexBasis: '75%',
        maxWidth: '75%',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            maxWidth: '100%',
        },
    },
    root: {
        padding: '0',
        backgroundColor: '#ccc !important',
    },
    box: {
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 200
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 100
        },
        [theme.breakpoints.down('sm')]: {
            padding: 20
        },
        width: '100%',
        paddingBottom: 200,
        paddingLeft: 300
    },
    headerBoxTitle: {
        fontSize: '2rem',
        textTransform: 'uppercase',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        padding: '10px 0 10px 0'
    },
    subHeader: {
        color: '#BEBEBE',
        fontWeight: "normal",
        fontSize: 18,
    },
    reviewBox: {
        backgroundColor: '#303030',
        display: 'inline-block',
        width: 400,
        height: '100%',
        flex: '0 0 auto',
        margin: '20px 20px 20px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
    innerBox: {
      margin: 20
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    scrollBox: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        margin: '20px 0 20px 20px',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0 20px 0px',
        },
        "&::-webkit-scrollbar": {
            backgroundColor: '#000',
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: '#12B5AD',
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#000',
        },
    },
    profilePicture: {
        height: 60,
        aspectRatio: 1,
        borderRadius: 90
    },
    reviewSubText: {
        fontFamily: 'Kanit',
        color: '#FFFDFD',
        fontWeight: 'lighter'
    }
}));
