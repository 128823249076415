import {lighten, makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 0 0 35px',
    width: 'fit-content',
    display: 'flex',
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '5px',
  },
  title: {
    fontSize: '1em',
    paddingRight: theme.spacing(2),
  },
  button: {
    padding: '30px 16px',
    width: '200px',
    [theme.breakpoints.up('lg')]: {
      padding: '12px 16px',
      margin: '0 24px 0 0',
    }
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 'normal',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--java-green)',
    "&&:hover": {
      color: 'rgba(18,181,173,0.5)'
    },
  },
  buttonTextDelete: {
    color: 'var(--error)',
    "&&:hover": {
      color: 'rgba(207,82,104,0.5)'
    },
  },
  dialogRoot: {
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    backgroundColor: '#202020',
    borderRadius: '2px'
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: '1.25',
    letterSpacing: '-0.3px',
    color: 'var(--loafer-white)',
    textTransform: 'uppercase',
  },
  dialogContent: {
    fontSize: '1.125em',
    fontWeight: '400',
    fontStretch: 'normal',
    lineHeight: '1.25',
    letterSpacing: '-0.3px',
    color: 'var(--loafer-white)',
  },
  partnerList: {
    margin: '8px 0'
  },
}))
