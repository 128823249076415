import React, {useEffect} from 'react';
import {Card, CardContent, Grid, Divider, Collapse, List, ListItem, ListItemText, Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import StyledButton from "../../../atoms/styled-button/styled-button.component";
import InfoIcon from "../../../assets/ic-info-blue.svg";
import MoveFormInformation from "../../../atoms/move-form-information/move-form-information.component";

const AdminArchiveCard = ({archiveObj, refetch}) => {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);
    const [edit, setEdit] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const convertToDate = (dateString) => {
        let d = dateString.split("/");
        let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
        return dat;
    }

    useEffect(() => {
        refetch();
    }, [edit]);

    return (
        <Card className={classes.card}>
            <MoveFormInformation amount={archiveObj.amount} previousLocation={archiveObj.original_location} open={edit} handleClose={() => setEdit(!edit)} moveObj={{price: archiveObj.price, date: convertToDate(archiveObj.date_archived), note: archiveObj.note, archive_id: archiveObj.archive_id}}/>
            <CardContent style={{padding: '1%'}}>
                <Grid container>
                    {/*Status icon, id & hotel*/}
                    <Grid item xs>
                        <Grid container direction='row' style={{marginBottom: '2%'}}>
                            <Grid item xs style={{display: 'flex', alignItems: 'center'}}>
                                {/*<Typography variant="h4" style={{color: '#dbc7b6', fontStyle: 'normal'}}>{archiveObj.price !== 0 ? archiveObj.original_location : "Diefstal"}</Typography>*/}
                                <Typography variant="h4" style={{color: '#dbc7b6', fontStyle: 'normal'}}>{archiveObj.price === 0 && archiveObj.original_location !== "Fatdaddy" ? "Diefstal" : archiveObj.original_location}</Typography>
                                {archiveObj.bike_id !== null &&
                                    <Tooltip
                                        arrow
                                        enterTouchDelay={0}
                                        placement="bottom-start"
                                        title={
                                            <div>
                                                <List classes={{root: classes.tooltipList}}>
                                                    <ListItem classes={{root: classes.tooltipListItem}}>
                                                        <ListItemText classes={{root: classes.tooltipText}}>
                                                            REF#{archiveObj.bike_id}
                                                        </ListItemText>
                                                    </ListItem>
                                                </List>
                                            </div>
                                        }
                                    >
                                        <img
                                            src={InfoIcon}
                                            className={classes.bookingInfoIcon}
                                            alt='info'
                                        />
                                    </Tooltip>
                                }

                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <StyledButton text="Info" primary onClick={handleExpandClick}/>
                            </Grid>
                            <Grid item xs={2}>
                                <StyledButton warning text="Edit" onClick={() => setEdit(!edit)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                        <Typography variant="h1" style={{color: '#f19413', fontStyle: 'normal', paddingRight: '5%', fontWeight: 100}}>€ {archiveObj.price}</Typography>
                    </Grid>

                    <Divider flexItem orientation='vertical' style={{backgroundColor: '#545454'}}/>
                    <Grid item xs={2} style={{paddingLeft: '2%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography variant="h7" className={classes.infoText}>Date archived</Typography>
                        <Typography variant="h7" className={[classes.infoText, classes.textSecondary]}>{archiveObj.date_archived}</Typography>
                        <Typography variant="h7" className={classes.infoText}>Amount</Typography>
                        <Typography variant="h7" className={[classes.infoText, classes.textSecondary]}>{archiveObj.amount}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <fieldset className={classes.card2}>
                        <legend><Typography variant="h4">Sale report bike</Typography></legend>
                        <Typography variant="h7" className={classes.infoText}>{archiveObj.note}</Typography>
                    </fieldset>
                </CardContent>
            </Collapse>
        </Card>
    )
};

export const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: '#202020'
    },
    card2: {
        boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
        backgroundColor: '#222222',
        border: '1px solid #12b5ad',
        [theme.breakpoints.up('sm')]: {
            margin: '0 0 0 0'
        },
        height: '100%',
        borderRadius: 5
    },
    infoText: {
        display: 'block',
        width: '100%',
        color: '#dbc7b6'
    },
    textSecondary: {
        color: '#12b2aa'
    },
    tooltipText: {
        "& > *": {
            fontSize: '16px',
        }
    },
    tooltipList: {
        backgroundColor: 'transparent !important',
    },
    tooltipListItem: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    bookingInfoIcon: {
        width: '24px',
        height: '24px',
        margin: '10px 8px 10px 0',
        objectFit: 'contain',
    }
}));
export default AdminArchiveCard;
