import React, {useState} from 'react';
import {useStyles} from "./hero-buy-section.styles";
import {Box, Container, Grid} from "@mui/material";
import SectionComponent from "../../../general/section/section.component";
import BikeBackground from '../../../../assets/v2-pictures/DSC08352.jpg'
import Typography from "@material-ui/core/Typography";
import BikePricingOption from '../../../../assets/bike-faded-pricing-option.png';
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import Button from "@material-ui/core/Button";
import Pattern from "../../../../assets/pattern-low-opacity.svg";
import {orderBike} from "../../../../utils/util";
import {Textfit} from "react-textfit";
import {newBikePrice, exDemoBikePrice} from '../../config'

const HeroBuySectionComponent = () => {

    // Imports
    const classes = useStyles();

    // States
    const [clicked, setClicked] = useState(null);

    // Functions

    // Declarations

    // Data retrieval

    return (
        <>
            <SectionComponent transparent={true} size="auto" className={classes.section}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container direction={'row'} className={classes.gridContainer}>
                        <Grid item className={classes.chooseGrid}>
                            <Box className={classes.chooseSection} style={{backgroundImage: `url(${Pattern})`}}>
                                <div className={classes.buyList}>
                                    <Typography className={classes.header}>THE ORIGINAL <a className={classes.vesperBlue}>VESPER</a> BIKE</Typography>
                                    <Box className={[classes.bikeBox, clicked === 'new' && classes.selectedBikeBox]} onClick={() => setClicked('new')}>
                                        <div className={classes.gridBikeBox}>
                                            <Typography className={classes.bikeBoxHeader}>VESPER BIKE</Typography>
                                            <Typography variant='caption2' className={classes.subHeader}>New</Typography>
                                            <Typography className={`${classes.bikeBoxHeader} ${classes.bikeBoxPrice}`}>From €{newBikePrice},-</Typography>
                                        </div>
                                        <div className={classes.pricingOptionBikeDiv}>
                                            <img src={BikePricingOption} alt="Vesper bike" className={classes.vesperBikePricingOption}/>
                                        </div>
                                      </Box>
                                    <Box className={[classes.bikeBox, clicked === 'ref' && classes.selectedBikeBox]} onClick={() => setClicked('ref')}>
                                        <div className={classes.gridBikeBox}>
                                            <Typography className={classes.bikeBoxHeader}>VESPER BIKE</Typography>
                                            <Typography variant='caption2' className={classes.subHeader}>Ex-demo</Typography>
                                            <Typography className={`${classes.bikeBoxHeader} ${classes.bikeBoxPrice}`}>From €{exDemoBikePrice},-</Typography>
                                        </div>
                                        <div className={classes.pricingOptionBikeDiv}>
                                            <img src={BikePricingOption} alt="Vesper bike" className={classes.vesperBikePricingOption}/>
                                        </div>
                                    </Box>
                                </div>
                            </Box>
                            <Box className={classes.priceSection}>
                                <div className={classes.buyListUnder}>
                                    <div className={classes.optionsSection}>
                                        <Typography className={classes.bikeBoxHeader} style={{paddingBottom: 5}}>From €{newBikePrice}.00</Typography>
                                        <div className={classes.horizontalFlex}>
                                            <Typography variant='caption2' className={classes.subHeaderBlack}>or</Typography>
                                            <Button
                                                className={classes.buttonLocations}
                                                component={Link}
                                                to='/shop/continue'
                                            >
                                                <Typography className={classes.buttonLocationsText}>
                                                    EX-DEMO
                                                </Typography>
                                            </Button>
                                            <Typography variant='caption2' className={classes.subHeaderBlack}>for <a style={{fontWeight: 'bold', fontStyle: 'italic'}}>€{exDemoBikePrice}.00</a></Typography>
                                        </div>
                                    </div>
                                    <div className={classes.orderHere}>
                                        <Button
                                            className={classes.buttonOrderNow}
                                            // onClick={() => orderBike(1749)}
                                            component={Link}
                                            to='/shop/continue'
                                        >
                                            <Typography className={classes.buttonLocationsText}>
                                                Order now
                                            </Typography>
                                            <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item className={classes.photoRightSection}>
                            <img src={BikeBackground} alt="Bike picture" className={classes.bikeImage}/>
                        </Grid>
                    </Grid>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroBuySectionComponent;
