import React from "react";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import logoHorizontal from '../../../assets/logo-color-horizontal-db.svg';
import linkedInImage from '../../../assets/social-linked-in.svg';
import instagramImage from '../../../assets/social-instagram.svg';
import facebookImage from '../../../assets/social-facebook.svg';

import {Link} from "../../../utils/router.js";
import {useStyles} from "./footer.styles";

function FooterComponent({topFooter = true}) {
  const classes = useStyles();
  return (
    <>
      {topFooter && (
        <div className={classes.root}>
          <Container maxWidth='xl' className={classes.container}>
            <Grid container className={classes.bgFooterTop}>
              <Grid container justify='space-between'>
                <Grid item xs={12} sm={3} lg={2} component={Link} to="/">
                  <img src={logoHorizontal}
                       className={classes.logoColorVesper}
                       alt='Logo'
                  />
                </Grid>
                <Grid container item xs={12} sm={3} direction='column' className={classes.linkContainer}>
                  <Grid item sm={12} className={classes.linkCategory}>
                    <Typography variant='body2'>For the riders</Typography>
                  </Grid>
                  <Grid item sm={12} component={Link} to='/booking' className={classes.links}>
                    Reserve a ride
                  </Grid>
                  <Grid item sm={12} component={Link} to='/faq' className={classes.links}>
                    FAQ
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={3} direction='column' className={classes.linkContainer}>
                  <Grid item sm={12} className={classes.linkCategory}>
                    <Typography variant='body2'>For the partners</Typography>
                  </Grid>
                  <Grid item sm={12} component={Link} to='/become-partner' className={classes.links}>
                    Become a partner
                  </Grid>
                  <Grid item sm={12} component={Link} to='/partner' className={classes.links}>
                    Business Portal
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={3} direction='column' className={classes.linkContainer}>
                  <Grid item sm={12} className={classes.linkCategory}>
                    <Typography variant='body2'>Follow us</Typography>
                  </Grid>
                  <Grid item sm={12} className={classes.socialLinksInfo}>
                    <Typography variant='body2'>Get inspired and stay up to date via our social channels.</Typography>
                  </Grid>
                  <Grid item sm={12} className={classes.socialIconContainer}>
                    <a target='_blank' rel="noreferrer"
                       href='https://www.linkedin.com/company/vesper-ebikes/?viewAsMember=true'>
                      <img src={linkedInImage}
                           className={classes.socialLinks}
                           alt='LinkedIn Icon'
                      />
                    </a>
                    <a target='_blank' rel="noreferrer" href="https://www.instagram.com/vesperbike/">
                      <img src={instagramImage}
                           className={classes.socialLinks}
                           alt='Instagram Icon'
                      />
                    </a>
                    <a target='_blank' rel="noreferrer" href='https://www.facebook.com/Vesperbikes'>
                      <img src={facebookImage}
                           className={classes.socialLinks}
                           alt='Youtube Icon'
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
      <div className={topFooter ? `${classes.bgFooter}` : `${classes.bgFooter} ${classes.bgFooterMargin}`}>
        <Typography item component="p" variant='body2' className={classes.copyrightText}>
          Copyright © 2021 Vesper e-bikes B.V. | Kvk: 80505007 |
          <Typography variant='body2' className={classes.copyrightTextGreen} component={Link} to='/terms'> Terms and
            Conditions</Typography> |
          <Typography variant='body2' className={classes.copyrightTextGreen} component={Link} to='/privacy'> Privacy
            Policy</Typography> |
          <Typography variant='body2' className={classes.copyrightTextGreen} component={Link} to='/cancel'> Cancel
            reservation</Typography>
        </Typography>
      </div>
    </>
  );
}

export default FooterComponent;
