import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heroImage: {
    objectFit: 'contain',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    maxWidth: '100%',
    height: 'auto',
    margin: '60px 0 0 0',
    [theme.breakpoints.up('lg')]: {
        margin: '118px 0 0 0',
      },
  },
  bottomShark: {
    position: 'relative',
    marginBottom: '-7px',
    [theme.breakpoints.up('sm')]: {
      width: '100vw'
    }
  },
  boxContainer: {
    minHeight: '250px',
    height: 'auto',
    margin: '370px 8px 32px 8px',
  },
}));
