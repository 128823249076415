import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0 !important',
        display: 'flex !important',
        justifyContent: 'center',
        backgroundColor: '#303030',
        paddingLeft: 300,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        },
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: 200
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 0
        },
        marginLeft: 300,
    },
    rightSectionPicture: {
        maxWidth: '41.666667%',
        flexBasis: '41.666667%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    leftSectionText: {
        maxWidth: '58.333333%',
        flexBasis: '58.333333%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
            padding: 20
        },
    },
    root: {
        padding: '0',
    },
    headerBoxTitle: {
        fontSize: '2rem',
        textTransform: 'uppercase',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        padding: '10px 0 10px 0'
    },
    subHeader: {
        color: '#FFFFFF',
        fontWeight: "lighter",
        fontSize: 18,
        paddingBottom: 50
    },
    leftTextBox: {
        justifyContent: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20%'
    },
    listItem: {
        "&::before": {
            content: "\\2022",
            color: '#12B5AD'
        }
    },
    redBox: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    divider: {
        height: 20,
        alignSelf: "baseline"
    },
    rightSection: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        clipPath: 'polygon(20% 100%, 0 0, 100% 0, 100% 100%)',
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            clipPath: 'polygon(0 100%, 0 0, 100% 20%, 100% 100%)',
        },
    },
    hotelPicture: {
        width: '100%',
        height: 750,
        objectFit: 'cover',
        transform: 'scaleX(-1)',
        [theme.breakpoints.down('sm')]: {
            height:'unset',
            aspectRatio: 1.5
        },
    },
    hoverPicture: {
        position: 'absolute',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    dashboardPicture: {
        [theme.breakpoints.down('lg')]: {
            height: 300,
            marginLeft: -100
        },
        [theme.breakpoints.down('md')]: {
            height: 200,
            marginLeft: 0
        },
        height: 450,
        marginLeft: -200,
        zIndex: 2
    },
    patternDiv: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: -100,
            bottom: '10vh',

        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            bottom: '15vh',
        },
        marginLeft: -200,
        zIndex: 1,
        position: 'absolute',
        bottom: '4vh',
        left: '-3vh'
    },
    pattern: {
        objectFit: 'contain',
        width: '7vw',
        aspectRatio: 1
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '5rem',
            lineHeight: '1',
            letterSpacing: '2px !important',
        },
        fontSize: '3rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        width: '80%'
    },
    vesperBlue: {
        color: '#12B5AD'
    },
    buttonLocations: {
        height: '48px',
        padding: '12px 16px',
        margin: '0 20px 0 0',
        backgroundColor: '#12b5ad',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#0f9690'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        }
    },
    buttonLocationsText: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
}));
