import React, {useEffect} from 'react';
import {useRouter} from "../../../../utils/router";
import axios from "axios";

const OrderConfirmComponent = () => {

    const router = useRouter();

    useEffect(() => {
        axios.get('/order/confirm', {params: {orderId: router.query.orderId, orderStatusId: router.query.orderStatusId}}).then((res) => {
            console.log(res.data);
            window.location.replace(res.data.url);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <>
        </>
    )
};

export default OrderConfirmComponent;
