import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-12)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '95vh',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      alignItems: 'flex-start',
    }
  },
  cancelContainer: {
    backgroundColor: '#121212',
    [theme.breakpoints.down('xs')]: {
      padding: '88px 24px',
    },
    [theme.breakpoints.up('sm')]: {
      boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.32)',
      backgroundColor: '#1d1d1d',
      width: '455px',
      padding: '24px',
      marginTop: '110px',
    },
  },
  header: {
    margin: '0 0 32px 0'
  },
  inputFieldInfo: {
    margin: '0 0 0 16px',
    [theme.breakpoints.up('lg')]: {
      margin: '4px 0 0 16px'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    }
  },
  button: {
    margin: '12px 0',
    border: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: '12px 0 12px 16px',
    }
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--java-green)',
    "&&:hover": {
      color: 'rgba(18,181,173,0.5)'
    },
  },
}));
