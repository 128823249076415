import React, {useState} from 'react';
import {useStyles} from "./hero-references.styles";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import SectionComponent from "../../../general/section/section.component";
import ZigZagDivider from '../../../../assets/v2-icons/zig-zag-divider.svg';
import PersonIcon from '../../../../assets/ic-person.svg';
import {Stack} from "@mui/material";
import {Rating} from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import {useQuery} from "react-query";
import {client} from "../../../../utils/contentful";

const HeroReferencesComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States
    const [reviews, setReviews] = useState([]);

    // Functions

    // Declarations

    // Data retrieval
    const {dataReviews} = useQuery(
        ['landing-business-reviews'],
        async () => {
            const res = await client.getEntries({content_type: 'landingBusinessReferences'});
            console.log(res.items);
            return res.items;
        },
        {
            onSuccess: (data) => setReviews(data)
        }
    );

    return (
        <>
            <SectionComponent size="auto" className={classes.root}>
                <Container maxWidth={false} className={classes.container}>
                    <Box className={classes.box}>
                        <Grid container>
                            <Grid item className={classes.text}>
                                <Box>
                                    <img src={ZigZagDivider} alt="Divider" width={127}/>
                                    <Typography className={classes.headerBoxTitle}>References</Typography>
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        {data?.referencesSubtitle}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item className={classes.referencesGrid}>
                                <div className={classes.scrollBox}>
                                    {reviews.map((obj, index) => (
                                        <Box className={classes.reviewBox}>
                                            <Box className={classes.innerBox}>
                                                <div className={classes.flexRow}>
                                                    <img src={obj.fields.picture ? obj.fields.picture.fields.file.url : PersonIcon} alt="Profile picture" className={classes.profilePicture}/>
                                                    <div className={classes.flexColumn}>
                                                        <Typography>{obj.fields.name}</Typography>
                                                        <Typography variant='caption2' className={classes.subHeader}>{obj.fields.hotelName}</Typography>
                                                    </div>
                                                </div>
                                                <Rating precision={0.25} name="read-only" value={obj.fields.rating} readOnly emptyIcon={<StarIcon style={{ opacity: 1 }} fontSize="inherit" />} icon={<StarIcon style={{ color: '#FFC700' }} fontSize="inherit" />} />
                                                <br/>
                                                <Typography variant='caption2' className={classes.subHeader}>{obj.fields.review}</Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </SectionComponent>
        </>
    )
};

export default HeroReferencesComponent;
