import React from 'react';
import HeroMainNavComponent from "../b2b/hero-main-nav/HeroMainNav.component";
import HeroBuySectionComponent from "./hero-buy-section/hero-buy-section.component";
import HeroConceptComponent from "./hero-concept/hero-concept.component";
import HeroSpecificationsComponent from "./hero-specifications/hero-specifications.component";
import HeroContentSectionComponent from "./hero-content-section/hero-content.component";
import HeroBikesComponent from "./hero-bikes/hero-bikes.component";
import HeroFaqComponent from "./hero-faq/hero-faq.component";
import HeroFooterComponent from "./hero-footer/hero-footer.component";
import HeroSmallFooterComponent from "./hero-small-footer/hero-small-footer.component";
import {useQuery} from "react-query";
import {client} from "../../../utils/contentful";

const LandingB2cComponent = () => {

    const {data} = useQuery(
        ['landing-b2c'],
        async () => {
            const res = await client.getEntries({content_type: 'landingConsumer'});
            return res.items[0].fields;
        }
    );

    const {data: contentSectionData} = useQuery(
        ['landing-b2c-contentsection'],
        async () => {
            const res = await client.getEntries({content_type: 'landingConsumerContentSection'});
            return res.items;
        }
    );

    return (
        <>
            <HeroMainNavComponent business={'shop'} returnTo={'/'} iconRoute={'/shop'}/>
            <HeroBuySectionComponent data={data}/>
            <HeroConceptComponent data={data}/>
            <HeroSpecificationsComponent data={data}/>
            {contentSectionData?.map((contentData, index) => {
                return (
                    <HeroContentSectionComponent key={index} data={contentData} inverted={contentData?.fields.horizontalInverted}/>
                )
            })}
            <HeroBikesComponent data={data}/>
            <HeroFaqComponent data={data}/>
            <HeroFooterComponent data={data}/>
            <HeroSmallFooterComponent/>
        </>
    )
};

export default LandingB2cComponent;
