import React, {useState} from 'react';
import {Box, Grid, Typography, Checkbox, Divider} from "@material-ui/core";
import MailIcon from '@mui/icons-material/Mail';
import ConstructionIcon from '@mui/icons-material/Construction';
import StyledButton from "../styled-button/styled-button.component";
import SimpleModal from "../simple-modal/simple-modal.component";
import axios from "axios";
import {useSnackbar} from "../../utils/snackbar";
import {BB, BM} from "../../assets/icons";
import {makeStyles} from "@material-ui/core/styles";

const PartnerServiceListItem = ({serviceItem}) => {
    const [open, setOpen] = useState(false);
    const [finish, setFinish] = useState(false);
    const {setSnackbar} = useSnackbar();

    const finishService = async () => {
        const serviceObj = {
            service_id: serviceItem.service_id,
        }

        await axios.post(`/dashboard/finish-service`, serviceObj).then((res) => {
            setSnackbar(`Reparatie verwerkt.`, 'success');
            serviceItem.finished = true;
            setFinish(!finish);
        }).catch((err) => {
            setSnackbar(`${err.response.data.message}`, 'error');
        });
    }

    const classes = useStyles();

    console.log(serviceItem);

    return (
        <>
            <SimpleModal open={open} handleClose={() => setOpen(!open)} title="Report info">
                <Grid container style={{padding: '2%', display: 'flex', alignItems: 'center', border: '1px solid #12b5ad', height: '100%', textAlign: 'center', marginBottom: '2%'}}>
                    {/*Partner name, upper row*/}
                   <Grid item xs={10}>
                        <Typography variant="h5" style={{paddingLeft: '5%'}}>{serviceItem.partner_name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Checkbox disabled checked={serviceItem.finished}/>
                    </Grid>
                    {/*    Middle section, alert, date, time*/}
                    <Grid item xs={4}>
                        <Typography variant="h7">Alert</Typography>
                        <br/>
                        <Typography variant="h6" style={{color: 'red'}}>{serviceItem.type}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h7">Date</Typography>
                        <br/>
                        <Typography variant="h6">{serviceItem.date}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h7">Time</Typography>
                        <br/>
                        <Typography variant="h6">{serviceItem.time.toString().split(".")[0]}</Typography>
                    </Grid>
                    {/*    Divider*/}
                    <Grid item xs={12}>
                        <Divider style={{ backgroundColor: '#12b5ad', height: '2px', width: '90%', margin: 'auto', borderRadius: 50}}/>
                    </Grid>
                    {/*    Section underneath, info*/}
                    <Grid item xs={2}>
                        <MailIcon style={{color: "#12b5ad"}}/>
                    </Grid>
                    <Grid item xs={10} style={{textAlign: 'left'}}>
                        <Typography variant="h7">{serviceItem.street_name} {serviceItem.street_number}, {serviceItem.postal_code} {serviceItem.city}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <ConstructionIcon style={{color: "#12b5ad"}}/>
                    </Grid>
                    <Grid item xs={10} style={{textAlign: 'left'}}>
                        <Typography variant="h7">{serviceItem.problem}</Typography>
                    </Grid>
                    {/*Bike*/}
                    <Grid item xs={2}>
                        <img src={BB} alt='bike-icon' style={{height: '100%', width: 24}}/>
                    </Grid>
                    <Grid item xs={10} style={{textAlign: 'left'}}>
                        <Typography variant="h7">Bike {serviceItem.display_number}</Typography>
                    </Grid>
                    {/*Divider*/}
                    <Grid item xs={12}>
                        <Divider style={{ backgroundColor: '#12b5ad', height: '2px', width: '90%', margin: 'auto', borderRadius: 50}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h7">Description</Typography>
                        <br/>
                        <Typography variant="h6">{serviceItem.description}</Typography>
                    </Grid>
                </Grid>
            </SimpleModal>
            <SimpleModal open={finish} handleClose={() => setFinish(!finish)} title={`Are you sure the service has been provided?`} confirmButtonText="Yes" onConfirm={() => finishService()}></SimpleModal>

            {/* Visible card */}
            <Grid container style={{padding: '0 2% 0 2%', display: 'flex', alignItems: 'center', border: '1px solid #12b5ad', height: '30%', textAlign: 'center', marginBottom: '2%'}}>
                {/*Status icon, id & hotel*/}
                <Grid item xs>
                    <Grid container direction="column" style={{height: '100%'}}>
                        <Grid item xs={12} style={{textAlign: 'start'}}>
                            <Grid container direction='row' style={{height: '100%'}}>
                                <Grid item xs={12}>
                                    <Typography variant="h7" style={{color: '#dbc7b6', fontStyle: 'normal'}}>{serviceItem.partner_name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container style={{height: '100%', alignItems: 'left'}}>
                                {!serviceItem.finished &&
                                    <Grid item xs={6}>
                                        <StyledButton noPadding primary text="Finish" onClick={() => setFinish(!finish)}/>
                                    </Grid>
                                }
                                <Grid item>
                                    <StyledButton noPadding text="Info" onClick={() => setOpen(!open)}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid container direction='column' style={{display: 'flex', alignItems: 'center'}}>
                        <Grid item xs={6}  style={{height: '100%', width: '100%'}}>
                            <ConstructionIcon style={{color: "#12b5ad"}}/>
                        </Grid>
                        <Grid item xs={6}  style={{height: '100%', width: '100%'}}>
                            <Typography variant="h7" className={classes.infoText}>{serviceItem.problem}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation='vertical' style={{backgroundColor: '#545454', height: '50%'}}/>
                <Grid item xs={2} style={{paddingLeft: '2%', paddingRight: '2%'}}>
                    <Grid container direction='column' style={{display: 'flex', alignItems: 'center'}}>
                        <Grid item xs={6}  style={{height: '100%', width: '100%'}}>
                            <img src={BB} alt='bike-icon' style={{height: '100%', width: '70%'}}/>
                        </Grid>
                        <Grid item xs={6}  style={{height: '100%', width: '100%'}}>
                            <Typography variant="h7" className={classes.infoText}>Bike {serviceItem.display_number}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation='vertical' style={{backgroundColor: '#545454', height: '50%'}}/>
                <Grid item xs={2} style={{paddingLeft: '2%', paddingRight: '2%'}}>
                    <Typography variant="h7" className={[classes.infoText, classes.textSecondary]}>Alert</Typography>
                    <Typography variant="h6" style={{color: 'red'}}>{serviceItem.type}</Typography>

                </Grid>
                <Divider orientation='vertical' style={{backgroundColor: '#545454', height: '50%'}}/>
                <Grid item xs={3} style={{paddingLeft: '2%', paddingRight: '2%'}}>
                    <Typography variant="h7" className={[classes.infoText, classes.textSecondary]}>Date, time</Typography>
                    <Typography variant="h7" className={classes.infoText}>{serviceItem.date}</Typography>
                    <Typography variant="h7" className={classes.infoText}>{serviceItem.time.toString().split(".")[0]}</Typography>
                </Grid>
            </Grid>
        </>
    )
}

export const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: '#202020'
    },
    infoText: {
        display: 'block',
        width: '100%',
        color: '#dbc7b6',
        textAlign: 'center',
        fontSize: '0.8em',
    },
    textSecondary: {
        color: '#12b2aa'
    },
    input: {
        color: "#12b2aa !important",
    },
}));

export default PartnerServiceListItem;
