import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#202020'
    },
    root: {
        padding: '0',
    },
    textBox: {
        marginTop: '20vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 'var(--max-width-12)',
    },
    header: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '12rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '10rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '8rem',
            marginTop: 60
        },
        fontSize: '15rem',
        letterSpacing: '0.5rem',
        marginRight: '-3rem',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: '#121212',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        position: 'absolute',
        top: 0,
        right: 0,
    },
    headerBox: {
        backgroundColor: '#12B5AD',
        marginBottom: 10,
        zIndex: 1,
        position: 'relative',
        padding: '5px 20px 5px 20px',
        width: 'fit-content',
    },
    headerBoxTitle: {
        fontSize: '2rem',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subHeader: {
        color: '#BEBEBE',
        margin: '8px 0 0 0',
        fontWeight: "normal",
        fontSize: 18,
        maxWidth: '70%',
        textAlign: 'center',
        paddingBottom: 50
    },
    picture: {
        [theme.breakpoints.down('lg')]: {
            width: '80%'
        },
        [theme.breakpoints.down('md')]: {
            width: '60%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            aspectRatio: 1,
            marginBottom: 80
        },
        width: '100%',
        aspectRatio: 3.33,
        objectFit: 'cover',
        marginBottom: 200,
        objectPosition: 'top'
    }
}));
