import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        padding: '0px !important'
    },
    b2bSection: {
        backgroundColor: 'rgba(32, 32, 32, 0.85)',
        height: '100vh',
        backfaceVisibility: 'hidden',
        zIndex: 2,
        position: 'absolute',
        right: 0,
        width: '55vw',
        clipPath: 'polygon(0 100%, 10vw 0, 100% 0, 100% 100%)',
    },
    b2cSection: {
        width: '55vw',
        left: 0,
        position: 'absolute',
        height: '100vh',
        backgroundColor: 'unset',
        clipPath: 'polygon(0 100%, 0 0, 100% 0, 45vw 100%)',

    },
    divContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '100px',
            lineHeight: '1.25',
            letterSpacing: '-0.81px',
        },
        fontSize: '3rem',
        letterSpacing: '-0.58px',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    textSectionLeft: {
        width: '35vw',
        height: '100vh',
        zIndex: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginLeft: '15vw'
    },
    textSectionRight: {
        right: 0,
        width: '30vw',
        height: '100vh',
        zIndex: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        flexDirection: 'column',
        marginRight: '15vw'
    },
    extraDiv: {
        width: '30vw',
        right: 0,
        position: 'absolute'
    },
    headerBox: {
        backgroundColor: '#fff',
        marginBottom: 10,
        width: '169%',
    },
    headerBoxRightEmpty: {
        height: 125
    },
    headerBoxTitle: {
        fontSize: 64,
        padding: '0 20px 0 20px',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        color: '#12B5AD',
        [theme.breakpoints.down('xl')]: {
            fontSize: 56,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 46,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 40,
        },
    },
    subHeaderContainer: {
        height: 64
    },
    subHeader: {
        color: 'var(--loafer-white)',
        margin: '8px 0 0 0',
        fontWeight: "normal",
        fontSize: 22,
        maxWidth: '30vw',
        [theme.breakpoints.down('lg')]: {
            fontSize: 16,
        },
    },
    subHeaderRight: {
        color: 'var(--loafer-white)',
        margin: '8px 0 0 0',
        fontWeight: "normal",
        fontSize: 22,
        maxWidth: '30vw',
        alignSelf: 'end',
        [theme.breakpoints.down('lg')]: {
            fontSize: 16,
        },
    },
    headerBoxRight: {
        backgroundColor: '#12B5AD',
        position: 'inherit',
        marginBottom: 10,
        width: '100%',
        zIndex: 5,
        [theme.breakpoints.down('xl')]: {
            position: 'relative',
            textAlign: 'right',
            right: '30vw',
            width: '60vw'
        },
    },
    headerBoxTitleRight: {
        fontSize: 64,
        width: '30vw',
        marginLeft: '30vw',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'left',
        zIndex: 5,
        position: 'inherit',
        [theme.breakpoints.down('xl')]: {
            fontSize: 56,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 46,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 40,
        },
    },
    headerBoxRightBehind: {
        backgroundColor: '#12B5AD',
        marginBottom: 10,
        width: '60vw',
        marginLeft: '-30vw',
        position: 'absolute',
        top: 0
    },
    headerBoxTitleRightBehind: {
        fontSize: 64,
        padding: '0 20px 0 20px',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'right'
    },
    paragraph: {
        paddingLeft: '20%',
        paddingRight: '20%',
        fontSize: 16,
        textAlign: 'center'
    },
    title: {
        fontSize: 120,
        fontStyle: 'italic',
        margin: 0
    },
    pattern: {
        objectFit: 'contain',
        height: 180
    },
    patternDiv: {
        position: 'absolute',
        top: '70vh',
        marginLeft: '-100px'
    },
    vesperBox: {
        backgroundColor: '#000',
        width: '10vh',
        height: '11vh',
        position: 'absolute',
        left: '47.5%',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: 'transform 0.5s'
    },
    vesperLogo: {
        paddingBottom: 10
    },
    vesperTitle: {
        width: '80%',
        paddingBottom: '10%'
    },
    innerTopBox: {
        flex: 1,
    },
    flexFill: {
        flex: 1
    },
    buttonLocations: {
        height: '48px',
        padding: '12px 16px',
        margin: '40px 20px 0 0',
        backgroundColor: '#12b5ad',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#0f9690'
        },
        width: 'max-content',
        [theme.breakpoints.up('sm')]: {
            width: 'max-content',
        }
    },
    buttonLocationsText: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    buttonLocationsRight: {
        height: '48px',
        padding: '12px 16px',
        margin: '40px 20px 0 0',
        backgroundColor: '#ffffff',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: 'rgba(255,255,255,0.73)'
        },
        width: 'max-content',
        [theme.breakpoints.up('sm')]: {
            width: 'max-content',
        }
    },
    buttonLocationsTextRight: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#12B5AD',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
}));
