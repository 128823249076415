import dayjs from "dayjs";
import * as isBetween from 'dayjs/plugin/isBetween';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

/**
 * Will be checked server-side
 */
const COST_PER_BIKE = 31.05;
const COST_INSURANCE_PER_BIKE = 3.95;

export const phoneNumberReg = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\\]?)?((?:\(?\d{1,}\)?[\\]?){0,})(?:[\\]?(?:#|ext\.?|extension|x)[\\]?(\d+))?$/i;

export const excludeDatesArray = (amountBikes, bikesAndDates) => {
  let excludedDateArray = [];
  let tempExcludedArray = [];
  let countDayBookings = {};
  let countEmptyBooking = 0;
  const partnerBikesAmount = [...new Map(bikesAndDates.map((row) => [row.bike_id, row])).values()].length;

  /**
   * Count bikes at partner without bookings
   */
  for (const bikeAndDate of bikesAndDates) {
    if (!bikeAndDate.dates?.length) {
      countEmptyBooking++;
    }
  }

  /**
   * Return empty excludedArray if requested bike amount is lower or equal than bikes without booking
   */
  if (countEmptyBooking >= amountBikes || 0) {
    return excludedDateArray;
  }

  /**
   * If bike has booked dates, add to tempExcludedArray
   */
  for (const bikeAndDate of bikesAndDates) {
    if (bikeAndDate.dates?.length) {
      tempExcludedArray.push(...bikeAndDate.dates);
    }
  }

  /**
   * Count every every unique date in tempExcludedArray
   * Partially taken from https://stackoverflow.com/a/19395302
   */
  tempExcludedArray.forEach(x => countDayBookings[x] = (countDayBookings[x] || 0) + 1);

  /**
   * If the amount of bikes requested is more than available for that date, add date to excludedArray
   */
  for (const count in countDayBookings) {
    if (partnerBikesAmount - countDayBookings[count] < amountBikes) {
      excludedDateArray.push(new Date(
        dayjs(count).get('year'),
        dayjs(count).get('month'),
        dayjs(count).get('date')
      ));
    }
  }

  /**
   * Remove any duplicates dates. ~ES6 MAGIC~
   * https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
   */
  return excludedDateArray.filter((date, i, self) =>
    self.findIndex(d => d.getTime() === date.getTime()) === i
  );

};

/**
 * Create a new array for every bike and their dates.
 * For the amount of the bikes chosen, while assigned bikes is less than chosen amount, iterate over bikesAndDates
 * If it doesn't have any dates, or any dates that matches any date in the rentalDates, add to bikesAssigned array
 */
export const assignBikes = (amountBikes, bikesAndDates, rentalDates) => {
    const bikesAssigned = [];
    const bikes = bikesAndStartDates(bikesAndDates);

    for (let i = 0; i < amountBikes; i++) { // Iterate over the amount of bikes chosen
      for (let bike of bikes) { // Iterate over the available bikes for the chosen date
        if (bikesAssigned.length < amountBikes) { // If list of assigned bikes is smaller than the chosen amount
          if (!bike.dates.length || !bike.dates.some(d => rentalDates.some(rd => d.isSame(rd, 'date')))) { // If list of available bikes is not empty or date is not present
            // bikesAssigned.push(parseInt(bike.bike_id)); // Add bike to chosen list
            bikesAssigned.push(bike); // Add bike to chosen list
          }
        }
      }
    }

    console.log("Assigned bikes: ", bikesAssigned);

    return bikesAssigned;
  };

/**
 * If the booked dates are not the same as the chosen date the bike will be added to the option
 * Else it will be added to a separate array
 * Filter any bike if bike_id is already present in array
 * Filter any bike that has the same ID as any bike in excluded array
 */
export const availableBikes = (data, rentalDates = [dayjs()]) => {

  const bikesAndDates = bikesDates(data);
  const excludesBikes = [];
  const options = [];

  bikesAndDates?.forEach((row) => {
    if (!row.dates.some(d => rentalDates.some(rd => d.isSame(rd, 'date')))) {
      options.push(row)
    } else {
      excludesBikes.push(row);
    }
  });

  const nextOptions = options.filter((row) => !excludesBikes.some(b => b.bike_id === row.bike_id));

  return [...new Map(nextOptions
    .map((row) => [row.bike_id, row])).values()]
    .map((row, index) => {
      return {
        value: index + 1,
        label: `${index + 1} V-bikes`
      };
    });
};

export const calculateCostBooking = (amountBikes, rentalDates, discount) => {
  let BIKE_PRICE = COST_PER_BIKE;
  if (discount){
    BIKE_PRICE = 16.05;
  }
  return (rentalDates.length * (amountBikes * (BIKE_PRICE + COST_INSURANCE_PER_BIKE))).toFixed(2);
};

/**
 * Create new array with bike_id + booked dates if present
 */
export const bikesDates = (data) => {
  return data?.map(row => {
    return {
      bike_id: row.bike_id,
      display_number: row.display_number,
      dates: row.rental_dates?.map(d => dayjs(d)) || []
      // Add amount of rides to access later
    };
  });
};

/**
 * Temporarily function to return an array of bikes that each have an array of booked dates
 */
export const bikesAndStartDates = (bikesAndDates) => {
  const bikes = [];

  for (let i = 0; i < bikesAndDates?.length; i++) {
    if (!bikes.some(b => b.bike_id === bikesAndDates?.[i].bike_id)) {
      bikes.push(bikesAndDates[i]);
    } else {
      for (let j = 0; j < bikes.length; j++) {
        if (bikes[j].bike_id === bikesAndDates?.[i].bike_id) {
          bikes[j].dates = [...bikes[j].dates, ...bikesAndDates?.[i].dates]
        }
      }
    }
  }

  return bikes;
}
