import React, {useEffect} from 'react';
import SectionComponent from "../../../general/section/section.component";
import {useStyles} from './hero-content-section.styles';
import {Container, Grid} from "@mui/material";
import {Box, Typography} from "@material-ui/core";
import ZigZagDivider from "../../../../assets/v2-icons/zig-zag-divider.svg";
import TeroPicture from '../../../../assets/v2-pictures/DSC08366.jpg';

const HeroContentSectionComponent = ({inverted, noHeightPadding, data}) => {

    // Imports
    const classes = useStyles();

    // States

    // Functions

    // Declarations

    // Data retrieval
    useEffect(() => {
        console.log("ContentSection: ", data)
    }, [data]);

    return (
        <>
            <SectionComponent size="auto" className={classes.root}>
                <Box className={classes.bgBox}>
                    <Container maxWidth={false} className={classes.container} style={{padding: noHeightPadding ? '0 0 0 0 !important' : '20vh 0 20vh 0'}}>
                        <Grid container>
                            {inverted
                                ?
                                <>
                                    <Grid item className={classes.section}>
                                        <img src={data?.fields.image.fields.file.url} alt="Picture" className={classes.picture}/>
                                    </Grid>
                                    <Grid item className={classes.section}>
                                        <Box className={classes.rightTextBox}>
                                            <img src={ZigZagDivider} alt="Divider" className={classes.divider}/>
                                            <Typography className={classes.headerBoxTitle}>{data?.fields.title}</Typography>
                                            <Typography variant='caption2' className={classes.subHeader}>
                                                {data?.fields.subtitle}
                                            </Typography>
                                            <ul className={classes.list}>
                                                {data?.fields.dots.map((dot, index) => {
                                                    return (
                                                        <li key={index} className={classes.listItem}>{dot}</li>
                                                    )
                                                })}
                                            </ul>
                                        </Box>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item className={classes.section}>
                                        <Box className={classes.leftTextBox}>
                                            <img src={ZigZagDivider} alt="Divider" className={classes.divider}/>
                                            <Typography className={classes.headerBoxTitle}>{data?.fields.title}</Typography>
                                            <Typography variant='caption2' className={classes.subHeader}>
                                                {data?.fields.subtitle}
                                            </Typography>
                                            <ul className={classes.list}>
                                                {data?.fields.dots.map((dot, index) => {
                                                    return (
                                                        <li key={index} className={classes.listItem}>{dot}</li>
                                                    )
                                                })}
                                            </ul>
                                        </Box>
                                    </Grid>
                                    <Grid item className={classes.section}>
                                        <img src={data?.fields.image.fields.file.url} alt="Picture" className={classes.picture}/>
                                    </Grid>
                                </>
                            }

                        </Grid>
                    </Container>
                </Box>
            </SectionComponent>
        </>
    )
};

export default HeroContentSectionComponent;
