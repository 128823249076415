import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {CircularProgress, TextField} from "@material-ui/core";
import SimpleDatePicker from "../../../../atoms/simple-date-picker/simple-date-picker.component";
import {phoneNumberReg} from "../../../../utils/booking";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {countries} from "../../../../utils/countries";
import icKeyboardIcon from "../../../../assets/ic-keyboard-arrow-down.svg";
import {useStyles} from './order-page.styles';
import SectionComponent from "../../../general/section/section.component";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import {useRouter} from "../../../../utils/router";
import {useSnackbar} from "../../../../utils/snackbar";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useStateMachine} from "little-state-machine";
import updateFormAction from "../../../../state-machine/actions/update-form-action";
import {useForm} from "react-hook-form";
import axios from "axios";
import HeroMainNavComponent from "../../b2b/hero-main-nav/HeroMainNav.component";
import HeroSmallFooterComponent from "../../b2c/hero-small-footer/hero-small-footer.component";
import {Step, StepLabel, Stepper} from "@mui/material";

const OrderPageComponent = () => {

    const router = useRouter();
    const {setSnackbar} = useSnackbar();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [isProcessing, setIsProcessing] = React.useState(false);
    const [acceptTerms, setAcceptTerms] = React.useState(false);
    const [date, setDate] = useState(null);
    const [country, setCountry] = useState(null);
    const handleTermsAccept = () => setAcceptTerms(!acceptTerms);

    const {actions, state} = useStateMachine({updateFormAction})
    const {handleSubmit, control, reset, register, errors, formState: {isValid, isDirty}} = useForm({
        defaultValues: state.bookingDetails,
        mode: "onChange"
    });

    const onSubmit = async (data) => {

        setIsProcessing(true);
        data['redirect_url'] = 'booking/success';
        data['methods'] = ['ideal', 'creditcard'];
        data['birth_date'] = date;
        data['country'] = country;
        actions.updateFormAction(data);

        // Workaround https://github.com/bluebill1049/little-state-machine/issues/42
        Object.assign(state.bookingDetails, data);

        axios.post(`/order`, {...data},{params: {id: `${router.query.bike}`}}).then((res) => {
            console.log("uitkomst: ", res.data);

            window.location.replace(res.data.transaction.paymentURL);
        }).catch((err) => {
            setSnackbar(`${err.response.data.message}.`, 'error');
        });

        setIsProcessing(false);
        reset();
    };

    const classes = useStyles();

    const steps = [
        'Choose bike',
        'Fill in contact details',
        'Complete payment',
    ];

    useEffect(() => {
        console.log(isDirty, ' and ', isValid);
    }, [isDirty, isValid]);

    return (
        <>
            <HeroMainNavComponent business={'consumers'}/>
            <SectionComponent size='auto' flexOne>
                <Container maxWidth='md' className={classes.container}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant='h2' className={classes.header}>Personal details</Typography>
                        </Grid>
                        <Grid item>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Stepper activeStep={1} alternativeLabel>
                                            {steps.map((label) => (
                                                <Step key={label}
                                                      sx={{
                                                          '& .MuiStepLabel-root .Mui-completed': {
                                                              color: 'primary.dark', // circle color (COMPLETED)
                                                          },
                                                          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                                              {
                                                                  color: 'grey.500', // Just text label (COMPLETED)
                                                              },
                                                          '& .MuiStepLabel-root .Mui-active': {
                                                              color: 'primary.main', // circle color (ACTIVE)
                                                          },
                                                          '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                              {
                                                                  color: 'common.white', // Just text label (ACTIVE)
                                                              },
                                                          '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                                              fill: 'black', // circle's number (ACTIVE)
                                                          },
                                                          '& .Mui-disabled .MuiStepLabel-labelContainer': {
                                                              color: '#fff',
                                                              opacity: 0.6
                                                          },
                                                          '& .Mui-disabled .MuiStepLabel-iconContainer .MuiStepIcon-root': {
                                                              fill: 'gray',
                                                          }
                                                      }}>
                                                    <StepLabel style={{color: '#fff'}}>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            type="text"
                                            label="First name"
                                            name="first_name"
                                            multiline={true}
                                            rows={1}
                                            error={errors.first_name ? true : false}
                                            helperText={errors.first_name && errors.first_name.message}
                                            fullWidth={true}
                                            inputRef={register({
                                                required: "This field is required",
                                                pattern: {
                                                    value: /^[a-zA-Z]+$/,
                                                    message: "Please only use alphabetic characters"
                                                }
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            type="text"
                                            label="Last name"
                                            name="last_name"
                                            multiline={true}
                                            rows={1}
                                            error={errors.last_name ? true : false}
                                            helperText={errors.last_name && errors.last_name.message}
                                            fullWidth={true}
                                            inputRef={register({
                                                required: "This field is required",
                                                pattern: {
                                                    value: /^[a-zA-Z\s]+$/,
                                                    message: "Please only use alphabetic characters"
                                                }
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SimpleDatePicker text={"Birth date"} chosenDate={date} setChosenDate={(date) => setDate(date)}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            type="text"
                                            label="Email address"
                                            name="email"
                                            multiline={true}
                                            rows={1}
                                            error={errors.email ? true : false}
                                            helperText={errors.email && errors.email.message}
                                            fullWidth={true}
                                            inputRef={register({
                                                required: "This field is required",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Enter a valid e-mail address",
                                                },
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            type="text"
                                            label="Phone number"
                                            name="phone_number"
                                            multiline={true}
                                            rows={1}
                                            error={errors.phone_number ? true : false}
                                            helperText={errors.phone_number && errors.phone_number.message}
                                            fullWidth={true}
                                            inputRef={register({
                                                pattern: {
                                                    value: phoneNumberReg,
                                                    message: "Enter a valid phone number",
                                                },
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            style={{height: '100%'}}
                                            classes={{paper: classes.autoComplete}}
                                            options={countries}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, value) => {
                                                setCountry(value.code);
                                            }}
                                            renderOption={(option) => (<span style={{color: '#000'}}>{option.label}</span>)}
                                            popupIcon={
                                                <img
                                                    src={icKeyboardIcon}
                                                    className={`${classes.keyboardIcon} ${classes.keyboardIconCountry}`}
                                                    alt='Select'
                                                />
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.inputField}
                                                    name="country"
                                                    label="Country"
                                                    variant="outlined"
                                                    error={errors.country ? true : false}
                                                    helperText={errors.country && errors.country.message}
                                                    inputRef={register({
                                                      required: "This field is required",
                                                    })}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            type="text"
                                            label="Street name"
                                            name="street_name"
                                            multiline={true}
                                            rows={1}
                                            error={errors.street_name ? true : false}
                                            helperText={errors.street_name && errors.street_name.message}
                                            fullWidth={true}
                                            inputRef={register({
                                                required: "This field is required",
                                                pattern: {
                                                    value: /^[a-zA-Z\s]+$/,
                                                    message: "Please only use alphabetic characters"
                                                }
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            type="text"
                                            label="House-number"
                                            name="house_number"
                                            multiline={true}
                                            rows={1}
                                            error={errors.house_number ? true : false}
                                            helperText={errors.house_number && errors.house_number.message}
                                            fullWidth={true}
                                            inputRef={register({
                                                required: "This field is required",
                                                pattern: {
                                                    value: phoneNumberReg,
                                                    message: "Please only use numeric characters"
                                                }
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            type="text"
                                            label="Postal code"
                                            name="postal_code"
                                            multiline={true}
                                            rows={1}
                                            error={errors.postal_code ? true : false}
                                            helperText={errors.postal_code && errors.postal_code.message}
                                            fullWidth={true}
                                            inputRef={register({
                                                required: "This field is required",
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            type="text"
                                            label="City"
                                            name="city"
                                            multiline={true}
                                            rows={1}
                                            error={errors.city ? true : false}
                                            helperText={errors.city && errors.city.message}
                                            fullWidth={true}
                                            inputRef={register({
                                                required: "This field is required",
                                                pattern: {
                                                    value: /^[a-zA-Z\s]+$/,
                                                    message: "Please only use alphabetic characters"
                                                }
                                            })}
                                        />
                                    </Grid>
                                    <Grid className={classes.buttonPaymentContainer} item xs={12}>
                                        <Button
                                            className={isValid && country !== null ?
                                                `${classes.buttonPayment}`
                                                :
                                                `${classes.buttonPayment} ${classes.buttonPaymentDisabled}`
                                            }
                                            disabled={!isValid && country === null}
                                            type="submit"
                                            name="submit"
                                            id="submit"
                                        >
                                            <Typography className={classes.buttonPaymentText}>
                                                {isProcessing ? <CircularProgress/> : `Continue to payment`}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            </SectionComponent>
            <HeroSmallFooterComponent/>

        </>
    )
};

export default OrderPageComponent;
