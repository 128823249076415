import React from 'react';
import {Typography} from "@mui/material";
import {Box, Checkbox, Divider, Grid, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {BB, BM} from "../../../assets/icons";

const AdminDashboardBookingCard = ({booking}) => {
    const classes = useStyles();

    return (
        <Grid container style={{padding: '0 2% 0 2%', display: 'flex', alignItems: 'center', border: '1px solid #12b5ad', height: '20%', textAlign: 'center', marginBottom: '2%'}}>
            {/*Status icon, id & hotel*/}
            <Grid item xs>
                <Grid container direction="column" style={{height: '100%'}}>
                    <Grid item xs={12} style={{textAlign: 'start'}}>
                        <Grid container direction='row' style={{height: '100%'}}>
                            <Grid item xs={12}>
                                <Typography variant="h7" style={{color: '#dbc7b6', fontStyle: 'normal'}}>{booking.partner_name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{height: '100%', alignItems: 'left'}}>
                            <Grid item xs style={{textAlign: 'start'}}>
                                <Typography variant="h7" style={{color: '#f1a209', fontStyle: 'normal', textAlign: 'left'}}>{booking.first_name} {booking.last_name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Grid container direction='column' style={{display: 'flex', alignItems: 'center'}}>
                    <Grid item xs={6}  style={{height: '100%', width: '100%'}}>
                        <img src={BM} alt='money-icon' style={{height: '100%', width: '50%'}}/>
                    </Grid>
                    <Grid item xs={6}  style={{height: '100%', width: '100%'}}>
                        <Typography variant="h7" className={classes.infoText}>{booking.discount_code === null ? 'Online' : (booking.discount_code === 'Yes' ? 'PC35' : 'PC20')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Divider orientation='vertical' style={{backgroundColor: '#545454', height: '50%'}}/>
            <Grid item xs={2} style={{paddingLeft: '2%', paddingRight: '2%'}}>
                <Grid container direction='column' style={{display: 'flex', alignItems: 'center'}}>
                    <Grid item xs={6}  style={{height: '100%', width: '100%'}}>
                        <img src={BB} alt='bike-icon' style={{height: '100%', width: '70%'}}/>
                    </Grid>
                    <Grid item xs={6}  style={{height: '100%', width: '100%'}}>
                        <Typography variant="h7" className={classes.infoText}>{booking.bike_count}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Divider orientation='vertical' style={{backgroundColor: '#545454', height: '50%'}}/>
            <Grid item xs={3} style={{paddingLeft: '2%', paddingRight: '2%'}}>
                <Typography variant="h7" className={[classes.infoText, classes.textSecondary]}>Res. Date</Typography>
                <Typography variant="h7" className={classes.infoText}>{booking.booking_date}</Typography>
            </Grid>
        </Grid>
    )
}

export const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: '#202020'
    },
    infoText: {
        display: 'block',
        width: '100%',
        color: '#dbc7b6',
        textAlign: 'center',
        fontSize: '0.8em',
    },
    textSecondary: {
        color: '#12b2aa'
    },
    input: {
        color: "#12b2aa !important",
    },
}));

export default AdminDashboardBookingCard;
