import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const StyledButton = ({text, primary, onClick, warning, list, popupConfirm, popupCancel, popupConfirmDisabled, listButtonClicked, noPadding}) => {
    const classes = useStyles();
    const [clicked, setClicked] = useState(false);

    return (
        <>
            {primary &&
                <Button className={classes.buttonPrimary} onClick={() => onClick()} style={{ padding: noPadding && 0 }}>
                    <Typography className={[classes.buttonText, classes.buttonTextPrimary]}>
                        {text}
                    </Typography>
                </Button>}

            {warning && <Button className={classes.buttonWarning} onClick={() => onClick()} style={{ padding: noPadding && 0 }}>
                <Typography className={[classes.buttonText]}>
                    {text}
                </Typography>
            </Button>}
            {(!primary && !warning && !list && !popupConfirm && !popupCancel && !popupConfirmDisabled) &&
                <Button className={classes.buttonSecondary} onClick={() => onClick()} style={{ padding: noPadding && 0 }}>
                    <Typography className={[classes.buttonText]}>
                        {text}
                    </Typography>
                </Button>}
            {list &&
                <Button className={classes.buttonList} style={{backgroundColor: listButtonClicked ? 'rgba(18,181,173,1)' : '#1f1f1f', "&&:hover": {backgroundColor: 'rgba(18,181,173,1)'}}} onClick={() => {onClick(); setClicked(!clicked)}}>
                    <Typography className={[classes.buttonText, classes.buttonTextPrimary]}>
                        {text}
                    </Typography>
                </Button>
            }
            {popupConfirm &&
            <Button className={classes.buttonPopup} onClick={() => onClick()} style={{ padding: noPadding && 0 }}>
                <Typography className={[classes.buttonText, classes.buttonTextPopupConfirm]}>
                    {text}
                </Typography>
            </Button>
            }
            {popupConfirmDisabled &&
            <Button disabled className={classes.buttonPopup} onClick={() => onClick()} style={{ padding: noPadding && 0 }}>
                <Typography className={[classes.buttonText, classes.buttonTextPopupConfirmDisabled]}>
                    {text}
                </Typography>
            </Button>
            }
            {popupCancel &&
            <Button className={classes.buttonPopup} onClick={() => onClick()} style={{ padding: noPadding && 0 }}>
                <Typography className={[classes.buttonText, classes.buttonTextPopupCancel]}>
                    {text}
                </Typography>
            </Button>
            }
        </>
    )
}

export const useStyles = makeStyles((theme) => ({
    buttonList: {
        width: '100%',
        padding: '12px 16px',
        backgroundColor: '#1f1f1f',
        "&&:hover": {
            backgroundColor: 'rgba(18,181,173,1) !important',
        },
        borderRadius: 0,
    },
    buttonPopup: {
        width: '100%',
        padding: '12px 16px',
        borderRadius: 0,
    },
    buttonPrimary: {
        width: '100%',
        padding: '12px 16px',
        borderRadius: 0,
        backgroundColor: 'var(--java-green)',
        "&&:hover": {
            backgroundColor: 'rgba(18,181,173,0.5)',
        },
        color: 'white'
    },
    buttonSecondary: {
        width: '100%',
        padding: '12px 16px',
        borderRadius: 0,
        backgroundColor: 'rgb(241, 162, 9)',
        "&&:hover": {
            backgroundColor: 'rgba(241, 162, 9,0.5)',
        },
        color: 'white'
    },
    buttonText: {
        fontSize: '1rem',
        fontWeight: '500',
        fontStyle: 'italic',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: 'var(--midnight-forest-black)',
    },
    buttonTextPrimary: {
        color: 'white'
    },
    buttonTextPopupCancel: {
        color: 'rgb(255, 87, 87)'
    },
    buttonTextPopupConfirm: {
        color: 'var(--java-green)'
    },
    buttonTextPopupConfirmDisabled: {
        color: 'gray'
    },
    buttonWarning: {
        width: '100%',
        padding: '12px 16px',
        borderRadius: 0,
        backgroundColor: 'rgb(255, 87, 87)',
        "&&:hover": {
            backgroundColor: 'rgba(255, 87, 87,0.5)',
        },
        color: 'white'
    }
}));
export default StyledButton;
