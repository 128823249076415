import React from 'react';

import {Link, useRouter} from "../../../utils/router";
import {useAuth} from "../../../utils/auth";

import {
  Container,
  Toolbar,
  Hidden,
  IconButton,
  Divider,
  Typography,
  Button,
  ListItem,
  List,
  Drawer,
  ListItemText,
  AppBar
} from "@material-ui/core";

import {useStyles} from "../main-nav/main-nav.styles";
import ScrollToColor from "../nav-scroll-wrapper/nav-scroll-wrapper.component";
import SectionComponent from "../section/section.component";

import MenuIcon from "../../../assets/ic-menu.svg";
import VesperIcon from "../../../assets/logo-color-icon.svg";
import logoHorizontal from "../../../assets/logo-color-horizontal-db.svg";
import exitImage from "../../../assets/ic-exit-to-app.svg";
import DrawerVesper from "../../../assets/logo-b-w-wordmark-db.svg";

const ProtectedNav = ({transparent = false, bookingGuest = false, admin = false}) => {
  const classes = useStyles();
  const router = useRouter();

  const auth = useAuth();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  // Active path
  const currentPath = router.pathname;
  const reservations = ['/partner/book'];
  const bikes = ['/partner/bikes', `/partner/bikes/damage/`, `/partner/bikes/failure/`];
  const faq = ['/partner/contact'];

  return (
    <SectionComponent transparent={transparent} size="auto">
      <ScrollToColor>
        <AppBar position="fixed" color='transparent' elevation={0}>
          <Container
            maxWidth={false}
            className={transparent ? `${classes.container}` : `${classes.container} ${classes.rootNav}`}
            disableGutters
          >
            <Toolbar
              className={`${transparent ? `${classes.toolBar} ${classes.toolbarNoShadow}` : `${classes.toolBar}`}`}>
              <Hidden smUp implementation="css"
                      className={auth.user ? `${classes.xsNavBarPortal}` : `${classes.xsNavbar}`}>
                <IconButton
                  className={classes.menuButton}
                  onClick={() => {
                    setDrawerOpen(true);
                  }}
                  color="inherit"
                >
                  <img src={MenuIcon} alt="Menu" className={classes.menuIcon}/>
                </IconButton>
                {auth.user && !admin && (
                  <>
                    <Divider className={classes.xsDivider}/>
                    <Typography className={classes.xsLogoTitle}>Business portal</Typography>
                  </>
                )}
                {auth.user && admin && (
                  <>
                    <Divider className={classes.xsDivider}/>
                    <Typography className={classes.xsLogoTitle}>Admin portal</Typography>
                  </>
                )}
                <Link to={`${router.match.url}`}
                      className={auth.user ? `${classes.logoContainerPortal}` : `${classes.logoContainer}`}>
                  <img src={VesperIcon} className={classes.xsLogo} alt="Logo"/>
                </Link>
              </Hidden>
              <Hidden mdDown implementation="css" className={classes.lgNavbar}>
                {auth.user && !admin && (
                  <>
                    <div className={classes.logoLgContainer}>
                      <Link to={`${router.match.url}`}>
                        <img src={logoHorizontal} className={classes.horizontalLogo} alt="Logo"/>
                      </Link>
                      <Divider className={classes.divider}/>
                      <Typography className={classes.logoTitle}>Business portal</Typography>
                    </div>
                    <div className={classes.subNav}>
                      <Button
                        className={reservations.some(r => currentPath.includes(r)) || currentPath === '/partner' ? `${classes.toolBarItemActive}` : `${classes.toolBarItem}`}
                        component={Link} to={`${router.match.url}`}
                      >
                        <p className={classes.toolbarItemText}>Reservations</p>
                      </Button>
                      <Button
                        className={bikes.some(b => currentPath.includes(b)) ? `${classes.toolBarItemActive}` : `${classes.toolBarItem}`}
                        component={Link} to={`${router.match.url}/bikes`}
                      >
                        <p className={classes.toolbarItemText}>Our bikes</p>
                      </Button>
                      <Button
                        className={faq.includes(currentPath) ? `${classes.toolBarItemActive}` : `${classes.toolBarItem}`}
                        component={Link} to={`${router.match.url}/contact`}
                      >
                        <p className={classes.toolbarItemText}>Contact & FAQ</p>
                      </Button>
                      <ListItem
                        className={classes.toolBarItemIcon}
                        button
                        onClick={() => {
                          auth.signout();
                        }}
                      >
                        <img
                          src={exitImage}
                          alt='Sign Out'
                          className={classes.exitAppIcon}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
                {auth.user && admin && (
                  <>
                    <div className={classes.logoLgContainer}>
                      <Link to={`${router.match.url}`}>
                        <img src={logoHorizontal} className={classes.horizontalLogo} alt="Logo"/>
                      </Link>
                      <Divider className={classes.divider}/>
                      <Typography className={classes.logoTitle}>ADMIN PORTAL</Typography>
                    </div>
                    <div className={classes.subNav}>
                      <Button className={classes.toolBarItem} component={Link} to={`${router.match.url}/rental`}>
                        <p className={classes.toolbarItemText} style={{color: (window.location.href.split("/").pop() === 'rental') ? '#00d9e1' : ''}}>Rental</p>
                      </Button>
                      <Button className={classes.toolBarItem} component={Link} to={`${router.match.url}`}>
                        <p className={classes.toolbarItemText} style={{color: (window.location.href.split("/").pop() === 'dashboard') ? '#00d9e1' : ''}}>Dashboard</p>
                      </Button>
                      <Button className={classes.toolBarItem} component={Link} to={`${router.match.url}/archive`}>
                        <p className={classes.toolbarItemText} style={{color: (window.location.href.split("/").pop() === 'archive') ? '#00d9e1' : ''}}>Archive</p>
                      </Button>
                      <Button className={classes.toolBarItem} component={Link} to={`${router.match.url}/invoices`}>
                        <p className={classes.toolbarItemText} style={{color: (window.location.href.split("/").pop() === 'invoices') ? '#00d9e1' : ''}}>Invoices</p>
                      </Button>
                      <ListItem
                        className={classes.toolBarItemIcon}
                        button
                        onClick={() => {
                          auth.signout();
                        }}
                      >
                        <img
                          src={exitImage}
                          alt='Sign Out'
                          className={classes.exitAppIcon}
                        />
                      </ListItem>
                    </div>
                  </>
                )}
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>
      </ScrollToColor>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={classes.drawer}
      >
        <List
          onClick={() => setDrawerOpen(false)}
          className={classes.drawerList}
        >
          <Link to="/">
            <img src={DrawerVesper} alt="Logo" className={classes.drawerVesper}/>
          </Link>
          {auth.user && !admin && (
            <>
              <div className={classes.drawerRoutes}>
                <Typography className={classes.drawerTitle}>Business portal</Typography>
                <ListItem
                  className={reservations.some(r => currentPath.includes(r)) ? `${classes.drawerListItemActive}` : `${classes.drawerListItem}`}
                  button component={Link}
                  to={`${router.match.url}`}
                >
                  <ListItemText className={classes.drawerListItemText}>Reservations</ListItemText>
                </ListItem>
                <ListItem
                  className={bikes.some(b => currentPath.includes(b)) ? `${classes.drawerListItemActive}` : `${classes.drawerListItem}`}
                  button component={Link}
                  to={`${router.match.url}/bikes`}
                >
                  <ListItemText className={classes.drawerListItemText}>Our bikes</ListItemText>
                </ListItem>
                <ListItem
                  className={faq.includes(currentPath) ? `${classes.drawerListItemActive}` : `${classes.drawerListItem}`}
                  button component={Link}
                  to={`${router.match.url}/contact`}
                >
                  <ListItemText className={classes.drawerListItemText}>Contact $ FAQ</ListItemText>
                </ListItem>
              </div>
              <div>
                <Divider className={classes.drawerDivider}/>
                <ListItem
                  className={classes.drawerToolBarItem}
                  button
                  onClick={() => {
                    auth.signout();
                  }}
                >
                  <img
                    src={exitImage}
                    alt='Sign Out'
                    className={classes.exitAppIcon}
                  />
                  <ListItemText className={classes.drawerListItemText}>Log out</ListItemText>
                </ListItem>
              </div>
            </>
          )}
          {auth.user && admin && (
            <>
              <div className={classes.drawerRoutes}>
                <div>
                  <Typography className={classes.drawerTitle}>Admin portal</Typography>
                  <ListItem className={classes.drawerListItem} button component={Link} to={`${router.match.url}/rental`}>
                    <ListItemText className={classes.drawerListItemText}>Rental</ListItemText>
                  </ListItem>
                  <ListItem className={classes.drawerListItem} button component={Link}
                            to={`${router.match.url}`}>
                    <ListItemText className={classes.drawerListItemText}>Dashboard</ListItemText>
                  </ListItem>
                  <ListItem className={classes.drawerListItem} button component={Link}
                            to={`${router.match.url}/archive`}>
                    <ListItemText className={classes.drawerListItemText}>Archive</ListItemText>
                  </ListItem>
                  <ListItem className={classes.drawerListItem} button component={Link}
                            to={`${router.match.url}/invoices`}>
                    <ListItemText className={classes.drawerListItemText}>Invoices</ListItemText>
                  </ListItem>
                </div>
                <div>
                  <Divider className={classes.drawerDivider}/>
                  <ListItem
                    className={classes.toolBarItem}
                    button
                    onClick={() => {
                      auth.signout();
                    }}
                  >
                    <img
                      src={exitImage}
                      alt='Sign Out'
                      className={classes.exitAppIcon}
                    />
                    <ListItemText className={classes.drawerListItemText}>Log out</ListItemText>
                  </ListItem>
                </div>
              </div>
            </>
          )}
          <Divider/>
        </List>
      </Drawer>
    </SectionComponent>
  )
};

export default ProtectedNav;
