import React from "react";
import {QueryClient, QueryClientProvider} from 'react-query'
import {StateMachineProvider, createStore} from 'little-state-machine';

import {Switch, Route, Router} from "./utils/router.js";
import {AuthProvider} from "./utils/auth";
import {ThemeProvider} from "./utils/theme";
import {AppSnackbar, SnackBarProvider} from "./utils/snackbar";

import PartnerPortal from "./modules/partner/portal-section/portal-section.component";
import AdminDashboard from "./modules/admin/admin-dashboard/admin-dashboard.component";
import GuestFaqSection from "./modules/guest/guest-faq-section/guest-faq-section.component";
import Terms from './modules/guest/terms/terms.component';
import Auth from "./modules/general/auth/auth.component";
import NotFoundComponent from "./modules/general/not-found/not-found.component.js";
import BookingGuestSection from "./modules/booking/booking-guest-section/booking-guest-section.component";
import BecomePartnerSection from "./modules/become-partner/become-partner-section/become-partner-section.component";
import BookingCancel from "./modules/booking/booking-cancel/booking-cancel.component";
import LocationsSection from "./modules/guest/locations-section/locations-section.component";
import PrivacyPolicy from "./modules/guest/privacy-policy/privacy-policy.component";
import RideReviewComponent from './modules/guest/ride-review/ride-review.component';
import LandingB2bComponent from './modules/guest-v2/b2b/landing.b2b.component';
import LandingB2cComponent from "./modules/guest-v2/b2c/landing.b2c.component";
import OrderSuccessComponent from "./modules/order/order-success/order-success.component";
import BusinessChoserDeviceComponent from "./modules/guest-v2/b2-choose/business-choser-device.component";
import LandingB2cShopComponent from "./modules/guest-v2/b2c-shop/landing.b2c-shop.component";
import OrderPageComponent from './modules/guest-v2/b2c-shop/order-page/order-page.component';
import OrderPageSuccessComponent from "./modules/guest-v2/b2c-shop/order-success/order-page-success.component";
import OrderConfirmComponent from "./modules/guest-v2/b2c-shop/order-confirm/order-confirm.component";
import ContactUsComponent from "./modules/guest-v2/contact-us/contact-us.component";

const queryClient = new QueryClient();

createStore({
  bookingDetails: {
    first_name: '',
    last_name: '',
    partner_code: 0,
    notes: '',
    rental_dates: [],
    amount_bikes: undefined,
    assigned_bikes: [],
    email: '',
    amount: 0
  }
});

function App() {
  return (
    <StateMachineProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <SnackBarProvider>
            <AuthProvider>
              <Router>
                <>
                  <Switch>
                    {/*<Route exact path="/" component={LandingComponent}/>*/}
                    <Route exact path="/" component={BusinessChoserDeviceComponent}/>
                    <Route exact path="/business" component={LandingB2bComponent}/>
                    <Route exact path="/shop" component={LandingB2cComponent}/>
                    <Route exact path="/shop/continue" component={LandingB2cShopComponent}/>
                    <Route exact path="/shop/continue/order/:bike" component={OrderPageComponent}/>
                    <Route exact path="/contact" component={ContactUsComponent}/>
                    <Route exact path="/terms" component={Terms}/>
                    <Route exact path="/privacy" component={PrivacyPolicy}/>
                    <Route path="/faq" component={GuestFaqSection}/>
                    <Route path="/booking" component={BookingGuestSection}/>
                    <Route path="/become-partner" component={BecomePartnerSection}/>
                    <Route path="/locations" component={LocationsSection}/>
                    <Route path="/cancel" component={BookingCancel}/>
                    <Route path="/partner" component={PartnerPortal}/>
                    <Route path="/dashboard" component={AdminDashboard}/>
                    <Route path="/review/:guestId/:bookingId" component={RideReviewComponent}/>
                    <Route exact path="/auth/:type" component={Auth}/>
                    <Route exact path="/order/success" component={OrderPageSuccessComponent}/>
                    <Route exact path="/order/confirm" component={OrderConfirmComponent}/>
                    <Route component={NotFoundComponent}/>
                  </Switch>
                </>
                <AppSnackbar/>
              </Router>
            </AuthProvider>
          </SnackBarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StateMachineProvider>
  );
}

export default App;
