import React from 'react';

import {Grid, Typography} from '@material-ui/core';
import {useStyles} from './section.styles';

const AdminDashboardInfoCard = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={4}>
                <fieldset className={classes.card}>
                    <legend><Typography variant="h4">{props.title}</Typography></legend>
                    {props.children}
                </fieldset>
            </Grid>
        </>
    );
};

export default (AdminDashboardInfoCard);
