import React from 'react';
import selectedSvg from './Rectangle 38.svg';
import unselectedSvg from './Rectangle 51.svg';

const HeroImageGalleryBtnComponent = ({selected, onClick}) => {
    return (
        <>
            {selected
                ?
                <img src={selectedSvg} alt="Selected btn" onClick={onClick} style={{cursor: 'pointer'}} height={12}/>

                :
                <img src={unselectedSvg} alt="Unselected btn" onClick={onClick} style={{cursor: 'pointer'}} height={7}/>
            }
        </>
    )
};

export default HeroImageGalleryBtnComponent;
