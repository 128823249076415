import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {CircularProgress, TextField} from "@material-ui/core";
import SimpleDatePicker from "../../../../atoms/simple-date-picker/simple-date-picker.component";
import {phoneNumberReg} from "../../../../utils/booking";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {countries} from "../../../../utils/countries";
import icKeyboardIcon from "../../../../assets/ic-keyboard-arrow-down.svg";
import {useStyles} from './order-page-success.styles';
import SectionComponent from "../../../general/section/section.component";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import {useRouter} from "../../../../utils/router";
import {useSnackbar} from "../../../../utils/snackbar";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useStateMachine} from "little-state-machine";
import updateFormAction from "../../../../state-machine/actions/update-form-action";
import {useForm} from "react-hook-form";
import axios from "axios";
import HeroMainNavComponent from "../../b2b/hero-main-nav/HeroMainNav.component";
import HeroSmallFooterComponent from "../../b2c/hero-small-footer/hero-small-footer.component";
import {Step, StepLabel, Stepper} from "@mui/material";
import CheckIcon from "../../../../assets/ic-check-circle-large.svg";
import ErrorIcon from '../../../../assets/ic-add.svg';
import dayjs from "dayjs";

const OrderPageSuccessComponent = () => {

    const router = useRouter();
    const {setSnackbar} = useSnackbar();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [isApproved, setIsApproved] = useState((router.query.orderStatusId === '-90' || router.query.orderStatusId === '-63') ? false : true);

    const [isProcessing, setIsProcessing] = React.useState(false);
    const [acceptTerms, setAcceptTerms] = React.useState(false);
    const [date, setDate] = useState(null);
    const handleTermsAccept = () => setAcceptTerms(!acceptTerms);

    const {actions, state} = useStateMachine({updateFormAction})
    const {handleSubmit, control, reset, register, errors, formState: {isValid, isDirty}} = useForm({
        defaultValues: state.bookingDetails,
        mode: "onChange"
    });

    const classes = useStyles();

    const steps = [
        'Choose bike',
        'Fill in contact details',
        'Complete payment',
    ];

    useEffect(() => {
        console.log(isDirty, ' and ', isValid);
    }, [isDirty, isValid]);

    return (
        <>
            <HeroMainNavComponent business={'consumers'}/>
            <SectionComponent size='auto' flexOne>
                <Container maxWidth='md' className={classes.container}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant='h2' className={classes.header}>Order confirmation</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stepper activeStep={3} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}
                                          sx={{
                                              '& .MuiStepLabel-root .Mui-completed': {
                                                  color: 'primary.dark', // circle color (COMPLETED)
                                              },
                                              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                                  {
                                                      color: 'grey.500', // Just text label (COMPLETED)
                                                  },
                                              '& .MuiStepLabel-root .Mui-active': {
                                                  color: 'primary.main', // circle color (ACTIVE)
                                              },
                                              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                  {
                                                      color: 'common.white', // Just text label (ACTIVE)
                                                  },
                                              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                                  fill: 'black', // circle's number (ACTIVE)
                                              },
                                              '& .Mui-disabled .MuiStepLabel-labelContainer': {
                                                  color: '#fff',
                                                  opacity: 0.6
                                              },
                                              '& .Mui-disabled .MuiStepLabel-iconContainer .MuiStepIcon-root': {
                                                  fill: 'gray',
                                              }
                                          }}>
                                        <StepLabel error={!isApproved} style={{color: '#fff'}}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={12}>
                            {isApproved
                                ?
                                <img src={CheckIcon} alt='Success' className={classes.checkIcon}/>
                                :
                                <img src={ErrorIcon} alt='Error' className={classes.checkIcon}/>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.greetingText}>
                                {/*Hi {first_name} {last_name},*/}
                                Hi you,
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h3' className={classes.titleText}>
                                {!isApproved
                                    ?
                                    'Order has been cancelled.'
                                    :
                                    'Thank you for your order.'
                                }
                            </Typography>
                        </Grid>
                        {isApproved
                            ?
                            <>
                                <Grid item xs={12}>
                                    <Typography variant='caption' className={classes.subHeader}>
                                        Order details
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1' className={classes.bookingThank}>
                                        We hope you'll enjoy your adventurous ride. We're looking forward to hearing your experiences!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2' className={classes.bookingThank}>
                                        A confirmation email has been sent to the given email-address with order id: {router.query.orderId}
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <>
                                {router.query.orderStatusId === '-63' &&
                                    <Grid item xs={12}>
                                        <Typography variant='body1' className={classes.bookingThank}>
                                         Unfortunately your payment got rejected.
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <Typography variant='body2' className={classes.bookingThank}>
                                        Please try again later.
                                    </Typography>
                                </Grid>
                            </>
                        }

                    </Grid>
                </Container>
            </SectionComponent>
            <HeroSmallFooterComponent/>
        </>
    )
};

export default OrderPageSuccessComponent;
