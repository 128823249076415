import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  partnerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '32px 0',
  },
  keyboardIcon: {
    width: '24px',
    height: '25px',
    objectFit: 'contain',
    margin: '0 15px 0 0',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
  },
  menuItem: {
    fontSize: '1em',
  },
  helperText: {
    color: 'var(--error)'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  continueButton: {
    padding: '12px 16px',
    backgroundColor: 'var(--buttercup-yellow)',
    borderRadius: 0,
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    },
    width: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      width: '118px',
    }
  },
  continueButtonDisabled: {
    backgroundColor: 'rgba(249,252,246,0.32)',
  },
  continueButtonText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--midnight-forest-black)',
  },
}));
