import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    bgBox: {
        backgroundColor: '#1F1F1F',
        padding: 20
    },
    container: {
        display: 'flex !important',
        maxWidth: 'var(--max-width-12)',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column',
        }
    },
    subHeaderFooter: {
        color: "#fff",
        "&&:active": {
            color: '#fff'
        },
        [theme.breakpoints.down('sm')]:{
            textAlign: 'center'
        }
    },
    links: {
        textAlign: 'left',
        [theme.breakpoints.down('sm')]:{
            textAlign: 'center'
        }
    }
}));
