import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  subHeader: {
    margin: '24px 0 4px 0',
    color: 'var(--loafer-white)',
  },
  text: {
    color: 'var(--loafer-white)',
  },
  textBold: {
    fontWeight: 'bold',
  },
  list: {
    backgroundColor: 'transparent !important',
  },
  listIconContainer: {
    minWidth: '20px',
    [theme.breakpoints.up('lg')]: {
      minWidth: '40px',
    }
  },
  listIcon: {
    height: '0.7em',
    width: '0.7em',
  },
  listIconNumber: {
    fontSize: '1.25em',
    fontWeight: 'bold',
    color: 'var(--java-green)'
  },
  listNested: {
    paddingTop: 0,
    paddingDown: 0,
    paddingLeft: theme.spacing(4),
  },
  listNestedIconNumber: {
    marginRight: theme.spacing(1),
  },
}));
