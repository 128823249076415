import React from 'react';

import axios from "axios";
import {useQuery} from "react-query";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Badge} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";

import SectionComponent from "../../general/section/section.component";
import {calendarBadges, initialCalendarBadges} from "../../../utils/calendar";
import {useStyles, materialTheme} from "./location-calendar.styles";

import ChevronRight from '../../../assets/ic-chevron-right.svg';
import ChevronLeft from '../../../assets/ic-chevron-left.svg';

const LocationCalendar = ({partnerId}) => {
  const classes = useStyles();
  const [bookedDays, setBookedDays] = React.useState([]);

  const {isLoading, data: partnerDb} = useQuery(
    ['partnerLocation'],
    async () => {
      const res = await axios.get(`/location/${partnerId}`);
      return res.data.rows;
    },
    {
      refetchInterval: 5000,
    }
  );

  React.useEffect(() => {
    isLoading ? setBookedDays([]) : setBookedDays(initialCalendarBadges(partnerDb));
  }, [partnerDb, isLoading]);

  const handleMonthChange = (date) => {
    const currentBookedDays = [];

    return new Promise(resolve => {
      calendarBadges(partnerDb).forEach((dayAndBikes) => {
        if (dayjs(dayAndBikes.date).get('month') === dayjs(date).get('month')) {
          currentBookedDays.push(dayAndBikes);
        }
        setBookedDays(currentBookedDays);
      });
      resolve();
    })
  }

  const excludeDate = (date) => {
    let available = false;

    bookedDays.forEach(dayAndBikes => {
      available = dayjs(date).isSame(dayjs(dayAndBikes.date), 'day') && dayAndBikes.amount_bikes === 0;
    });

    return available;
  }

  const handleCalendarBadge = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    const date = dayjs(day).get('date');

    let bookedDay = dayjs();
    let badgeContent = partnerDb?.[0]?.total_bikes;
    let badgeStyle = classes.badgeAvailable;

    bookedDays.forEach(dayAndBikes => {
      if (dayInCurrentMonth && dayjs(dayAndBikes?.date).get('date') === date) {
        bookedDay = dayAndBikes;
        badgeContent = bookedDay.amount_bikes > 0 ? bookedDay.amount_bikes : '-';
        if (bookedDay.amount_bikes <= 0) {
          badgeStyle = classes.badgeDisabled;
        }
      }
    });

    return <Badge
      badgeContent={dayInCurrentMonth ? badgeContent : undefined}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{root: badgeStyle}}
    >
      {dayComponent}
    </Badge>
  }

  return (
    <>
      <SectionComponent>
        <Container maxWidth='xl' className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h3' className={classes.mainHeader}>
                V-bike availability
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.subHeader}>
                Check the calendar to see how many v-bikes are available on your desired dates.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DayjsUtils}>
                  <DatePicker
                    variant='static'
                    disableToolbar
                    onChange={() => {
                    }}
                    onMonthChange={handleMonthChange}
                    leftArrowIcon={<img src={ChevronLeft} alt='Next month'/>}
                    rightArrowIcon={<img src={ChevronRight} alt='Previous month'/>}
                    renderDay={handleCalendarBadge}
                    shouldDisableDate={excludeDate}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Container>
      </SectionComponent>
    </>
  )
};

export default LocationCalendar;
