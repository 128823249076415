import React from 'react';

import {Breadcrumbs} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import chevronLeft from '../../../assets/ic-chevron-left-white.svg'
import chevronRight from "../../../assets/ic-chevron-right-white.svg";
import {useRouter} from "../../../utils/router";
import {useStyles} from "./double-bread-crumb.styles";

const DoubleBreadCrumb = ({start, current, rerouteUrl, margin = true}) => {
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Breadcrumbs
        className={margin ? `${classes.breadCrumbs}` : `${classes.breadCrumbs} ${classes.breadCrumbsBottomFree}`}
        separator={<img src={matches ? chevronRight : chevronLeft} alt='next' className={classes.rightArrow}/>}
        aria-label="breadcrumb"
      >
        {
          !matches && (
            <div style={{display: 'flex'}}>
              <img src={chevronLeft} alt='next' className={classes.rightArrow}/>
              <Typography
                button
                onClick={() => router.push(`${rerouteUrl}`)}
                className={`${classes.breadCrumb} ${classes.backCrumb}`}
                style={{marginLeft: '5px'}}
              >
                {start}
              </Typography>
            </div>
          )
        }
        {
          matches && (
            <Typography
              button
              onClick={() => router.push(`${rerouteUrl}`)}
              className={`${classes.breadCrumb} ${classes.backCrumb}`}
            >
              {start}
            </Typography>
          )
        }
        {
          matches && (
            <Typography variant='body2'>
              {current}
            </Typography>
          )
        }
      </Breadcrumbs>
    </>
  )
};

export default DoubleBreadCrumb;
