import React from 'react';

import {Collapse, ListItem, ListItemText, Typography} from "@material-ui/core";

import keyboardArrowUp from '../../assets/ic-keyboard-arrow-up.svg'
import keyboardArrowDown from '../../assets/ic-keyboard-arrow-down.svg'
import {useStyles} from "./faq-list-item.styles";

const FaqListItem = ({question, answer}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <ListItem
        className={!open ? `${classes.itemOpenContainer}` : `${classes.itemOpenContainer} ${classes.itemTransform}`}
        button onClick={handleClick}
      >
        <div className={classes.listItemTextDiv}>
          <ListItemText className={classes.itemQuestionText} primary={question}/>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ListItemText className={classes.itemAnswerText} primary={answer}/>
          </Collapse>
        </div>
        {open ?
          <img src={keyboardArrowUp}
               className={classes.arrowOpenClose} alt='Arrow'/>
          :
          <img src={keyboardArrowDown}
               className={classes.arrowOpenClose} alt='Arrow'/>
        }
        <Typography className={classes.header}>V</Typography>
      </ListItem>
    </>
  )
};

export default FaqListItem;
