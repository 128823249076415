import React, {useEffect, useRef, useState} from 'react';
import {useStyles} from "./hero-footer.styles";
import {Box, Typography} from "@material-ui/core";
import {Container, Grid} from "@mui/material";
import ZigZagDivider from "../../../../assets/v2-icons/zig-zag-divider.svg";
import Button from "@material-ui/core/Button";
import {Link} from "../../../../utils/router";
import {RightArrowIcon} from "../../../../assets/v2-icons";
import FaqListItem from "../../../../atoms/faq-list-item/faq-list-item.component";
import SectionComponent from "../../../general/section/section.component";
import Pattern from '../../../../assets/group-961.svg';
import FacebookIcon from '../../../../assets/social-facebook.svg';
import LinkedInIcon from '../../../../assets/social-linked-in.svg';
import InstagramIcon from '../../../../assets/social-instagram.svg';
import axios from "axios";
import {useSnackbar} from "../../../../utils/snackbar";

const HeroFooterComponent = ({data}) => {

    // Imports
    const classes = useStyles();

    // States
    const backgroundRef = useRef();
    const [backgroundHeight, setBackgroundHeight] = useState(300);
    const [phone, setPhone] = useState('');
    const [mail, setMail] = useState('');
    const [name, setName] = useState('');

    // Functions
    const checkForDevice = () => {
        let windowWidth = window.innerWidth;
        if (windowWidth < 767.98) {
            return true;
        } else {
            return false;
        }
    };

    // Declarations
    const {setSnackbar} = useSnackbar();

    const sendRequest = () => {
        const obj = {mail: mail, name: name, phone_number: phone};

        const isEmpty = Object.values(obj).some(value => {
            if (value === null || value.length === 0){
                return true;
            }

            return false;
        })

        console.log(isEmpty);

        if (!isEmpty){
            axios.post('/contact', {obj}).then((res) => {
                setSnackbar(`Request successfully sent.`, 'success');
            }).catch((err) => {
                setSnackbar(`${err}`, 'error');
            })
        } else {
            setSnackbar(`Not all values have been filled in.`, 'error');
        }
    }

    // Data retrieval
    useEffect(() => {
        if (checkForDevice()){
            setBackgroundHeight(backgroundRef.current.clientHeight ? backgroundRef.current.clientHeight + 200 : 300);
        } else {
            setBackgroundHeight(backgroundRef.current.clientHeight ? backgroundRef.current.clientHeight : 300);
        }
    }, [backgroundRef.current]);

    return (
        <>
            <SectionComponent size="auto">
                <Box className={classes.bgBox} style={{backgroundImage: `url(${Pattern})`}}>
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box className={classes.partnerUp}>
                                    <div className={classes.background} style={{height: backgroundHeight}}>
                                        <Typography className={classes.backgroundV}>V</Typography>
                                    </div>
                                    <Grid container className={classes.grid} ref={backgroundRef}>
                                        <Grid item className={classes.leftSection}>
                                            <div className={classes.headerSection}>
                                                <Typography className={classes.header} style={{color: '#000'}}>{data?.footerTitleBlack} <Typography style={{color: '#fff'}} className={classes.header}>{data?.footerTitleWhite}</Typography></Typography>
                                            </div>
                                            <Typography variant='caption2' className={classes.subHeader}>
                                                {data?.footerSubtitle}
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.rightSection}>
                                            <Grid container style={{zIndex: 5}} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.headerInput}>NAAM</Typography>
                                                    <input className={classes.styledInput} value={name} onChange={(event) => {
                                                        setName(event.target.value);
                                                    }}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography className={classes.headerInput}>EMAIL</Typography>
                                                    <input className={classes.styledInput} value={mail} onChange={(event) => {
                                                        setMail(event.target.value);
                                                    }}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography className={classes.headerInput}>PHONE</Typography>
                                                    <input className={classes.styledInput} value={phone} onChange={(event) => {
                                                        setPhone(event.target.value);
                                                    }}/>
                                                </Grid>
                                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'end'}}>
                                                    <Button
                                                        className={classes.buttonLocations}
                                                        onClick={() => sendRequest()}
                                                    >
                                                        <Typography className={classes.buttonLocationsText}>
                                                            Contact Us
                                                        </Typography>
                                                        <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid container style={{paddingTop: '5vh'}}>
                                    <Grid item className={classes.sectionThree}>
                                    </Grid>
                                    <Grid item xs={6}/>
                                    <Grid item className={classes.sectionThree}>
                                        <Typography className={classes.footerHeader}>FOllOW OUR SOCIALS</Typography>
                                        <Typography variant='caption2' className={classes.subHeaderFooter}>Get inspired and stay up to date via our social channels</Typography>
                                        <div className={classes.socials}>
                                            <a href="src/modules/guest-v2/b2b/hero-footer/hero-footer.component">
                                                <img src={InstagramIcon} alt="Instagram icon"/>
                                            </a>
                                            <a href="src/modules/guest-v2/b2b/hero-footer/hero-footer.component">
                                                <img src={LinkedInIcon} alt="Linkedin icon"/>
                                            </a>
                                            <a href="src/modules/guest-v2/b2b/hero-footer/hero-footer.component">
                                                <img src={FacebookIcon} alt="Facebook icon"/>
                                            </a>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </SectionComponent>
        </>
    )
};

export default HeroFooterComponent;

// <Grid item xs={4} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingRight: '10%'}}>
//     <div style={{display: 'flex', flexDirection: 'row'}}>
//         <Typography className={classes.header}><a
//             style={{color: '#000'}}>INTERESTED IN AN </a>EX-DEMO VESPER BIKE?</Typography>
//     </div>
//     <Typography variant='caption2' className={classes.subHeader}>
//         Leave your contact details here and we will contct you about one of our vesper bikes.</Typography>
// </Grid>
// <Grid item xs={8} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', zIndex: 2}}>
//     <Grid container columnSpacing={2} rowSpacing={1}>
//         <Grid item xs={12}>
//             <Typography className={classes.headerInput}>NAAM</Typography>
//             <input className={classes.styledInput}/>
//         </Grid>
//         <Grid item xs={6}>
//             <Typography className={classes.headerInput}>EMAIL</Typography>
//             <input className={classes.styledInput}/>
//         </Grid>
//         <Grid item xs={6}>
//             <Typography className={classes.headerInput}>PHONE</Typography>
//             <input className={classes.styledInput}/>
//         </Grid>
//         <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
//             <Button
//                 className={classes.buttonLocations}
//                 component={Link}
//                 to='/become-partner'
//             >
//                 <Typography className={classes.buttonLocationsText}>
//                     Contact me
//                 </Typography>
//                 <img src={RightArrowIcon} style={{paddingLeft: 20}}/>
//             </Button>
//         </Grid>
//     </Grid>
// </Grid>
