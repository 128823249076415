import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-12)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '95vh',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      alignItems: 'flex-start',
    }
  },
  loginContainer: {
    backgroundColor: '#121212',
    [theme.breakpoints.down('xs')]: {
      padding: '88px 24px',
    },
    [theme.breakpoints.up('sm')]: {
      boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.32)',
      backgroundColor: '#1d1d1d',
      width: '450px',
      padding: '24px',
      marginTop: '110px',
    },
  },
  loginHeader: {
    margin: '0 0 32px 0'
  },
}));
