import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        maxWidth: 'var(--max-width-12)',
    },
    sectionThree: {
        display: 'flex',
        flexDirection: 'column !important',
        maxWidth: '25%',
        flexBasis: '25%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
            paddingBottom: 50
        },
    },
    sectionSix: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50%',
        flexBasis: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    bgBox: {
        width: '100%',
        paddingBottom: 50,
        [theme.breakpoints.down('sm')]: {
            height: 'unset'
        },
    },
    partnerUp: {
        marginTop: -150,
        width: '100%',
        backgroundColor: '#12B5AD',
        [theme.breakpoints.down('sm')]: {
            height: 'unset',
            padding: '100px 0 100px 0'
        },
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
            lineHeight: '1.25',
            letterSpacing: '-0.81px',
        },
        fontSize: '2rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    footerHeader: {
        fontSize: '1.5rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    socials: {
        display: 'flex',
        flexDirection: 'row'
    },
    headerSection: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    backgroundV: {
        fontSize: '60rem',
        letterSpacing: '1px !important',
        lineHeight: '0.1',
        position: 'absolute',
        right: -200,
        fontWeight: 'bold',
        color: '#099891',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        [theme.breakpoints.down('sm')]: {
            fontSize: '72rem',
            right: -300,
        },
    },
    background: {
        position: 'absolute',
        // height: '100%',
        width: 'calc(var(--max-width-12) - 48px)',
        display: 'flex',
        justifyContent: 'flex-end',
        overflow: "hidden",
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 32px)',
            marginTop: -100,
        },
    },
    grid: {
        paddingLeft: 80,
        paddingRight: 80,
        paddingTop: 50,
        paddingBottom: 50,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: 20
        },
    },
    leftSection: {
        flexBasis: '33.333333%',
        maxWidth: '33.333333%',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '5%',
        flexDirection: 'column !important',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            maxWidth: '100%',
        },
    },
    rightSection: {
        flexBasis: '66.666667%',
        maxWidth: '66.666667%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            maxWidth: '100%',
            justifyContent: 'left',
        },
    },
    subHeader: {
        color: '#fff',
        fontWeight: "normal",
        fontSize: 18,
        paddingBottom: 20
    },
    subHeaderFooter: {
        color: '#BEBEBE',
        fontWeight: "normal",
        fontSize: 18,
        margin: '5px 0 5px 0'
    },
    buttonLocations: {
        height: 'max-content',
        padding: '12px 16px',
        backgroundColor: '#000',
        borderRadius: 0,
        marginTop: 20,
        transition: 'transform 0.2s',
        "&&:hover": {
            backgroundColor: '#111111',
            transform: 'scale(1.2)',
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 'unset',
            alignSelf: 'flex-start'
        },
    },
    buttonLocationsText: {
        fontSize: '0.8rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic',
    },
    headerInput: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1.25',
            letterSpacing: '-0.81px',
        },
        fontSize: '2rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'var(--loafer-white)',
        textTransform: 'uppercase',
        fontStyle: 'italic'
    },
    styledInput: {
        width: '100%',
        height: 40,
        backgroundColor: '#1f1f1f',
        border: 'none',
        padding: 10,
        color: '#fff',
        "&&:active": {
            border: 'none',
        },
    },
}));
