import React from 'react';
import {useQuery} from "react-query";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Card, CardActionArea, CardContent, CardMedia} from "@material-ui/core";

import MainNav from "../../general/main-nav/main-nav.component";
import SectionComponent from "../../general/section/section.component";
import FooterComponent from "../../general/main-footer/footer.component";
import PageLoaderComponent from "../../general/page-loader/page-loader.component";
import {useRouter} from "../../../utils/router";
import {client} from '../../../utils/contentful'
import {useStyles} from "./locations.styles";

const Locations = () => {
  const classes = useStyles();
  const router = useRouter();

  const {isLoading, data} = useQuery(
    ['locations'],
    async () => {
      const res = await client.getEntries({content_type: 'partner'});
      return res.items;
    }
  );

  return (
    <>
      <MainNav/>
      <SectionComponent size='normal'>
        <Container maxWidth='xl' className={classes.container}>
          <Grid container classes={{root: classes.gridRoot}}>
            <Grid item xs={12}>
              <Typography variant='h1'>
                Locations
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.subHeader}>
                Go on an adventure while staying in one of our partner accommodations.
                Which area are you going to explore next?
              </Typography>
            </Grid>
            {isLoading ?
              <PageLoaderComponent/>
              :
              <Grid container item xs={12}>
                {
                  data.map((p, i) => (
                    <Grid key={i} item xs={12} sm={6} lg={4} classes={{root: classes.cardGrid}}>
                      <Card
                        className={classes.card}
                        onClick={() => router.push(`locations/${p.fields.partnerId}`)}
                      >
                        <CardActionArea>
                          <CardMedia
                            image={p.fields.partnerThumbnailImage.fields.file.url}
                            className={classes.cardMedia}
                          />
                          <CardContent classes={{root: classes.cardRoot}} className={classes.cardContent}>
                            <div className={classes.topCardContent}>
                              <Typography variant='h5'>
                                {p.fields.partnerCity}
                              </Typography>
                              <Typography variant='body2' className={classes.cardInfo}>
                                {p.fields.partnerIntro}
                              </Typography>
                            </div>
                            <Divider className={classes.divider}/>
                            <div className={classes.secondCardContent}>
                              <Typography variant='caption' className={classes.cardNameIntro}>
                                Book a stay and ride from
                              </Typography>
                              <div className={classes.cardNameIconContainer}>
                                <Typography variant='body2' className={classes.cardPartnerName}>
                                  {p.fields.partnerName}
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                }
              </Grid>
            }
          </Grid>
        </Container>
      </SectionComponent>
      <FooterComponent topFooter={true}/>
    </>
  )
};

export default Locations;
