import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-8)',
    paddingTop: '40px'
  },
  mainHeader: {
    margin: '40px 0 16px 0'
  },
  subHeader: {
    margin: '40px 0 0 0',
  },
  subInfo: {
    margin: '16px 0 0 0'
  },
  subInfo2: {
    margin: '24px 0 0 0'
  },
  richIconContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0 0 0'
  },
  richIconSubContainer: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '-40px',
    },
  },
  boxInfoGreen: {
    fontWeight: 'normal',
    color: 'var(--java-green)',
    margin: '0 5px 0 0',
    "&&:hover": {
      cursor: 'pointer',
      color: 'rgba(18,181,173,0.5)'
    },
  },
  richIconImage: {
    objectFit: 'contain',
    height: '80px',
    width: '80px',
    borderRadius: '5px'
  },
  richIconHeader: {
    margin: '0 0 4px 0'
  },
  richIconText: {
    [theme.breakpoints.up('lg')]: {
      minWidth: '790px'
    }
  },
}));
