import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-10)',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '128px !important'
    }
  },
  form: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
  payment: {
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
  sectionTitleReservation: {
    margin: '32px 0 18px 0',
  },
  sectionTitlePayment: {
    margin: '32px 0 18px 0',
  },
  nameFirst: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '24px'
    }
  },
  nameLast: {
    [theme.breakpoints.up('sm')]: {
      // marginLeft: '12px'
    }
  },
  rootText: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  autoComplete: {
    "& > *": {
      fontSize: '16px',
      backgroundColor: '#2e2e2e',
      color: 'var(--loafer-white-soft)',
    }
  },
  keyboardIcon: {
    width: '24px',
    height: '25px',
    objectFit: 'contain',
    margin: '0 25px 0 0',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
  },
  keyboardIconCountry: {
    margin: '0 18px 0 0',
    position: 'relative',
  },
  selectSvgHidden: {
    visibility: 'hidden',
  },
  extraDayDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  addDayButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  extraDayIcon: {
    "&&:hover": {
      opacity: '0.8'
    }
  },
  pricePaper: {
    borderRadius: 0,
    padding: '20px 24px',
    border: 'solid 1px #2f2f2f',
    backgroundColor: 'var(--aztec-black)',
  },
  priceDivider: {
    height: '1px',
    margin: '6px 0 16px',
    opacity: 0.12,
    backgroundColor: 'var(--loafer-white)',
  },
  totalTitle: {
    color: 'var(--loafer-white)',
  },
  radioButtonContainer: {
    margin: '0 0 24px 0',
  },
  radioButtonBox: {
    display: 'flex',
    alignItems: 'center',
  },
  radioText: {
    color: 'var(--loafer-white-soft)',
  },
  discountText: {
    margin: '12px 0',
    width: 'inherit',
  },
  termsPaper: {
    borderRadius: 0,
    border: 'solid 1px #2f2f2f',
    backgroundColor: 'var(----background-surface)',
    padding: '24px',
  },
  termsAccept: {
    alignItems: 'center',
  },
  termsLink: {
    margin: '0 0 0 5px',
    color: 'var(--java-green)',
    textDecoration: 'none'
  },
  rootFooter: {
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    padding: '24px',
    boxShadow: '1px 16px 16px 0 rgba(0, 0, 0, 0.32)',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column-reverse',
    marginTop: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
  button: {
    padding: '12px 16px',
    borderRadius: 0,
    margin: '0 0 16px 0',
    backgroundColor: 'var(--buttercup-yellow);',
    "&&:hover": {
      backgroundColor: 'rgba(241,162,9,0.5)'
    },
    [theme.breakpoints.up('lg')]: {
      width: '227px',
      margin: 0,
    },
  },
  buttonDisabled: {
    backgroundColor: 'rgba(249,252,246,0.32)',
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--midnight-forest-black)',
    display: 'flex',
  },
  cancelButton: {
    height: '48px',
    padding: '12px 16px',
    borderRadius: '0',
    border: 'solid 1px var(--java-green) !important',
    [theme.breakpoints.up('lg')]: {
      width: '102px',
    },
  },
  cancelButtonText: {
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    letterSpacing: '1.25px',
    color: 'var(--java-green)',
    display: 'flex',
  },
}));
