import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        maxWidth: 'var(--max-width-12)',
        padding: '0 24px 0 24px',
        marginBottom: 200
    },
    root: {
        padding: '0',
    },
}));
