import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'var(--max-width-12)',
    padding: '60px 24px',
    [theme.breakpoints.up('lg')]: {
      padding: '130px 24px 70px 24px',
    }
  },
  gridRoot: {
    justifyContent: 'center',
  },

  subHeader: {
    maxWidth: '628px',
    [theme.breakpoints.up('sm')]: {
      margin: '8px auto 28px 0'
    }
  },
  cardGrid: {
    padding: '20px 0 !important',
  },
  card: {
    boxShadow: '1px 2px 2px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#222222',
    [theme.breakpoints.up('sm')]: {
      margin: '0 24px 0 0'
    },
  },
  cardMedia: {
    border: 'solid 1px #2f2f2f',
    objectFit: 'contain',
    height: '0',
    paddingTop: '57.65%',
  },
  cardContent: {
    border: 'solid 1px #2f2f2f',
    backgroundColor: '#222222',
  },
  topCardContent: {
    padding: '24px 24px 16px 24px'
  },
  secondCardContent: {
    padding: '16px 24px 24px 24px'
  },
  cardRoot: {
    padding: 0
  },
  cardInfo: {
    marginTop: '8px',
    fontSize: '1rem',
  },
  divider: {
    height: '1px',
    backgroundColor: 'rgba(249,252,246, 0.12)',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    }
  },
  cardNameIntro: {
    margin: '5px 0',
    color: 'var(--loafer-white-soft)',
  },
  cardPartnerName: {
    margin: '4px 0 0 0',
    color: 'var(--loafer-white-soft)',
  },
  cardNameIconContainer: {
    display: 'flex'
  },
  cardPartnerIcon: {
    width: '24px',
    height: '24px',
    margin: '0 8px 8px 0',
    objectFit: 'contain',
  },
}));
