import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  contactRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    height: '1px',
    opacity: '0.12',
    backgroundColor: 'var(--loafer-white-soft)',
  },
  contactTitle: {
    width: '518px',
    height: '48.8px',
    fontSize: '2.4375em',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: '1.25',
    letterSpacing: '-0.3px',
    color: 'var(--loafer-white-soft)',
    textTransform: 'uppercase',
    margin: '60px 0 0 0',
    [theme.breakpoints.up('lg')]: {
      margin: '80px 0 0 0',
      textAlign: 'center'
    },
  },
  vesperTitle: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'var(--loafer-white-soft)',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '16px',
    }
  },
  contactDetails: {
    fontSize: '1.125em',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    color: 'var(--loafer-white-soft)',
  },
  contactDetailsAddress: {
    height: '24px',
  },
  contactDetailsGreen: {
    color: 'var(--java-green)',
  },
  contactDetailsBold: {
    margin: '24px 0 0 0',
    fontWeight: 'bold',
  },
  formGridContainer: {
    margin: 0,
  },
  cssLabel: {
    opacity: '0.6',
    fontSize: '1rem',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: '-0.25px',
  },
  cssOutlinedInput: {
    margin: '0 0 20px 0',
    borderRadius: '4px',
    width: '-webkit-fill-available',
  },
  notchedOutline: {
    borderWidth: '0.1px',
  },
  button: {
    height: '48px',
    margin: '0 0 80px 0',
    padding: '12px 16px',
    backgroundColor: 'var(--java-green)',
    "&&:hover": {
      backgroundColor: 'rgba(18,181,173,0.5)'
    },
    borderRadius: 0,
    width: '-webkit-fill-available',
    [theme.breakpoints.up('lg')]: {
      width: '75px',
    },
  },
  buttonText: {
    width: '43px',
    fontSize: '1rem',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 'normal',
    letterSpacing: '1.25px',
    textAlign: 'center',
    color: 'var(--midnight-forest-black)',
  },
}));
