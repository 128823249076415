import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Container, Typography, Box, Divider, Grid} from '@material-ui/core'

const RevenueCards = ({title, amount, color}) => {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth={'sm'} style={{height: '100%'}}>
                <Box className={classes.box} style={{backgroundColor: color}}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" style={{height: '100%'}}>
                        <Grid item xs className={classes.grid}>
                            <Typography variant={'h7'}>{title}</Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                        <Grid item xs className={classes.grid}>
                            <Typography variant={"h5"}>€ {amount}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )

}

const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: 'white',
        height: '2px',
        width: '80%',
        margin: 'auto'
    },
    box: {
        borderRadius: 10,
        height: '100%',
    },
    grid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%'
    }
}));
export default RevenueCards;
