import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './business.choser.styles';
import SectionComponent from "../../../general/section/section.component";
import bgImage from '../../../../assets/v2-pictures/DSC08352.jpg';
import { Container } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import { Link } from "../../../../utils/router";
import { RightArrowJavaGreen, RightArrowIcon } from "../../../../assets/v2-icons";
import Button from "@material-ui/core/Button";
import Pattern from '../../../../assets/pattern-transparent.svg'
import HeroMainNavNoPaddingComponent from "../hero-main-nav-no-padding/HeroMainNav.component";

import { useQuery } from "react-query";
import { client } from "../../../../utils/contentful";


const BusinessChoserComponent = () => {

    // Imports
    const classes = useStyles();

    // States
    const behindBackgroundRef = useRef();
    const [behindHeight, setBehindHeight] = useState(96);

    useEffect(() => {
        setBehindHeight(behindBackgroundRef.current.clientHeight);
    }, [behindBackgroundRef.current]);

    // Functions
    const { data } = useQuery(
        ['business-choser-desktop'],
        async () => {
            const res = await client.getEntries({ content_type: 'landingDesktop' });
            return res.items[0].fields;
        }
    );

    // Declarations

    return (
        <>
            <SectionComponent transparent={true} size='auto' bgImageOpacity={0.3}>
                <Container maxWidth={false} className={classes.container}>
                    <HeroMainNavNoPaddingComponent />
                    <div className={classes.divContainer}>



                        <SectionComponent transparent={true} size='auto' className={classes.b2cSection} bgImage={Pattern}>
                            <div className={classes.textSectionLeft}>
                                {/* <div style={{ backgroundColor: "green" }}> */}

                                <div style={{ height: 125 }}>
                                    <Typography className={classes.header}>Vesper</Typography>
                                </div>
                                <div className={classes.headerBox}>
                                    <Typography ref={behindBackgroundRef} className={classes.headerBoxTitle}>For business</Typography>
                                </div>
                                <div className={classes.subHeaderContainer} >
                                    <Typography variant='caption2' className={classes.subHeader}>
                                        {data?.businessSubtitle}
                                    </Typography>
                                </div>
                                <Button
                                    className={classes.buttonLocations}
                                    component={Link}
                                    to='/business'
                                >
                                    <Typography className={classes.buttonLocationsText}>
                                        Go to business website
                                    </Typography>
                                    <img src={RightArrowIcon} style={{ paddingLeft: 20 }} />
                                </Button>
                                {/* </div> */}
                            </div>
                        </SectionComponent>

                        <SectionComponent transparent={true} size='auto' zoom={1.2} objectPosition={'bottom right'} bgImage={data?.background.fields.file.url} className={classes.b2bSection}>
                            <div className={classes.textSectionRight}>
                                {/* <div className={classes.extraDiv}> */}
                                {/* <div style={{ backgroundColor: "blue" }}> */}
                                {/* <div className={classes.headerBoxRightEmpty}></div> */}
                                <div style={{ height: 125 }}>
                                </div>
                                <div className={classes.headerBoxRightBehind} style={{ position: 'relative' }}>
                                    <Typography className={classes.headerBoxTitleRight}>For consumers</Typography>
                                </div>
                                {/* <div className={classes.headerBoxRightBehind}>
                                        <div style={{ height: behindHeight }}></div>
                                    </div> */}
                                <div className={classes.subHeaderContainer}>
                                    <Typography variant='caption2' className={classes.subHeaderRight}>
                                        {data?.consumerSubtitle}
                                    </Typography>
                                </div>
                                <Button
                                    className={classes.buttonLocationsRight}
                                    component={Link}
                                    to='/shop'
                                >
                                    <Typography className={classes.buttonLocationsTextRight}>
                                        Go to shop
                                    </Typography>
                                    <img src={RightArrowJavaGreen} style={{ paddingLeft: 20 }} />
                                </Button>

                                {/* </div> */}
                                {/* </div> */}
                            </div>
                        </SectionComponent>



                    </div>
                </Container >
            </SectionComponent >
        </>
    )
};

export default BusinessChoserComponent;
