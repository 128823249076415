import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const RadioButton = withStyles({
  root: {
    color: 'var(--java-green)',
    '&$checked': {
      color: 'var(--java-green)',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default RadioButton;
