import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  itemOpenContainer: {
    transition: 'all 0.2s ease-in-out',
    margin: '16px 0 0 0',
    padding: '16px 24px',
    boxShadow: '1px 4px 4px 0 rgba(0, 0, 0, 0.32)',
    backgroundColor: '#333333',
    "&&:hover": {
      backgroundColor: '#333333',
    },
    overflow: 'hidden'
  },
  itemTransform: {
    [theme.breakpoints.up('sm')]: {
      // transform: 'scale(1.05)',
    }
  },
  listItemTextDiv: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1
  },
  itemQuestionText: {
    margin: '0 24px 0 0',
    "& > *": {
      fontWeight: '500',
    }
  },
  itemAnswerText: {
    marginTop: '16px',
  },
  arrowOpenClose: {
    width: '24px',
    height: '25px',
    margin: '1px 0 auto auto',
    objectFit: 'contain',
    zIndex: 1
  },
  header: {
    fontSize: '12rem',
    lineHeight: '0.75',
    fontWeight: 'bold',
    color: '#222222',
    textTransform: 'uppercase',
    fontStyle: 'italic',
    position: 'absolute',
    // top: '-110%',
    top: -70,
    right: -20,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14rem',
    },
  },
}));
