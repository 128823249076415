import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0 !important',
        display: 'flex !important',
        justifyContent: 'center',
        backgroundColor: '#303030',
        paddingLeft: 300
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: 200
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 100
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            padding: 0
        },
      marginLeft: 300,
    },
    root: {
        padding: '0',
    },
    headerBoxTitle: {
        fontSize: '2rem',
        textTransform: 'uppercase',
        fontFamily: "Kanit",
        fontWeight: 'bold',
        padding: '10px 0 10px 0',
    },
    subHeader: {
        color: '#BEBEBE',
        fontWeight: "normal",
        fontSize: 18,
    },
    leftTextBox: {
        justifyContent: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20%',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    listItem: {
        "&::marker": {
            color: 'var(--java-green)'
        }
    },
    redBox: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    divider: {
        height: 20,
        alignSelf: "baseline",
        [theme.breakpoints.down('sm')]: {
            marginTop: 40
        },
    },
    textSection: {
      maxWidth: '41.666667%',
      flexBasis: '41.666667%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
            padding: '20px 20px 120px 20px'
        },
    },
    photoSection: {
        maxWidth: '58.333333%',
        flexBasis: '58.333333%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    rightSection: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hotelPicture: {
        [theme.breakpoints.up('sm')]: {
            clipPath: 'polygon(10% 100%, 0 0, 100% 0, 100% 100%)',
        },
        width: '100%',
        height: 600,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: 300,
        },
    },
    hoverPicture: {
        position: 'absolute',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'unset',
        },
    },
    list: {
        paddingLeft: '20px !important'
    },
    dashboardPicture: {
        [theme.breakpoints.down('lg')]: {
            height: 300,
            marginLeft: -100
        },
        [theme.breakpoints.down('md')]: {
            height: 200,
            marginLeft: 0
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 200,
        },
        height: 450,
        marginLeft: -200,
        zIndex: 2
    },
    patternDiv: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: -100,
            bottom: '10vh',

        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            bottom: '15vh',
        },
        marginLeft: -200,
        zIndex: 1,
        position: 'absolute',
        bottom: '4vh',
        left: '-3vh'
    },
    pattern: {
        objectFit: 'contain',
        width: '7vw',
        aspectRatio: 1
    },
}));
