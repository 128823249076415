import React from 'react';
import {Route, useRouter} from "../../../utils/router";
import Locations from "../locations/locations.component";
import Location from "../location/location.component";

const LocationsSection = () => {
  const router = useRouter();

  return (
    <>
      <Route exact path={`${router.match.url}`} component={Locations}/>
      <Route exact path={`${router.match.url}/:id`} component={Location}/>
    </>
  )
};

export default LocationsSection;
