import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex !important',
        maxWidth: 'var(--max-width-12)',
        marginTop: 100,
        padding: '5vh 24px 5vh 24px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 50
        }
    },
    leftSection: {
        maxWidth: '50%',
        flexBasis: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    imageGallery: {
      marginRight: 50,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    rightSection: {
        maxWidth: '50%',
        flexBasis: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    listItem: {
        color: 'gray',
        "&::marker": {
            color: 'var(--java-green)'
        }
    },
    bgBox: {
        backgroundColor: '#fff',
    },
    root: {
        padding: '0',
    },
    thumbnailClass: {
        aspectRatio: 1.5
    },
    header: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.5rem',
            lineHeight: '1',
            letterSpacing: '2px !important',
        },
        fontSize: '3rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'black',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    vesperBlue: {
        color: '#12B5AD'
    },
    optionsSection: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        paddingBottom: 20
    },
    bikeBoxHeader: {
        paddingTop: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1',
            letterSpacing: '1px !important',
        },
        fontSize: '3rem',
        letterSpacing: '1px !important',
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: 'black',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        }
    },
    horizontalFlex: {
        display: 'flex',
        flexDirection: 'row'
    },
    subHeaderBlack: {
        fontWeight: "normal",
        fontSize: 18,
        color: '#000'
    },
    buttonLocations: {
        height: 30,
        padding: '12px 16px',
        margin: '0 10px 0 10px',
        backgroundColor: '#000',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#000'
        },
        width: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'max-content'
        }
    },
    buttonLocationsText: {
        fontSize: '0.8rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        maxWidth: 'max-content'
    },
    buttonLocationsOrder: {
        height: '48px',
        padding: '12px 16px',
        margin: '0 20px 0 0',
        backgroundColor: '#12b5ad',
        width: 'fit-content',
        borderRadius: 0,
        "&&:hover": {
            backgroundColor: '#0f9690'
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0',
            width: '100%'
        }
    },
    buttonLocationsTextOrder: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1.25px',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        paddingRight: 200,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
            flex: 1
        }
    },
}));
