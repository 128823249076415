import React, {useEffect} from 'react';
import PageLoaderComponent from "../../general/page-loader/page-loader.component";
import ScrollableList from "../../../atoms/scrollable-list/scrollable-list.component";
import {useQuery} from "react-query";
import axios from "axios";
import AdminDashboardBookingCard from "../admin-dashboard-booking-card/admin-dashboard-booking-card.component";
import {Typography} from "@material-ui/core";

const AdminDashboardBooking = ({timespan}) => {
    const {isLoading, data, refetch} = useQuery(
        ['dashboard-bookings-upcoming'],
        async () => {
            const res = await axios.get('/dashboard/get-upcoming-bookings', { params: { timespan: timespan }});
            return res.data.rows;
        }
    );

    useEffect(async () => {
        await refetch();
    }, [timespan]);

    return (
        <ScrollableList height="95%">
            {isLoading ?
                <PageLoaderComponent/>
                :
                <>
                    {data?.length === 0
                        ?
                            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography>Er zijn geen toekomstige boekingen geplaatst.</Typography>
                            </div>
                        :
                        <>
                            {data?.map((booking, index) => {
                                return <AdminDashboardBookingCard key={index} booking={booking}/>
                            })}
                        </>
                    }
                </>
            }
        </ScrollableList>
    )
}
export default AdminDashboardBooking;
