import React from 'react'
import axios from "axios";

import DeleteIcon from '@material-ui/icons/Delete'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Button,
  Typography,
  IconButton
} from "@material-ui/core";

import {useStyles} from './admin-toolbar.styles';
import {useSnackbar} from "../../../utils/snackbar";
import CreatePartnerForm from "../admin-create-partner-form/create-partner-form.component";

const TableToolbar = ({numSelected, selectedRowIds, data}) => {
  const classes = useStyles();
  const {setSnackbar} = useSnackbar();

  const selectedIds = Object.keys(selectedRowIds).map(i => parseInt(i));
  const selectedPartners = [];
  data?.forEach((row, index) => {
    selectedIds?.forEach(selectedRow => {
      index === selectedRow && selectedPartners?.push(data?.[index])
    })
  });

  const [openPartner, setOpenPartner] = React.useState(false)
  const [openDelete, setOpenDialog] = React.useState(false)

  const handlePartnerDialog = () => setOpenPartner(!openPartner);
  const handleDeleteDialog = () => setOpenDialog(!openDelete);

  const handleDeletePartners = () => {
    axios.delete('dashboard/delete-partners', {data: selectedPartners})
      .then((res) => {
        console.log(res);
        setSnackbar('Partner deleted.', 'success');
      })
      .catch((err) => {
        console.log(err);
        setSnackbar('Something went wrong, please try again.', 'error');
      });
    setOpenDialog(false);
  };

  return (
    <div className={classes.root}>
      <Button
        onClick={handlePartnerDialog}
        className={classes.button}
      >
        <Typography className={classes.buttonText}>
          Add partner
        </Typography>
      </Button>
      <Dialog
        maxWidth={false}
        open={openPartner}
        onClose={handlePartnerDialog}
      >
        <CreatePartnerForm handleDialog={handlePartnerDialog}/>
      </Dialog>
      {numSelected > 0 && (
        <div className={numSelected > 0 && `${classes.highlight}`}>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={handleDeleteDialog}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Dialog
        maxWidth={false}
        open={openDelete}
        onClose={handleDeleteDialog}
      >
        <div className={classes.dialogRoot}>
          <DialogTitle className={classes.dialogTitle}>Delete partner</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            Are you sure you want to delete the following partner(s)?
            {
              <ul>
                {
                  selectedPartners.map((p, index) => (
                    <li className={classes.partnerList} key={index}>{p.partner_name}</li>
                  ))
                }
              </ul>
            }
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={handleDeletePartners}
            >
              <Typography className={`${classes.buttonText} ${classes.buttonTextDelete}`}>
                Delete
              </Typography>
            </Button>
            <Button
              className={classes.button}
              onClick={handleDeleteDialog}
            >
              <Typography className={classes.buttonText}>
                Cancel
              </Typography>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}

export default TableToolbar
